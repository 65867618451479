import { observer } from 'mobx-react-lite';
import React, { useState } from 'react'
import { Equation } from 'react-equation';
import { Button, Container, Grid, Header, Label, Segment, Table } from 'semantic-ui-react'
import { IDamperRateOutput } from '../../app/models/DamperRateDto';
import { useStore } from '../../app/stores/Store';
import "./rates.css";


const heights = [
    { text: '12', value: '12' },
    { text: '14', value: '14' },
    { text: '16', value: '16' },
    { text: '18', value: '18' },
    { text: '20', value: '20' },
    { text: '24', value: '24' },
    { text: '28', value: '28' },
    { text: '32', value: '32' },
    { text: '36', value: '36' },
    { text: '40', value: '40' },
    { text: '44', value: '44' },
    { text: '48', value: '48' },
    { text: '54', value: '54' },
    { text: '60', value: '60' },
    { text: '66', value: '66' },
    { text: '72', value: '72' },
]
const widths = [
    { text: '12', value: '12' },
    { text: '14', value: '14' },
    { text: '16', value: '16' },
    { text: '18', value: '18' },
    { text: '20', value: '20' },
    { text: '24', value: '24' },
    { text: '28', value: '28' },
    { text: '32', value: '32' },
    { text: '36', value: '36' },
    { text: '40', value: '40' },
    { text: '44', value: '44' },
    { text: '48', value: '48' },
]

const ControlDamperRatesHome = () => {
    const { rateStore } = useStore();
    const {
        gettingRate,
        costOfSheet, setCostOfSheet,
        discountPercentage, setDiscountPercentage,
        width, setWidth,
        height, setHeight,
        expectedDeliveryFactor, setExpectedDeliveryFactor,
        salesCommission, setSalesCommission,
        getRateForControlDamper,
        damperRateCalc
    } = rateStore;

    const [quotes, setQuotes] = useState<IDamperRateOutput[]>([]);

    const computeRate = async () => {
        let rate = await getRateForControlDamper();
        if (rate !== undefined && rate !== null) {
            setQuotes([...quotes, rate]);
        }
    }

    return (
        <>
            <Container style={{ padding: '5em 0em' }}>
                <Header as='h2'>Rates Calculator For Control Dampers</Header>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment.Group>
                                <Segment>
                                    <p>Inputs </p>
                                </Segment>
                                <Segment.Group>
                                    <Table>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Width</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <select
                                                        value={width}
                                                        onChange={e => setWidth(e.target.value)}
                                                    >
                                                        {widths.map(o => (
                                                            <option key={o.value} value={o.value}>{o.text}</option>
                                                        ))}
                                                    </select>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>Height</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <select
                                                        value={height}
                                                        onChange={e => setHeight(e.target.value)}
                                                    >
                                                        {heights.map(o => (
                                                            <option key={o.value} value={o.value}>{o.text}</option>
                                                        ))}
                                                    </select>
                                                </Table.HeaderCell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.HeaderCell>Cost Of Galvanised Sheet</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <input type='text'
                                                        value={costOfSheet}
                                                        onChange={(e) => { setCostOfSheet(e.target.value) }}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>Discount Percentage</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <input type='text'
                                                        value={discountPercentage}
                                                        onChange={(e) => { setDiscountPercentage(e.target.value) }}
                                                    />
                                                </Table.HeaderCell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.HeaderCell>Expected Delivery Factor</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <input type='text'
                                                        value={expectedDeliveryFactor}
                                                        onChange={(e) => { setExpectedDeliveryFactor(e.target.value) }}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>Sales Commission</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <input type='text'
                                                        value={salesCommission}
                                                        onChange={(e) => { setSalesCommission(e.target.value) }}
                                                    />
                                                </Table.HeaderCell>
                                            </Table.Row>

                                        </Table.Header>

                                    </Table>

                                </Segment.Group>
                            </Segment.Group>

                        </Grid.Column>

                        <Grid.Column>

                            <Segment.Group>
                                <Segment textAlign='center'>
                                    <Button.Group>
                                        <Button positive loading={gettingRate} onClick={computeRate}>Add To Quote</Button>
                                        <Button.Or />
                                        <Button onClick={() => setQuotes([])}>Clear Quotes</Button>
                                    </Button.Group>

                                </Segment>
                            </Segment.Group>
                            {quotes !== null && quotes.length > 0 && (

                                <Table fixed celled selectable compact size='small' striped>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{ width: '10%' }} textAlign='center'>Id</Table.HeaderCell>
                                            <Table.HeaderCell textAlign='center'>Price</Table.HeaderCell>
                                            <Table.HeaderCell textAlign='center'>Width</Table.HeaderCell>
                                            <Table.HeaderCell textAlign='center'>Height</Table.HeaderCell>
                                            <Table.HeaderCell textAlign='center'>Calcs</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    {

                                        quotes.map((myquote: IDamperRateOutput, index) => (
                                            <Table.Row key={index} >
                                                <Table.Cell textAlign='center'>{(index + 1)}</Table.Cell>
                                                <Table.Cell textAlign='center'>
                                                    <Label color='red' key='1' size='large' basic>
                                                        {myquote.salesPrice}
                                                    </Label>
                                                </Table.Cell>
                                                <Table.Cell textAlign='center'>{myquote.width}</Table.Cell>
                                                <Table.Cell textAlign='center'>{myquote.height}</Table.Cell>
                                                <Table.Cell textAlign='center'>Base Rate : {myquote.baseRate}<br /> Cost Of Sheet:{myquote.costOfSheet}</Table.Cell>
                                            </Table.Row>
                                        ))
                                    }
                                </Table>



                            )}


                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment.Group>
                                <Segment color='blue'>
                                    <p>Formulae</p>

                                </Segment>
                                <Segment color='blue'>
                                    <p>
                                        <Equation
                                            value='Discount Adjuster = Factor Based on Grid Quadrant'
                                        />
                                    </p>
                                    <p>
                                        <Equation
                                            value='DiscountFactor = 1 - (Discount Percentage / 100)'
                                        />
                                    </p>
                                    <p>
                                        <Equation
                                            value='Calculated Value = (Base Rate / 0.53) X Cost Of Sheet'
                                        />
                                    </p>
                                    <p>
                                        <Equation
                                            value='Calculated Value = Calculated Value + DiscountFactor'
                                        />
                                    </p>
                                    <p>
                                        <Equation
                                            value='Calculated Value = Calculated Value + Calculated Value ( Sales Commission / 100)'
                                        />
                                    </p>
                                    <p>
                                        <Equation
                                            value='Calculated Value = Calculated Value + Calculated Value ( Expected Delivery Factor / 100)'
                                        />
                                    </p>
                                    <p>
                                        <Equation
                                            value='Calculated Value = Calculated Value x Discount Adjuster'
                                        />
                                    </p>
                                </Segment>
                            </Segment.Group>

                        </Grid.Column>

                        <Grid.Column>
                            {damperRateCalc && (
                                <Segment.Group>
                                    <Segment color='green'>
                                        <p>Applied Calculations</p>

                                    </Segment>
                                    <Segment color='green'>
                                        <p>
                                            Discount Adjuster = {damperRateCalc?.gridFactor.toPrecision(3)}
                                        </p>
                                        <p>
                                            DiscountFactor = {damperRateCalc?.discountFactor.toPrecision(3)}
                                        </p>
                                        <p>
                                            BaseRate ={damperRateCalc?.baseRate}, Cost Of Sheet = {damperRateCalc?.costOfSheet.toPrecision(3)}
                                        </p>
                                        <p>
                                            Sales Commission = {damperRateCalc?.salesCommission.toPrecision(3)}
                                        </p>
                                        <p>
                                            Expected Delivery Factor = {damperRateCalc?.expectedDeliveryFactor.toPrecision(3)}
                                        </p>
                                    </Segment>
                                </Segment.Group>

                            )}


                        </Grid.Column>
                    </Grid.Row>

                </Grid>
            </Container>


        </>

    )
}

export default observer(ControlDamperRatesHome)
