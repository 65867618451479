import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react'
import { getTaxesForOntarioHardCoded, Taxes } from '../../../app/models/Taxes';
import { useStore } from '../../../app/stores/Store';



const DocumentTaxes = () => {
    const { salesOrderStore/*, modalStore*/ } = useStore();
    const { loadingSalesOrder, currentSalesOrder, runningTotal, getNetAmount, computeTotals, updateSalesOrder } = salesOrderStore;

    const [taxdata/*, setTaxData*/] = useState<Taxes>(getTaxesForOntarioHardCoded());

    useEffect(() => {
        if (currentSalesOrder !== undefined && currentSalesOrder !== null)
            computeTotals(currentSalesOrder.salesOrderDetails);
    }, [computeTotals, currentSalesOrder])

    if (currentSalesOrder === null || loadingSalesOrder) {
        return <Fragment />
    }


    return (
        <Fragment>

            <div className="dtTaxesFlexBox">


                {currentSalesOrder.so_invoice_no ? (
                    <div className="dtCommentsGrid">
                        <div className="dtGridHeading">Remarks:</div>
                        <div className="dtGridTextArea">
                            <textarea
                                readOnly={false}
                                name='so_remarks'
                                rows={6}
                                cols={50}
                                value={currentSalesOrder.so_remarks}
                                onChange={(e) => updateSalesOrder(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className="dtGridHeading">Client Notes:</div>
                        <div className="dtGridTextArea">
                            <textarea
                                readOnly={false}
                                rows={6}
                                cols={50}
                                className='tdRemarksTextArea'
                                name='so_client_notes'
                                value={currentSalesOrder.so_client_notes}
                                onChange={(e) => updateSalesOrder(e.target.name, e.target.value)}
                            />
                        </div>
                    </div>

                ) : (
                    <div className="dtCommentsGrid">
                        <div className="dtGridHeading">Remarks:</div>
                        <div className="dtGridTextArea">
                            <textarea
                                name='so_remarks'
                                rows={6}
                                cols={50}
                                value={currentSalesOrder.so_remarks}
                                onChange={(e) => updateSalesOrder(e.target.name, e.target.value)}
                            />
                        </div>
                        <div className="dtGridHeading">Client Notes:</div>
                        <div className="dtGridTextArea">
                            <textarea
                                rows={6}
                                cols={50}
                                className='tdRemarksTextArea'
                                name='so_client_notes'
                                value={currentSalesOrder.so_client_notes}
                                onChange={(e) => updateSalesOrder(e.target.name, e.target.value)}
                            />
                        </div>
                    </div>

                )}

                <div className="dtTaxTable">
                    <div className="dtCell dtHeading">Subtotal</div>
                    <div className="dtCell dtValue"> <p><b>{runningTotal}</b></p></div>
                    <div className="dtCell dtHeading">HST [13%]</div>
                    <div className="dtCell dtValue"><p>{currentSalesOrder.so_hst_amount}</p></div>
                    <div className="dtCell dtHeading">GST [0%]</div>
                    <div className="dtCell dtValue"> <p>{currentSalesOrder.so_gst_amount}</p></div>
                    <div className="dtCell dtHeading">PST [0%]</div>
                    <div className="dtCell dtValue"> <p>{currentSalesOrder.so_pst_amount}</p></div>
                    <div className="dtCell dtHeading">QST [0%]</div>
                    <div className="dtCell dtValue"><p>{currentSalesOrder.so_qst_amount}</p></div>
                    <div className="dtCell dtHeading">Total Amount</div>
                    <div className="dtCell dtValue"> <p>$ {getNetAmount(taxdata)}</p></div>
                </div>
            </div>

        </Fragment>
    )
}

export default observer(DocumentTaxes)
