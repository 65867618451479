import { observer } from 'mobx-react-lite'
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Grid, Header, Icon, Label, Segment, Step, Table, Input } from 'semantic-ui-react';
import { IInventoryItem, IInventoryMovement } from '../../../app/models/Inventory';
import { useStore } from '../../../app/stores/Store';
import TrackingTablePlaceholder from './TrackingTablePlaceholder';


interface IProps {
    inventoryItem: IInventoryItem | null;
    onCancel: () => void;
}

const InventoryDetailForm: React.FC<IProps> = ({ inventoryItem, onCancel }) => {
    const { inventoryStore } = useStore();
    const { inventory, loadingInventory } = inventoryStore;

    const [selectedMovements, setSelectedMovements] = useState<IInventoryMovement[] | null>(null);

    const [selectedMovementType, setSelectedMovementType] = useState<string>('Purchase');



    useEffect(() => {
        let movements: IInventoryMovement[] = [];
        if (inventory !== null) {
            for (let index = 0; index < inventory?.inventoryMovements?.length; index++) {
                const movement = inventory.inventoryMovements[index];
                if (movement.transactionType === "Purchase") {
                    movements = [...movements, movement];
                }
            }
        }
        setSelectedMovements(movements);
    }, [inventory, setSelectedMovements])

    const setMovementType = (movementType: string) => {
        setSelectedMovementType(movementType);
        setMovements(movementType);
    }

    const setMovements = (transactionType: string) => {
        if (inventory === null || inventory === undefined) return;

        let movements: IInventoryMovement[] = [];

        for (let index = 0; index < inventory.inventoryMovements.length; index++) {
            const movement = inventory.inventoryMovements[index];
            if (movement.transactionType === transactionType) {
                movements = [...movements, movement];
            }
        }
        setSelectedMovements(movements);
    }

    return (
        <Fragment>
            <Fragment>
                <Button icon color='red' style={{ 'position': 'relative', 'zIndex': '99' }} floated='right'
                    onClick={onCancel}>
                    <Icon name='close' />
                    Cancel
                </Button>
            </Fragment>

            <Header>
                <Label as='a' color='orange' ribbon>
                    Inventory Item # : {inventoryItem && inventoryItem.specs}
                </Label>
            </Header>
            <Grid>
                <Grid.Row stretched>
                    <Grid.Column width={5}>
                        <Segment color='teal'>
                            <Table basic='very' celled collapsing>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>
                                            <Header as='h4' image>
                                                <Header.Content>
                                                    Part No
                                                    <Header.Subheader>Read Only For Now</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                        </Table.Cell>
                                        <Table.Cell><Input size='mini' value={inventory?.partNo} /></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            <Header as='h4' image>
                                                <Header.Content>
                                                    Details
                                                    <Header.Subheader>Read Only For Now</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                        </Table.Cell>
                                        <Table.Cell><Input size='mini' value={inventory?.details} /></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            <Header as='h4' image>
                                                <Header.Content>
                                                    Description
                                                    <Header.Subheader>Read Only For Now</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                        </Table.Cell>
                                        <Table.Cell><Input size='mini' value={inventory?.description} /></Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>

                        </Segment>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <Segment color='teal'>

                            <Table basic='very' celled collapsing>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>
                                            <Header as='h4' image>
                                                <Header.Content>
                                                    Specifications
                                                    <Header.Subheader>Read Only For Now</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                        </Table.Cell>
                                        <Table.Cell><Input size='mini' value={inventory?.specs} /></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            <Header as='h4' image>
                                                <Header.Content>
                                                    Unit Of Measure
                                                    <Header.Subheader>Read Only For Now</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                        </Table.Cell>
                                        <Table.Cell><Input size='mini' value={inventory?.unitOfMeasure} /></Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <Segment color='teal'>

                            <Table basic='very' celled collapsing>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>
                                            <Header as='h4' image>
                                                <Header.Content>
                                                    Estimated Quantity
                                                    <Header.Subheader>Read Only For Now</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                        </Table.Cell>
                                        <Table.Cell><Input size='mini' value={inventory?.estimatedQuantity} /></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            <Header as='h4' image>
                                                <Header.Content>
                                                    Actual Quantity
                                                    <Header.Subheader>Read Only For Now</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                        </Table.Cell>
                                        <Table.Cell><Input size='mini' value={inventory?.actualQuantity} /></Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>
                                            <Header as='h4' image>
                                                <Header.Content>
                                                    Ordered Quantity
                                                    <Header.Subheader>Read Only For Now</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                        </Table.Cell>
                                        <Table.Cell><Input size='mini' value={inventory?.openOrderedQuantity} /></Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row textAlign='center'>
                    <Grid.Column width={4}>
                        <Step.Group vertical>
                            {
                                <Step active={selectedMovementType === "Purchase" ? true : false}
                                    onClick={() => setMovementType("Purchase")}>
                                    <Icon name='print' />
                                    <Step.Content>
                                        <Step.Title>Purchase Orders</Step.Title>
                                        <Step.Description>{inventoryItem?.updateDate.toString().split('T')[0]}</Step.Description>

                                    </Step.Content>
                                </Step>
                            }
                            {
                                <Step active={selectedMovementType === "GoodsRecieved" ? true : false}
                                    onClick={() => setMovementType("GoodsRecieved")}>
                                    <Icon name='print' />
                                    <Step.Content>
                                        <Step.Title>Good Recieved Notes</Step.Title>
                                        <Step.Description>No Details Found</Step.Description>

                                    </Step.Content>
                                </Step>
                            }
                            {
                                <Step active={selectedMovementType === "InventoryUsed" ? true : false}
                                    onClick={() => setMovementType("InventoryUsed")}>
                                    <Icon name='print' />
                                    <Step.Content>
                                        <Step.Title>Used Inventory</Step.Title>
                                        <Step.Description>No Details Found</Step.Description>

                                    </Step.Content>
                                </Step>
                            }
                        </Step.Group>

                    </Grid.Column>
                    <Grid.Column width={12}>

                        {loadingInventory ?
                            (
                                <TrackingTablePlaceholder />
                            ) :
                            (
                                <Table fixed celled selectable compact size='small' striped>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Trasaction</Table.HeaderCell>
                                            <Table.HeaderCell>Supplier</Table.HeaderCell>
                                            <Table.HeaderCell>Rate</Table.HeaderCell>
                                            <Table.HeaderCell>Quantity</Table.HeaderCell>
                                            <Table.HeaderCell>User Name</Table.HeaderCell>
                                            <Table.HeaderCell>Date</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {selectedMovements?.map((movement) => (
                                            <Table.Row key={movement.id}>
                                                <Table.Cell>{movement.transactionType}</Table.Cell>
                                                <Table.Cell>{movement.supplierName}</Table.Cell>
                                                <Table.Cell>{movement.supplierRate}</Table.Cell>
                                                <Table.Cell>{movement.quantity}</Table.Cell>
                                                <Table.Cell>{movement.userName}</Table.Cell>
                                                <Table.Cell>{movement.updateDate.toString().split('T')[0]}</Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            )}


                    </Grid.Column>
                </Grid.Row>

            </Grid >

        </Fragment >

    )
}

export default observer(InventoryDetailForm)
