import { observer } from 'mobx-react-lite'
import React, { Fragment, useState } from 'react'
import { Container, Grid, Icon, Segment, Step, Table } from 'semantic-ui-react'
import { useStore } from '../../app/stores/Store'
import CostControlDamper from './costproducts/CostControlDamper'
import CostDynamicDamper from './costproducts/CostDynamicDamper'
import CostFireDamper from './costproducts/CostFireDamper'
import CostFireSmokeDamper from './costproducts/CostFireSmokeDamper'
import CostQuotes from './costproducts/CostQuotes'
import CRD from './costproducts/CRD'
import Doors from './costproducts/Doors'
import Louvers from './costproducts/Louvers'
import RCD from './costproducts/RCD'
import Silencers from './costproducts/Silencers'
import NewCostFireDamper from './costproducts/NewCostFireDamper'
import NewCostFireSmokeDamper from './costproducts/NewCostFireSmokeDamper'

const CostCalculator = () => {
    const { costingStore } = useStore();
    const {
        currentCalculation
    } = costingStore;

    const [selectedCalculatorType, setSelectedCalcularType] = useState<string>('FireDamper');

    const getCalculatorInput = () => {
        if (selectedCalculatorType === "FireDamper")
            return <CostFireDamper />
        else if (selectedCalculatorType === "NewFireDamper")
            return <NewCostFireDamper />
        else if (selectedCalculatorType === "ControlDamper")
            return <CostControlDamper />
        else if (selectedCalculatorType === "DynamicDamper")
            return <CostDynamicDamper />
        else if (selectedCalculatorType === "FireSmokeDamper")
            return <CostFireSmokeDamper />
        else if (selectedCalculatorType === "NewFireSmokeDamper")
            return <NewCostFireSmokeDamper />
        else if (selectedCalculatorType === "Doors")
            return <Doors />
        else if (selectedCalculatorType === "Louvers")
            return <Louvers />
        else if (selectedCalculatorType === "CRD")
            return <CRD />
        else if (selectedCalculatorType === "RCD")
            return <RCD />
        else if (selectedCalculatorType === "Silencers") {
            return <Silencers />
        }
        return <CostFireDamper />
    }

    const GetRounded = (value: number) => {
        return Math.round((value + Number.EPSILON) * 100) / 100;
    }


    return (
        <Fragment>
            <Grid columns={3} style={{ padding: '4em 0em' }} divided>
                <Grid.Row textAlign='center'>
                    <Grid.Column width={3}>
                        <Container style={{ margin: '5em 0em' }}>
                            <Step.Group vertical>
                                {
                                    <Step
                                        active={selectedCalculatorType === "FireDamper" ? true : false}
                                        onClick={() => setSelectedCalcularType("FireDamper")}>
                                        <Step.Content>
                                            <Step.Title><Icon name='calculator' />Fire Damper</Step.Title>
                                        </Step.Content>
                                    </Step>
                                }
                                {
                                    <Step active={selectedCalculatorType === "DynamicDamper" ? true : false}
                                        onClick={() => setSelectedCalcularType("DynamicDamper")}>
                                        <Step.Content>
                                            <Step.Title><Icon name='industry' />Dynamic Damper</Step.Title>
                                        </Step.Content>
                                    </Step>
                                }
                                {
                                    <Step active={selectedCalculatorType === "ControlDamper" ? true : false}
                                        onClick={() => setSelectedCalcularType("ControlDamper")}>
                                        <Step.Content>
                                            <Step.Title><Icon name='modx' />Control Damper</Step.Title>
                                        </Step.Content>
                                    </Step>
                                }
                                {
                                    <Step
                                        active={selectedCalculatorType === "NewFireDamper" ? true : false}
                                        onClick={() => setSelectedCalcularType("NewFireDamper")}>
                                        <Step.Content>
                                            <Step.Title><Icon name='tint' color='orange' />* New Fire Damper</Step.Title>
                                        </Step.Content>
                                    </Step>
                                }
                                {
                                    <Step active={selectedCalculatorType === "NewFireSmokeDamper" ? true : false}
                                        onClick={() => setSelectedCalcularType("NewFireSmokeDamper")}>
                                        <Step.Content>
                                            <Step.Title><Icon name='fire' color='orange' />*FireSmoke Damper</Step.Title>
                                        </Step.Content>
                                    </Step>
                                }
                                {
                                    <Step active={selectedCalculatorType === "FireSmokeDamper" ? true : false}
                                        onClick={() => setSelectedCalcularType("FireSmokeDamper")}>
                                        <Step.Content>
                                            <Step.Title><Icon name='gripfire' />FireSmoke Damper</Step.Title>
                                        </Step.Content>
                                    </Step>
                                }
                                {
                                    <Step active={selectedCalculatorType === "Doors" ? true : false}
                                        onClick={() => setSelectedCalcularType("Doors")}>
                                        <Step.Content>
                                            <Step.Title><Icon name='book' />Doors</Step.Title>
                                        </Step.Content>
                                    </Step>
                                }
                                {
                                    <Step active={selectedCalculatorType === "CRD" ? true : false}
                                        onClick={() => setSelectedCalcularType("CRD")}>
                                        <Step.Content>
                                            <Step.Title><Icon name='bullseye' />CRD</Step.Title>
                                        </Step.Content>
                                    </Step>
                                }
                                {
                                    <Step active={selectedCalculatorType === "RCD" ? true : false}
                                        onClick={() => setSelectedCalcularType("RCD")}>
                                        <Step.Content>
                                            <Step.Title><Icon name='chess board' />RCD</Step.Title>
                                        </Step.Content>
                                    </Step>
                                }
                                {
                                    <Step active={selectedCalculatorType === "Louvers" ? true : false}
                                        onClick={() => setSelectedCalcularType("Louvers")}>
                                        <Step.Content>
                                            <Step.Title><Icon name='grid layout' />Louvers</Step.Title>
                                        </Step.Content>
                                    </Step>
                                }
                                {
                                    <Step active={selectedCalculatorType === "Silencers" ? true : false}
                                        onClick={() => setSelectedCalcularType("Silencers")}>
                                        <Step.Content>
                                            <Step.Title><Icon name='mute' />Silencers</Step.Title>
                                        </Step.Content>
                                    </Step>
                                }
                            </Step.Group>
                        </Container>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        {getCalculatorInput()}
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <CostQuotes />
                    </Grid.Column>
                </Grid.Row>

                {currentCalculation && (
                    <Grid.Row>
                        <Grid.Column width={3}>&nbsp;</Grid.Column>

                        <Grid.Column width={5}>
                            <Segment.Group>
                                <Segment color='green'>
                                    <p>Calculation CheatSheet For {currentCalculation.selectedProduct} ({currentCalculation.width} X {currentCalculation.height}) </p>

                                    <p>Total Cost of <u>{GetRounded(currentCalculation.totalCost)} </u></p>


                                </Segment>
                                <Segment color='green'>
                                    <Table>
                                        {currentCalculation.costBreakups.map((costBreakup, i) => (
                                            <Table.Row key={i}>
                                                <Table.Cell>
                                                    {costBreakup.factorName}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {GetRounded(costBreakup.factorValue)}
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table>
                                </Segment>
                            </Segment.Group>
                        </Grid.Column>

                    </Grid.Row>

                )}


            </Grid >
        </Fragment >

    )
}

export default observer(CostCalculator)
