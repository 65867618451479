import React, { Fragment } from 'react'
import { Placeholder, Segment } from 'semantic-ui-react'

const SalesOrderDetailsPlaceHolder = () => {
    return (
        <Fragment>
            <Segment.Group>
                <Segment style={{ minHeight: 150 }}>
                    <Placeholder>
                        <Placeholder.Header image>
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Header>
                        <Placeholder.Paragraph>
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Paragraph>
                    </Placeholder>
                </Segment>
                <Segment style={{ minHeight: 10 }}>
                    <Placeholder>
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder>
                </Segment>
                <Segment style={{ minHeight: 250 }}>
                    <Placeholder>
                        <Placeholder>
                            <Placeholder.Header image>
                                <Placeholder.Line length='medium' />
                                <Placeholder.Line length='full' />
                            </Placeholder.Header>
                            <Placeholder.Paragraph>
                                <Placeholder.Line length='full' />
                                <Placeholder.Line length='medium' />
                            </Placeholder.Paragraph>
                            <Placeholder.Paragraph>
                                <Placeholder.Line length='full' />
                                <Placeholder.Line length='medium' />
                            </Placeholder.Paragraph>
                            <Placeholder.Paragraph>
                                <Placeholder.Line length='full' />
                                <Placeholder.Line length='medium' />
                            </Placeholder.Paragraph>
                        </Placeholder>
                        <Placeholder.Paragraph>
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Paragraph>
                    </Placeholder>
                </Segment>
                <Segment style={{ minHeight: 50 }}>
                    <Placeholder>
                        <Placeholder>
                            <Placeholder.Header image>
                                <Placeholder.Line length='medium' />
                                <Placeholder.Line length='full' />
                            </Placeholder.Header>
                        </Placeholder>
                        <Placeholder.Paragraph>
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Paragraph>
                    </Placeholder>
                </Segment>
                <Segment secondary style={{ minHeight: 70 }}>
                    <Placeholder>
                        <Placeholder.Line />
                        <Placeholder.Line />
                    </Placeholder>
                </Segment>
            </Segment.Group>

        </Fragment>
    )
}

export default SalesOrderDetailsPlaceHolder
