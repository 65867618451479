import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react'
import { Label } from 'semantic-ui-react';
import { useStore } from '../../../app/stores/Store';

const DocumentHeaderLogo = () => {
    const { salesOrderStore } = useStore();
    const { loadingSalesOrder, currentSalesOrder } = salesOrderStore;

    if (currentSalesOrder === null || loadingSalesOrder) {
        return <Fragment />
    }
    return (
        <div className='hlogo'>
            <img
                className='hlogo__imagestyle'
                src='/assets/logo.png'
                alt='BriskMfg Inc.'
            />
            {currentSalesOrder.so_invoice_no && (
                <Label className='hlogo__documentNumber' color='blue'>{currentSalesOrder.so_invoice_no}</Label>
            )}
        </div>
    )
}

export default observer(DocumentHeaderLogo)
