import React, { Fragment } from 'react';
import { Container, Segment, Header, Image, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import LoginForm from '../user/LoginForm';
import RegisterForm from '../user/RegisterForm';
import { useStore } from '../../app/stores/Store';

const HomePage = () => {
    const { userStore, modalStore } = useStore();
    const { isLoggedIn, user } = userStore;
    const { openModal } = modalStore;

    return (
        <Segment inverted textAlign='center' vertical className='masthead'>
            <Container text>
                <Image size='massive' src='/assets/logo.png' alt='logo' style={{ marginBotton: 12, borderRadius: '2%' }} />
                {
                    /* <Header inverted as='h1'>
                            Brisk Mfg
                        </Header> */
                }

                {isLoggedIn ?
                    (
                        <Fragment>
                            <Header as='h2' inverted content={`Welcome back, ${user!.displayname}`} />
                            <Button as={Link} to='/sales' size='huge' inverted>
                                Back To Sales Orders
                            </Button>
                        </Fragment>
                    )
                    :
                    (
                        <Fragment>
                            <Header as='h2' inverted content='Welcome to Brisk' />
                            <Button onClick={() => openModal(<LoginForm />)} size='huge' inverted>
                                Login
                            </Button>
                            <Button onClick={() => openModal(<RegisterForm />)} to='/register' size='huge' inverted>
                                Register
                            </Button>
                        </Fragment>
                    )
                }


            </Container>
        </Segment>
    )
}

export default HomePage
