import React, { Fragment } from 'react'
import { Icon, Table, Button, ButtonGroup, Pagination } from 'semantic-ui-react'
import CustomerListPlaceholder from './CustomerListPlaceholder';
import CustomerTabPane from '../tabs/CustomerTabPane';
import { observer } from 'mobx-react-lite';
import { ILegacyCustomer } from '../../../../app/models/Customer';
import { isNumber } from 'util';
import { useStore } from '../../../../app/stores/Store';

const CustomerSearchResults = () => {
    const { customerStore, deleteModalStore, searchStore, commonStore } = useStore();
    const { loadingInitial, loadingSearch, customers, loadCustomersOnPage, deleteCustomer } = customerStore;
    const { showDeleteModal, closeDeleteModal } = deleteModalStore;
    const { totalPages, currentPageNo } = searchStore;
    const { addTabPane, tabPanes } = commonStore;

    const addTab = (id: string, tabName: string) => {
        console.log('add tab clicked')
        addTabPane(tabName, {
            menuItem: tabName,
            render: () => <CustomerTabPane alias={tabName} customerid={id} tabCount={tabPanes.length} />
        });
    }

    const handleDelete = (e: any, id: number) => {
        e.stopPropagation();

        showDeleteModal('Delete Customer', 'Are you sure you want to Delete this Customer', () => {
            deleteCustomer(id);
            closeDeleteModal();

        });
        return true;
    }
    const handlePageChange = (pageNo: string | number | undefined) => {
        if (isNumber(pageNo))
            loadCustomersOnPage(pageNo - 1)
    }
    if (loadingSearch || loadingInitial) return <CustomerListPlaceholder />

    return (
        <Fragment>
            <Table fixed celled selectable compact size='small' striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell style={{ width: '10%' }}>Alias</Table.HeaderCell>
                        <Table.HeaderCell>Company Name</Table.HeaderCell>
                        <Table.HeaderCell>Address</Table.HeaderCell>
                        <Table.HeaderCell>Shipping Address</Table.HeaderCell>
                        <Table.HeaderCell>Email / Phone No</Table.HeaderCell>
                        <Table.HeaderCell style={{ width: '5%' }}>Delete</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        customers.map((customer: ILegacyCustomer) => (
                            <Table.Row key={customer.yellowpages_id} onClick={() => addTab(`${customer.yellowpages_id}`, customer.name_alias)} >
                                <Table.Cell>{customer.name_alias}</Table.Cell>
                                <Table.Cell>{customer.fullname}</Table.Cell>
                                <Table.Cell>{customer.address}</Table.Cell>
                                <Table.Cell>{customer.shipping_address}</Table.Cell>
                                <Table.Cell>{customer.phone1}<br />{customer.cell ? 'Cell -' + customer.cell : ''}<br />{customer.phone2}</Table.Cell>
                                <Table.Cell>
                                    <ButtonGroup>
                                        <Button basic icon color='blue' onClick={(e) => handleDelete(e, customer.yellowpages_id)}>
                                            <Icon name='align justify' />
                                        </Button>
                                    </ButtonGroup>
                                </Table.Cell>
                            </Table.Row>

                        ))
                    }
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan='6'>
                            <Pagination
                                floated='right'
                                defaultActivePage={currentPageNo + 1}
                                totalPages={totalPages}
                                onPageChange={(e, data) => handlePageChange(data.activePage)} />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>

            </Table>
        </Fragment>
    )
}

export default observer(CustomerSearchResults)
