import { debounce } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect } from 'react'
import { Segment } from 'semantic-ui-react';
import { useStore } from '../../../../../app/stores/Store';


interface IProps {
    customerName: string;
    isForSoldToAddress: boolean;
    setAddress: (value: string) => void;
}

const CustomerAddress: React.FC<IProps> = ({ customerName, isForSoldToAddress, setAddress }) => {
    const { modalStore, customerStore } = useStore();
    const { closeModal } = modalStore;
    const { loadingAddres, loadSoldToAddressForCustomer, loadShipToAddressForCustomer, loadedAddresses, resetLoadedAddress, filterAddress } = customerStore;
    useEffect(() => {
        resetLoadedAddress();
        if (isForSoldToAddress) {
            loadSoldToAddressForCustomer(customerName);
        } else {
            loadShipToAddressForCustomer(customerName);
        }
    }, [loadSoldToAddressForCustomer, loadShipToAddressForCustomer, isForSoldToAddress, customerName, resetLoadedAddress])

    const onAddressSelected = (value: string) => {
        setAddress(value);
        closeModal();
    }

    const performantDebounce = React.useRef(
        debounce(async (criteria) => {
            await filterAddress(customerName, criteria, isForSoldToAddress);
        }, 1000)
    ).current;

    function handleChange(e: any) {
        let searchValue = e?.target?.value;
        if (searchValue === null || searchValue === undefined || searchValue === "") {
            searchValue = "";
        }
        performantDebounce(searchValue);
    }

    return (
        <Segment loading={loadingAddres}>

            {!Array.isArray(loadedAddresses) || loadedAddresses === undefined || loadedAddresses.length === 0 ? (
                <>
                    <textarea
                        id='textarea'
                        style={{ paddingLeft: '10px', paddingTop: '8px' }}
                        onChange={handleChange}
                    />
                    <div>No Data Found!</div>
                </>
            ) : (
                <Fragment>

                    <textarea
                        id='textarea'
                        style={{ paddingLeft: '10px', paddingTop: '8px' }}
                        onChange={handleChange}
                    />

                    {
                        loadedAddresses.map((address, index) => (
                            <Segment key={index} onClick={() => onAddressSelected(address)}>
                                <div style={{ whiteSpace: "pre-wrap" }}>{address}</div>
                            </Segment>
                        ))
                    }
                </Fragment>

            )}

        </Segment >
    )
}

export default observer(CustomerAddress)
