import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { IDamperRateOutput } from "../models/DamperRateDto";

export default class RateStore {
    constructor() {
        makeAutoObservable(this);
    }


    gettingRate = false;
    damperType = "FireDamper";
    product = 'A';
    productType = 'Horizontal';
    costOfSheet = '1.4';
    discountPercentage = '70';
    width = '12';
    height = '12';
    angles = 'No';
    sleeves = 'No';
    expectedDeliveryFactor = '0';
    salesCommission = '0';
    salesPrice = '';
    damperRateCalc: IDamperRateOutput | null = null;


    quotes: IDamperRateOutput[] = [];
    setQuotes = (rate: IDamperRateOutput) => {
        this.quotes = [...this.quotes, rate];
    }

    resetQuotes = () => {
        this.quotes = [];
    }

    removeQuote = (quote: IDamperRateOutput) => {
        var index = this.quotes.indexOf(quote);
        if (index > -1) {
            this.quotes.splice(index, 1);
        }
        /*
        this.quotes = this.quotes.filter(x =>
            x.damperType !== quote.damperType
            && x.salesPrice !== quote.salesPrice
            && x.width !== quote.width
            && x.height !== quote.height);
            */
    }


    setProduct = (value: string) => {
        this.product = value;
    }

    setProductType = (value: string) => {
        this.productType = value;
    }

    setCostOfSheet = (value: string) => {
        this.costOfSheet = value;
    }

    setDiscountPercentage = (value: string) => {
        this.discountPercentage = value;
    }

    setWidth = (value: string) => {
        this.width = value;
    }

    setHeight = (value: string) => {
        this.height = value;
    }

    setAngles = (value: string) => {
        this.angles = value;
    }

    setSleeves = (value: string) => {
        this.sleeves = value;
    }

    setExpectedDeliveryFactor = (value: string) => {
        this.expectedDeliveryFactor = value;
    }

    setSalesCommission = (value: string) => {
        this.salesCommission = value;
    }


    get axiosParams() {
        const params = new URLSearchParams();
        params.append('damperType', this.damperType);
        params.append('product', this.product);
        params.append('productType', this.productType);
        params.append('costOfSheet', this.costOfSheet);
        params.append('discountPercentage', this.discountPercentage);
        params.append('width', this.width);
        params.append('height', this.height);
        params.append('angles', this.angles);
        params.append('sleeves', this.sleeves);
        params.append('expectedDeliveryFactor', this.expectedDeliveryFactor);
        params.append('salesCommission', this.salesCommission);
        return params;
    }


    getRateForFireDamper = async () => {
        this.damperType = "FireDamper";
        this.gettingRate = true;
        this.salesPrice = "--NA--";
        try {
            console.log(this.axiosParams.toString());
            const computedRate = await agent.rates.getrate(this.axiosParams);
            runInAction(() => {
                this.damperRateCalc = computedRate;
                this.salesPrice = computedRate.salesPrice.toString();
                this.gettingRate = false;
            });
            return computedRate;
        } catch (error) {
            runInAction(() => {
                this.gettingRate = false;
            });
            console.log(error);
        }

    }

    getRateForControlDamper = async () => {
        this.damperType = "ControlDamper";

        this.gettingRate = true;
        this.salesPrice = "--NA--";
        try {
            console.log(this.axiosParams.toString());
            const computedRate = await agent.rates.getrate(this.axiosParams);
            runInAction(() => {
                this.damperRateCalc = computedRate;
                this.salesPrice = computedRate.salesPrice.toString();
                this.gettingRate = false;
            });
            return computedRate;
        } catch (error) {
            runInAction(() => {
                this.gettingRate = false;
            });
            console.log(error);
        }

    }

}

//npx browserslist@latest --update-db