export interface Taxes {
    province: string;
    provinceCode: string;
    gst: number;
    hst: number;
    qst: number;
    pst: number;
}

export const getTaxesForOntarioHardCoded = () => {
    return {
        province: 'Ontario',
        provinceCode: 'ON',
        gst: 0,
        hst: 13,
        qst: 0,
        pst: 0,
    }
}


export const getDefaultStateToSelect = (customerName: string | undefined, customerAddress: string | undefined) => {
    if (customerName === undefined || customerAddress === undefined) {
        return 'ON'
    }
    if (customerName?.toLocaleLowerCase().includes('rtc') && customerAddress?.toLocaleLowerCase().includes('edmonton')) {
        return 'AB'
    }
    return 'ON'
}