import { observer } from 'mobx-react-lite'
import React, { Fragment } from 'react'
import { Item } from 'semantic-ui-react'
import { useStore } from '../../../../app/stores/Store'
import PurchaseOrderListItem from './PurchaseOrderListItem'

const PurchaseOrderList = () => {
    const { poStore } = useStore();
    const { purchaseorders } = poStore;
    return (
        <Fragment>
            <Item.Group className="mygrid" divided>
                {
                    purchaseorders.map((purchaseorder, index) => (
                        <PurchaseOrderListItem key={index} purchaseorder={purchaseorder} />
                    ))
                }
            </Item.Group>
        </Fragment>
    )
}

export default observer(PurchaseOrderList)
