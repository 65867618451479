import { observer } from 'mobx-react-lite';
import React, { Fragment, useState } from 'react';
import { Button, Header, Icon, Input, Menu } from 'semantic-ui-react';
import { useStore } from '../../app/stores/Store';

const DataSyncDashboard = () => {
    const { dataSyncStore } = useStore();
    const { syncingCustomers, SyncCustomers,
        syncingParts, SyncParts,
        syncingPoParts, SyncPoParts,
        syncingPurchaseOrders, SyncPurchaseOrders,
        syncingSalesOrders, SyncSalesOrders,
        deletingTestSalesOrders, DeleteTestSalesOrders,
        RefreshUnderProcessOrders, RefreshInPackagingOrders, RefreshNewOrders
    } = dataSyncStore;

    const [customerRowCount, setCustomerRowCount] = useState(100);
    const [partRowCount, setPartRowCount] = useState(100);
    const [poPartRowCount, setPoPartRowCount] = useState(100);
    const [purchaseOrderCount, setPurchaseOrderRowCount] = useState(100);
    const [salesOrderRowCount, setSalesOrderRowCount] = useState(100);

    return (
        <Fragment>
            <Menu size={'large'} style={{ width: '40%', marginTop: 50 }}>
                <Header icon={'filter'} attached color={'blue'} content={'Sync Customer Data With Max Records'} />
                <Menu.Item style={{ width: '30%' }}>
                    <Input type='text'
                        value={customerRowCount} onChange={(e) => setCustomerRowCount(parseInt(e.target.value))}
                        style={{ width: '100%' }}
                        placeholder='rowcount' action>
                        <input />
                        <Button icon color='blue' onClick={() => SyncCustomers(customerRowCount)} loading={syncingCustomers}>
                            <Icon name='sync' />
                        </Button>
                    </Input>
                </Menu.Item>
            </Menu>
            <Menu size={'large'} style={{ width: '40%', marginTop: 50 }}>
                <Header icon={'filter'} attached color={'teal'} content={'Sync Parts Data With Max Records'} />
                <Menu.Item style={{ width: '30%' }}>
                    <Input type='text'
                        value={partRowCount} onChange={(e) => setPartRowCount(parseInt(e.target.value))}
                        style={{ width: '100%' }}
                        placeholder='rowcount' action>
                        <input />
                        <Button icon color='teal' onClick={() => SyncParts(partRowCount)} loading={syncingParts}>
                            <Icon name='sync' />
                        </Button>
                    </Input>
                </Menu.Item>
            </Menu>
            <Menu size={'large'} style={{ width: '40%', marginTop: 50 }}>
                <Header icon={'filter'} attached color={'green'} content={'Sync Purchase Order Parts Data With Max Records'} />
                <Menu.Item style={{ width: '30%' }}>
                    <Input type='text'
                        value={poPartRowCount} onChange={(e) => setPoPartRowCount(parseInt(e.target.value))}
                        style={{ width: '100%' }}
                        placeholder='rowcount' action>
                        <input />
                        <Button icon color='green' onClick={() => SyncPoParts(poPartRowCount)} loading={syncingPoParts}>
                            <Icon name='sync' />
                        </Button>
                    </Input>
                </Menu.Item>
            </Menu>
            <Menu size={'large'} style={{ width: '40%', marginTop: 50 }}>
                <Header icon={'filter'} attached color={'orange'} content={'Sync Purchase Orders With Max Records'} />
                <Menu.Item style={{ width: '30%' }}>
                    <Input type='text'
                        value={purchaseOrderCount} onChange={(e) => setPurchaseOrderRowCount(parseInt(e.target.value))}
                        style={{ width: '100%' }}
                        placeholder='rowcount' action>
                        <input />
                        <Button icon color='orange' onClick={() => SyncPurchaseOrders(purchaseOrderCount)} loading={syncingPurchaseOrders}>
                            <Icon name='sync' />
                        </Button>
                    </Input>
                </Menu.Item>
            </Menu>
            <Menu size={'large'} style={{ width: '40%', marginTop: 50 }}>
                <Header icon={'filter'} attached color={'brown'} content={'Sync Sales OrdersWith Max Records'} />
                <Menu.Item style={{ width: '30%' }}>
                    <Input type='text'
                        value={salesOrderRowCount} onChange={(e) => setSalesOrderRowCount(parseInt(e.target.value))}
                        style={{ width: '100%' }}
                        placeholder='rowcount' action>
                        <input />
                        <Button icon color='brown' onClick={() => SyncSalesOrders(salesOrderRowCount)} loading={syncingSalesOrders}>
                            <Icon name='sync' />
                        </Button>
                    </Input>
                </Menu.Item>
            </Menu>
            <Menu size={'large'} style={{ width: '40%', marginTop: 50 }}>
                <Header icon={'filter'} attached color={'brown'} content={'Refresh'} />
                <Menu.Item style={{ width: '100%' }}>
                    <Button style={{ margin: '5px' }} icon color='brown' onClick={() => RefreshUnderProcessOrders()} loading={syncingSalesOrders}>
                        <Icon name='sync' />&nbsp;Under Process
                    </Button>
                    <Button style={{ margin: '5px' }} icon color='brown' onClick={() => RefreshInPackagingOrders()} loading={syncingSalesOrders}>
                        <Icon name='sync' />&nbsp;In Packaging
                    </Button>
                    <Button style={{ margin: '5px' }} icon color='brown' onClick={() => RefreshNewOrders()} loading={syncingSalesOrders}>
                        <Icon name='sync' />&nbsp;New Orders
                    </Button>
                </Menu.Item>
            </Menu>
            <Menu size={'large'} style={{ width: '40%', marginTop: 50 }}>
                <Header icon={'filter'} attached color={'red'} content={'*** Delete Test Sales Order Data'} />
                <Menu.Item style={{ width: '30%' }}>
                    <Button icon color='red' onClick={() => DeleteTestSalesOrders()} loading={deletingTestSalesOrders}>
                        <Icon name='trash' />
                    </Button>
                </Menu.Item>
            </Menu>

        </Fragment>
    )
}

export default observer(DataSyncDashboard)
