import { runInAction, makeAutoObservable } from "mobx";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import agent from "../api/agent";
import { IDocument } from "../models/Kiosk";
import { ISalesOrderDailyReport } from "../models/SalesOrder";
import { store } from "./Store";

export const LabelsGroup = "LabelsCreated";
export const ProductionGroup = "ProductionAcknowledged";
export const DeliveryGroup = "DeliveryAcknowledged";

export const Method_Dampers = "dampers";
export const Method_CRD = "crd";
export const Method_ControlDampers = "controldampers";
export const Method_Doors = "doors";

export default class KioskStore {


    constructor() {
        makeAutoObservable(this);


    }

    hubConnection: HubConnection | null = null;

    movements: string[] = [];
    labelmovements: IDocument[] = [];
    dampermovements: IDocument[] = [];
    crdmovements: IDocument[] = [];
    controldampermovements: IDocument[] = [];
    doorsmovements: IDocument[] = [];


    loadingDashboard = false;

    dailySalesOrders: ISalesOrderDailyReport[] = [];


    createProductionHubConnection = () => {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_CHAT_URL!, { accessTokenFactory: () => store.commonStore.token! })
            .configureLogging(LogLevel.Information)
            .build();

        this.hubConnection.start()
            .then(() => console.log(this.hubConnection!.state))
            .then(() => {
                console.log('Attempting to join group', LabelsGroup);
                this.hubConnection!.invoke('JoinGroup', LabelsGroup);
                console.log('Attempting to join group', ProductionGroup);
                this.hubConnection!.invoke('JoinGroup', ProductionGroup);
            })
            .catch((error) => console.log('Error Establishing connection', error));

        this.hubConnection!.on(LabelsGroup, (documents: IDocument[]) => {
            runInAction(() => {
                this.labelmovements = documents;
            });
        });

        this.hubConnection!.on(Method_Dampers, (documents: IDocument[]) => {
            runInAction(() => {
                this.dampermovements = documents;
            });
        });

        this.hubConnection!.on(Method_CRD, (documents: IDocument[]) => {
            runInAction(() => {
                this.crdmovements = documents;
            });
        });

        this.hubConnection!.on(Method_ControlDampers, (documents: IDocument[]) => {
            runInAction(() => {
                this.controldampermovements = documents;
            });
        });

        this.hubConnection!.on(Method_Doors, (documents: IDocument[]) => {
            runInAction(() => {
                this.doorsmovements = documents;
            });
        });

    }


    createDeliveryHubConnection = () => {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_CHAT_URL!, { accessTokenFactory: () => store.commonStore.token! })
            .configureLogging(LogLevel.Information)
            .build();

        this.hubConnection.start()
            .then(() => console.log(this.hubConnection!.state))
            .then(() => {
                console.log('Attempting to join group', LabelsGroup);
                this.hubConnection!.invoke('JoinGroup', LabelsGroup);
                console.log('Attempting to join group', DeliveryGroup);
                this.hubConnection!.invoke('JoinGroup', DeliveryGroup);
            })
            .catch((error) => console.log('Error Establishing connection', error));

        this.hubConnection!.on(Method_Dampers, (documents: IDocument[]) => {
            runInAction(() => {
                this.dampermovements = documents;
            });
        });

        this.hubConnection!.on(Method_CRD, (documents: IDocument[]) => {
            runInAction(() => {
                this.crdmovements = documents;
            });
        });

        this.hubConnection!.on(Method_ControlDampers, (documents: IDocument[]) => {
            runInAction(() => {
                this.controldampermovements = documents;
            });
        });

        this.hubConnection!.on(Method_Doors, (documents: IDocument[]) => {
            runInAction(() => {
                this.doorsmovements = documents;
            });
        });

    }


    createHubConnection = (groupName: string) => {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_CHAT_URL!, { accessTokenFactory: () => store.commonStore.token! })
            .configureLogging(LogLevel.Information)
            .build();

        this.hubConnection.start()
            .then(() => console.log(this.hubConnection!.state))
            .then(() => {
                console.log('Attempting to join group', groupName);
                this.hubConnection!.invoke('JoinGroup', groupName);
            })
            .catch((error) => console.log('Error Establishing connection', error));

        this.hubConnection!.on('RecieveScans', (message: string) => {
            runInAction(() => {
                console.log('recieved scans!!!! ', message);
                this.movements.push(message);
            });
        });

        this.hubConnection!.on('MovementDashboard', (message: string) => {
            //toast.info(message);
        });
    }

    stopHubConnection = (groupName: string) => {
        this.hubConnection!.invoke('LeaveGroup', groupName)
            .then(() => this.hubConnection!.stop())
            .then(() => 'logging out')
            .catch((error) => console.log(error));
    }

    loadProductionKiosk = async () => {
        this.loadingDashboard = true;
        try {
            var productionDashboard = await agent.kiosk.productiondashboard();
            runInAction(() => {
                this.labelmovements = productionDashboard.labels;
                this.dampermovements = productionDashboard.dampersInProduction;
                this.crdmovements = productionDashboard.crdInProduction;
                this.controldampermovements = productionDashboard.controlDampersInProduction;
                this.doorsmovements = productionDashboard.doorsInProduction;
                this.loadingDashboard = false;
            });
        } catch (error) {
            console.log(error);
            //toast.error(error);
            runInAction(() => {
                this.loadingDashboard = false;
            });
        }
    }

    loadDeliveryKiosk = async () => {
        this.loadingDashboard = true;
        try {
            console.log('loading delivery dashboard')
            var deliveryDashboard = await agent.kiosk.deliverydashboard();
            runInAction(() => {
                this.dampermovements = deliveryDashboard.dampersInDelivery;
                this.crdmovements = deliveryDashboard.crdInDelivery;
                this.controldampermovements = deliveryDashboard.controlDampersInDelivery;
                this.doorsmovements = deliveryDashboard.doorsInDelivery;
                this.loadingDashboard = false;
            });
        } catch (error) {
            console.log(error);
            //toast.error(error);
            runInAction(() => {
                this.loadingDashboard = false;
            });
        }
    }


    sendEmailOfDailySalesOrderReport = async () => {
        this.loadingDashboard = true;
        try {
            console.log('emailing daily report')
            await agent.salesorder.emaildailyreport();
            runInAction(() => {
                this.loadingDashboard = false;
            });
        } catch (error) {
            console.log(error);
            //toast.error(error);
            runInAction(() => {
                this.loadingDashboard = false;
            });
        }
    }

    loadDailSalesOrderReport = async () => {
        this.loadingDashboard = true;
        try {
            console.log('loading daily report')
            var salesorders = await agent.salesorder.dailyreport();
            runInAction(() => {
                this.dailySalesOrders = salesorders;
                this.loadingDashboard = false;
            });
        } catch (error) {
            console.log(error);
            //toast.error(error);
            runInAction(() => {
                this.loadingDashboard = false;
            });
        }
    }

}