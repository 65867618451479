import { observer } from 'mobx-react-lite';
import React from 'react'
import { Button, Grid, Header, Table } from 'semantic-ui-react'
import { useStore } from '../../../app/stores/Store';
import "./rates.css";



const heights = [
    { text: '6', value: '6' },
    { text: '8', value: '8' },
    { text: '10', value: '10' },
    { text: '12', value: '12' },
    { text: '16', value: '16' },
    { text: '20', value: '20' },
    { text: '24', value: '24' },
    { text: '28', value: '28' },
    { text: '32', value: '32' },
    { text: '36', value: '36' },
    { text: '40', value: '40' },
    { text: '44', value: '44' },
    { text: '48', value: '48' },
    { text: '52', value: '52' },
    { text: '56', value: '56' },
    { text: '60', value: '60' },

]

const widths = [
    { text: '6', value: '6' },
    { text: '8', value: '8' },
    { text: '10', value: '10' },
    { text: '12', value: '12' },
    { text: '16', value: '16' },
    { text: '20', value: '20' },
    { text: '24', value: '24' },
    { text: '28', value: '28' },
    { text: '32', value: '32' },
    { text: '36', value: '36' },
    { text: '40', value: '40' },
    { text: '44', value: '44' },
    { text: '48', value: '48' },
    { text: '52', value: '52' },
    { text: '56', value: '56' },
    { text: '60', value: '60' },

]
const producttypes = [
    { text: 'Horizontal', value: 'Horizontal' },
    { text: 'Vertical', value: 'Vertical' },
]

const products = [
    { text: 'A', value: 'A' },
    { text: 'B', value: 'B' },
    { text: 'C', value: 'C' },
    { text: 'CR', value: 'CR' },
    { text: 'CO', value: 'CO' },
    { text: 'DA', value: 'DA' },
    { text: 'DB', value: 'DB' },
]

const yesnos = [
    { text: 'Yes', value: 'Yes' },
    { text: 'No', value: 'No' },
]


const FireDamperInput = () => {
    const { rateStore } = useStore();
    const {
        gettingRate,
        product, setProduct,
        productType, setProductType,
        costOfSheet, setCostOfSheet,
        discountPercentage, setDiscountPercentage,
        width, setWidth,
        height, setHeight,
        angles, setAngles,
        sleeves, setSleeves,
        expectedDeliveryFactor, setExpectedDeliveryFactor,
        salesCommission, setSalesCommission,
        getRateForFireDamper, setQuotes
    } = rateStore;

    const computeRate = async () => {
        let rate = await getRateForFireDamper();
        if (rate !== undefined && rate !== null) {
            setQuotes(rate);
        }
    }

    return (
        <>
            <Header as='h2'>Fire Damper</Header>
            <Grid columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        <Button positive loading={gettingRate} onClick={computeRate} floated='right'>Add To Quote</Button>
                        <Table style={{ margin: '3em 0em' }}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Width</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={width}
                                            onChange={e => setWidth(e.target.value)}
                                        >
                                            {widths.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Height</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={height}
                                            onChange={e => setHeight(e.target.value)}
                                        >
                                            {heights.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>Product Type</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={productType}
                                            onChange={e => setProductType(e.target.value)}
                                        >
                                            {producttypes.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Product</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={product}
                                            onChange={e => setProduct(e.target.value)}
                                        >
                                            {products.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>

                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>Cost Of Galvanised Sheet</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <input type='text'
                                            value={costOfSheet}
                                            onChange={(e) => { setCostOfSheet(e.target.value) }}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Discount Percentage</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <input type='text'
                                            value={discountPercentage}
                                            onChange={(e) => { setDiscountPercentage(e.target.value) }}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>Include Angles</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={angles}
                                            onChange={e => setAngles(e.target.value)}
                                        >
                                            {yesnos.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Include Sleeves</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={sleeves}
                                            onChange={e => setSleeves(e.target.value)}
                                        >
                                            {yesnos.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>Expected Delivery Factor</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <input type='text'
                                            value={expectedDeliveryFactor}
                                            onChange={(e) => { setExpectedDeliveryFactor(e.target.value) }}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Sales Commission</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <input type='text'
                                            value={salesCommission}
                                            onChange={(e) => { setSalesCommission(e.target.value) }}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>

                            </Table.Header>

                        </Table>
                        <Button positive loading={gettingRate} onClick={computeRate} floated='right'>Add To Quote</Button>

                    </Grid.Column>

                </Grid.Row>

            </Grid>


        </>
    )
}

export default observer(FireDamperInput)
