import { observer } from 'mobx-react-lite'
import React from 'react'
import { Grid } from 'semantic-ui-react'
import SalesOrderSearchResults from './SalesOrderSearchResults'
import SearchSalesOrdersForm from './SearchSalesOrdersForm'

const SalesOrderSearch = () => {
    return (
        <Grid fluid='true'>
            <Grid.Row>
                <Grid.Column width={16}>
                    <SearchSalesOrdersForm />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <SalesOrderSearchResults />
                </Grid.Column>
            </Grid.Row>

        </Grid>
    )
}

export default observer(SalesOrderSearch)
