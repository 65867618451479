import { observer } from 'mobx-react-lite'
import React, { Fragment } from 'react'
import { Item } from 'semantic-ui-react'
import { useStore } from '../../../../app/stores/Store'
import SalesOrderListItem from './SalesOrderListItem'

const SalesOrderList = () => {
    const { salesOrderStore } = useStore();
    const { salesorders } = salesOrderStore;
    return (
        <Fragment>
            <Item.Group className="mygrid" divided>
                {
                    salesorders.map((salesorder, index) => (
                        <SalesOrderListItem key={index} salesorder={salesorder} />
                    ))
                }
            </Item.Group>
        </Fragment>
    )
}

export default observer(SalesOrderList)
