import { store } from "./Store";
import { runInAction, makeAutoObservable } from "mobx";
import agent from "../api/agent";
import { toast } from "react-toastify";
import { IDocumentSummary } from "../models/IDocumentSummary";

const toast_timeperiod = 4000;
export default class ScanStore {

    constructor() {
        makeAutoObservable(this);


    }

    scanning = false;


    loadingMovements = false;
    loadingInitial = false;
    documentMap = new Map();
    selectedDocument: IDocumentSummary | null = null;
    loadingDocument = false;

    get documents() {
        return Array.from(this.documentMap.values());
    }
    loadDocuments = async () => {
        this.loadingInitial = true;
        try {
            const documents = await agent.scanorders.list();
            runInAction(() => {
                this.documentMap.clear();
                documents.map((document) => {
                    this.documentMap.set(document.documentId, document);
                    return this.documentMap;
                });
                store.searchStore.totalRecordCount = 20;
                this.loadingInitial = false;
            });
        } catch (error) {
            runInAction(() => {
                console.log(error);
            });
        } finally {
            runInAction(() => {
                this.loadingInitial = false;
            });
        }
    }

    getDocument = (id: string) => {
        return this.documentMap.get(id);
    }

    loadDocument = async (workorderid: string) => {
        try {
            this.loadingDocument = true;
            let documentsummary = await agent.scanorders.documentstatus(workorderid);
            runInAction(() => {
                this.selectedDocument = documentsummary;
                this.loadingDocument = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadingDocument = false;
            });
        }
    }

    loadMovements = async (workorderid: string, movementid: number) => {
        this.loadingMovements = true;
        try {
            console.log(`getting movements for ${workorderid} ${movementid}`)
            let movements = await agent.scanorders.getMovements(workorderid, movementid);
            runInAction(() => {
                this.loadingMovements = false;
            });
            return movements;
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadingMovements = false;
            });

        }
    }

    searchDocuments = async (searchvalue: string) => {
        this.loadingInitial = true;
        try {
            const documents = await agent.scanorders.searchDocuments(searchvalue);
            runInAction(() => {
                this.documentMap.clear();
                documents.map((document) => {
                    this.documentMap.set(document.documentId, document);
                    return this.documentMap;
                });
                store.searchStore.totalRecordCount = 20;
                this.loadingInitial = false;
            });
        } catch (error) {
            runInAction(() => {
                console.log(error);
            });
        } finally {
            runInAction(() => {
                this.loadingInitial = false;
            });
        }
    }

    scanBarcode = async (workorderid: string) => {
        this.scanning = true;
        try {
            const params = new URLSearchParams();
            params.append('data', workorderid);
            await agent.scanorders.scanmovement(params);
            runInAction(() => {
                this.scanning = false;
                toast.success(`Scannned :${workorderid}`, { autoClose: toast_timeperiod });
            });
        } catch (error: any) {
            runInAction(() => {
                this.scanning = false;
            });
            if (error.data && Object.keys(error.data.errors).length > 0) {
                var myerror = Object.values(error.data.errors).flat();
                toast.error(myerror.toString(), { autoClose: toast_timeperiod });
            }
        }
    }

    scanManually = async (id: string, code: string) => {
        this.scanning = true;
        try {
            const params = new URLSearchParams();
            params.append('data', id);
            params.append('movementtype', code);
            await agent.scanorders.scanmovementmanual(params);
            runInAction(() => {
                this.scanning = false;
                toast.success(`Scannned :${id}`, { autoClose: toast_timeperiod });
            });
        } catch (error: any) {
            runInAction(() => {
                this.scanning = false;
            });
            if (error.data && Object.keys(error.data.errors).length > 0) {
                var myerror = Object.values(error.data.errors).flat();
                toast.error(myerror.toString(), { autoClose: toast_timeperiod });
            }
        }
    }


    scanWorkOrder = async (workorderid: string) => {
        this.scanning = true;
        try {
            const params = new URLSearchParams();
            params.append('workorderid', workorderid);
            await agent.scanorders.workorder(params);
            runInAction(() => {
                this.scanning = false;
                toast.success(`Scannned :${workorderid}`);
            });
        } catch (error: any) {
            runInAction(() => {
                this.scanning = false;
            });
            if (error.data && Object.keys(error.data.errors).length > 0) {
                var myerror = Object.values(error.data.errors).flat();
                toast.error(myerror.toString());
            }
        }
    }

    scanMaterial = async (id: string, code: string) => {
        this.scanning = true;
        try {
            const params = new URLSearchParams();
            params.append('id', id);
            params.append('code', code);
            await agent.scanorders.material(params);
            runInAction(() => {
                this.scanning = false;
                toast.success(`Scannned :${id}`);
            });
        } catch (error: any) {
            runInAction(() => {
                this.scanning = false;
            });
            if (error.data && Object.keys(error.data.errors).length > 0) {
                var myerror = Object.values(error.data.errors).flat();
                toast.error(myerror.toString());
            }
        }
    }

}