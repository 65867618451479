import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { useStore } from '../../../app/stores/Store';
import TopActionButtons from './TopActionButtons';
import './css/salesOrderDetail.css';
import DocumentHeaderLogo from './DocumentHeaderLogo';
import DocumentHeaderAndAddress from './DocumentHeaderAndAddress';
import DocumentSoldToShipTo from './DocumentSoldToShipTo';
import DocumentCustomerContacts from './DocumentCustomerContacts';
import DocumentShipmentDetails from './DocumentShipmentDetails';
import DocumentItemDetails from './DocumentItemDetails';
import SalesOrderDetailsPlaceHolder from './SalesOrderDetailsPlaceHolder';

interface ParamAtributes {
    id: string
}

const SalesOrderDetail: React.FC<RouteComponentProps<ParamAtributes>> = ({ match }) => {

    const { salesOrderStore, dataSyncStore } = useStore();
    const { loadSalesOrderByOrderNo, loadingSalesOrder, currentSalesOrder } = salesOrderStore;
    const { syncingSalesOrders } = dataSyncStore;
    useEffect(() => {
        loadSalesOrderByOrderNo(match.params.id);
    }, [loadSalesOrderByOrderNo, match.params.id])

    if (currentSalesOrder === null || loadingSalesOrder) {
        return <LoadingComponent />
    }


    return (
        <Fragment>

            <TopActionButtons />

            {syncingSalesOrders ? (
                <div className='invoiceDocument'>
                    <SalesOrderDetailsPlaceHolder />
                </div>
            ) : (
                <div className='invoiceDocument'>
                    <DocumentHeaderLogo />
                    <DocumentHeaderAndAddress />
                    <DocumentSoldToShipTo />
                    <DocumentCustomerContacts />
                    <DocumentShipmentDetails />
                    <DocumentItemDetails />

                </div>

            )}

        </Fragment>
    )
}

export default observer(SalesOrderDetail)
