import { observer } from "mobx-react-lite";
import React, { Fragment, useState } from "react";
import { Button, ButtonGroup, Header, Icon, Label, Segment } from "semantic-ui-react";
//import { formatDistance } from 'date-fns';
import DatePicker from "react-datepicker";
import { useStore } from "../../app/stores/Store";
//import { saveAs } from 'file-saver';


const DailyReportForQB = () => {
    const { kioskStore } = useStore();
    const { sendEmailOfDailySalesOrderReport, loadingDashboard } = kioskStore;


    const [startInvoiceDate, setStartInvoiceDate] = useState(new Date());
    const handleStartDateChange = (date: any) => setStartInvoiceDate(date);

    const [endInvoiceDate, setEndInvoiceDate] = useState(new Date());
    const handleEndDateChange = (date: any) => setEndInvoiceDate(date);


    // useEffect(() => {
    //     loadDailSalesOrderReport();
    // }, [loadDailSalesOrderReport])



    return (
        <Fragment>
            <ButtonGroup floated="right">
                <Button basic secondary onClick={sendEmailOfDailySalesOrderReport} loading={loadingDashboard}>Email</Button>
            </ButtonGroup>
            <Header as='h2' icon textAlign='center'>
                <Icon name='book' circular />
                <Header.Content>Quick Books Helper Page</Header.Content>
            </Header>
            <br></br>
            <Segment.Group horizontal>
                <Segment>
                    <Label>Start Date &nbsp;:</Label>
                    <DatePicker selected={startInvoiceDate} onChange={handleStartDateChange} dateFormat="yyyy-MM-dd" />
                </Segment>
                <Segment>
                    <Label>End Date &nbsp;&nbsp;&nbsp;&nbsp;:</Label>
                    <DatePicker selected={endInvoiceDate} onChange={handleEndDateChange} dateFormat="yyyy-MM-dd" />
                </Segment>
                <Segment>
                    <a href={process.env.REACT_APP_API_URL + `/reports/downloadqbsalesdata?startDate=${startInvoiceDate.toISOString().split('T')[0]}&endDate=${endInvoiceDate.toISOString().split('T')[0]}`}>
                        <Button primary size='small'>Download Invoice Data</Button>
                    </a>
                </Segment>
            </Segment.Group>
        </Fragment >
    )
}

export default observer(DailyReportForQB)