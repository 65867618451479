import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
export default class DataSyncStore {

    constructor() {
        makeAutoObservable(this);
    }

    syncingCustomers = false;
    syncingParts = false;
    syncingPoParts = false;
    syncingPurchaseOrders = false;
    syncingSalesOrders = false;
    deletingTestSalesOrders = false;


    SyncCustomers = async (recordCount: number) => {
        this.syncingCustomers = true;
        try {
            await agent.syncdata.customers(recordCount);
            runInAction(() => {
                this.syncingCustomers = false;
                toast.success('Customer changes synched!')
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.syncingCustomers = false;
            });
        }
    }

    SyncParts = async (recordCount: number) => {
        this.syncingParts = true;
        try {
            await agent.syncdata.parts(recordCount);
            runInAction(() => {
                this.syncingParts = false;
                toast.success('Parts changes synched!')
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.syncingParts = false;
            });
        }
    }

    SyncPoParts = async (recordCount: number) => {
        this.syncingPoParts = true;
        try {
            await agent.syncdata.poparts(recordCount);
            runInAction(() => {
                this.syncingPoParts = false;
                toast.success('Purhase Order Parts changes synched!')
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.syncingPoParts = false;
            });
        }
    }

    SyncPurchaseOrders = async (recordCount: number) => {
        this.syncingPurchaseOrders = true;
        try {
            await agent.syncdata.purchaseorders(recordCount);
            runInAction(() => {
                this.syncingPurchaseOrders = false;
                toast.success('Purchase Order changes synched!')
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.syncingPurchaseOrders = false;
            });
        }
    }

    SyncSalesOrders = async (recordCount: number) => {
        this.syncingSalesOrders = true;
        try {
            await agent.syncdata.salesorders(recordCount);
            runInAction(() => {
                this.syncingSalesOrders = false;
                toast.success('Sales Order changes synched!')
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.syncingSalesOrders = false;
            });
        }
    }

    SyncSalesOrder = async (id: string) => {
        this.syncingSalesOrders = true;
        try {
            await agent.syncdata.salesorder(id);
            runInAction(() => {
                this.syncingSalesOrders = false;
                toast.success('Sales Order changes synched!')
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.syncingSalesOrders = false;
            });
        }
    }

    RefreshUnderProcessOrders = async () => {
        this.syncingSalesOrders = true;
        try {
            await agent.syncdata.refreshunderprocessorders();
            runInAction(() => {
                this.syncingSalesOrders = false;
                toast.success('Under Process Sales Orders have been refreshed!')
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.syncingSalesOrders = false;
            });
        }
    }

    RefreshInPackagingOrders = async () => {
        this.syncingSalesOrders = true;
        try {
            await agent.syncdata.refreshordersinpackaging();
            runInAction(() => {
                this.syncingSalesOrders = false;
                toast.success('All Sales Orders in Packaging have been refreshed!')
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.syncingSalesOrders = false;
            });
        }
    }

    RefreshNewOrders = async () => {
        this.syncingSalesOrders = true;
        try {
            await agent.syncdata.refreshneworders();
            runInAction(() => {
                this.syncingSalesOrders = false;
                toast.success('All New Sales Orders have been refreshed!')
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.syncingSalesOrders = false;
            });
        }
    }


    DeleteTestSalesOrders = async () => {
        this.deletingTestSalesOrders = true;
        try {
            await agent.syncdata.deletetestsalesorders();
            runInAction(() => {
                this.deletingTestSalesOrders = false;
                toast.success('Test Sales Order data deleted!')
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.deletingTestSalesOrders = false;
            });
        }
    }


}