import React from 'react'
import { Icon, Item, Label, Segment } from 'semantic-ui-react'
import { history } from '../../../..';
import { ICompactPurchaseOrder } from '../../../../app/models/PurchaseOrder';


const PurchaseOrderListItem: React.FC<{ purchaseorder: ICompactPurchaseOrder }> = ({ purchaseorder }) => {


    const getDateOnly = (datetime: Date | null) => {
        if (!datetime) return ' ? ';

        if (datetime.toString().startsWith('000'))
            return ' ? ';
        else
            return datetime.toString().split('T')[0];
    }

    const purchaseOrderDetails = (id: string) => {
        history.push(`/purchaseorder/${id}`);
    }
    /*
        const isGreenColored = (status: string) => {
    
            return status !== "Under Process" &&
                status !== "Cancelled" &&
                status !== "Packing Slip";
        }
    */
    return (
        <Segment.Group className="mygridcell"
            onClick={() => purchaseOrderDetails(purchaseorder.purchase_order_master_id.toString())}>
            <Segment color={'red'}>
                <Item.Group>
                    <Item >
                        <Item.Content className="gridheader">
                            {purchaseorder.po_order_status === "Closed" ? (
                                <Item.Header><Label circular color='blue' size='mini' /> &nbsp;&nbsp;
                                    Order :<b>{purchaseorder.po_order_no}</b>
                                </Item.Header>
                            ) : (
                                <Item.Header><Label circular color='green' size='mini' /> &nbsp;&nbsp;
                                    Order :<b>{purchaseorder.po_order_no}</b>
                                </Item.Header>
                            )}
                            <Item.Description>
                                {/*
                                {purchaseorder.po_order_status === "Under Process" && <Label size='large' color='orange' basic content={purchaseorder.po_order_status} />}
                                {purchaseorder.po_order_status === "Cancelled" && <Label size='large' color='red' basic content={purchaseorder.po_order_status} />}
                                {purchaseorder.po_order_status === "Packing Slip" && <Label size='large' color='blue' basic content={purchaseorder.po_order_status} />}
                                {isGreenColored(purchaseorder.po_order_status) && <Label size='large' color='green' basic content={purchaseorder.po_order_status} />}
                                */}
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
                <Item.Group>
                    <Item className="gridheader">
                        {purchaseorder.po_order_status === "Closed" ? (
                            <Item.Header>&nbsp;
                                <b style={{ color: 'red' }}>{purchaseorder.po_order_status}</b>
                            </Item.Header>
                        ) : (
                            <Item.Header>&nbsp;
                                <b style={{ color: 'green' }}>{purchaseorder.po_order_status}</b>
                            </Item.Header>
                        )}

                        <Item.Description>
                            <Label>
                                <Icon name='dollar' />{purchaseorder.po_net_amount}</Label>
                        </Item.Description>
                    </Item>
                </Item.Group>
            </Segment>
            <Segment color={'red'}>
                <Item.Group>
                    <Item>
                        <Item.Content>
                            <Item.Header as='a'>
                                <div><u>{purchaseorder.po_customer_name}</u></div>
                            </Item.Header>

                            <Item.Description>Last Update : <b>{getDateOnly(purchaseorder.po_ship_date)}</b></Item.Description>
                            <Item.Description>Shipping Date : <Icon name='clock' /><b>{getDateOnly(purchaseorder.po_ship_date)}</b></Item.Description>
                            <Item.Description>Recipient : {purchaseorder.po_order_recipient}</Item.Description>
                            <Item.Description>
                                <b><u>Address :</u></b>
                            </Item.Description>
                            <Item.Description>
                                <textarea className="mytextarea" readOnly={true} cols={30} rows={6} defaultValue={purchaseorder.po_shipping_address} />
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
            <Segment secondary clearing>
                Delivery Type: <div>{purchaseorder.po_supplier_address}</div>
            </Segment>

        </Segment.Group>

    )
}

export default PurchaseOrderListItem
