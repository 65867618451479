import React, { useEffect } from 'react'
import { Tab } from 'semantic-ui-react'
import TrackingDetailsForm from './TrackingDetailsForm'

import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/Store';


interface IProps {
    documentid: string,
    alias: string,
    tabCount: number
}


const TrackingTabPane: React.FC<IProps> = ({ documentid, alias }) => {
    const { commonStore, scanStore } = useStore();
    const { removeTabPane } = commonStore;
    const { loadDocument, selectedDocument, loadingDocument } = scanStore;

    useEffect(() => {
        loadDocument(documentid);
    }, [loadDocument, documentid])


    const handleOnCancel = () => {
        removeTabPane(alias);
    }
    return (
        <Tab.Pane attached={true} key={documentid} loading={loadingDocument}>
            <TrackingDetailsForm documentSummary={selectedDocument} onCancel={handleOnCancel} />
        </Tab.Pane >
    )
}

export default observer(TrackingTabPane)
