import React from 'react';
import { Field } from 'react-final-form';
import { Label } from 'semantic-ui-react';
import DateInput from '../../../../../app/common/form/DateInput';
import SelectInput from '../../../../../app/common/form/SelectInput';
import { ILegacySalesOrder } from '../../../../../app/models/SalesOrder';


const salesorderstatuses = [
    { key: 'onhold', text: 'On Hold', value: 'On Hold' },
    { key: 'underprocess', text: 'Under Process', value: 'Under Process' },
    { key: 'packingslip', text: 'Packing Slip', value: 'Packing Slip' },
    { key: 'cancelled', text: 'Cancelled', value: 'Cancelled' },
    { key: 'invoiced', text: 'Invoiced', value: 'Invoiced' },
]
const addressStyle = {
    width: '250px',
    height: '110px',
    float: 'left',
    marginLeft: '20px'
} as React.CSSProperties

const tableStyle = {
    marginTop: '1px',
    width: '350px',
    float: 'right',
    marginBottom: '20px',
} as React.CSSProperties

const tableTdHead = {
    textAlign: 'left',
    border: '1px solid #000',
    padding: '5px',
} as React.CSSProperties

const tableTd = {
    textAlign: 'right',
    border: '1px solid #000',
    background: '#eee',
} as React.CSSProperties

const tableTdTextArea = {
    width: '100%',
    height: '20px',
    textAlign: 'left',
    overflow: 'hidden',
    resize: 'none'

} as React.CSSProperties

interface IProps {
    salesorder: ILegacySalesOrder;
}



const DocumentHeaderAddressAndDetails: React.FC<IProps> = ({ salesorder }) => {
    return (
        <div>
            <div style={addressStyle}>
                1090 Brevik Place, <br /> Mississauaga, L4W 3Y5, ON, Canada,
                <br />Phone: (905) 206-6888
            </div>
            {salesorder.so_invoice_no ? (
                <table className='table' style={tableStyle}>
                    <tbody>
                        <tr>
                            <td style={tableTdHead}>Status</td>
                            <td style={tableTd}>
                                <Label>{salesorder.so_order_status}</Label>
                            </td>
                        </tr>
                        <tr>
                            <td style={tableTdHead}>Order #</td>
                            <td style={tableTd}>
                                <Label>{salesorder.so_order_no}</Label>
                            </td>
                        </tr>
                        <tr>
                            <td style={tableTdHead}>Pkg Slip Date</td>
                            <td style={tableTd}>
                                <Field fluid date={true} placeholder='Package Slip Date' name='so_pack_slip_date' component={DateInput} />
                            </td>
                        </tr>
                        <tr>
                            <td style={tableTdHead}>Ship Date</td>
                            <td style={tableTd}>
                                <Field fluid date={true} placeholder='Ship Date' name='so_ship_date' component={DateInput} />
                            </td>
                        </tr>
                        <tr>
                            <td style={tableTdHead}>Amount Due</td>
                            <td style={tableTd}>
                                <Label>{salesorder.so_net_amount}</Label>
                            </td>
                        </tr>
                    </tbody>
                </table>

            ) : (
                <table className='table' style={tableStyle}>
                    <tbody>
                        <tr>
                            <td style={tableTdHead}>Status</td>
                            <td style={tableTd}>
                                <Field
                                    inline={true}
                                    style={{ width: '100%', margin: '0' }}
                                    placeholder='Status'
                                    name='so_order_status'
                                    component={SelectInput}
                                    options={salesorderstatuses} />
                            </td>
                        </tr>
                        <tr>
                            <td style={tableTdHead}>Order #</td>
                            <td style={tableTd}>
                                <Field component='textarea' id='textarea' style={tableTdTextArea} name='so_order_no' value={salesorder.so_order_no} />
                            </td>
                        </tr>
                        <tr>
                            <td style={tableTdHead}>Pkg Slip Date</td>
                            <td style={tableTd}>
                                <Field fluid date={true} placeholder='Package Slip Date' name='so_pack_slip_date' component={DateInput} />
                            </td>
                        </tr>
                        <tr>
                            <td style={tableTdHead}>Ship Date</td>
                            <td style={tableTd}>
                                <Field fluid date={true} placeholder='Ship Date' name='so_ship_date' component={DateInput} />
                            </td>
                        </tr>
                        <tr>
                            <td style={tableTdHead}>Amount Due</td>
                            <td style={tableTd}><Field component='textarea' id='textarea' style={tableTdTextArea}
                                name='so_net_amount'
                                value={salesorder.so_net_amount} /></td>
                        </tr>
                    </tbody>
                </table>

            )}

        </div>
    )
}

export default DocumentHeaderAddressAndDetails
