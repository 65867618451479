import { observer } from 'mobx-react-lite'
import React from 'react'
import { Grid } from 'semantic-ui-react'
import PurchaseOrderSearchResults from './PurchaseOrderSearchResults'
import SearchPurchaseOrdersForm from './SearchPurchaseOrdersForm'

const PurchaseOrderSearch = () => {
    return (
        <Grid fluid='true'>
            <Grid.Row>
                <Grid.Column width={16}>
                    <SearchPurchaseOrdersForm />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <PurchaseOrderSearchResults />
                </Grid.Column>
            </Grid.Row>

        </Grid>
    )
}

export default observer(PurchaseOrderSearch)
