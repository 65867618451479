import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, Fragment } from 'react';
import { Button, Table } from 'semantic-ui-react';
import { IPurchaseOrderDetail } from '../../../app/models/PurchaseOrder';
import { useStore } from '../../../app/stores/Store';

interface IProps {
    forceEdit?: boolean;
}


const Grn: React.FC<IProps> = ({ forceEdit }) => {
    const { poStore, modalStore } = useStore();
    const { currentPurchaseOrder, updatePurchaseOrder, updatePurchaseOrderDetail } = poStore;
    const { closeModal } = modalStore;

    const updateAllQuantitiesReceived = () => {
        if (currentPurchaseOrder === null || currentPurchaseOrder.purchaseOrderDetails === null) {
            return;
        }
        currentPurchaseOrder.purchaseOrderDetails.forEach(poDetail => {
            poDetail.grn_qty = poDetail.po_qty;
        });
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, currentIndex: number) => {
        if (currentPurchaseOrder === null || currentPurchaseOrder.purchaseOrderDetails === null) {
            return;
        }
        const { name, value } = event.target;
        let detail: IPurchaseOrderDetail | null = null;
        detail = { ...currentPurchaseOrder.purchaseOrderDetails[currentIndex], [name]: value }

        updatePurchaseOrderDetail(name, detail, currentIndex);
        if (currentPurchaseOrder.po_customer_name === "" || detail?.po_part_description === "" || value === "" || value === null) {
            return;
        }
    }

    const handleFocus = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => event.target.select();


    return (
        <Fragment>

            {(!forceEdit && currentPurchaseOrder?.po_order_status === "Closed") ? (
                <>
                    <div style={{ textAlign: "center" }}>
                        <Table celled striped>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='3'>Goods Received Note (Required to close a PO) </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell >
                                        Grn No:<br />
                                        {currentPurchaseOrder?.grn}
                                    </Table.Cell>
                                    <Table.Cell>
                                        Remarks:<br />
                                        {currentPurchaseOrder?.grn_Remarks}
                                    </Table.Cell>
                                    <Table.Cell collapsing textAlign='right'>
                                        <Button.Group>
                                            <Button color='yellow' onClick={() => closeModal()}>Close</Button>
                                        </Button.Group>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>

                    <br />
                    <br />
                    <div className="grnItemDetails">
                        <div className="didCell didHeader">No</div>
                        <div className="didCell didHeader">Description</div>
                        <div className="didCell didHeader">Quantity</div>
                        <div className="didCell didHeader">Received Quantity</div>
                        <div className="didCell didHeader">Grn No</div>
                        <div className="didCell didHeader">Remarks</div>
                        {
                            currentPurchaseOrder != null && currentPurchaseOrder.purchaseOrderDetails != null && currentPurchaseOrder.purchaseOrderDetails.map((poDetail, currentIndex) => (
                                <Fragment key={currentIndex}>
                                    <div className="didCell"><p>{currentIndex + 1}</p></div>
                                    <div className="didCell"><p>{poDetail.po_part_description}<br /> {poDetail.po_part_notes}</p></div>
                                    <div className="didCell"><p>{poDetail.po_qty}</p></div>
                                    <div className="didCell">
                                        {currentPurchaseOrder.purchaseOrderDetails[currentIndex].grn_qty}
                                    </div>
                                    <div className="didCell">
                                        {currentPurchaseOrder.purchaseOrderDetails[currentIndex].grn}
                                    </div>
                                    <div className="didCell">
                                        {currentPurchaseOrder.purchaseOrderDetails[currentIndex].grn_Remarks}
                                    </div>
                                </Fragment>
                            )
                            )
                        }
                    </div>
                </>
            ) : (
                <>
                    <div style={{ textAlign: "center" }}>
                        <Table celled striped>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='3'>Goods Received Note (Required to close a PO)</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell >
                                        Grn No:<br />
                                        <textarea
                                            id='textarea'
                                            name='grn'
                                            value={currentPurchaseOrder?.grn}
                                            onChange={(e) => updatePurchaseOrder(e.target.name, e.target.value)}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>
                                        Remarks:<br />
                                        <textarea
                                            id='textarea'
                                            name='grn_Remarks'
                                            rows={3}
                                            value={currentPurchaseOrder?.grn_Remarks}
                                            onChange={(e) => updatePurchaseOrder(e.target.name, e.target.value)}
                                        />
                                    </Table.Cell>
                                    <Table.Cell collapsing textAlign='right'>
                                        <Button.Group>
                                            <Button onClick={() => updateAllQuantitiesReceived()}>Update All Quantities</Button>
                                            <Button.Or />
                                            <Button color='yellow' onClick={() => closeModal()}>Close</Button>
                                        </Button.Group>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>

                    <br />
                    <br />
                    <div className="grnItemDetails">
                        <div className="didCell didHeader">No</div>
                        <div className="didCell didHeader">Description</div>
                        <div className="didCell didHeader">Quantity</div>
                        <div className="didCell didHeader">Received Quantity</div>
                        <div className="didCell didHeader">Grn No</div>
                        <div className="didCell didHeader">Remarks</div>
                        {
                            currentPurchaseOrder != null && currentPurchaseOrder.purchaseOrderDetails != null && currentPurchaseOrder.purchaseOrderDetails.map((poDetail, currentIndex) => (
                                <Fragment key={currentIndex}>
                                    <div className="didCell"><p>{currentIndex + 1}</p></div>
                                    <div className="didCell"><p>{poDetail.po_part_description}<br />{poDetail.po_part_notes}</p></div>
                                    <div className="didCell"><p>{poDetail.po_qty}</p></div>
                                    <div className="didCell">
                                        <textarea
                                            id='textarea'
                                            name='grn_qty'
                                            value={currentPurchaseOrder.purchaseOrderDetails[currentIndex].grn_qty}
                                            onChange={(e) => handleInputChange(e, currentIndex)}
                                            onFocus={handleFocus}
                                        />
                                    </div>
                                    <div className="didCell">
                                        <textarea
                                            id='textarea'
                                            name='grn'
                                            value={currentPurchaseOrder.purchaseOrderDetails[currentIndex].grn}
                                            onChange={(e) => handleInputChange(e, currentIndex)}
                                            onFocus={handleFocus}
                                        />
                                    </div>
                                    <div className="didCell">
                                        <textarea
                                            id='textarea'
                                            name='grn_Remarks'
                                            value={currentPurchaseOrder.purchaseOrderDetails[currentIndex].grn_Remarks}
                                            onChange={(e) => handleInputChange(e, currentIndex)}
                                            onFocus={handleFocus}
                                        />
                                    </div>
                                </Fragment>
                            )
                            )
                        }
                    </div>
                </>
            )
            }




        </Fragment >);
};

export default observer(Grn);
