import React, { useEffect, useState } from 'react'
import { Grid, Segment, Table, Button, Checkbox, Input, Menu, Header, Select, Icon } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite';
import { IPartCompact } from '../../../../../app/models/Part';
import { toJS } from 'mobx';
import { ISalesOrderDetail, getBlankSalesOrderDetail } from '../../../../../app/models/SalesOrder';
import { useStore } from '../../../../../app/stores/Store';



const options = [
    { key: 'partNo', text: 'Part No', value: 'partno' },
    { key: 'partDescription', text: 'Description', value: 'partdescription' }
]

interface IProps {
    sales_order_master_id: number;
    addSalesOrderDetailsArray: (salesOrderDetails: ISalesOrderDetail[]) => void;
}

const SalesOrderMultipleRows: React.FC<IProps> = ({ sales_order_master_id, addSalesOrderDetailsArray }) => {
    const { modalStore, partStore } = useStore();
    const { closeModal } = modalStore;
    const partContext = partStore;
    const { loadingParts, searchParts, loadParts, searchedParts } = partContext;
    const [searchValue, setSearchValue] = useState('');
    const [searchType, setSearchType] = useState<any>('partno');


    const [checkedPartsLHS, setCheckedPartsLHS] = useState<string[]>([]);
    const [checkedPartsRHS, setCheckedPartsRHS] = useState<string[]>([]);
    const [rhsParts, setRHSParts] = useState<IPartCompact[]>([]);


    const [checkedPartsDictionary, setCheckedPartsDictionary] = useState<IPartCompact[]>([]);

    useEffect(() => {
        loadParts();
    }, [loadParts])


    const onLHSRowClicked = (index: number) => {
        const partno = searchedParts[index].part_no;
        if (checkedPartsLHS.includes(partno)) {
            setCheckedPartsLHS(checkedPartsLHS.filter(part => { return partno !== part }));
        } else {
            setCheckedPartsDictionary([...checkedPartsDictionary, searchedParts[index]])
            setCheckedPartsLHS([...checkedPartsLHS, partno]);
        }
    }

    const onRHSRowClicked = (index: number) => {
        console.log('clicked')
        const partno = rhsParts[index].part_no;
        if (checkedPartsRHS.includes(partno)) {
            setCheckedPartsRHS(checkedPartsRHS.filter(part => { return partno !== part }));
        } else {
            setCheckedPartsRHS([...checkedPartsRHS, partno]);
        }
    }

    const pushToRight = () => {
        let partsToAdd: IPartCompact[] = [];

        checkedPartsLHS.forEach(partno => {
            let selectedPart = toJS(checkedPartsDictionary.filter(part => { return part.part_no === partno })[0]);
            //if (!rhsParts.some((part) => part.part_no === selectedPart.part_no)) {
            partsToAdd.push(selectedPart);
            //}
        })

        setRHSParts([...rhsParts, ...partsToAdd]);
    }

    const trashRHSSelectedParts = () => {
        let remainingParts: IPartCompact[] = [];
        rhsParts.forEach(part => {
            if (!checkedPartsRHS.includes(part.part_no))
                remainingParts.push(part);
        })
        setRHSParts([...remainingParts]);
        setCheckedPartsRHS([]);
    }

    const onSelectChange = (value: any) => {
        setSearchType(value);
        setSearchValue("");
    }

    const onSearchProducts = () => {
        console.log(searchType, searchValue);
        setCheckedPartsLHS([]);
        searchParts(searchType, searchValue);
    }

    const addDetails = () => {
        let salesOrderDetails: ISalesOrderDetail[] = [];
        rhsParts.forEach(part => {
            salesOrderDetails.push({ ...getBlankSalesOrderDetail(), sales_order_master_id: sales_order_master_id, 'so_part_no': part.part_no, 'so_part_description': part.part_description });
        });
        addSalesOrderDetailsArray(salesOrderDetails);
        closeModal();
    }

    return (
        <Segment loading={loadingParts}>
            <div style={{ textAlign: "center" }}>
                <Menu size={'large'} style={{ width: '100%', marginTop: 5 }}>
                    <Header icon={'filter'} attached color={'teal'} content={'Part Filters'} />
                    <Menu.Item style={{ width: '70%' }}>
                        <Input type='text' value={searchValue} onChange={(e) => setSearchValue(e.target.value)} style={{ width: '70%' }} placeholder='Search...' action>
                            <input />
                            <Select compact options={options} value={searchType} onChange={(e, data) => { onSelectChange(data.value) }} />
                            <Button icon color='blue' onClick={onSearchProducts} loading={loadingParts}>
                                <Icon name='search' />
                            </Button>

                        </Input>
                    </Menu.Item>
                    <Menu.Item style={{ width: '30%' }}>
                        <Button.Group>
                            <Button color='yellow' onClick={() => closeModal()}>Cancel</Button>
                            <Button.Or />
                            <Button positive onClick={() => addDetails()}>Add </Button>
                        </Button.Group>
                    </Menu.Item>

                </Menu>
            </div>

            <br />
            <br />

            <Grid columns={3} divided verticalAlign='middle'>
                <Grid.Row stretched>
                    <Grid.Column width='8'>
                        <Segment.Group compact>
                            <div className='scrollable_table'>
                                <Table compact celled definition>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.Cell width='2' style={{ backgroundColor: 'lightgrey' }}></Table.Cell>
                                            <Table.HeaderCell width='4'>Part No</Table.HeaderCell>
                                            <Table.HeaderCell width='10'>Part Description</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            searchedParts.map((part: IPartCompact, index) => (
                                                <Table.Row key={`left-${part.part_no}`} onClick={() => onLHSRowClicked(index)}>
                                                    <Table.Cell collapsing>
                                                        <Checkbox slider checked={checkedPartsLHS.includes(part.part_no)} />
                                                    </Table.Cell>
                                                    <Table.Cell>{part.part_no}</Table.Cell>
                                                    <Table.Cell>{part.part_description}</Table.Cell>
                                                </Table.Row>

                                            ))
                                        }
                                    </Table.Body>

                                </Table>
                            </div>
                        </Segment.Group>
                    </Grid.Column>
                    <Grid.Column width='1'>
                        <Segment.Group compact>
                            <Button icon='right arrow' positive onClick={() => pushToRight()} />
                            <br />
                            <Button icon='left arrow' color='yellow' onClick={() => trashRHSSelectedParts()} />
                        </Segment.Group>
                    </Grid.Column>
                    <Grid.Column width='7'>
                        <Segment.Group compact>
                            <div className='scrollable_table'>
                                <Table compact celled definition>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell width='2'><Checkbox slider color='red' /></Table.HeaderCell>
                                            <Table.HeaderCell width='4'>Part No</Table.HeaderCell>
                                            <Table.HeaderCell width='10'>Part Description</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {
                                            rhsParts.map((part: IPartCompact, index: number) => (
                                                <Table.Row key={`right-${part.part_no}`} onClick={() => onRHSRowClicked(index)}>
                                                    <Table.Cell collapsing>
                                                        <Checkbox slider checked={checkedPartsRHS.includes(part.part_no)} />
                                                    </Table.Cell>
                                                    <Table.Cell>{part.part_no}</Table.Cell>
                                                    <Table.Cell>{part.part_description}</Table.Cell>
                                                </Table.Row>

                                            ))
                                        }
                                    </Table.Body>

                                </Table>
                            </div>

                        </Segment.Group>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}

export default observer(SalesOrderMultipleRows)
