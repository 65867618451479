import { observer } from 'mobx-react-lite'
import React from 'react'
import { Table } from 'semantic-ui-react';
import { IInventoryItem } from '../../app/models/Inventory';
import { useStore } from '../../app/stores/Store';
import InventoryTabPane from './tabs/InventoryTabPane';

const InventoryResultForm = () => {
    const { inventoryStore } = useStore();

    const { inventoryitems, addTabPane, tabPanes } = inventoryStore;

    const addTab = (id: string, tabName: string) => {
        addTabPane(tabName, {
            menuItem: tabName,
            render: () => <InventoryTabPane inventoryitemid={id} tabCount={tabPanes.length} />
        });
    }


    return (
        <Table fixed celled selectable compact size='small' striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={1}>No</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Part No</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Part Type</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Details</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Spec</Table.HeaderCell>
                    <Table.HeaderCell>Actual Qty</Table.HeaderCell>
                    <Table.HeaderCell>Estimated Qty</Table.HeaderCell>
                    <Table.HeaderCell>Expected Delivery</Table.HeaderCell>
                    <Table.HeaderCell>Units</Table.HeaderCell>
                    <Table.HeaderCell>Update Date</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {
                    inventoryitems.map((inventoryitem: IInventoryItem, index: number) => (
                        <Table.Row key={inventoryitem.id} onClick={() => addTab(`${inventoryitem.id}`, inventoryitem.partNo + inventoryitem.id)} >
                            <Table.Cell>{index + 1}</Table.Cell>
                            <Table.Cell>{inventoryitem.partNo}</Table.Cell>
                            <Table.Cell>{inventoryitem.partType}</Table.Cell>
                            <Table.Cell>Desc: {inventoryitem.description} <br />Details:{inventoryitem.details}</Table.Cell>
                            <Table.Cell>{inventoryitem.specs}</Table.Cell>
                            {inventoryitem.actualQuantityWeight !== "" ? (
                                <Table.Cell>{inventoryitem.actualQuantity} ({inventoryitem.actualQuantityWeight} lbs) </Table.Cell>

                            ) : (
                                <Table.Cell>{inventoryitem.actualQuantity}</Table.Cell>
                            )}
                            {inventoryitem.estimatedQuantityWeight !== "" ? (
                                <Table.Cell>{inventoryitem.estimatedQuantity} ({inventoryitem.estimatedQuantityWeight} lbs) </Table.Cell>

                            ) : (
                                <Table.Cell>{inventoryitem.estimatedQuantity}</Table.Cell>
                            )}

                            {inventoryitem.openOrderedQuantityWeight !== "" ? (
                                <Table.Cell>{inventoryitem.openOrderedQuantity} ({inventoryitem.openOrderedQuantityWeight} lbs) </Table.Cell>

                            ) : (
                                <Table.Cell>{inventoryitem.openOrderedQuantity}</Table.Cell>
                            )}
                            <Table.Cell>{inventoryitem.unitOfMeasure}</Table.Cell>
                            <Table.Cell>{inventoryitem.updateDate.toString().split('T')[0]}</Table.Cell>
                        </Table.Row>

                    ))
                }
            </Table.Body>
            {/* 
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan='6'>
                        <Pagination
                            floated='right'
                            defaultActivePage={currentPageNo + 1}
                            totalPages={totalPages}
                            onPageChange={(e, data) => handlePageChange(data.activePage)} />
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
            
            
            */}
        </Table>
    )
}

export default observer(InventoryResultForm)
