import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, Fragment } from 'react'
import { Button } from 'semantic-ui-react';
import POPartNoTypeAheadPure from '../../../app/common/form/POPartNoTypeAheadPure';
import { IPurchaseOrderDetail } from '../../../app/models/PurchaseOrder';
import { useStore } from '../../../app/stores/Store';


interface IProps {
    currentIndex: number;
    poDetail: IPurchaseOrderDetail;
    itemCount: number;
}

const MyLineItemRow: React.FC<IProps> = ({ poDetail, currentIndex, itemCount }) => {
    const { poStore } = useStore();
    const { loadingPO, currentPurchaseOrder,
        removePurchaseOrderDetail,
        addOrEditRowItem,
        updatePurchaseOrderDetail } = poStore;
    //const { openModal } = modalStore;

    if (currentPurchaseOrder === null || loadingPO) {
        return <Fragment />
    }

    const handleOnTypeAheadComplete = () => {
        if (currentIndex === (itemCount - 1)) {
            //updatePurchaseOrderDetail(getBlankSalesOrderDetail());
        }
    }


    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;

        let detail: IPurchaseOrderDetail | null = null;
        if (name.includes('po_item_qty')) {
            detail = { ...poDetail/*, [name]: value, 'po_item_shipped': parseInt(value) || 0*/ }
        } else {
            detail = { ...poDetail, [name]: value }
        }

        updatePurchaseOrderDetail(name, detail, currentIndex);
        if (currentPurchaseOrder.po_customer_name === "" || detail?.po_part_description === "" || value === "" || value === null) {
            return;
        }


    }
    const handleFocus = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => event.target.select();


    return (
        <Fragment>
            {currentPurchaseOrder.po_invoice_no ? (
                <Fragment>
                    <div className="didCell rowspan2"><p>{currentIndex + 1}</p></div>
                    <div className="didCell"><p>{poDetail.po_part_tag_no}</p></div>
                    <div className="didCell"><p>{poDetail.po_part_description}</p></div>
                    <div className="didCell"><p>{poDetail.po_part_notes}</p></div>
                    <div className="didCell"><p>{currentPurchaseOrder.purchaseOrderDetails[currentIndex].po_qty}</p></div>
                    <div className="didCell"> <p>{currentPurchaseOrder.purchaseOrderDetails[currentIndex].po_unit_price}</p></div>
                    <div className="didCell"> <p>{currentPurchaseOrder.purchaseOrderDetails[currentIndex].po_total_amount}</p></div>
                </Fragment>
            ) : (
                <Fragment>
                    <div className="didCell">
                        {currentIndex < (itemCount - 1) ? (
                            <div className="delete-wpr">
                                <div>{currentIndex + 1}</div>
                                <Button
                                    color='red'
                                    style={{ padding: '5px', margin: 0, marginTop: '5px' }}
                                    className="delete"
                                    onClick={() => removePurchaseOrderDetail(currentIndex)}>X</Button>
                            </div>
                        ) : (
                            <Fragment>
                                {poDetail.po_part_tag_no === "" && poDetail.po_part_description === "" ? (
                                    <div>{currentIndex + 1}</div>
                                ) : (
                                    <div className="delete-wpr">
                                        <div>{currentIndex + 1}</div>
                                        <Button
                                            color='red'
                                            style={{ padding: '5px', margin: 0, marginTop: '5px' }}
                                            className="delete"
                                            onClick={() => removePurchaseOrderDetail(currentIndex)}>X</Button>
                                    </div>

                                )}
                            </Fragment>
                        )}
                    </div>
                    <div className="didCell">
                        <POPartNoTypeAheadPure index={currentIndex} setData={addOrEditRowItem} name="po_part_tag_no" value={poDetail.po_part_tag_no}
                            handleOnTypeAheadComplete={handleOnTypeAheadComplete}
                        /></div>
                    <div className="didCell"><textarea
                        id='textarea'
                        name='po_part_description'
                        value={poDetail.po_part_description}
                        onChange={handleInputChange}
                    /></div>
                    <div className="didCell">
                        <textarea
                            rows={5}
                            id='textarea'
                            name='po_part_notes'
                            value={poDetail.po_part_notes}
                            onChange={handleInputChange}
                            onFocus={handleFocus}
                        /></div>
                    <div className="didCell">
                        <textarea
                            id='textarea'
                            name='po_qty'
                            value={currentPurchaseOrder.purchaseOrderDetails[currentIndex].po_qty}
                            onChange={handleInputChange}
                            onFocus={handleFocus}
                        />
                    </div>
                    <div className="didCell">
                        <textarea
                            id='textarea'
                            name='po_unit_price'
                            value={currentPurchaseOrder.purchaseOrderDetails[currentIndex].po_unit_price}
                            onChange={handleInputChange}
                            onFocus={handleFocus}
                        />
                    </div>
                    <div className="didCell"><p>{currentPurchaseOrder.purchaseOrderDetails[currentIndex].po_total_amount}</p></div>

                </Fragment>
            )}

        </Fragment>
    )
}

export default observer(MyLineItemRow)
