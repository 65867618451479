import React, { useEffect, Fragment } from 'react'
import { Grid, Segment, Header } from 'semantic-ui-react';
import SegmentList from './segments/SegmentList';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/Store';

const DeliveryKiosk = () => {
    const { kioskStore } = useStore();
    const { createDeliveryHubConnection,
        stopHubConnection,
        loadDeliveryKiosk,
        loadingDashboard,
        dampermovements,
        crdmovements,
        controldampermovements,
        doorsmovements
    } = kioskStore;



    useEffect(() => {
        createDeliveryHubConnection();
        loadDeliveryKiosk();

        //const timeInMinutes = 60 * 2;
        const timeInMinutes = 15;
        const oneMinute = 60000;
        const interval = setInterval(() => loadDeliveryKiosk(), oneMinute * timeInMinutes);

        return () => {
            stopHubConnection("Movements");
            clearInterval(interval);
        }
    }, [createDeliveryHubConnection, stopHubConnection, loadDeliveryKiosk])

    // if (loadingDashboard) return <LoadingComponent />;
    return (
        <Fragment>
            <Grid centered columns={4} style={{ height: '90vh' }}>

                <Grid.Row style={{ height: '90%' }}>
                    <Grid.Column>
                        <Segment raised color='red' size='massive' loading={loadingDashboard}>
                            <Header>Dampers</Header>
                            <br />
                            <SegmentList documents={dampermovements} />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment color='orange' size='massive'>
                            <Header>CRD</Header>
                            <br />
                            <SegmentList documents={crdmovements} />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment raised color='yellow' size='massive'>
                            <Header>Control Dampers</Header>
                            <br />
                            <SegmentList documents={controldampermovements} />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment color='olive' size='massive'>
                            <Header>Doors</Header>
                            <br />
                            <SegmentList documents={doorsmovements} />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Fragment>
    )
}

export default observer(DeliveryKiosk)
