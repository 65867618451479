import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Button, Grid, Header, Table } from 'semantic-ui-react'
import { useStore } from '../../../app/stores/Store';


const yesno = [
    { text: 'Yes', value: 'Y' },
    { text: 'No', value: 'N' }
]
const CostFireDamper = () => {
    const { costingStore } = useStore();
    const {
        gettingRate,
        setWidth,
        setHeight,
        setIsHorizontal,
        setAngles,
        setCollars,
        setSleeves,
        setSleeveDepth,
        getRateForFireDamper, setQuotes
    } = costingStore;

    const [widthString, setWidthString] = useState('12')
    const [heightString, setHeightString] = useState('12')
    const [horizontalString, setHorizontalString] = useState('Y')
    const [anglesString, setAnglesString] = useState('N')
    const [collarString, setCollarString] = useState('N')
    const [sleeveString, setSleeveString] = useState('N')
    const [sleeveDepthString, setSleeveDepthString] = useState('16')


    const computeRate = async () => {

        setWidth(Number.parseFloat(widthString));
        setHeight(Number.parseFloat(heightString));
        setIsHorizontal(horizontalString !== 'N')
        setAngles(anglesString !== 'N')
        setCollars(collarString !== 'N')
        setSleeves(sleeveString !== 'N')
        setSleeveDepth(Number.parseFloat(sleeveDepthString));

        let rate = await getRateForFireDamper();
        if (rate !== undefined && rate !== null) {
            setQuotes(rate);
        }
    }

    return (
        <>
            <Header as='h2'>Fire Damper</Header>
            <Grid columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        <Table style={{ padding: '5px' }}>
                            <Table.Body>
                                <Table.Row>
                                    <Table.HeaderCell>Width</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <input type='text'
                                            value={widthString}
                                            onChange={(e) => { setWidthString(e.target.value) }}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Height</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <input type='text'
                                            value={heightString}
                                            onChange={(e) => { setHeightString(e.target.value) }}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>Horizontal</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={horizontalString}
                                            onChange={e => setHorizontalString(e.target.value)}
                                        >
                                            {yesno.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>HasAngles</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={anglesString}
                                            onChange={e => setAnglesString(e.target.value)}
                                        >
                                            {yesno.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>HasCollar</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={collarString}
                                            onChange={e => setCollarString(e.target.value)}
                                        >
                                            {yesno.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>HasSleeves</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={sleeveString}
                                            onChange={e => setSleeveString(e.target.value)}
                                        >
                                            {yesno.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>

                                    </Table.HeaderCell>
                                </Table.Row>
                                {sleeveString === 'Y' && (
                                    <Table.Row>
                                        <Table.HeaderCell>Sleeve Depth</Table.HeaderCell>
                                        <Table.HeaderCell>
                                            <input type='text'
                                                value={sleeveDepthString}
                                                onChange={(e) => { setSleeveDepthString(e.target.value) }}
                                            />
                                        </Table.HeaderCell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                        <Button positive loading={gettingRate} onClick={computeRate} floated='right'>Calculate</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    )
}

export default observer(CostFireDamper)
