import { observer } from 'mobx-react-lite';
import React from 'react'
import { Button, Grid, Header, Table } from 'semantic-ui-react'
import { useStore } from '../../../app/stores/Store';
import "./rates.css";


const heights = [
    { text: '12', value: '12' },
    { text: '14', value: '14' },
    { text: '16', value: '16' },
    { text: '18', value: '18' },
    { text: '20', value: '20' },
    { text: '24', value: '24' },
    { text: '28', value: '28' },
    { text: '32', value: '32' },
    { text: '36', value: '36' },
    { text: '40', value: '40' },
    { text: '44', value: '44' },
    { text: '48', value: '48' },
    { text: '54', value: '54' },
    { text: '60', value: '60' },
    { text: '66', value: '66' },
    { text: '72', value: '72' },
]
const widths = [
    { text: '12', value: '12' },
    { text: '14', value: '14' },
    { text: '16', value: '16' },
    { text: '18', value: '18' },
    { text: '20', value: '20' },
    { text: '24', value: '24' },
    { text: '28', value: '28' },
    { text: '32', value: '32' },
    { text: '36', value: '36' },
    { text: '40', value: '40' },
    { text: '44', value: '44' },
    { text: '48', value: '48' },
]

const ControlDamperInput = () => {
    const { rateStore } = useStore();
    const {
        gettingRate,
        costOfSheet, setCostOfSheet,
        discountPercentage, setDiscountPercentage,
        width, setWidth,
        height, setHeight,
        expectedDeliveryFactor, setExpectedDeliveryFactor,
        salesCommission, setSalesCommission,
        getRateForControlDamper, setQuotes
    } = rateStore;


    const computeRate = async () => {
        let rate = await getRateForControlDamper();
        if (rate !== undefined && rate !== null) {
            setQuotes(rate);
        }
    }

    return (
        <>
            <Header as='h2'>Control Damper</Header>
            <Grid columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        <Button positive loading={gettingRate} onClick={computeRate} floated='right'>Add To Quote</Button>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Width</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={width}
                                            onChange={e => setWidth(e.target.value)}
                                        >
                                            {widths.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Height</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={height}
                                            onChange={e => setHeight(e.target.value)}
                                        >
                                            {heights.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>Cost Of Galvanised Sheet</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <input type='text'
                                            value={costOfSheet}
                                            onChange={(e) => { setCostOfSheet(e.target.value) }}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Discount Percentage</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <input type='text'
                                            value={discountPercentage}
                                            onChange={(e) => { setDiscountPercentage(e.target.value) }}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>Expected Delivery Factor</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <input type='text'
                                            value={expectedDeliveryFactor}
                                            onChange={(e) => { setExpectedDeliveryFactor(e.target.value) }}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Sales Commission</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <input type='text'
                                            value={salesCommission}
                                            onChange={(e) => { setSalesCommission(e.target.value) }}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                        </Table>
                        <Button positive loading={gettingRate} onClick={computeRate} floated='right'>Add To Quote</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>


        </>

    )
}

export default observer(ControlDamperInput)
