import { makeAutoObservable } from "mobx";

export class DeleteModalStore {


    constructor() {
        makeAutoObservable(this);


    }

    modal = {
        open: false,
        body: null,
        header: null,
        callBacKFunction: null
    }

    showDeleteModal = (header: any, content: any, callBacKFunction: any) => {
        this.modal.open = true;
        this.modal.header = header;
        this.modal.body = content;
        this.modal.callBacKFunction = callBacKFunction;
    }


    closeDeleteModal = () => {
        this.modal.open = false;
        this.modal.body = null;
        this.modal.header = null;
        this.modal.callBacKFunction = null;
    }

}