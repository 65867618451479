import React, { Fragment } from 'react'
import { Button, Grid, Header, Icon, Label } from 'semantic-ui-react';
import { useStore } from '../../../app/stores/Store';
import { history } from '../../..';
import { observer } from 'mobx-react-lite';


const TopActionButtons = () => {
    const { salesOrderStore, dataSyncStore } = useStore();
    const { loadingSalesOrder, currentSalesOrder, generateCustomerAcknowlegement,
        generatePackingList, generateWorkOrder, generateInvoice, createOrUpdateSalesOrder, savingSalesOrder, acknowledge } = salesOrderStore;
    const { syncingSalesOrders } = dataSyncStore;


    if (currentSalesOrder === null || loadingSalesOrder) {
        return <Fragment />
    }

    const getColorBasedOnStatus = (status: string) => {

        if (status === 'Invoiced') {
            return 'blue';
        }
        if (status === 'On Hold') {
            return 'red';
        }
        if (status === 'Under Process') {
            return 'teal';
        }
        if (status === 'Packing Slip') {
            return 'yellow';
        }

        return 'orange';
    }

    const CreateCustomerAcknowledgement = () => {
        generateCustomerAcknowlegement(currentSalesOrder.so_order_no).then((blob) => {
            saveAs(blob, `OrderAck_${currentSalesOrder.so_order_no}.pdf`);
        });
    }

    const CreatePackingList = () => {
        generatePackingList(currentSalesOrder.so_order_no).then((blob) => {
            saveAs(blob, `Pkg_${currentSalesOrder.so_order_no}.pdf`);
        });
    }

    const CreateWorkOrder = () => {
        generateWorkOrder(currentSalesOrder.so_order_no).then((blob) => {
            saveAs(blob, `Wrk_${currentSalesOrder.so_order_no}.pdf`);
        });
    }

    const CreateInvoice = () => {
        generateInvoice(currentSalesOrder.so_invoice_no).then((blob) => {
            saveAs(blob, `Invoice_${currentSalesOrder.so_invoice_no}.pdf`);
        });
    }
    const shouldDisableButton = () => {
        if (currentSalesOrder.so_order_status === "On Hold")
            return true;
        if (syncingSalesOrders)
            return true;
        return false;
    }

    const disablePackingSlipButton = (changedStatus: string) => {
        if (syncingSalesOrders)
            return true;
        if (currentSalesOrder.so_order_status === "On Hold")
            return true;
        if (currentSalesOrder.so_order_status === "Invoiced" || changedStatus === "Invoiced")
            return false;
        if (currentSalesOrder.so_order_status === "Packing Slip" || changedStatus === "Packing Slip")
            return false;
        return true;
    }

    const disableInvoiceButton = (changedStatus: string) => {
        if (syncingSalesOrders)
            return true;
        if (currentSalesOrder.so_order_status === "On Hold")
            return true;
        if (currentSalesOrder.so_order_status === "Invoiced" || changedStatus === "Invoiced")
            return false;
        return true;
    }


    return (
        <Fragment>
            <Header>
                <Label as='a' color={getColorBasedOnStatus(currentSalesOrder.so_order_status)} ribbon>
                    Order # : {currentSalesOrder && currentSalesOrder.so_order_no}
                </Label>
                <Label size='small' color='red' basic>
                    Rep Name : {currentSalesOrder && currentSalesOrder.so_order_rep_name}
                </Label>
                {/*
                {currentSalesOrder?.sales_order_master_id > 0 && (
                    <Button size='large' basic color='green' floated='right' onClick={() => SyncSalesOrder(currentSalesOrder.sales_order_master_id.toString())} ><Icon name='refresh' />Refresh</Button>

                )}
                */}
                <Grid columns={2}>

                    {!acknowledge ? (

                        <Grid.Row>
                            <Grid.Column width='3'>
                            </Grid.Column>
                            <Grid.Column width='13' floated='left'>
                                <Button.Group floated='left'>
                                    <Button color='teal' onClick={CreateCustomerAcknowledgement} disabled={shouldDisableButton()}>
                                        <Icon name='file pdf outline' />Acknowlegement
                                    </Button>
                                    <Button.Or />
                                    <Button color='orange' onClick={CreateWorkOrder} disabled={shouldDisableButton()}>
                                        <Icon name='file pdf outline' />Work Order
                                    </Button>
                                    <Button.Or />
                                    <Button color='yellow' onClick={CreatePackingList} disabled={disablePackingSlipButton(currentSalesOrder.so_order_status)}>
                                        <Icon name='file pdf outline' />Packing List
                                    </Button>
                                    <Button.Or />
                                    <Button color='blue' onClick={CreateInvoice} disabled={disableInvoiceButton(currentSalesOrder.so_order_status)}>
                                        <Icon name='file pdf outline' />Invoice
                                    </Button>

                                </Button.Group>
                                <Button icon color='green' floated='left'
                                    style={{ 'position': 'relative', 'zIndex': '99', marginLeft: '10px' }}
                                    loading={savingSalesOrder}
                                    disabled={syncingSalesOrders}
                                    onClick={createOrUpdateSalesOrder}>
                                    <Icon name='save' />
                                    Save
                                </Button>
                                <Button color='red' onClick={() => { history.push(`/sales`) }} disabled={syncingSalesOrders}>
                                    <Icon name='close' />Close
                                </Button>
                            </Grid.Column>
                        </Grid.Row>

                    ) : (
                        <Grid.Row>
                            <Grid.Column width='3'>
                            </Grid.Column>
                            <Grid.Column width='13' floated='left'>
                                <Button.Group floated='left'>
                                    <Button color='teal' onClick={CreateCustomerAcknowledgement} disabled={true}>
                                        <Icon name='file pdf outline' />Acknowlegement
                                    </Button>
                                    <Button.Or />
                                    <Button color='orange' onClick={CreateWorkOrder} disabled={true}>
                                        <Icon name='file pdf outline' />Work Order
                                    </Button>
                                    <Button.Or />
                                    <Button color='yellow' onClick={CreatePackingList} disabled={true}>
                                        <Icon name='file pdf outline' />Packing List
                                    </Button>
                                    <Button.Or />
                                    <Button color='blue' onClick={CreateInvoice} disabled={true}>
                                        <Icon name='file pdf outline' />Invoice
                                    </Button>

                                </Button.Group>
                                <Button icon color='green' floated='left'
                                    style={{ 'position': 'relative', 'zIndex': '99', marginLeft: '10px' }}
                                    loading={savingSalesOrder}
                                    disabled={true}
                                    onClick={createOrUpdateSalesOrder}>
                                    <Icon name='save' />
                                    Save
                                </Button>
                                <Button color='red' onClick={() => { history.push(`/sales`) }} disabled={syncingSalesOrders}>
                                    <Icon name='close' />Close
                                </Button>
                            </Grid.Column>
                        </Grid.Row>

                    )}


                </Grid>
            </Header>
        </Fragment>

    )
}

export default observer(TopActionButtons)
