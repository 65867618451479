import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { FormFieldProps, Form, Label } from 'semantic-ui-react';

interface IProps extends FieldRenderProps<string, HTMLElement>, FormFieldProps {
    label: string;
}

const TextAreaInput: React.FC<IProps> = ({ input, width, type, placeholder, rows, meta: { touched, error }, label }) => {
    return (
        <Form.Field width={width} type={type} error={touched && !!error} >
            <label>{label ? label.toString() : ''}</label>
            <textarea rows={rows} {...input} placeholder={placeholder} />
            {touched && error && <Label basic color='red'>{error}</Label>}
        </Form.Field>
    )
}

export default TextAreaInput