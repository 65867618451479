import { observer } from 'mobx-react-lite'
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Checkbox, Grid, Header, Icon, Input, Menu, Segment, Table } from 'semantic-ui-react'
import { ISalesOrderDetail } from '../../../app/models/SalesOrder';
import { useStore } from '../../../app/stores/Store';

const SalesOrderDetailsCopier = () => {
    const { salesOrderStore, modalStore } = useStore();
    const { loadingSalesOrderDetails, currentSalesOrder, searchSalesOrderDetailsForCustomer, appendSalesOrderDetails } = salesOrderStore;
    const { closeModal } = modalStore;

    const [lhsDetails, setLhsDetails] = useState<ISalesOrderDetail[]>([]);
    const [rhsDetails, setRhsDetails] = useState<ISalesOrderDetail[]>([]);

    const [lhsSelectedItems, setLhsSelectedItems] = useState<ISalesOrderDetail[]>([]);
    const [rhsSelectedItems, setRhsSelectedItems] = useState<ISalesOrderDetail[]>([]);

    const [rhsAllToggleState, setRhsAllToggleState] = useState(false);
    const [lhsAllToggleState, setLhsAllToggleState] = useState(false);

    const [partsearch, setPartSearch] = useState('');
    useEffect(() => {
        //currentSalesOrder?.salesOrderDetails;
        searchSalesOrderDetailsForCustomer().then((details) => {
            if (details) {
                setLhsDetails(details);
            }

        });
    }, [currentSalesOrder, searchSalesOrderDetailsForCustomer])


    const onUpdateSalesOrderDetails = () => {
        performSearch();
    }

    const performSearch = () => {
        searchSalesOrderDetailsForCustomer(partsearch).then((details) => {
            if (details) {
                setLhsDetails(details);
            }

        });
    }

    const updateSalesOrderDetails = () => {
        console.log('need to update sales order details');
        appendSalesOrderDetails(rhsDetails);
        //closeModal();
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            performSearch();
        }
    }

    const toggleAllLeftSelectItem = () => {
        if (lhsDetails.length === 0) {
            setLhsAllToggleState(false);
            return;
        }


        let newToggleState = !lhsAllToggleState;
        if (newToggleState) {
            setLhsSelectedItems([...lhsDetails]);
        } else {
            setLhsSelectedItems([]);
        }

        setLhsAllToggleState(newToggleState);
    }
    const toggleAllRightSelectItem = () => {
        if (rhsDetails.length === 0) {
            setRhsAllToggleState(false);
            return;
        }


        let newToggleState = !rhsAllToggleState;
        if (newToggleState) {
            setRhsSelectedItems([...rhsDetails]);
        } else {
            setRhsSelectedItems([]);
        }

        setRhsAllToggleState(newToggleState);
    }

    const toggleRightSelectItem = (detail: ISalesOrderDetail) => {
        let foundItem = false;
        let newList: ISalesOrderDetail[] = [];
        rhsSelectedItems.forEach(itemInList => {
            let mykey = getKey(itemInList);
            if (mykey === getKey(detail)) {
                foundItem = true;
            } else {
                newList.push(itemInList);
            }
        });

        if (!foundItem) {
            newList.push(detail);
        }

        setRhsSelectedItems([...newList]);
    }

    const toggleLeftSelectItem = (detail: ISalesOrderDetail) => {
        let foundItem = false;
        let newList: ISalesOrderDetail[] = [];
        lhsSelectedItems.forEach(itemInList => {
            let mykey = getKey(itemInList);
            if (mykey === getKey(detail)) {
                foundItem = true;
            } else {
                newList.push(itemInList);
            }
        });

        if (!foundItem) {
            newList.push(detail);
        }

        setLhsSelectedItems([...newList]);
    }

    const isSelectedLeft = (detail: ISalesOrderDetail) => {
        let foundItem = false;
        lhsSelectedItems.forEach(itemInList => {
            let mykey = getKey(itemInList);
            if (mykey === getKey(detail)) {
                foundItem = true;
            }
        });
        return foundItem;
    }

    const isSelectedRight = (detail: ISalesOrderDetail) => {
        let foundItem = false;
        rhsSelectedItems.forEach(itemInList => {
            let mykey = getKey(itemInList);
            if (mykey === getKey(detail)) {
                foundItem = true;
            }
        });
        return foundItem;
    }

    const getKey = (detail: ISalesOrderDetail) => {
        let key = detail.so_part_description + "W" + detail.so_item_width + "H" + detail.so_item_height + "D" + detail.so_item_dia;
        return key;
    }

    const pushToRight = () => {
        let newList: ISalesOrderDetail[] = rhsDetails;
        let existingKeys: string[] = [];
        newList.forEach(newList => {
            existingKeys.push(getKey(newList));
        });

        lhsSelectedItems.forEach(selectedElement => {
            let key = getKey(selectedElement);
            if (!existingKeys.includes(key)) {
                newList.push(selectedElement);
            }
        });
        setRhsDetails([...newList]);
        setLhsAllToggleState(false);
    }

    const pushToLeft = () => {

        let newLhsSelectedList: ISalesOrderDetail[] = lhsSelectedItems;
        let newRhsDetailsList: ISalesOrderDetail[] = rhsDetails;

        rhsSelectedItems.forEach(element => {
            let mykey = getKey(element);
            newLhsSelectedList = newLhsSelectedList.filter(item => mykey !== getKey(item));
            newRhsDetailsList = newRhsDetailsList.filter(item => mykey !== getKey(item));
        });

        setRhsDetails([...newRhsDetailsList]);
        setLhsSelectedItems([...newLhsSelectedList]);
        setRhsAllToggleState(false);
    }


    return (
        <Fragment>
            <div style={{ textAlign: "center" }}>
                <Menu size={'large'} style={{ width: '100%', marginTop: 5 }}>
                    <Header icon={'filter'} attached color={'teal'} content={'Filter'} />
                    <Menu.Item style={{ width: '70%' }}>
                        <Input type='text' value={partsearch}
                            onChange={(e) => setPartSearch(e.target.value)}
                            onKeyDown={handleKeyDown}
                            style={{ width: '70%' }} placeholder='Search...' action>
                            <input />
                            <Button icon color='blue' onClick={onUpdateSalesOrderDetails} loading={loadingSalesOrderDetails}>
                                <Icon name='search' />
                            </Button>

                        </Input>
                    </Menu.Item>
                    <Menu.Item style={{ width: '30%' }}>
                        <Button.Group>
                            <Button color='yellow' onClick={() => closeModal()}>Close</Button>
                            <Button.Or />
                            <Button positive onClick={() => updateSalesOrderDetails()}>Add... </Button>
                        </Button.Group>
                    </Menu.Item>

                </Menu>
            </div>

            <br />
            <br />
            <Grid columns={3} divided verticalAlign='middle'>
                <Grid.Row stretched>
                    <Grid.Column width='8'>
                        <Segment.Group compact>
                            <div className='scrollable_table'>
                                <Table padded celled definition>

                                    <Table.Body>
                                        <Table.Row>
                                            <Table.HeaderCell width='2'>
                                                <Checkbox slider color='red'
                                                    checked={lhsAllToggleState}
                                                    onChange={() => toggleAllLeftSelectItem()}
                                                /></Table.HeaderCell>
                                            <Table.HeaderCell width='4'>Part No</Table.HeaderCell>
                                            <Table.HeaderCell width='10'>Description</Table.HeaderCell>
                                        </Table.Row>
                                        {
                                            lhsDetails.map((detail: ISalesOrderDetail, index) => (
                                                <Table.Row key={index} onClick={() => toggleLeftSelectItem(detail)}>
                                                    <Table.Cell collapsing>
                                                        <Checkbox slider checked={isSelectedLeft(detail)} />
                                                    </Table.Cell>
                                                    <Table.Cell>{detail.so_part_no}</Table.Cell>
                                                    {detail.so_item_dia !== "" ? (
                                                        <Table.Cell>{detail.so_part_description}<br /> - Dia ({detail.so_item_dia})</Table.Cell>
                                                    ) : (
                                                        <Table.Cell>{detail.so_part_description}<br /> - W ({detail.so_item_width}) X H ({detail.so_item_height})</Table.Cell>
                                                    )}
                                                </Table.Row>
                                            ))
                                        }
                                    </Table.Body>

                                </Table>
                            </div>
                        </Segment.Group>
                    </Grid.Column>
                    <Grid.Column width='1'>
                        <Segment.Group compact>
                            <Button icon='right arrow' positive onClick={pushToRight} />
                            <br />
                            <Button icon='left arrow' color='yellow' positive onClick={pushToLeft} />
                        </Segment.Group>
                    </Grid.Column>
                    <Grid.Column width='7'>
                        <Segment.Group compact>
                            <div className='scrollable_table'>
                                <Table padded celled definition>
                                    <Table.Body>
                                        <Table.Row style={{ lineHeight: '20px', padding: '20px' }}>
                                            <Table.HeaderCell width='2'>
                                                <Checkbox slider color='red'
                                                    checked={rhsAllToggleState}
                                                    onChange={() => toggleAllRightSelectItem()}
                                                /></Table.HeaderCell>
                                            <Table.HeaderCell width='4'>Part No</Table.HeaderCell>
                                            <Table.HeaderCell width='10'>Part Description</Table.HeaderCell>
                                        </Table.Row>
                                        {
                                            rhsDetails.map((detail: ISalesOrderDetail, index) => (
                                                <Table.Row key={index} onClick={() => toggleRightSelectItem(detail)}>
                                                    <Table.Cell collapsing>
                                                        <Checkbox slider checked={isSelectedRight(detail)} />
                                                    </Table.Cell>
                                                    <Table.Cell>{detail.so_part_no}</Table.Cell>
                                                    <Table.Cell>{detail.so_part_description}</Table.Cell>
                                                </Table.Row>
                                            ))
                                        }
                                    </Table.Body>

                                </Table>
                            </div>

                        </Segment.Group>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Fragment>

    )
}

export default observer(SalesOrderDetailsCopier)
