import React from 'react'
import { Segment } from 'semantic-ui-react'

const NotImplemented = () => {
    return (
        <Segment>
            Darn it! -- you caught me, this is feature is being built; please let me know how urgently you want this working :).
      </Segment>
    )
}

export default NotImplemented
