import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react'
import { Button, Radio } from 'semantic-ui-react';
import { history } from '../../../..';
import { useStore } from '../../../../app/stores/Store';

/*
const options = [
    { key: 'customerName', text: 'Customer Name', value: 'customerName' },
    { key: 'customerAlias', text: 'Customer Alias', value: 'customerAlias' },
    { key: 'orderNumber', text: 'Order Number', value: 'orderNumber' },
    { key: 'orderStatus', text: 'Order Status', value: 'orderStatus' },
    { key: 'poNumber', text: 'P O Number', value: 'poNumber' }
]
*/





const SearchSalesOrdersForm = () => {
    const { salesOrderStore, searchStore } = useStore();
    const { searchSalesOrders, loadSalesOrders } = salesOrderStore;
    const { resetSearch, showAllOrders, showInProgressOnly, searchType, searchValue } = searchStore;


    const [orderNumber, setOrderNumber] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [ponumber, setPONumber] = useState('');
    const [ordersShowAll, setOrdersShowAll] = useState(false);


    useEffect(() => {
        if (searchType === "orderNumber") {
            setOrderNumber(searchValue);
        } else if (searchType === "customerName") {
            setCustomerName(searchValue);
        } else if (searchType === "ponumber") {
            setPONumber(searchValue);
        }

        return () => {
            setOrderNumber('');
            setCustomerName('');
            setPONumber('');
        }

    }, [searchType, searchValue])


    const toggleSearchOrderFilter = () => {
        let newState = !ordersShowAll;
        basicReset();
        if (newState) {
            showAllOrders();
        } else {
            showInProgressOnly();
        }
        setOrdersShowAll(newState);
        loadSalesOrders();
    }

    const searchOrderByOrderNumber = () => {
        setCustomerName('');
        showAllOrders();
        setOrdersShowAll(true);
        searchSalesOrders('orderNumber', orderNumber);
    }
    const searchOrdersByCustomerName = () => {
        setOrderNumber('');
        showAllOrders();
        setOrdersShowAll(true);
        searchSalesOrders('customerName', customerName);
    }

    const searchOrdersByPO = () => {
        console.log('search po number ' + ponumber);
        setOrderNumber('');
        showAllOrders();
        setOrdersShowAll(true);
        searchSalesOrders('ponumber', ponumber);
    }


    const basicReset = () => {
        resetSearch();
        setCustomerName('');
        setOrderNumber('');
    }
    const resetForm = () => {
        console.log('reset form clicked');
        basicReset();
        setOrdersShowAll(false);
        loadSalesOrders();
    }


    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            if (event.target.name.includes('ponumber')) {
                searchOrdersByPO();
            } else if (event.target.name.includes('order')) {
                searchOrderByOrderNumber();
            } else {
                searchOrdersByCustomerName();
            }
        }
    }

    const handleFocus = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => event.target.select();
    return (
        <Fragment>
            <div className='resultOptions'>
                <div className='resultOptions__toggle'>
                    {ordersShowAll ? (
                        <div className='resultOptions__toggle_heading toggle_heading_all'>Showing: All Orders</div>
                    ) : (
                        <div className='resultOptions__toggle_heading'>Showing: Orders Not Invoiced</div>
                    )}

                    <Radio toggle checked={ordersShowAll} onChange={() => toggleSearchOrderFilter()} />
                </div>
                <div className="ui action input">
                    <input name='orderNumber' type="text" value={orderNumber} placeholder="Search Order No ..." style={{ height: '40px' }}
                        onFocus={handleFocus}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setOrderNumber(e.target.value)}
                    />
                    <button className="ui icon button" onClick={() => searchOrderByOrderNumber()} ><i aria-hidden="true" className="search icon"></i></button>
                </div>
                <div className="ui action input">
                    <input name='customerName' type="text" value={customerName} placeholder="Search Customer ..." style={{ height: '40px' }}
                        onFocus={handleFocus}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setCustomerName(e.target.value)}
                    />
                    <button className="ui icon button" onClick={() => searchOrdersByCustomerName()}><i aria-hidden="true" className="search icon"></i></button>
                </div>
                <div className="ui action input">
                    <input name='ponumber' type="text" value={ponumber} placeholder="Search PO ..." style={{ height: '40px' }}
                        onFocus={handleFocus}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setPONumber(e.target.value)}
                    />
                    <button className="ui icon button" onClick={() => searchOrdersByPO()}><i aria-hidden="true" className="search icon"></i></button>
                </div>
                <div>
                    <Button color='green' onClick={() => history.push(`/salesorder/create`)} > Create New</Button>
                    <Button color='orange' onClick={resetForm} > Reset</Button>
                </div>
            </div>
        </Fragment >
    )
}

export default observer(SearchSalesOrdersForm)
