import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { Button, Icon } from 'semantic-ui-react';
import CustomerAliasTypeAhead from '../../../../../app/common/form/CustomerAliasTypeAhead';
import { ILegacySalesOrder } from '../../../../../app/models/SalesOrder';
import { useStore } from '../../../../../app/stores/Store';
import CustomerAddress from '../modals/CustomerAddress';


const tableSearchStyle = {
    marginTop: '1px',
    width: '150px',
    border: '0px solid black'
} as React.CSSProperties

const tableStyle = {
    marginTop: '1px',
    width: '300px',
    border: '1px solid black'
} as React.CSSProperties


const tableTitle = {
    fontSize: '20px',
    fontWeight: 700,
    float: 'right',
    width: '100%'
} as React.CSSProperties


// const tableTd = {
//     textAlign: 'right',
//     background: '#eee',
// } as React.CSSProperties

const tableTdSmallTextArea = {
    width: '100%',
    height: '30px',
    textAlign: 'left'
} as React.CSSProperties

const tableTdTextArea = {
    width: '100%',
    height: '100px',
    textAlign: 'left'
} as React.CSSProperties

interface IProps {
    salesorder: ILegacySalesOrder;
    setCustomerData: (name: string, value: string) => void;
}


const DocumentSoldToShipTo: React.FC<IProps> = ({ salesorder, setCustomerData }) => {

    const { modalStore } = useStore();
    const { openModal } = modalStore;

    const [customerAlias, setCustomerAlias] = useState('');
    //  const [customerName, setCustomerName] = useState('');
    const [shipToAddress, setShipToAddress] = useState(salesorder.so_billing_address);
    const [soldToAddress, setSoldToAddress] = useState(salesorder.so_shipping_address);


    useEffect(() => {

        //  if (!salesorder.so_sold_address.includes(salesorder.so_customer_name)) {
        //      setSoldToAddress(salesorder.so_customer_name + "\n" + salesorder.so_billing_address)
        // } else {
        setSoldToAddress(salesorder.so_billing_address)
        // }
        setShipToAddress(salesorder.so_shipping_address);
    }, [salesorder])

    const updateSoldToAddress = (value: string) => {
        setSoldToAddress(value);
        //setCustomerData("billing_address", value);
        salesorder.so_billing_address = value;
    }
    const updateShipToAddress = (value: string) => {
        setShipToAddress(value);
        //setCustomerData("shipping_address", value);
        salesorder.so_shipping_address = value;
    }

    return (
        <div>

            {salesorder.so_invoice_no ? (
                <Fragment>

                    <table className='table' style={{ ...tableStyle, float: 'left' }}>
                        <tbody>
                            <tr>
                                <td>
                                    <div style={tableTitle}>Sold To</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {salesorder.so_customer_name}<br /> {salesorder.so_sold_address}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className='table' style={{ ...tableStyle, float: 'right' }}>
                        <tbody>
                            <tr>
                                <td>
                                    <div style={tableTitle}>Ship To</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Field
                                        component="textarea"
                                        id='textarea'
                                        style={tableTdTextArea}
                                        name='so_shipping_address'
                                        value={salesorder.so_shipping_address} />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </Fragment>
            ) : (
                <Fragment>

                    <table className='table' style={{ ...tableStyle, float: 'left' }}>
                        <tbody>
                            <tr>
                                <td>
                                    <div style={tableTitle}>Sold To
                                        <Button basic floated={'right'} icon color='green' size='small'
                                            onClick={() => openModal(<CustomerAddress
                                                customerName={salesorder.so_customer_alias}
                                                isForSoldToAddress={true}
                                                setAddress={updateSoldToAddress}
                                            />)} >
                                            <Icon name='plus' />
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Field
                                        component="textarea"
                                        id='textarea'
                                        style={tableTdSmallTextArea}
                                        name='so_customer_name'
                                        value={salesorder.so_customer_name}
                                    />
                                    <textarea rows={6} cols={45} name='so_sold_address' placeholder={"Sold To Address"} value={soldToAddress}
                                        onChange={(e) => updateSoldToAddress(e.target.value)} onBlur={(e) => updateSoldToAddress(e.target.value)} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className='table' style={{ ...tableSearchStyle, float: 'left' }}>
                        <tbody>
                            <tr>
                                <td>
                                    <CustomerAliasTypeAhead
                                        setLocalState={setCustomerAlias}
                                        setData={setCustomerData}
                                        name="so_customer_alias"
                                        value={customerAlias} />
                                </td>
                            </tr>
                            {/*
                            <tr>
                                <td>
                                    <CustomerNameTypeAhead
                                        setLocalState={setCustomerName}
                                        setData={setCustomerData}
                                        name="fullname"
                                        value={customerName} />
                                </td>
                            </tr>
                            */}
                        </tbody>
                    </table>
                    <table className='table' style={{ ...tableStyle, float: 'right' }}>
                        <tbody>
                            <tr>
                                <td>
                                    <div style={tableTitle}>Ship To
                                    <Button basic floated={'right'} icon color='green' size='small'
                                            onClick={() => openModal(<CustomerAddress
                                                customerName={salesorder.so_customer_alias}
                                                isForSoldToAddress={false}
                                                setAddress={updateShipToAddress}
                                            />)} >
                                            <Icon name='plus' />
                                        </Button>

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Field
                                        component="textarea"
                                        id='textarea'
                                        style={tableTdSmallTextArea}
                                        name='so_customer_name'
                                        value={salesorder.so_customer_name}
                                    />
                                    <textarea rows={6} cols={45} name='so_ship_to_address' placeholder={"Ship To Address"} value={shipToAddress}
                                        onChange={(e) => updateShipToAddress(e.target.value)} onBlur={(e) => updateShipToAddress(e.target.value)} />

                                </td>
                            </tr>
                        </tbody>
                    </table>

                </Fragment>
            )}
        </div>

    )
}

export default observer(DocumentSoldToShipTo)
