import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button, Grid, Header, Table } from 'semantic-ui-react';
import { useStore } from '../../../app/stores/Store';


const DoorType = [
    { text: 'Oval Door', value: 'OvalDoor' },
    { text: 'Square Door', value: 'SquareDoor' }
]



const OvalDoorDimensions = [
    { text: '8 X 5', value: '8x5' },
    { text: '12 X 6', value: '12x6' },
    { text: '18 X 10', value: '18x10' },
    { text: '22 X 14', value: '22x14' },
    { text: '26 X 18', value: '26x18' },
]


const SquareDoorDimensions = [
    { text: '6 X 6', value: '6x6' },
    { text: '8 X 8', value: '8x8' },
    { text: '10 X 10', value: '10x10' },
    { text: '12 X 12', value: '12x12' },
    { text: '14 X 14', value: '14x14' },
    { text: '16 X 16', value: '16x16' },
    { text: '18 X 18', value: '18x18' },
    { text: '20 X 20', value: '20x20' },
    { text: '22 X 22', value: '22x22' },
    { text: '24 X 24', value: '24x24' },
]

const Doors = () => {
    const { costingStore } = useStore();
    const {
        setWidth,
        setHeight,
        setSelectedProduct,
        gettingRate,
        getRateForDoors, setQuotes
    } = costingStore;


    const [doorsTypeString, setDoorsTypeString] = useState('OvalDoor');
    const [dimensionString, setDimensionString] = useState('8x5');

    const ChangeDoorType = (value: string) => {
        setDoorsTypeString(value);
        if (value === 'OvalDoor') {
            setDimensionString('8x5');
        } else {
            setDimensionString('6x6');
        }

    }

    const computeRate = async () => {
        setSelectedProduct(doorsTypeString);

        const dimensions = dimensionString.split('x');
        setWidth(Number.parseInt(dimensions[0]));
        setHeight(Number.parseInt(dimensions[1]));

        let rate = await getRateForDoors();
        if (rate !== undefined && rate !== null) {
            setQuotes(rate);
        }
    }

    return (
        <>
            <Header as='h2'>Doors</Header>
            <Grid columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        <Table style={{ padding: '5px' }}>
                            <Table.Body>
                                <Table.Row>
                                    <Table.HeaderCell>Door Type</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={doorsTypeString}
                                            onChange={e => ChangeDoorType(e.target.value)}
                                        >
                                            {DoorType.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Dimension</Table.HeaderCell>
                                    <Table.HeaderCell>

                                        {doorsTypeString === 'OvalDoor' ? (
                                            <select
                                                value={dimensionString}
                                                onChange={e => setDimensionString(e.target.value)}
                                            >
                                                {OvalDoorDimensions.map(o => (
                                                    <option key={o.value} value={o.value}>{o.text}</option>
                                                ))}
                                            </select>
                                        ) : (
                                            <select
                                                value={dimensionString}
                                                onChange={e => setDimensionString(e.target.value)}
                                            >
                                                {SquareDoorDimensions.map(o => (
                                                    <option key={o.value} value={o.value}>{o.text}</option>
                                                ))}
                                            </select>

                                        )}

                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <Button positive loading={gettingRate} onClick={computeRate} floated='right'>Calculate</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>

    );
};

export default observer(Doors);
