import React, { Fragment, useEffect } from 'react'
import CustomerForm from '../CustomerForm';
import { observer } from 'mobx-react-lite';
import { Segment } from 'semantic-ui-react';
import { useStore } from '../../../app/stores/Store';

const CreateNewCustomer = () => {

    const { customerStore } = useStore();
    const { selectedCustomer, setSelectedCustomer } = customerStore;

    useEffect(() => {
        setSelectedCustomer({
            yellowpages_id: 0,
            is_company: 0,
            name_title: '',
            fullname: '',
            name_alias: '',
            phone1: '',
            phone1_ext: '',
            phone2: '',
            phone2_ext: '',
            cell: '',
            fax: '',
            website: '',
            country: '',
            city: '',
            multiemail: '',
            address: '',
            billing_address: '',
            shipping_address: '',
            crm_notes: '',
            fein_number: '',
            carrier_name: '',
            added_by: 2,
            added_on: new Date(),
            updated_by: 0,
            updated_on: new Date()
        });
    }, [setSelectedCustomer])

    const handleOnCancel = () => {
        console.log('route to customers');
    }


    return (
        <Fragment>
            <br />
            <br />
            <Segment>
                <CustomerForm intialValue={selectedCustomer} onCancel={handleOnCancel} />
            </Segment>
        </Fragment>
    )
}

export default observer(CreateNewCustomer)
