import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { IGnattData } from "../models/Chart";
import { ISalesOrderDatePlan, IUpdatedDate } from "../models/PlanningModels";

export default class PlanningStore {

    constructor() {
        makeAutoObservable(this);
    }

    loadingInitial = false;
    rawPlannedOrderMap = new Map();
    rawGnattOrderMap = new Map();

    get RawSalesOrders(): ISalesOrderDatePlan[] {
        return Array.from(this.rawPlannedOrderMap.values());
    }

    get GnattSalesOrders(): IGnattData[] {
        return Array.from(this.rawGnattOrderMap.values());
    }

    UpdatePlannedData = (item: IGnattData) => {
        let toBeUpdated = this.rawGnattOrderMap.get(item.id);
        toBeUpdated.start = item.start;
    }

    updateDateForOrder = async (data: IUpdatedDate) => {
        this.loadingInitial = true;
        try {
            await agent.orderplanning.updateDates(data);
            runInAction(() => {
                console.log('updating dates ');
                this.loadingInitial = false;
            })
        } catch (error) {
            console.log(error);
            this.loadingInitial = false;
        }
    }

    synchronizeData = async () => {
        this.loadingInitial = true;
        try {
            await agent.orderplanning.sync();
            runInAction(() => {
                console.log('synchronized');
                this.loadingInitial = false;
            })
        } catch (error) {
            console.log(error);
            this.loadingInitial = false;
        }
    }

    getPlannedData = async () => {
        this.loadingInitial = true;
        try {
            console.log('getting planned orders');
            const orders = await agent.orderplanning.list();
            runInAction(() => {
                console.log('got planned orders ', orders);

                orders.forEach(order => {
                    this.rawPlannedOrderMap.set(order.id, order);

                    if (order.linkId === -1 || order.operationType === "MainOrder") {
                        this.addMainOrder(order);
                    } else {
                        this.addOrderItem(order);
                    }
                });
                this.loadingInitial = false;
                return orders;
            })
        } catch (error) {
            console.log(error);
            this.loadingInitial = false;
        }
    }

    private addMainOrder = (plannedOrder: ISalesOrderDatePlan) => {
        let mydata = {
            id: plannedOrder.id,
            start: new Date(plannedOrder.startDate),
            end: new Date(plannedOrder.endDate),
            name: plannedOrder.name,
            color: 'black'
        }
        this.rawGnattOrderMap.set(plannedOrder.id, mydata);
    };

    private addOrderItem = (plannedOrder: ISalesOrderDatePlan) => {
        let mycolor = "yellow";
        if (plannedOrder.operationType === "Shear") {
            mycolor = "red";
        } else if (plannedOrder.operationType === "Bend") {
            mycolor = "blue";
        } else if (plannedOrder.operationType === "Assemble") {
            mycolor = "green";
        }


        let mydata = {
            id: plannedOrder.id,
            start: new Date(plannedOrder.startDate),
            end: new Date(plannedOrder.endDate),
            name: plannedOrder.name,
            color: mycolor
        }
        this.rawGnattOrderMap.set(plannedOrder.id, mydata);
    }

}