import React, { Fragment } from 'react';
import { ILegacyCustomer } from '../../app/models/Customer';
import { Form as FinalForm, Field } from 'react-final-form';
import { Grid, Header, Form, Button, Label, Icon, Segment } from 'semantic-ui-react';
import { combineValidators, isRequired, composeValidators, hasLengthGreaterThan } from 'revalidate';
import TextInput from '../../app/common/form/TextInput';
import TextAreaInput from '../../app/common/form/TextAreaInput';
import { observer } from 'mobx-react-lite';
import { history } from '../..'
import { useStore } from '../../app/stores/Store';

interface IProps {
    intialValue: ILegacyCustomer | null;
    onCancel: () => void;
}

const validate = combineValidators({
    fullname: isRequired({ message: 'Customer Name is a required field' }),
    name_alias: composeValidators(
        isRequired('Alias'),
        hasLengthGreaterThan(3)({ message: 'Alias needs to be atleast 4 characters' })
    )()
});

const CustomerForm: React.FC<IProps> = ({ intialValue, onCancel }) => {
    const { location } = history;
    const { customerStore } = useStore();
    const { createCustomer, editCustomer, submitting, selectedCustomer } = customerStore;

    const handleFinalFormSubmit = (values: any) => {
        if (selectedCustomer === undefined) return;
        if (selectedCustomer!.yellowpages_id > 0)
            editCustomer(values);
        else
            createCustomer(values);
    }
    return (
        <Fragment>
            <FinalForm initialValues={intialValue} validate={validate} onSubmit={handleFinalFormSubmit}>
                {({ handleSubmit, invalid, pristine }) => (
                    <Form onSubmit={handleSubmit} size='small'>
                        <Fragment>
                            {!location.pathname.startsWith('/create') && (
                                <Button icon color='red' style={{ 'position': 'relative', 'zIndex': '99' }} floated='right'
                                    onClick={onCancel}>
                                    <Icon name='close' />
                                       Cancel
                                </Button>
                            )}
                            <Button type='submit'
                                color='green' loading={submitting}
                                //disabled={submitting || invalid || pristine}
                                disabled={true}
                                style={{ 'position': 'relative', 'zIndex': '99' }} floated='right'>
                                <Icon name='save' />
                                Save
                            </Button>
                        </Fragment>

                        <Header>
                            <Label as='a' color='orange' ribbon>
                                Customer Alias # : {selectedCustomer && selectedCustomer !== undefined && selectedCustomer.name_alias}
                            </Label>
                        </Header>
                        <Grid>
                            <Grid.Row stretched>
                                <Grid.Column>
                                    <Segment.Group compact>
                                        <Segment color='green'>
                                            <Form.Group>
                                                <Field width='8' label='Customer Name' placeholder='Customer Name' name='fullname' component={TextInput} />
                                                <Field width='8' label='Customer Alias' placeholder='Customer Alias' name='name_alias' component={TextInput} />
                                            </Form.Group>

                                            <Form.Group>
                                                <Field width='4' label='Fein Number' placeholder='Fein Number' name='fein_number' component={TextInput} />
                                                <Field width='4' label='Carrier Name' placeholder='Carrier Name' name='carrier_name' component={TextInput} />
                                                <Field width='4' label='Website' placeholder='Website' name='website' component={TextInput} />
                                                <Field width='4' label='City' placeholder='City' name='city' component={TextInput} />
                                            </Form.Group>
                                        </Segment>
                                    </Segment.Group>
                                </Grid.Column>
                            </Grid.Row>


                            <Grid.Row stretched textAlign='center'>
                                <Grid.Column width={4}>
                                    <Segment.Group compact>
                                        <Segment color='teal'>
                                            <h5>Contact Numbers:</h5>
                                            <Form.Group>
                                                <Field width='10' fluid placeholder='Phone 1' name='phone1' component={TextInput} />
                                                <Field width='6' fluid placeholder='Extn' name='phone1_ext' component={TextInput} />
                                            </Form.Group>
                                            <Form.Group>
                                                <Field width='10' fluid placeholder='Phone 2' name='phone2' component={TextInput} />
                                                <Field width='6' fluid placeholder='Extn' name='phone2_ext' component={TextInput} />
                                            </Form.Group>
                                            <Form.Group>
                                                <Field width='16' fluid label={'Cell No'} placeholder='Cell No' name='cell' component={TextInput} />
                                            </Form.Group>
                                            <Form.Group>
                                                <Field width='16' fluid label={'Fax No'} placeholder='Fax No' name='fax' component={TextInput} />
                                            </Form.Group>
                                        </Segment>
                                    </Segment.Group>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Segment.Group compact>
                                        <Segment color='orange'>
                                            <h5>Main Address:</h5>
                                            <Field rows={12} placeholder='Main Address' name='address' component={TextAreaInput} />
                                        </Segment>
                                    </Segment.Group>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Segment.Group compact>
                                        <Segment color='blue'>
                                            <h5>Shipping Address:</h5>
                                            <Field rows={12} placeholder='Shipping Address' name='shipping_address' component={TextAreaInput} />
                                        </Segment>
                                    </Segment.Group>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Segment.Group compact>
                                        <Segment color='red'>
                                            <h5>Billing Address:</h5>
                                            <Field rows={12} placeholder='Billing Address' name='billing_address' component={TextAreaInput} />
                                        </Segment>
                                    </Segment.Group>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        {/* {<pre> {JSON.stringify(form.getState(), null, 2)}</pre>} */}
                    </Form>
                )}
            </FinalForm>
        </Fragment>
    )
}

export default observer(CustomerForm)
