import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Button, Grid, Header, Table } from 'semantic-ui-react'
import { useStore } from '../../../app/stores/Store';

const yesno = [
    { text: 'Yes', value: 'Y' },
    { text: 'No', value: 'N' }
]


const motorModels = [
    { text: '---- NA ----', value: '' },
    { text: 'Bellimo1', value: 'Bellimo1' },
    { text: 'Bellimo2', value: 'Bellimo2' },
    { text: 'Bellimo3', value: 'Bellimo3' }
]

const CostFireSmokeDamper = () => {
    const { costingStore } = useStore();
    const {
        gettingRate,
        setUpperDimension,
        setLeftRightHeight,
        setAngles,
        setCollars,
        setSleeves,
        setSleeveDepth,
        motorModel,
        setMotorModel,
        getRateForFireSmokeDamper, setQuotes
    } = costingStore;

    const [upperDimString, setUpperDimString] = useState('12')
    const [leftRightHeightString, setLeftRightHeightString] = useState('12')
    const [anglesString, setAnglesString] = useState('N')
    const [collarString, setCollarString] = useState('N')
    const [sleeveString, setSleeveString] = useState('N')
    const [sleeveDepthString, setSleeveDepthString] = useState('16')


    const computeRate = async () => {
        setUpperDimension(Number.parseFloat(upperDimString));
        setLeftRightHeight(Number.parseFloat(leftRightHeightString));

        setAngles(anglesString !== 'N')
        setCollars(collarString !== 'N')
        setSleeves(sleeveString !== 'N')
        setSleeveDepth(Number.parseFloat(sleeveDepthString));

        let rate = await getRateForFireSmokeDamper();
        if (rate !== undefined && rate !== null) {
            setQuotes(rate);
        }
    }

    return (
        <>
            <Header as='h2'>Fire Smoke Damper</Header>
            <Grid columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Upper Dim</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <input type='text'
                                            value={upperDimString}
                                            onChange={(e) => { setUpperDimString(e.target.value) }}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>LH / RH</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <input type='text'
                                            value={leftRightHeightString}
                                            onChange={(e) => { setLeftRightHeightString(e.target.value) }}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.HeaderCell>HasAngles</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={anglesString}
                                            onChange={e => setAnglesString(e.target.value)}
                                        >
                                            {yesno.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>HasCollar</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={collarString}
                                            onChange={e => setCollarString(e.target.value)}
                                        >
                                            {yesno.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>HasSleeves</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={sleeveString}
                                            onChange={e => setSleeveString(e.target.value)}
                                        >
                                            {yesno.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>

                                    </Table.HeaderCell>
                                    {sleeveString === 'Y' ? (
                                        <>
                                            <Table.HeaderCell>Sleeve Depth</Table.HeaderCell>
                                            <Table.HeaderCell>
                                                <input type='text'
                                                    value={sleeveDepthString}
                                                    onChange={(e) => { setSleeveDepthString(e.target.value) }}
                                                />
                                            </Table.HeaderCell>
                                        </>
                                    ) : (
                                        <>
                                            <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                                            <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                                        </>
                                    )}
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>Motor Model</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={motorModel}
                                            onChange={e => setMotorModel(e.target.value)}
                                        >
                                            {motorModels.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                                    <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                                </Table.Row>

                            </Table.Header>
                        </Table>
                        <Button positive loading={gettingRate} onClick={computeRate} floated='right'>Calculate</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>

    )
}

export default observer(CostFireSmokeDamper)
