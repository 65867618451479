import React from 'react'
import { Table } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import TrackingTabPane from './tabs/TrackingTabPane';
import { IDocument } from '../../app/models/Kiosk';
import { useStore } from '../../app/stores/Store';

const TrackingResultForm = () => {
    const { scanStore, commonStore } = useStore();
    const { documents } = scanStore;
    const { addTabPane, tabPanes } = commonStore;

    const addTab = (id: string, tabName: string) => {
        addTabPane(tabName, {
            menuItem: tabName,
            render: () => <TrackingTabPane alias={tabName} documentid={id} tabCount={tabPanes.length} />
        });
    }
    return (
        <Table fixed celled selectable compact size='small' striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Document Id</Table.HeaderCell>
                    <Table.HeaderCell>Confirmed State</Table.HeaderCell>
                    <Table.HeaderCell>Total Scans</Table.HeaderCell>
                    <Table.HeaderCell>Update Date / Time</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {
                    documents.map((document: IDocument) => (
                        <Table.Row key={document.id} onClick={() => addTab(`${document.documentId}`, document.documentId)} >
                            <Table.Cell>{document.documentId}</Table.Cell>
                            <Table.Cell>{document.commitedMovementCode}</Table.Cell>
                            <Table.Cell>{document.totalExpectedScans}</Table.Cell>
                            <Table.Cell>{document.updateDate.split('T')[0]} / {document.updateDate.split('T')[1].split('.')[0]}</Table.Cell>
                        </Table.Row>

                    ))
                }
            </Table.Body>
        </Table>
    )
}

export default observer(TrackingResultForm)
