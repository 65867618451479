import { observer } from 'mobx-react-lite'
import React from 'react'
import { Grid, Tab } from 'semantic-ui-react'
import InventoryResultForm from './InventoryResultForm'
import InventorySearchForm from './InventorySearchForm'

const InventoryHomeTab = () => {
    return (
        <Tab.Pane attached={true}>
            <Grid fluid='true'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <InventorySearchForm />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <InventoryResultForm />
                    </Grid.Column>
                </Grid.Row>

            </Grid>

        </Tab.Pane>
    )
}

export default observer(InventoryHomeTab)
