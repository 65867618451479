import React, { Fragment } from 'react'
import { Table, Pagination, ButtonGroup, Button, Icon } from 'semantic-ui-react';
import { isNumber } from 'util';
import { ILegacyPurchaseOrder } from '../../../app/models/PurchaseOrder';
import { observer } from 'mobx-react-lite';
import POListPlaceholder from './POListPlaceholder';
import { useStore } from '../../../app/stores/Store';
import { history } from '../../../';

const POSearchResults = () => {
    const { poStore, searchStore } = useStore();
    const { loadingInitial, loadingSearch, loadingPO,
        purchaseorders, loadPurchaseOrdersOnPage
    } = poStore;
    //    const { showDeleteModal, closeDeleteModal } = deleteModalStore;
    const { totalPages, currentPageNo } = searchStore;
    //const { addTabPane, tabPanes } = commonStore;

    /*
        const addTab = (id: string, tabName: string) => {
            // addTabPane(tabName, {
            //     menuItem: tabName,
            //     render: () => <POTabPane alias={tabName} poID={id} tabCount={tabPanes.length} />
            // });
    
            console.log(tabName, id);
            addTabPane(tabName, {
                menuItem: tabName,
                render: () => <PurchaseOrderDocumentView order={tabName} orderid={id} tabCount={tabPanes.length} />
            });
        }
        */

    // const handleDelete = (e: any, id: number) => {
    //     e.stopPropagation();

    //     showDeleteModal('Delete Customer', 'Are you sure you want to Delete this Purchase Order?', () => {
    //         deletePurchaseOrder(id);
    //         closeDeleteModal();

    //     });
    //     return true;
    // }

    const handlePageChange = (pageNo: string | number | undefined) => {
        if (isNumber(pageNo))
            loadPurchaseOrdersOnPage(pageNo - 1)
    }


    const purchaseOrderDetails = (id: string) => {
        history.push(`/purchaseorder/${id}`);
    }

    /*
    const createNewAndOpen = () => {
        createPurchaseOrder().then((porder) => {
            if (porder) {
                addTab(`${porder.purchase_order_master_id}`, porder.po_order_no);
            }
        });
    }
    */

    if (loadingSearch || loadingInitial) return <POListPlaceholder />

    return (
        <Fragment>
            <Table fixed celled selectable compact size='small' striped>
                <Table.Header>
                    <Table.Row textAlign='center'>
                        <Table.HeaderCell style={{ width: '10%' }}>P O Number</Table.HeaderCell>
                        <Table.HeaderCell>Supplier Name</Table.HeaderCell>
                        <Table.HeaderCell>Supplier Address</Table.HeaderCell>
                        <Table.HeaderCell>Shipping Address</Table.HeaderCell>
                        <Table.HeaderCell>Net Amount</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>UpdateDate</Table.HeaderCell>
                        <Table.HeaderCell style={{ width: '5%' }}>
                            <Button icon color='green' floated='right' onClick={() => history.push(`/purchaseorder/create`)} loading={loadingPO}>
                                <Icon name='plus' />
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        purchaseorders.map((purchaseorder: ILegacyPurchaseOrder) => (
                            <Table.Row textAlign='center' key={purchaseorder.purchase_order_master_id}
                                onClick={() => purchaseOrderDetails(purchaseorder.purchase_order_master_id.toString())}>
                                <Table.Cell>{purchaseorder.po_order_no}</Table.Cell>
                                <Table.Cell>{purchaseorder.po_customer_name}</Table.Cell>
                                <Table.Cell>{purchaseorder.po_supplier_address}</Table.Cell>
                                <Table.Cell>{purchaseorder.po_shipping_address}</Table.Cell>
                                <Table.Cell>$ {purchaseorder.po_net_amount}</Table.Cell>
                                <Table.Cell>{purchaseorder.po_order_status}</Table.Cell>
                                <Table.Cell>{purchaseorder.po_updated_on?.toString().split('T')[0]} </Table.Cell>
                                <Table.Cell>
                                    <ButtonGroup>
                                        <Button basic icon color='blue'
                                            onClick={() => purchaseOrderDetails(purchaseorder.purchase_order_master_id.toString())}>
                                            <Icon name='align justify' />
                                        </Button>
                                    </ButtonGroup>
                                </Table.Cell>
                            </Table.Row>

                        ))
                    }
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan='6'>
                            <Pagination
                                floated='right'
                                defaultActivePage={currentPageNo + 1}
                                totalPages={totalPages}
                                onPageChange={(e, data) => handlePageChange(data.activePage)} />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>

            </Table>
        </Fragment>
    )
}

export default observer(POSearchResults)
