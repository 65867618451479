import React from 'react';
import { Field } from 'react-final-form';
import { ILegacySalesOrder } from '../../../../../app/models/SalesOrder';

const tableTd = {
    textAlign: 'right',
    border: '1px solid #000',
    background: '#eee',
} as React.CSSProperties



interface IProps {
    salesorder: ILegacySalesOrder;
}


const DocumentShipmentDetails: React.FC<IProps> = ({ salesorder }) => {
    return (
        <table className='table' id='meta' style={{ width: '100%', border: 'none', marginTop: '10px' }}>
            <thead>
                <tr className='item-row'>
                    <th className='darken'>Sales Rep.</th>
                    <th className='darken'>Shipping Method</th>
                    <th className='darken'>Account No</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={tableTd}>
                        <Field
                            component='textarea'
                            id='textarea'
                            style={{ textAlign: 'left' }}
                            name='so_order_rep_name'
                            value={salesorder.so_order_rep_name}
                        />
                    </td>
                    <td style={tableTd}>
                        <Field
                            component='textarea'
                            id='textarea'
                            style={{ textAlign: 'left' }}
                            name='so_shipping_carrier_name'
                            value={salesorder.so_shipping_carrier_name}
                        />
                    </td>
                    <td style={tableTd}>
                        <Field
                            component='textarea'
                            id='textarea'
                            style={{ textAlign: 'left' }}
                            name='so_shipping_account_no'
                            value={salesorder.so_shipping_account_no}
                        />
                    </td>
                </tr>
            </tbody>
        </table >
    )
}

export default DocumentShipmentDetails
