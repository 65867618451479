import React from 'react';
import { IDocument } from '../../../app/models/Kiosk';
import { Segment, Item, SemanticCOLORS } from 'semantic-ui-react';
import { formatDistance } from 'date-fns';

interface IProps {
    documents: IDocument[];
    isHorizontal?: boolean;
}

const SegmentList: React.FC<IProps> = ({ documents, isHorizontal }) => {

    const GenerateSegmentWithColor = (document: IDocument, mycolor: SemanticCOLORS) => {
        return (
            <Segment key={document.id} stacked inverted color={mycolor} tertiary>
                <Item.Group divided>
                    <Item>
                        <Item.Content verticalAlign='top'>
                            <Item.Header>{document.documentId} [{document.customerName.substring(0, 15)}...]</Item.Header>
                            <Item.Meta>
                                <b>Last Change:
                                    {
                                        formatDistance(
                                            Date.parse(document.updateDate.substring(0, document.updateDate.lastIndexOf("."))),
                                            Date.parse(document.queryDate.substring(0, document.queryDate.lastIndexOf("."))))
                                    } ago</b>
                            </Item.Meta>
                            <Item.Description>
                                Scanned: [{document.totalFoundScans}] out of [{document.totalExpectedScans}]
                        </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
        );
    }

    const SegmentRenderer = (document: IDocument) => {
        switch (document.progressScore) {
            case 1:
                return GenerateSegmentWithColor(document, "green");
            case 2:
                return GenerateSegmentWithColor(document, "yellow");
            case 3:
                return GenerateSegmentWithColor(document, "orange");
            case 4:
                return GenerateSegmentWithColor(document, "red");
            default:
                return GenerateSegmentWithColor(document, "green");
        }
    }

    return (
        <Segment.Group raised horizontal={isHorizontal ? true : false}>
            {documents.map(document => (
                SegmentRenderer(document)
            ))}
        </Segment.Group>
    )
}

export default SegmentList
