import { observer } from 'mobx-react-lite'
import React, { Fragment, useEffect, useState } from 'react'
import { Search } from 'semantic-ui-react'
import { useStore } from '../../stores/Store';


interface IProps {
    name: string,
    value: string,
    setData: (name: string, value: string) => void,
    setLocalState: (value: string) => void;
}


const SupplierNameTypeAhead: React.FC<IProps> = ({ name, value, setData, setLocalState }) => {
    const { customerStore } = useStore();
    const { loadingSearch, typeAheadSearchCustomers, searchedCustomers } = customerStore;

    const [searchTerm, setSearchTerm] = useState(value);
    const [handler, setHandler] = useState<any>(null);

    useEffect(() => {
        if (value === '')
            setSearchTerm('');
        if (value !== searchTerm)
            setSearchTerm(value);

    }, [value, searchTerm])


    const handleResultSelect = (e: any, { result }: any) => {
        setSearchTerm(result.fullname);
        setLocalState(result.fullname);


        setData('po_customer_name', result.fullname);
    }

    const handleSearchChange = (e: any, { value }: any) => {
        if (searchTerm === value) return;
        setLocalState(value);
        setSearchTerm(value);
        //setData(name, value);
        if (value === '') return;

        if (handler) {
            clearTimeout(handler);
        }
        let newHandler = setTimeout(() => {
            typeAheadSearchCustomers('suppliernames', value);
        }, 500);
        setHandler(newHandler);
    }

    const handleOnBlur = () => {
        setData(name, searchTerm);
    }
    const handleOnFocus = () => {
        setSearchTerm('');
        setLocalState('');
        //resetSearchedCustomers();
    }

    return (
        <Fragment>
            <Search style={{ marginTop: 0, marginBottom: 22 }}
                fluid
                loading={loadingSearch}
                aligned='right'
                placeholder='Supplier Name'
                onFocus={handleOnFocus}
                onResultSelect={handleResultSelect}
                onSearchChange={handleSearchChange}
                results={searchedCustomers ? searchedCustomers : []}
                value={searchTerm}
                name={name}
                onBlur={handleOnBlur}
                resultRenderer={(result) =>
                (
                    result && result.fullname &&
                    <div key={result.fullname} className='content'>
                        {result.fullname && <div>{result.fullname}</div>}
                    </div>
                )}
            />
        </Fragment>
    )
}



export default observer(SupplierNameTypeAhead)
