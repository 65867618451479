import React from 'react'
import { Tab, Grid } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import SearchPurchaseOrdersForm from './SearchPurchaseOrdersForm'
import POSearchResults from '../results/POSearchResults'

const POSearchTab = () => {
    return (
        <Tab.Pane attached={true}>
            <Grid fluid='true'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <SearchPurchaseOrdersForm />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <POSearchResults />
                    </Grid.Column>
                </Grid.Row>

            </Grid>

        </Tab.Pane>
    )
}

export default observer(POSearchTab)
