import { runInAction, toJS, makeAutoObservable } from "mobx";
import { getBlankPurchaseOrder, getBlankPurchaseOrderDetail, ILegacyPurchaseOrder, IPurchaseOrderDetail, PurchaseOrderSetter } from "../models/PurchaseOrder";
import agent from "../api/agent";
import { toast } from "react-toastify";
import { store } from "./Store";
import { getTaxesForOntarioHardCoded, Taxes } from "../models/Taxes";

export default class POStore {
    constructor() {
        makeAutoObservable(this);
    }

    loadingPO = false;
    loadingSearch = false;
    loadingInitial = false;
    poMap = new Map();
    purchaseorder: ILegacyPurchaseOrder | null = null;

    previousPurchaseOrderCopy: ILegacyPurchaseOrder | null = null;

    submitting = false;

    tabPanes: any = [];
    activeTabIndex = -1;
    tabVsIndexMap = new Map();
    savingPurchaseOrder = false;


    newPODetail: IPurchaseOrderDetail = getBlankPurchaseOrderDetail();

    addNewPartNo = (value: string) => {
        this.newPODetail.po_part_tag_no = value;
    }

    addNewPartDesc = (value: string) => {
        this.newPODetail.po_part_description = value;
    }



    addTabPane = (key: string, tab: any) => {
        if (this.tabVsIndexMap.has(key)) {
            const index = this.tabVsIndexMap.get(key);
            this.setActiveTabIndex(index);
        } else {
            this.tabPanes = [...this.tabPanes, tab];
            this.setActiveTabIndex(this.tabPanes.length - 1);
            this.tabVsIndexMap.set(key, this.activeTabIndex);
        }
    }

    getHSTAmount = (taxData: Taxes) => {
        if (this.currentPurchaseOrder === null) return 0;
        if (this.currentPurchaseOrder.po_hst_amount >= 0) {
            return parseFloat(this.currentPurchaseOrder.po_hst_amount.toString());
        } else {
            return this.getRoundedValue(this.runningTotal * (taxData!.hst / 100));
        }
    }
    getGSTAmount = (taxData: Taxes) => {
        if (this.currentPurchaseOrder === null) return 0;
        if (this.currentPurchaseOrder.po_gst_amount >= 0) {
            return parseFloat(this.currentPurchaseOrder.po_gst_amount.toString());
        } else {
            return this.getRoundedValue(this.runningTotal * (taxData!.gst / 100));
        }
    }
    getPSTAmount = (taxData: Taxes) => {
        if (this.currentPurchaseOrder === null) return 0;
        if (this.currentPurchaseOrder.po_pst_amount >= 0) {
            return parseFloat(this.currentPurchaseOrder.po_pst_amount.toString());
        } else {
            return this.getRoundedValue(this.runningTotal * (taxData!.pst / 100));
        }
    }

    getNetAmount = (taxData: Taxes) => {
        return this.getRoundedValue(this.runningTotal + this.getHSTAmount(taxData) + this.getGSTAmount(taxData) + this.getPSTAmount(taxData));
    }


    removeTabPane = (key: string) => {
        //console.log(`Trying to delete ${key} in ${this.tabVsIndexMap}`);
        if (this.tabVsIndexMap.has(key)) {
            //console.log("Got in the loop");
            this.tabPanes = this.tabPanes.filter((tab: any) => {
                return tab.menuItem !== key;
            });
            this.tabVsIndexMap.delete(key);
            this.loadPurchaseOrders();
            this.setActiveTabIndex(0);
        }
    }


    setActiveTabIndex = (tabIndex: any) => {
        this.activeTabIndex = tabIndex;
    }

    resetTabs = () => {
        this.tabPanes = [];
        this.activeTabIndex = -1;
        this.tabVsIndexMap.clear();
    }


    get purchaseorders() {
        return Array.from(this.poMap.values());
    }

    getPurchaseOrder = (id: string) => {
        return this.poMap.get(parseInt(id));
    }

    loadProgressivePurchaseOrders = async () => {
        this.loadingInitial = true;
        try {
            const salesorderEnvelope = await agent.purchaseorder.list(store.searchStore.getAxiosParams());
            const { purchaseOrders, purchaseOrderCount } = salesorderEnvelope;
            runInAction(() => {
                purchaseOrders.forEach(purchaseOrder => {
                    this.poMap.set(purchaseOrder.purchase_order_master_id, purchaseOrder);
                });
                store.searchStore.totalRecordCount = purchaseOrderCount;
                this.loadingInitial = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadingInitial = false;
            });
        }
    }

    taxes: Taxes = getTaxesForOntarioHardCoded();

    getTaxesForState = async (stateCode: string) => {
        try {
            let taxes = await agent.taxes.gettaxes(stateCode);
            runInAction(() => {
                this.taxes = taxes;
            })
            return taxes;
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.taxes = getTaxesForOntarioHardCoded();
            });
        }
    }

    addOrEditRowItem = (index: number, name: string, value: string) => {

        console.log(`called with index${index} name${name} value${value}`)

        if (this.currentPurchaseOrder === null) return;
        let poDetail = this.currentPurchaseOrder.purchaseOrderDetails[index];
        poDetail = { ...poDetail, [name]: value };
        this.updatePurchaseOrderDetail(name, poDetail, index);

    }

    updatePurchaseOrderDetail = (name: string, poDetail: IPurchaseOrderDetail, index: number) => {
        runInAction(() => {
            if (this.currentPurchaseOrder === null) return;

            if (poDetail.po_qty > 0 && poDetail.po_unit_price > 0) {
                let qty = poDetail.po_qty;
                let price = poDetail.po_unit_price;
                poDetail.po_total_amount = (this.getRoundedValue(qty * price));
                if (name.includes("price") || name.includes("qty"))
                    this.updatePurchaseOrderTotals();
            } else {
                poDetail.po_total_amount = 0;
            }


            this.currentPurchaseOrder.purchaseOrderDetails[index] = { ...this.currentPurchaseOrder.purchaseOrderDetails[index], ...poDetail };
            this.computeTotals(this.currentPurchaseOrder.purchaseOrderDetails);
        });
    }


    appendPurchaseOrderDetails = (salesOrderDetails: IPurchaseOrderDetail[]) => {
        runInAction(() => {
            if (this.currentPurchaseOrder === null) return;

            this.currentPurchaseOrder.purchaseOrderDetails = [...salesOrderDetails, ...this.currentPurchaseOrder.purchaseOrderDetails];
            this.computeTotals(this.currentPurchaseOrder.purchaseOrderDetails);
        });
    }

    updatePurchaseOrderTotals = () => {
        runInAction(() => {
            if (this.currentPurchaseOrder === null) return;
            let total = 0;


            this.currentPurchaseOrder?.purchaseOrderDetails.forEach(poDetail => {
                if (poDetail.po_part_tag_no !== "") {
                    total += poDetail.po_unit_price;
                }
            });
            this.runningTotal = total;
            this.currentPurchaseOrder.po_net_amount = total;
        });
    }

    removePurchaseOrderDetail = (index: number) => {
        if (this.currentPurchaseOrder === null) return;

        this.currentPurchaseOrder.purchaseOrderDetails.splice(index, 1);
        this.currentPurchaseOrder.purchaseOrderDetails = [...this.currentPurchaseOrder.purchaseOrderDetails];
        this.computeTotals(this.currentPurchaseOrder.purchaseOrderDetails);
    }

    computeTotals = (poDetails: IPurchaseOrderDetail[]) => {
        let total = 0;
        poDetails.forEach((po: IPurchaseOrderDetail) => {
            total += po.po_total_amount;
        });

        this.runningTotal = this.getRoundedValue(total);
        this.reComputeTaxes(this.taxes);
    }


    addBlankPurchaseOrderItem = () => {
        this.addPurchaseOrderDetail(getBlankPurchaseOrderDetail());
    }

    addPurchaseOrderDetailsArray = (purchaseOrderDetails: IPurchaseOrderDetail[]) => {
        if (this.currentPurchaseOrder === null) return;
        this.currentPurchaseOrder.purchaseOrderDetails = purchaseOrderDetails;
    }
    addPurchaseOrderDetail = (purchaseOrderDetail: IPurchaseOrderDetail) => {
        if (this.currentPurchaseOrder === null) return;
        if (purchaseOrderDetail.purchase_order_master_id === 0) {
            purchaseOrderDetail.purchase_order_master_id = this.currentPurchaseOrder.purchase_order_master_id;
        }
        this.currentPurchaseOrder.purchaseOrderDetails.push(purchaseOrderDetail);
    }

    currentPurchaseOrder: ILegacyPurchaseOrder | null = null;
    runningTotal: number = 0;

    updatePurchaseOrder = (name: string, value: string) => {

        if (this.currentPurchaseOrder !== null) {
            this.currentPurchaseOrder = PurchaseOrderSetter.SetValue(this.currentPurchaseOrder, name, value);
            this.currentPurchaseOrder = { ...this.currentPurchaseOrder }
        }
    }

    updatePurchaseOrderDetails = (details: IPurchaseOrderDetail[]) => {
        if (this.currentPurchaseOrder !== null) {
            this.currentPurchaseOrder.purchaseOrderDetails = details;
        }
    }

    loadPurchaseOrderByOrderNo = async (orderNo: string) => {
        if (orderNo === "create") {
            this.currentPurchaseOrder = getBlankPurchaseOrder();
            return;
        }
        try {
            this.loadingPO = true;
            let purchaseOrder: ILegacyPurchaseOrder | null = null;
            purchaseOrder = await agent.purchaseorder.details(orderNo);
            runInAction(() => {
                this.currentPurchaseOrder = purchaseOrder;
                this.loadingPO = false;
            })
            return this.currentPurchaseOrder;
        } catch (error) {
            runInAction(() => {
                this.loadingPO = false;
            })
            console.log(error);
        }
    }


    loadPurchaseOrder = async (id: string) => {
        let purchaseorder = this.getPurchaseOrder(id);
        if (false && purchaseorder) {
            this.purchaseorder = purchaseorder;
            return toJS(purchaseorder);  // converting from observable to Javascript object!
        } else {
            try {
                this.loadingPO = true;
                purchaseorder = await agent.purchaseorder.details(id);
                runInAction(() => {
                    this.poMap.set(purchaseorder.id, purchaseorder);
                    this.loadingPO = false;
                })
                return purchaseorder;
            } catch (error) {
                runInAction(() => {
                })
                this.loadingPO = false;
                console.log(error);
            }
        }
    }

    generatePurchaseOrder = async (orderno: string) => {
        return await agent.pdfcreator.createPurchaseOrder(orderno);
    }

    searchPurchaseOrders = async (searchType: string, searchValue: string) => {
        this.loadingSearch = true;
        try {
            this.poMap.clear();
            store.searchStore.searchType = searchType;
            store.searchStore.searchValue = searchValue;

            const poEnvelope = await agent.purchaseorder.list(store.searchStore.getAxiosParams());
            const { purchaseOrders, purchaseOrderCount } = poEnvelope;

            runInAction(() => {
                purchaseOrders.forEach(purchaseOrder => {
                    this.poMap.set(purchaseOrder.purchase_order_master_id, purchaseOrder);
                });
                store.searchStore.totalRecordCount = purchaseOrderCount;
                this.loadingSearch = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadingSearch = false;
            });
        }
    }


    loadPurchaseOrders = async () => {
        this.loadingInitial = true;
        try {
            const poEnvelope = await agent.purchaseorder.list(store.searchStore.getAxiosParams());
            const { purchaseOrders, purchaseOrderCount } = poEnvelope;

            runInAction(() => {
                this.poMap.clear();
                purchaseOrders.forEach(purchaseOrder => {
                    this.poMap.set(purchaseOrder.purchase_order_master_id, purchaseOrder);
                });
                store.searchStore.totalRecordCount = purchaseOrderCount;
                this.loadingInitial = false;
            });
        } catch (error) {
            runInAction(() => {
                console.log(error);
            });
        } finally {
            runInAction(() => {
                this.loadingInitial = false;
            });
        }
    }

    loadPurchaseOrdersOnPage = async (pageNo: number) => {
        this.loadingInitial = true;
        try {
            store.searchStore.currentPageNo = pageNo;
            const poEnvelope = await agent.purchaseorder.list(store.searchStore.getAxiosParams());
            const { purchaseOrders, purchaseOrderCount } = poEnvelope;

            runInAction(() => {
                this.poMap.clear();
                purchaseOrders.forEach(purchaseorder => {

                    this.poMap.set(purchaseorder.purchase_order_master_id, purchaseorder);
                });
                store.searchStore.totalRecordCount = purchaseOrderCount;
                this.loadingInitial = false;
            });
        } catch (error) {
            runInAction(() => {
                console.log(error);
            });
        } finally {
            runInAction(() => {
                this.loadingInitial = false;
            });
        }
    }


    editPurchaseOrder = async (purchaseorder: ILegacyPurchaseOrder) => {
        this.loadingPO = true;
        try {

            await agent.purchaseorder.edit(purchaseorder);

            runInAction(() => {
                this.loadingPO = false;
                toast.success('Edit Your changes are Saved!')
            })
            return purchaseorder;
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadingPO = false;
            });
        }
    }

    createPurchaseOrder = async () => {
        this.loadingPO = true;
        try {
            let purchaseorder = await agent.purchaseorder.create(getBlankPurchaseOrder());
            runInAction(() => {
                this.poMap.set(purchaseorder.purchase_order_master_id, purchaseorder);
                this.loadingPO = false;
                toast.success('New Purchase Order created!')
            })
            return purchaseorder;
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadingPO = false;
            });
        }
    }

    cleanPurchaseOrderDetails = (purchaseorder: ILegacyPurchaseOrder) => {

        let purchaseOrderDetails: IPurchaseOrderDetail[] = [];
        purchaseorder.purchaseOrderDetails.forEach(poDetail => {
            if (poDetail.po_part_description !== "") {
                purchaseOrderDetails.push(PurchaseOrderSetter.CleanDetailValues(poDetail));
            }
        });
        return purchaseOrderDetails;
    }

    createOrUpdatePurchaseOrder = async () => {
        if (this.currentPurchaseOrder === null)
            return;
        this.savingPurchaseOrder = true;
        this.currentPurchaseOrder.purchaseOrderDetails = this.cleanPurchaseOrderDetails(this.currentPurchaseOrder);
        try {
            let purchaseorder: ILegacyPurchaseOrder | null = null;

            if (this.currentPurchaseOrder.po_order_no === "") {
                purchaseorder = await agent.purchaseorder.create(this.currentPurchaseOrder);
                //console.log(toJS(this.currentSalesOrder));
                //salesorder = this.currentSalesOrder;
            } else {
                purchaseorder = await agent.purchaseorder.edit(this.currentPurchaseOrder);
            }
            runInAction(() => {
                this.currentPurchaseOrder = purchaseorder;
                this.savingPurchaseOrder = false;
                toast.success('New Purchase Order Saved')
            })
            return purchaseorder;
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.savingPurchaseOrder = false;
            });
        }
    }


    deletePurchaseOrder = async (id: number) => {
        this.loadingSearch = true;
        try {
            await agent.purchaseorder.delete(id);
            runInAction(() => {
                this.poMap.delete(id);
                this.loadingSearch = false;
            });
        } catch (error) {
            runInAction(() => {
                this.loadingSearch = false;
            });
            console.log(error);
        }
    }

    getRoundedValue = (value: number) => {
        return Math.round(value * 100) / 100;
    }
    reComputeTaxes = (taxdata: Taxes) => {
        runInAction(() => {
            if (this.currentPurchaseOrder === null) return;
            if (taxdata) {
                this.currentPurchaseOrder.po_hst_amount = this.getRoundedValue(this.runningTotal * (taxdata?.hst / 100));
                this.currentPurchaseOrder.po_gst_amount = this.getRoundedValue(this.runningTotal * (taxdata?.gst / 100));
                this.currentPurchaseOrder.po_pst_amount = this.getRoundedValue(this.runningTotal * (taxdata?.pst / 100));
                //         this.currentPurchaseOrder.po_qst_amount = this.getRoundedValue(this.runningTotal * (taxdata?.qst / 100));
                this.currentPurchaseOrder.po_gross_amount = this.runningTotal;
                this.currentPurchaseOrder.po_net_amount = this.getRoundedValue(this.runningTotal
                    + this.currentPurchaseOrder.po_hst_amount
                    + this.currentPurchaseOrder.po_gst_amount
                    + this.currentPurchaseOrder.po_pst_amount
                    /*+ this.currentPurchaseOrder.po_qst_amount*/);
            }
        });

    }


    getPreviousPurchaseOrderForSupplier = async (supplierName: string) => {
        this.loadingSearch = true;
        try {
            let cachePurchaseOrder = await agent.purchaseorder.searchBySupplierName(supplierName);
            runInAction(() => {
                this.previousPurchaseOrderCopy = cachePurchaseOrder;
                this.loadingSearch = false;
            });
        } catch {
            runInAction(() => {
                this.loadingSearch = false;
            });
        }

    }


}

