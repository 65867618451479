import React from 'react'
import { Tab, Grid } from 'semantic-ui-react'
import TrackingSearchForm from './TrackingSearchForm'
import TrackingResultForm from './TrackingResultForm'
import { observer } from 'mobx-react-lite'

const TrackingSearchTab = () => {
    return (
        <Tab.Pane attached={true}>
            <Grid fluid='true'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <TrackingSearchForm />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <TrackingResultForm />
                    </Grid.Column>
                </Grid.Row>

            </Grid>

        </Tab.Pane>
    )
}

export default observer(TrackingSearchTab)
