import React, { useEffect, Fragment } from 'react'
import { Segment, Grid, Header } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import SegmentList from './segments/SegmentList';
import { useStore } from '../../app/stores/Store';

const ProductionKiosk = () => {
    const { kioskStore } = useStore();
    const { createProductionHubConnection,
        stopHubConnection,
        loadProductionKiosk,
        loadingDashboard,
        labelmovements,
        dampermovements,
        crdmovements,
        controldampermovements,
        doorsmovements
    } = kioskStore;



    useEffect(() => {
        createProductionHubConnection();
        loadProductionKiosk();

        const timeInMinutes = 15;
        const oneMinute = 60000;
        const interval = setInterval(() => loadProductionKiosk(), oneMinute * timeInMinutes);

        return () => {
            stopHubConnection("Movements");
            clearInterval(interval);
        }
    }, [createProductionHubConnection, stopHubConnection, loadProductionKiosk])

    //if (loadingDashboard) return <LoadingComponent />;

    return (
        <Fragment>
            <Grid centered columns={5} style={{ height: '95vh' }}>
                <Grid.Row style={{ height: '30%' }}>
                    <Grid.Column width={16}>
                        <Segment raised color='purple' size='massive' loading={loadingDashboard}>
                            <Header>Labels</Header>
                            <SegmentList documents={labelmovements} isHorizontal={true} />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{ height: '70%' }}>
                    <Grid.Column>
                        <Segment raised color='red' size='massive'>
                            <Header>Dampers</Header>
                            <br />
                            <SegmentList documents={dampermovements} />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment color='orange' size='massive'>
                            <Header>CRD</Header>
                            <br />
                            <SegmentList documents={crdmovements} />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment raised color='yellow' size='massive'>
                            <Header>Control Dampers</Header>
                            <br />
                            <SegmentList documents={controldampermovements} />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment color='olive' size='massive'>
                            <Header>Doors</Header>
                            <br />
                            <SegmentList documents={doorsmovements} />
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment color='olive' size='massive'>
                            <Header>Deliveries</Header>
                            <br />
                            <SegmentList documents={doorsmovements} />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Fragment>
    )
}

export default observer(ProductionKiosk)
