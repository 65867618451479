import React from 'react'
import { Tab, Grid } from 'semantic-ui-react'
import SearchSalesOrdersForm from './SearchSalesOrdersForm'
import SalesOrderSearchResults from '../results/SalesOrderSearchResults'
import { observer } from 'mobx-react-lite'


const SalesOrderSearchTab = () => {

    return (
        <Tab.Pane attached={true}>

            <Grid fluid='true'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <SearchSalesOrdersForm />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <SalesOrderSearchResults />
                    </Grid.Column>
                    {/* <Grid.Column width={3}>
                    <div>Comes from my component</div>
                </Grid.Column> */}

                </Grid.Row>

            </Grid>
        </Tab.Pane>
    )
}

export default observer(SalesOrderSearchTab)
