import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { Button, Grid, Icon, Select } from 'semantic-ui-react';
import { getBlankSalesOrderDetail, ILegacySalesOrder, ISalesOrderDetail } from '../../../../../app/models/SalesOrder';
import { getTaxesForOntarioHardCoded, Taxes } from '../../../../../app/models/Taxes';
import { useStore } from '../../../../../app/stores/Store';
import SalesOrderMultipleRows from '../modals/SalesOrderMultipleRows';
import SalesOrderDetailsCsvDownload from '../SalesOrderDetailsCsvDownload';
import SalesOrderExcelAwareTable from '../SalesOrderExcelAwareTable';
import DocumentItemDetail from './DocumentItemDetail';

const tableStyle = {
    clear: 'both',
    width: '100%',
    margin: '10px 0 0',
    border: '1px solid #000',
} as React.CSSProperties;

const tableHeading = {
    background: '#eee'
} as React.CSSProperties;

// const tableRow = {
//     border: 0,
//     verticalAlign: 'top'
// } as React.CSSProperties;

const tableTd = {
    textAlign: 'left',
    border: '1px solid #000',
    background: '#eee',
} as React.CSSProperties

const tdBlank = {
    border: '0'
} as React.CSSProperties;

// const tdMid = {
//     width: '175px',
//     border: '1px solid #000',
// } as React.CSSProperties;

const tdBig = {
    width: '300px',
    border: '1px solid #000',
} as React.CSSProperties;

// const tdTextArea = {
//     width: '100%',
//     resize: 'none',
//     overflow: 'hidden',
//     border: 0
// } as React.CSSProperties;

// const tdTextAreaCenter = {
//     width: '100%',
//     textAlign: 'center'
// } as React.CSSProperties;

const tdTotals = {
    borderRight: '0',
    textAlign: 'right'
} as React.CSSProperties;

const tdTotalTextArea = {
    height: '20px',
    textAlign: 'right'
} as React.CSSProperties;

const tdRemarksTextArea = {
    height: '60px',
    textAlign: 'left'
} as React.CSSProperties;



const statecodes = [
    { key: 'AB', text: 'Alberta', value: 'AB' },
    { key: 'BC', text: 'British Columbia', value: 'BC' },
    { key: 'MB', text: 'Manitoba', value: 'MB' },
    { key: 'NB', text: 'New Brunswick', value: 'NB' },
    { key: 'NL', text: 'Newfoundland and Labrador', value: 'NL' },
    { key: 'NS', text: 'Nova Scotia', value: 'NS' },
    { key: 'NT', text: 'Northwest Territories', value: 'NT' },
    { key: 'NU', text: 'Nunavut', value: 'NU' },
    { key: 'ON', text: 'Ontario', value: 'ON' },
    { key: 'PE', text: 'Prince Edward Island', value: 'PE' },
    { key: 'QC', text: 'Qubec', value: 'QC' },
    { key: 'SK', text: 'Saskatchewan', value: 'SK' },
    { key: 'YT', text: 'Yukon', value: 'YT' },
]

interface IProps {
    salesorder: ILegacySalesOrder;
    salesorderdetails: ISalesOrderDetail[];
    addSalesOrderDetail: (salesOrderDetail: ISalesOrderDetail) => void;
    removeSalesOrderDetail: (index: number) => void;
    removeAllSalesOrderDetails: () => void;
    editSalesOrderDetail: (index: number, salesOrderDetail: ISalesOrderDetail, propertyName: string) => void;
    addSalesOrderDetailsArray: (salesOrderDetails: ISalesOrderDetail[]) => void;
    editSalesOrderTaxes: (newsalesorder: ILegacySalesOrder) => void;
    editLoadedSalesOrder: (name: string, value: string) => void;
    runningTotal: number;
}

const DocumentItemDetails: React.FC<IProps> = ({
    salesorder,
    salesorderdetails,
    addSalesOrderDetail,
    removeSalesOrderDetail,
    removeAllSalesOrderDetails,
    addSalesOrderDetailsArray,
    editSalesOrderDetail,
    editSalesOrderTaxes,
    editLoadedSalesOrder,
    runningTotal }) => {

    const { salesOrderStore, modalStore } = useStore();

    const { openModal } = modalStore;

    const { getTaxesForState } = salesOrderStore;

    const [stateSelected, setStateSelected] = useState('ON');

    const [taxdata, setTaxData] = useState<Taxes | undefined>(getTaxesForOntarioHardCoded());

    useEffect(() => {

    }, [salesorder])


    const reComputeTaxes = () => {
        if (taxdata) {
            let hst = getRoundedValue(runningTotal * (taxdata?.hst / 100));
            let gst = getRoundedValue(runningTotal * (taxdata?.gst / 100));
            let pst = getRoundedValue(runningTotal * (taxdata?.pst / 100));
            let qst = getRoundedValue(runningTotal * (taxdata?.qst / 100));

            salesorder.so_hst_amount = hst;
            salesorder.so_gst_amount = gst;
            salesorder.so_pst_amount = pst;
            salesorder.so_qst_amount = qst;
            salesorder.so_gross_amount = runningTotal;
            salesorder.so_net_amount = getRoundedValue(runningTotal + hst + gst + pst + qst);
            editSalesOrderTaxes(salesorder);
        }
    }
    const onStateChange = async (value: any) => {
        let mydata = await getTaxesForState(value);
        setTaxData(mydata);
        setStateSelected(value);
        if (mydata) {
            let hst = getRoundedValue(runningTotal * (mydata?.hst / 100));
            let gst = getRoundedValue(runningTotal * (mydata?.gst / 100));
            let pst = getRoundedValue(runningTotal * (mydata?.pst / 100));
            let qst = getRoundedValue(runningTotal * (mydata?.qst / 100));

            salesorder.so_hst_amount = hst;
            salesorder.so_gst_amount = gst;
            salesorder.so_pst_amount = pst;
            salesorder.so_qst_amount = qst;
            salesorder.so_gross_amount = runningTotal;
            salesorder.so_net_amount = getRoundedValue(runningTotal + hst + gst + pst + qst);
            editSalesOrderTaxes(salesorder);
        }
    }

    const getHSTAmount = () => {
        if (salesorder.so_hst_amount >= 0) {
            return parseFloat(salesorder.so_hst_amount.toString());
        } else {
            return getRoundedValue(runningTotal * (taxdata!.hst / 100));
        }
    }
    const getGSTAmount = () => {
        if (salesorder.so_gst_amount >= 0) {
            return parseFloat(salesorder.so_gst_amount.toString());
        } else {
            return getRoundedValue(runningTotal * (taxdata!.gst / 100));
        }
    }
    const getPSTAmount = () => {
        if (salesorder.so_pst_amount >= 0) {
            return parseFloat(salesorder.so_pst_amount.toString());
        } else {
            return getRoundedValue(runningTotal * (taxdata!.pst / 100));
        }
    }
    const getQSTAmount = () => {
        if (salesorder.so_qst_amount >= 0) {
            return parseFloat(salesorder.so_qst_amount.toString());
        } else {
            return getRoundedValue(runningTotal * (taxdata!.qst / 100));
        }
    }
    const getRoundedValue = (value: number) => {
        return Math.round(value * 100) / 100;
    }

    const getNetAmount = () => {
        return getRoundedValue(runningTotal + getHSTAmount() + getGSTAmount() + getPSTAmount() + getQSTAmount());
    }


    const addBlankSalesOrderItem = () => {
        addSalesOrderDetail(getBlankSalesOrderDetail());
    }
    return (
        <Fragment>
            <Grid divided style={{ padding: 0, marginTop: '5px' }}>
                <Grid.Row>
                    <Grid.Column >
                        <Button basic floated={'right'} icon color='green' size='small' onClick={() => openModal(<SalesOrderMultipleRows
                            sales_order_master_id={salesorder.sales_order_master_id}
                            addSalesOrderDetailsArray={addSalesOrderDetailsArray}
                        />, true)} >
                            <Icon name='list' /> Add Multiple
                            </Button>
                        <SalesOrderDetailsCsvDownload salesOrderDetails={salesorderdetails} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <SalesOrderExcelAwareTable addSalesOrderDetail={addSalesOrderDetail}>
                <table className='table' style={tableStyle}>
                    <thead>
                        <tr style={tableHeading}>
                            <th>No</th>
                            <th>Item</th>
                            <th>Description</th>
                            <th>Width</th>
                            <th>Height</th>
                            <th>Dia</th>
                            <th>Quantity</th>
                            <th>Unit Cost</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {salesorderdetails.map((sodetail, i) => {
                            return <DocumentItemDetail
                                key={i}
                                soDetail={sodetail}
                                itemCount={salesorderdetails.length}
                                currentIndex={i}
                                removeSalesOrderDetail={removeSalesOrderDetail}
                                addSalesOrderDetail={addSalesOrderDetail}
                                editSalesOrderDetail={editSalesOrderDetail}
                            />
                        }
                        )}
                        <tr>
                            <td colSpan={8}></td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <Button color='green' onClick={addBlankSalesOrderItem} ><Icon name='plus' />Add a row</Button>
                            </td>
                            <td colSpan={3}>
                                <Select
                                    style={{ width: '100%', margin: '0', float: 'right' }}
                                    onChange={(e, data) => onStateChange(data.value)}
                                    value={stateSelected}
                                    options={statecodes}
                                    placeholder={'Taxes'} />
                            </td>
                            <td colSpan={3}>
                                <Button
                                    onClick={reComputeTaxes}
                                    basic
                                    color='teal' style={{ width: '100%', margin: '0', float: 'right' }}>Re-Calculate Taxes</Button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={4} style={tdBlank}></td>
                            <td colSpan={4} style={tdTotals}>Subtotal</td>
                            <td style={tdTotals}>
                                <b>${runningTotal}</b>

                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{ ...tdBlank, textAlign: 'right' }}>Remarks :</td>
                            <td colSpan={2} rowSpan={2} style={tdBig}>
                                <Field
                                    component='textarea'
                                    id='textarea'
                                    style={tdRemarksTextArea}
                                    name='so_remarks'
                                    value={salesorder.so_remarks}
                                />
                            </td>

                            <td colSpan={4} style={tdTotals}>HST [{taxdata?.hst}%]</td>
                            <td style={tableTd}>
                                <textarea
                                    id='textarea'
                                    style={tdTotalTextArea}
                                    name='so_hst_amount'
                                    value={salesorder.so_hst_amount}
                                    onChange={(e) => {
                                        try {
                                            console.log(e.target.value);
                                            if (e.target.value) {
                                                editLoadedSalesOrder('so_hst_amount', e.target.value)
                                            } else {
                                                if (e.target.value.length === 0)
                                                    editLoadedSalesOrder('so_hst_amount', '')
                                            }
                                        } catch (error) {
                                            editLoadedSalesOrder('so_hst_amount', '')
                                        }
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={4} style={tdBlank}></td>
                            <td colSpan={4} style={tdTotals}>GST [{taxdata?.gst}%]</td>
                            <td style={tableTd}>
                                <textarea
                                    id='textarea'
                                    style={tdTotalTextArea}
                                    name='so_gst_amount'
                                    value={salesorder.so_gst_amount}
                                    onChange={(e) => {
                                        try {
                                            if (e.target.value) {
                                                editLoadedSalesOrder('so_gst_amount', e.target.value)
                                            } else {
                                                if (e.target.value.length === 0)
                                                    editLoadedSalesOrder('so_gst_amount', '')
                                            }
                                        } catch (error) {
                                            editLoadedSalesOrder('so_gst_amount', '')
                                        }
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{ ...tdBlank, textAlign: 'right' }}>Client Notes :</td>
                            <td colSpan={2} rowSpan={2} style={tdBig}>
                                <Field
                                    component='textarea'
                                    id='textarea'
                                    style={tdRemarksTextArea}
                                    name='so_client_notes'
                                    value={salesorder.so_client_notes}
                                />
                            </td>
                            <td colSpan={4} style={tdTotals}>PST [{taxdata?.pst}%]</td>
                            <td style={tableTd}>
                                <textarea
                                    id='textarea'
                                    style={tdTotalTextArea}
                                    name='so_pst_amount'
                                    value={salesorder.so_pst_amount}
                                    onChange={(e) => {
                                        try {
                                            console.log(e.target.value);
                                            if (e.target.value) {
                                                editLoadedSalesOrder('so_pst_amount', e.target.value)
                                            } else {
                                                if (e.target.value.length === 0)
                                                    editLoadedSalesOrder('so_pst_amount', '')
                                            }
                                        } catch (error) {
                                            editLoadedSalesOrder('so_pst_amount', '')
                                        }
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={4} style={tdBlank}></td>
                            <td colSpan={4} style={tdTotals}>QST [{taxdata?.qst}%]</td>
                            <td style={tableTd}>
                                <textarea
                                    id='textarea'
                                    style={tdTotalTextArea}
                                    name='so_qst_amount'
                                    value={salesorder.so_qst_amount}
                                    onChange={(e) => {
                                        try {
                                            console.log(e.target.value);
                                            if (e.target.value) {
                                                editLoadedSalesOrder('so_qst_amount', e.target.value)
                                            } else {
                                                if (e.target.value.length === 0)
                                                    editLoadedSalesOrder('so_qst_amount', '')
                                            }
                                        } catch (error) {
                                            editLoadedSalesOrder('so_qst_amount', '')
                                        }
                                    }}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td colSpan={4} style={tdBlank}></td>
                            <td colSpan={4} style={tdTotals}>Total Amount</td>
                            <td style={{ ...tableTd, textAlign: "right" }}>
                                ${getNetAmount()}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </SalesOrderExcelAwareTable>

        </Fragment>

    )
}

export default observer(DocumentItemDetails)
