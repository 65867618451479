import { store } from './Store'
import { makeAutoObservable, runInAction } from 'mobx';
import { IUser, IUserFormValues } from '../models/User';
import { history } from '../..';
import agent from '../api/agent';

export default class UserStore {
    constructor() {
        makeAutoObservable(this);
    }

    user: IUser | null = null;


    get isLoggedIn() {
        return !!this.user;
    }

    login = async (values: IUserFormValues) => {
        try {
            console.log('attempting to login');
            const user = await agent.user.login(values);
            runInAction(() => {
                console.log('got user');
                this.user = user;
                store.commonStore.setToken(user.token);
                store.modalStore.closeModal();
                history.push('/sales');

            });
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    getUser = async () => {
        try {
            const user = await agent.user.current();
            runInAction(() => {
                this.user = user;
            })
            return user;
        } catch (error) {
            console.log(error);
        }

    }

    logout = async () => {
        try {
            store.commonStore.setToken(null);
            this.user = null;
            history.push('/');
        } catch (error) {

        }
    }

    register = async (values: IUserFormValues) => {
        try {
            const user = await agent.user.register(values);
            runInAction(() => {
                this.user = user;
                store.commonStore.setToken(user.token);
                store.modalStore.closeModal();
                history.push('/sales');
            });
        } catch (error) {
            throw error;
        }
    }
}