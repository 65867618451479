
export interface IPurchaseOrderEnvelope {
    purchaseOrders: ICompactPurchaseOrder[];
    purchaseOrderCount: number;
}

export interface ICompactPurchaseOrder {
    purchase_order_master_id: number;
    po_order_no: string;
    po_order_date: Date | null;
    po_sales_order_no: string;
    po_customer_id: number;
    po_customer_name: string;
    po_customer_alias: string;
    po_supplier_address: string;
    po_shipping_address: string;
    po_billing_address: string;
    po_order_status: string;
    po_invoice_no: string;
    po_pack_slip_no: string;
    po_ship_date: Date | null;
    po_order_recipient: string;
    po_details_for_query_search: string;
    po_net_amount: number;
}

export interface ILegacyPurchaseOrder {
    purchase_order_master_id: number;
    po_order_no: string;
    po_order_date: Date | null;
    po_sales_order_no: string;
    po_customer_id: number;
    po_customer_name: string;
    po_customer_alias: string;
    po_supplier_address: string;
    po_shipping_address: string;
    po_billing_address: string;
    po_order_status: string;
    po_invoice_no: string;
    po_pack_slip_no: string;
    po_ship_date: Date | null;
    po_order_recipient: string;
    po_order_recipient_email: string;
    po_order_recipient_phone: string;
    po_order_rep_name: string;
    po_order_picked_by: string;
    po_order_taken_by: string;
    po_country_name: string;
    po_currency_name: string;
    po_client_notes: string;
    po_ship_weight: string;
    po_ship_dimz: string;
    po_freight: string;
    po_remarks: string;
    po_shipping_carrier_name: string;
    po_shipping_account_no: string;
    po_ship_via: string;
    po_shipping_instructions: string;
    po_shipping_handling_charges: string;
    po_payment_terms: string;
    po_purchase_order_no: string;
    po_fein_irs_no: string;
    po_gross_amount: number;
    po_discount_amount: number;
    po_hst_amount: number;
    po_gst_amount: number;
    po_pst_amount: number;
    po_other_tax_amount: number;
    po_net_amount: number;
    po_is_new_order: boolean;
    po_service_po_number: string;
    po_is_back_order: boolean;
    po_is_order_complete: boolean;
    po_is_return_exchange: boolean;
    po_is_other_notes: string;
    po_is_not_complete: boolean;
    po_syslog: string;
    po_status_id: number;
    po_details_for_query_search: string;
    po_added_on: Date | null;
    po_added_by: number;
    po_updated_on: Date | null;
    po_updated_by: number;
    purchaseOrderDetails: IPurchaseOrderDetail[];
    grn: string;
    grn_Remarks: string;
}


export class PurchaseOrderSetter {

    static SetValue(purchaseOrder: ILegacyPurchaseOrder, name: string, value: string) {
        if (this.hasToBeInteger(name)) {
            return { ...purchaseOrder, [name]: parseInt(value) || 0 };
        } else if (this.hasToBeFloat(name)) {
            return { ...purchaseOrder, [name]: parseFloat(value) || 0 };
        } else {
            return { ...purchaseOrder, [name]: value };
        }
    }

    static SetDetailValue(purchaseOrder: IPurchaseOrderDetail, name: string, value: string) {
        if (this.hasToBeInteger(name)) {
            return { ...purchaseOrder, [name]: parseInt(value) || 0 };
        } else if (this.hasToBeFloat(name)) {
            return { ...purchaseOrder, [name]: parseFloat(value) || 0 };
        } else {
            return { ...purchaseOrder, [name]: value };
        }
    }

    static CleanDetailValues(purchaseOrder: IPurchaseOrderDetail) {
        let cleanDetail = PurchaseOrderSetter.SetDetailValue(purchaseOrder, "po_qty", purchaseOrder.po_qty.toString());
        cleanDetail = PurchaseOrderSetter.SetDetailValue(cleanDetail, "po_unit_price", purchaseOrder.po_unit_price.toString());
        cleanDetail = PurchaseOrderSetter.SetDetailValue(cleanDetail, "po_total_amount", purchaseOrder.po_total_amount.toString());

        return cleanDetail;
    }


    static hasToBeInteger(name: string) {
        return name === "po_customer_id" ||
            name === "purchase_order_master_id" ||
            name === "purchase_order_master_id" ||
            name === "po_qty";
    }
    static hasToBeFloat(name: string) {
        return name === "po_item_sale_price" ||
            name === "po_unit_price" ||
            name === "po_item_total_amount" ||
            name === "po_total_amount";
    }

}


export interface IPurchaseOrderDetail {
    purchase_order_detail_id: number;
    purchase_order_master_id: number;
    po_order_no: string;
    po_item_sortseq: number;
    po_part_tag_no: string;
    po_weight_tare: string;
    po_heat: string;
    po_gross: string;
    stock_unit_measurement: string;
    purchase_order_parts_master_id: number;
    purchaseOrderPart?: any;
    po_part_description: string;
    po_qty: number;
    po_unit_price: number;
    po_total_amount: number;
    po_item_is_Taxed: number;
    po_part_notes: string;
    grn: string;
    grn_Remarks: string;
    grn_qty: number;
}



export const getBlankPurchaseOrderDetail = () => {
    return {
        purchase_order_detail_id: 0,
        purchase_order_master_id: 0,
        po_order_no: '',
        po_item_sortseq: 0,
        po_part_tag_no: '',
        po_weight_tare: '',
        po_heat: '',
        po_gross: '',
        stock_unit_measurement: '',
        purchase_order_parts_master_id: 0,
        purchaseOrderPart: null,
        po_part_description: '',
        po_qty: 0,
        po_unit_price: 0,
        po_total_amount: 0,
        po_item_is_Taxed: 0,
        po_part_notes: '',
        grn: '',
        grn_Remarks: '',
        grn_qty: 0
    }
}


export const getBlankPurchaseOrder = () => {
    return {
        purchase_order_master_id: 0,
        po_order_no: '',
        po_order_date: null,
        po_sales_order_no: '',
        po_customer_id: 0,
        po_customer_name: '',
        po_customer_alias: '',
        po_supplier_address: '',
        po_shipping_address: '1090 Brevik Place,\nMississauaga, \nL4W 3Y5, \nON, Canada,',
        po_billing_address: '1090 Brevik Place,\nMississauaga, \nL4W 3Y5, \nON, Canada,',
        po_order_status: '',
        po_invoice_no: '',
        po_pack_slip_no: '',
        po_ship_date: null,
        po_order_recipient: '',
        po_order_recipient_email: '',
        po_order_recipient_phone: '',
        po_order_rep_name: '',
        po_order_picked_by: '',
        po_order_taken_by: '',
        po_country_name: '',
        po_currency_name: '',
        po_client_notes: '',
        po_ship_weight: '',
        po_ship_dimz: '',
        po_freight: '',
        po_remarks: '',
        po_shipping_carrier_name: '',
        po_shipping_account_no: '',
        po_ship_via: '',
        po_shipping_instructions: '',
        po_shipping_handling_charges: '',
        po_payment_terms: '',
        po_purchase_order_no: '',
        po_fein_irs_no: '',
        po_gross_amount: 0,
        po_discount_amount: 0,
        po_hst_amount: 0,
        po_gst_amount: 0,
        po_pst_amount: 0,
        po_other_tax_amount: 0,
        po_net_amount: 0,
        po_is_new_order: false,
        po_service_po_number: '',
        po_is_back_order: false,
        po_is_order_complete: false,
        po_is_return_exchange: false,
        po_is_other_notes: '',
        po_is_not_complete: false,
        po_syslog: '',
        po_status_id: 0,
        po_details_for_query_search: '',
        po_added_on: null,
        po_added_by: 0,
        po_updated_on: null,
        po_updated_by: 0,
        purchaseOrderDetails: [],
        grn: '',
        grn_Remarks: ''
    }
}
