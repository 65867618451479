import { runInAction, toJS, makeAutoObservable } from 'mobx';
import agent from '../api/agent';
import { ICompactCustomer, ILegacyCustomer } from '../models/Customer';
import { toast } from 'react-toastify';
import { store } from './Store';

export default class CustomerStore {

    constructor() {
        makeAutoObservable(this);
    }

    loadingCustomer = false;
    loadingInitial = false;
    loadingSearch = false;
    customerMap = new Map();

    selectedCustomer: ILegacyCustomer | null = null;
    loadedAddresses: string[] = [];
    submitting = false;

    loadingAddres = false;

    searchedCustomers: ICompactCustomer[] = [];


    resetSearchedCustomers = () => {
        runInAction(() => {
            this.searchedCustomers = [];
        });
    }

    get customers() {
        return Array.from(this.customerMap.values());
    }

    get customersByDate() {
        return this.groupCustomersByDate(Array.from(this.customerMap.values()))
    }
    get allCustomersSortedByDate() {
        return this.getSortedCustomers(Array.from(this.customerMap.values()))
    }

    getSortedCustomers(customers: ILegacyCustomer[]) {
        const sortedCustomers = customers.sort(
            (a, b) => new Date(a.updated_on).getTime() - new Date(b.updated_on).getTime()
        )
        return sortedCustomers;
    }
    groupCustomersByDate(customers: ILegacyCustomer[]) {

        const sortedCustomers = customers.sort(
            (a, b) => new Date(a.updated_on).getTime() - new Date(b.updated_on).getTime()
        )
        return Object.entries(sortedCustomers.reduce((customers, customer) => {
            const date = new Date(customer.updated_on).toISOString().split('T')[0];
            customers[date] = customers[date] ? [...customers[date], customer] : [customer];
            return customers;
        }, {} as { [key: string]: ILegacyCustomer[] }));
    }


    resetLoadedAddress = async () => {
        runInAction(() => {
            this.loadedAddresses = [];
        });
    }

    filterAddress = async (customeAlias: string, searchValue: string, isForSoldToAddress: boolean) => {
        try {

            if (searchValue.length === 0) {
                searchValue = " ";
            }

            this.loadingAddres = true;
            const addresses = await agent.customer.addressFilterSearch(customeAlias, searchValue, isForSoldToAddress);
            runInAction(() => {
                this.loadedAddresses = addresses;
                this.loadingAddres = false;
            })
        } catch (error) {
            runInAction(() => {
                this.loadingAddres = false;
            })
            console.log(error);
        }

    }

    loadSoldToAddressForCustomer = async (name: string) => {
        try {
            this.loadingAddres = true;
            const addresses = await agent.customer.getSoldToAddresses(name);
            runInAction(() => {
                this.loadedAddresses = addresses;
                this.loadingAddres = false;
            })
        } catch (error) {
            runInAction(() => {
                this.loadingAddres = false;
            })
            console.log(error);
        }
    }

    loadShipToAddressForCustomer = async (name: string) => {
        try {
            this.loadingAddres = true;
            const addresses = await agent.customer.getShipToAddresses(name);
            runInAction(() => {
                this.loadedAddresses = addresses;
                this.loadingAddres = false;
            })
        } catch (error) {
            runInAction(() => {
                this.loadingAddres = false;
            })
            console.log(error);
        }
    }


    setSelectedCustomer = (customer: ILegacyCustomer) => {
        this.selectedCustomer = customer;
    }

    editCustomer = async (customer: ILegacyCustomer) => {
        this.submitting = true;
        try {
            await agent.customer.edit(customer);
            runInAction(() => {
                this.customerMap.set(customer.yellowpages_id, customer);
                this.selectedCustomer = customer;
                this.submitting = false;
                toast.success('Customer changes saved!')
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.submitting = false;
            });
        }
    }

    createCustomer = async (customer: ILegacyCustomer) => {
        this.submitting = true;
        try {
            await agent.customer.create(customer);
            runInAction(() => {
                //this.customerMap.set(customer.yellowpages_id, customer);
                this.selectedCustomer = customer;
                this.submitting = false;
                toast.success('New Customer created!')
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.submitting = false;
            });
        }
    }

    deleteCustomer = async (id: number) => {
        this.loadingSearch = true;
        try {
            await agent.customer.delete(id);
            runInAction(() => {
                this.customerMap.delete(id);
                this.loadingSearch = false;
            });
        } catch (error) {
            runInAction(() => {
                this.loadingSearch = false;
            });
            console.log(error);
        }
    }

    loadCustomer = async (id: string) => {
        let customer = this.getCustomer(id);
        if (false && customer) {
            this.selectedCustomer = customer;
            return toJS(customer);  // converting from observable to Javascript object!
        } else {
            try {
                this.loadingCustomer = true;
                customer = await agent.customer.details(id);
                runInAction(() => {
                    //this.customerMap.set(customer.id, customer);
                    this.selectedCustomer = customer;
                    this.loadingCustomer = false;
                })
                return customer;
            } catch (error) {
                runInAction(() => {
                    this.loadingCustomer = false;
                })
                console.log(error);
            }
        }
    }


    searchCustomers = async (searchType: string, searchValue: string) => {
        this.loadingSearch = true;
        try {
            this.customerMap.clear();
            //         const customers = await agent.customer.searchCustomers(searchType, searchValue);
            const customerEnvelope = await agent.customer.list(store.searchStore.getAxiosParams());
            const { customers } = customerEnvelope;
            runInAction(() => {
                let results: ICompactCustomer[] = [];
                customers.forEach(customer => {
                    this.customerMap.set(customer.yellowpages_id, customer);
                    results.push(customer);
                });
                this.searchedCustomers = results;
                this.loadingSearch = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadingSearch = false;
            });
        }
    }

    typeAheadSearchCustomers = async (searchType: string, searchValue: string) => {
        this.loadingSearch = true;
        try {
            this.customerMap.clear();
            const customers = await agent.customer.searchCustomers(searchType, searchValue);
            runInAction(() => {
                let results: ICompactCustomer[] = [];
                customers.forEach(customer => {
                    this.customerMap.set(customer.yellowpages_id, customer);
                    results.push(customer);
                });
                this.searchedCustomers = results;
                this.loadingSearch = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadingSearch = false;
            });
        }
    }



    loadCustomersOnPage = async (pageNo: number) => {
        this.loadingInitial = true;
        try {
            store.searchStore.currentPageNo = pageNo;
            console.log(store.searchStore.getAxiosParams().toString());
            const customerEnvelope = await agent.customer.list(store.searchStore.getAxiosParams());
            const { customers, customerCount } = customerEnvelope;

            runInAction(() => {
                this.customerMap.clear();
                customers.forEach(customer => {

                    this.customerMap.set(customer.yellowpages_id, customer);
                });
                store.searchStore.totalRecordCount = customerCount;
                this.loadingInitial = false;
            });
        } catch (error) {
            runInAction(() => {
                console.log(error);
            });
        } finally {
            runInAction(() => {
                this.loadingInitial = false;
            });
        }
    }

    loadCustomers = async () => {
        this.loadingInitial = true;
        try {
            const customerEnvelope = await agent.customer.list(store.searchStore.getAxiosParams());
            const { customers, customerCount } = customerEnvelope;

            runInAction(() => {
                this.customerMap.clear();
                customers.forEach(customer => {
                    this.customerMap.set(customer.yellowpages_id, customer);
                });
                store.searchStore.totalRecordCount = customerCount;
                this.loadingInitial = false;
            });
        } catch (error) {
            runInAction(() => {
                console.log(error);
            });
        } finally {
            runInAction(() => {
                this.loadingInitial = false;
            });
        }
    }

    getCustomer = (id: string) => {
        return this.customerMap.get(parseInt(id));
    }

    loadCustomerDetails = async (id: string) => {
        let customer = this.getCustomer(id);
        if (customer) {
            this.selectedCustomer = customer;
            this.loadingInitial = false;
            return toJS(customer); // converting from observable to Javascript object!
        }
        try {
            this.loadingInitial = true;
            customer = await agent.customer.details(id);
            console.log(`Got Customer ${customer}`);
            runInAction(() => {
                this.selectedCustomer = customer;
                this.customerMap.set(customer.id, customer);
                this.loadingInitial = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadingInitial = false;
            });
        }
    }
}

