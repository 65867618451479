import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Divider, Grid, Icon, Select } from 'semantic-ui-react';
import { Taxes } from '../../../app/models/Taxes';
import { useStore } from '../../../app/stores/Store';
import PurchaseOrderMultipleRows from '../../purchaseorders/tabs/modals/PurchaseOrderMultipleRows';
import DocumentTaxes from './DocumentTaxes';
import Grn from './Grn';
import MyLineItemRow from './MyLineItemRow';
import PurchaseOrderExcelAwareTable from './PurchaseOrderExcelAwareTable';

const statecodes = [
    { key: 'OO', text: 'USA', value: 'OO' },
    { key: 'AB', text: 'Alberta', value: 'AB' },
    { key: 'BC', text: 'British Columbia', value: 'BC' },
    { key: 'MB', text: 'Manitoba', value: 'MB' },
    { key: 'NB', text: 'New Brunswick', value: 'NB' },
    { key: 'NL', text: 'Newfoundland and Labrador', value: 'NL' },
    { key: 'NS', text: 'Nova Scotia', value: 'NS' },
    { key: 'NT', text: 'Northwest Territories', value: 'NT' },
    { key: 'NU', text: 'Nunavut', value: 'NU' },
    { key: 'ON', text: 'Ontario', value: 'ON' },
    { key: 'PE', text: 'Prince Edward Island', value: 'PE' },
    { key: 'QC', text: 'Quebec', value: 'QC' },
    { key: 'SK', text: 'Saskatchewan', value: 'SK' },
    { key: 'YT', text: 'Yukon', value: 'YT' },
]


const DocumentItemDetails = () => {
    const { poStore, modalStore } = useStore();
    const { loadingPO, currentPurchaseOrder, addBlankPurchaseOrderItem, addPurchaseOrderDetail,
        getTaxesForState, addPurchaseOrderDetailsArray, reComputeTaxes } = poStore;
    const { openModal } = modalStore;
    const [stateSelected, setStateSelected] = useState('ON');
    const [taxes, setTaxes] = useState<Taxes | null>(null);


    useEffect(() => {
        if (currentPurchaseOrder !== null && currentPurchaseOrder.purchaseOrderDetails.length === 0) {
            addBlankPurchaseOrderItem();
        }
    }, [currentPurchaseOrder, addBlankPurchaseOrderItem])

    if (currentPurchaseOrder === null || loadingPO) {
        return <Fragment />
    }

    const getTaxes = async (value: string, resetValue: boolean = false) => {
        let mydata = await getTaxesForState(value);
        if (mydata !== undefined)
            setTaxes(mydata);
        if (resetValue) {
            setStateSelected(value);
        }
        return mydata;
    }
    const onStateChange = async (value: any) => {
        let mydata = await getTaxes(value, true);
        if (mydata) {
            reComputeTaxes(mydata)
        }
    }


    const handleReCalculateTaxes = async () => {
        if (taxes !== undefined && taxes !== null)
            reComputeTaxes(taxes);
        let mydata = await getTaxes(stateSelected);
        if (mydata) {
            reComputeTaxes(mydata)
        }
    }
    return (
        <div className='hdocumentDetails'>
            <Divider />
            {currentPurchaseOrder.po_invoice_no === "" && (
                <Grid divided style={{ padding: 0, marginTop: '5px', marginBottom: '5px' }}>
                    <Grid.Row>
                        <Grid.Column >
                            <Button basic floated={'right'} icon color='green' size='small' onClick={() => openModal(<PurchaseOrderMultipleRows
                                purchase_order_master_id={currentPurchaseOrder.purchase_order_master_id}
                                addPurchaseOrderDetailsArray={addPurchaseOrderDetailsArray}
                            />, true)} >
                                <Icon name='list' /> Add Multiple
                            </Button>
                            {currentPurchaseOrder.po_customer_name !== '' && (
                                <>
                                    <Button
                                        onClick={() => openModal(<Grn />, true)}
                                    ><Icon color='yellow' size='large' name='lightbulb' />Add Goods Receipt Note</Button>
                                </>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}


            <PurchaseOrderExcelAwareTable addPurchaseOrderDetail={addPurchaseOrderDetail}>
                <hr />
                <div className="purchaseDocumentItemDetails">
                    <div className="didCell didHeader">No</div>
                    <div className="didCell didHeader">PartNo</div>
                    <div className="didCell didHeader">Description</div>
                    <div className="didCell didHeader">Dimensions</div>
                    <div className="didCell didHeader">Quantity</div>
                    <div className="didCell didHeader">Unit Cost</div>
                    <div className="didCell didHeader">Price</div>
                    {
                        currentPurchaseOrder != null && currentPurchaseOrder.purchaseOrderDetails != null && currentPurchaseOrder.purchaseOrderDetails.map((podetail, i) => {
                            return <MyLineItemRow
                                key={i}
                                poDetail={podetail}
                                currentIndex={i}
                                itemCount={currentPurchaseOrder.purchaseOrderDetails.length}
                            />
                        }
                        )
                    }
                </div>

                {
                    !currentPurchaseOrder.po_invoice_no && (
                        <div className="didGridTableButtons">
                            <div>
                                <Button color='green' onClick={addBlankPurchaseOrderItem} ><Icon name='plus' />Add a row</Button>
                            </div>
                            <div className="didTaxSelector">
                                <Select
                                    onChange={(e, data) => onStateChange(data.value)}
                                    value={stateSelected}
                                    options={statecodes}
                                    placeholder={'Taxes'} />
                                <Button
                                    onClick={handleReCalculateTaxes}
                                    basic
                                    color='teal' style={{ width: '100%', margin: '0', float: 'right' }}>Re-Calculate Taxes</Button>
                            </div>
                        </div>
                    )}

            </PurchaseOrderExcelAwareTable>
            <DocumentTaxes />



        </div>
    )
}

export default observer(DocumentItemDetails)