import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react'
import { Button, Divider, Icon } from 'semantic-ui-react';
import { useStore } from '../../../app/stores/Store';
import { debounce } from 'lodash';

const DocumentCustomerContacts = () => {
    const { salesOrderStore } = useStore();
    const { loadingSalesOrder, currentSalesOrder, updateSalesOrder,
        provideAcknowledgement, setAcknowledgementForPO, acknowledgeMessage,
        acknowledge } = salesOrderStore;

    //const [po, setPO] = useState("");

    const performantDebounce = React.useRef(
        debounce(async (criteria) => {
            await checkAcknowledgement(criteria);
        }, 1400)
    ).current;

    /*
    const debouncedSearch = debounce(async (criteria) => {
        checkAcknowledgement(criteria);
    }, 5000);
    */


    if (currentSalesOrder === null || loadingSalesOrder) {
        return <Fragment />
    }


    const checkAcknowledgement = async (poValue: string) => {
        await setAcknowledgementForPO(poValue);
        updateSalesOrder("so_purchase_order_no", poValue)
    }



    function handleChange(e: any) {
        updateSalesOrder("so_purchase_order_no", e?.target?.value)
        //debouncedSearch(e.target.value);
        performantDebounce(e?.target?.value);

    }


    return (
        <Fragment>
            {/* 
            <div className="wrapper">
                <div className="item">BOX 1</div>
                <div className="item">BOX 2</div>
                <div className="item">BOX 3</div>
                <div className="item">BOX 4</div>
                <div className="item">BOX 5</div>
            </div>
            
            
            */}
            <Divider />
            {acknowledge && (
                <div>
                    <div className='dcc_error'>
                        {acknowledgeMessage}
                        <Button color='youtube' style={{ marginLeft: '20px' }} onClick={provideAcknowledgement}>
                            <Icon name='ban' /> I Acknowledge
                        </Button>
                    </div>
                </div>
            )}

            {currentSalesOrder.so_invoice_no ? (
                <div className='documentCustomerContacts'>
                    <div className='dcc_col1 dcc_header'>
                        Customer Code
                    </div>
                    <div className='dcc_col2 dcc_header'>
                        Contact Name
                    </div>
                    <div className='dcc_col3 dcc_header'>
                        Customer PO
                    </div>
                    <div className='dcc_col1 dcc_value'>
                        <p>{currentSalesOrder.so_customer_alias}</p>
                    </div>
                    <div className='dcc_col2 dcc_value'>
                        <p>{currentSalesOrder.so_order_recipient}</p>
                    </div>
                    <div className='dcc_col3 dcc_value'>
                        <p>{currentSalesOrder.so_purchase_order_no}</p>
                    </div>
                </div>

            ) : (
                <div className='documentCustomerContacts'>
                    <div className='dcc_col1 dcc_header'>
                        Customer Code
                    </div>
                    <div className='dcc_col2 dcc_header'>
                        Contact Name
                    </div>
                    <div className='dcc_col3 dcc_header'>
                        Customer PO
                    </div>
                    <div className='dcc_col1 dcc_value'>
                        <textarea
                            id='textarea'
                            name='so_customer_alias'
                            value={currentSalesOrder.so_customer_alias}
                            onChange={(e) => updateSalesOrder(e.target.name, e.target.value)} />
                    </div>
                    <div className='dcc_col2 dcc_value'>
                        <textarea
                            id='textarea'
                            name='so_order_recipient'
                            value={currentSalesOrder.so_order_recipient}
                            onChange={(e) => updateSalesOrder(e.target.name, e.target.value)}
                        />
                    </div>
                    <div className='dcc_col3 dcc_value'>
                        <textarea
                            id='textarea'
                            value={currentSalesOrder.so_purchase_order_no}
                            style={{ paddingLeft: '10px', paddingTop: '8px' }}
                            onChange={handleChange}
                        />
                    </div>

                    {/*

                         <input
                            type="search"
                            placeholder="Enter your search"
                            onChange={handleChange}
                        />


                        <div className='dcc_col3 dcc_value'>
                            <textarea
                                id='textarea'
                                name='so_purchase_order_no'
                                value={currentSalesOrder.so_purchase_order_no}
                                onChange={(e) => updateSalesOrder(e.target.name, e.target.value)}
                            />
                        </div>
                    */}

                </div>

            )}
        </Fragment >
    )
}

export default observer(DocumentCustomerContacts)
