import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Divider, Grid, Icon, Label, Select } from 'semantic-ui-react';
import { getDefaultStateToSelect, Taxes } from '../../../app/models/Taxes';
import { useStore } from '../../../app/stores/Store';
import SalesOrderMultipleRows from '../../sales/dashboard/tabs/modals/SalesOrderMultipleRows';
import SalesOrderDetailsCsvDownload from '../../sales/dashboard/tabs/SalesOrderDetailsCsvDownload';
import DocumentTaxes from './DocumentTaxes';
import MyLineItemRow from './MyLineItemRow';
import SalesOrderDetailsCopier from './SalesOrderDetailsCopier';
import SalesOrderExcelAwareTable from './SalesOrderExcelAwareTable';

const statecodes = [
    { key: 'AB', text: 'Alberta', value: 'AB' },
    { key: 'BC', text: 'British Columbia', value: 'BC' },
    { key: 'MB', text: 'Manitoba', value: 'MB' },
    { key: 'NB', text: 'New Brunswick', value: 'NB' },
    { key: 'NL', text: 'Newfoundland and Labrador', value: 'NL' },
    { key: 'NS', text: 'Nova Scotia', value: 'NS' },
    { key: 'NT', text: 'Northwest Territories', value: 'NT' },
    { key: 'NU', text: 'Nunavut', value: 'NU' },
    { key: 'ON', text: 'Ontario', value: 'ON' },
    { key: 'PE', text: 'Prince Edward Island', value: 'PE' },
    { key: 'QC', text: 'Quebec', value: 'QC' },
    { key: 'SK', text: 'Saskatchewan', value: 'SK' },
    { key: 'YT', text: 'Yukon', value: 'YT' },
    { key: 'USA', text: 'USA', value: 'USA' },

]


const DocumentItemDetails = () => {
    const { salesOrderStore, modalStore } = useStore();
    const { loadingSalesOrder, currentSalesOrder, addBlankSalesOrderItem, addSalesOrderDetail, getTaxesForState, addSalesOrderDetailsArray, reComputeTaxes } = salesOrderStore;
    const { openModal } = modalStore;
    const [stateSelected, setStateSelected] = useState('ON');
    const [taxes, setTaxes] = useState<Taxes | null>(null);

    useEffect(() => {
        if (currentSalesOrder !== null && currentSalesOrder.salesOrderDetails.length === 0) {
            addBlankSalesOrderItem();
        } else {
            if (currentSalesOrder?.isCustomerTaxExempt) {
                getTaxesForState('BC').then((mydata) => {
                    if (mydata !== undefined) {
                        setTaxes(mydata);
                        reComputeTaxes(mydata)
                        setStateSelected('BC');
                    }
                });
            } else {

                const defaultState = getDefaultStateToSelect(currentSalesOrder?.so_customer_name, currentSalesOrder?.so_sold_address);

                getTaxesForState(defaultState).then((mydata) => {
                    if (mydata !== undefined) {
                        setTaxes(mydata);
                        reComputeTaxes(mydata)
                        setStateSelected(defaultState);
                    }
                });
            }
            /*
            if (currentSalesOrder?.so_customer_name === "Pacaire") {
                console.log('handle pacaire --- ', currentSalesOrder.isCustomerTaxExempt);

                getTaxesForState('BC').then((mydata) => {
                    console.log("my data ...", mydata);
                    if (mydata !== undefined) {
                        setTaxes(mydata);
                        reComputeTaxes(mydata, true)
                    }

                });

            }
            */
        }
    }, [currentSalesOrder, addBlankSalesOrderItem, getTaxesForState, setTaxes, reComputeTaxes])

    if (currentSalesOrder === null || loadingSalesOrder) {
        return <Fragment />
    }


    const getTotalQuantity = () => {
        if (currentSalesOrder.salesOrderDetails === null || currentSalesOrder.salesOrderDetails.length === 0)
            return 0;

        // let total = 0;
        // currentSalesOrder.salesOrderDetails.forEach((so: ISalesOrderDetail) => {
        //     total += so.so_item_qty;
        // });

        const sum = currentSalesOrder.salesOrderDetails.reduce((accumulator, object) => {
            return accumulator + +object.so_item_qty;
        }, 0);
        return sum;
    }

    const getTaxes = async (value: string, resetValue: boolean = false) => {

        let mydata = await getTaxesForState(value);
        if (mydata !== undefined)
            setTaxes(mydata);
        if (resetValue) {
            setStateSelected(value);
        }
        return mydata;
    }
    const onStateChange = async (value: any) => {
        let mydata = await getTaxes(value, true);
        if (mydata) {
            reComputeTaxes(mydata)
        }
    }


    const handleReCalculateTaxes = async () => {
        if (taxes !== undefined && taxes !== null)
            reComputeTaxes(taxes);
        let mydata = await getTaxes(stateSelected);
        if (mydata) {
            reComputeTaxes(mydata)
        }
    }
    return (
        <div className='hdocumentDetails'>
            <Divider />
            {currentSalesOrder.so_invoice_no === "" && (
                <Grid divided style={{ padding: 0, marginTop: '5px', marginBottom: '5px' }}>
                    <Grid.Row>
                        <Grid.Column >
                            <Button basic floated={'right'} icon color='green' size='small' onClick={() => openModal(<SalesOrderMultipleRows
                                sales_order_master_id={currentSalesOrder.sales_order_master_id}
                                addSalesOrderDetailsArray={addSalesOrderDetailsArray}
                            />, true)} >
                                <Icon name='list' /> Add Multiple
                            </Button>
                            <SalesOrderDetailsCsvDownload salesOrderDetails={currentSalesOrder.salesOrderDetails} />
                            {currentSalesOrder.so_customer_name !== '' && (
                                <Button
                                    onClick={() => openModal(<SalesOrderDetailsCopier />, true)}
                                ><Icon color='yellow' size='large' name='lightbulb' />Quick Add</Button>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}


            <SalesOrderExcelAwareTable addSalesOrderDetail={addSalesOrderDetail}>
                <hr />
                <div className="documentItemDetails">
                    <div className="didCell didHeader">No</div>
                    <div className="didCell didHeader">Item</div>
                    <div className="didCell didHeader">Description</div>
                    <div className="didCell didHeader">Width</div>
                    <div className="didCell didHeader">Height</div>
                    <div className="didCell didHeader">Dia</div>
                    <div className="didCell didHeader">Quantity</div>
                    <div className="didCell didHeader">Shipped</div>
                    <div className="didCell didHeader">Unit Cost</div>
                    <div className="didCell didHeader">Price</div>

                    {
                        currentSalesOrder.salesOrderDetails.map((sodetail, i) => {
                            return <MyLineItemRow
                                key={i}
                                soDetail={sodetail}
                                currentIndex={i}
                                itemCount={currentSalesOrder.salesOrderDetails.length}
                            />
                        }
                        )
                    }
                </div>

                {
                    (!currentSalesOrder.so_invoice_no || currentSalesOrder.so_invoice_no === "IN-8687") && (
                        <div className="didGridTableButtons">
                            <div>
                                <Button color='green' onClick={addBlankSalesOrderItem} ><Icon name='plus' />Add a row</Button>
                            </div>
                            <div style={{ marginLeft: 'auto', paddingRight: '20px', alignSelf: 'center' }}>
                                Total Quantity # &nbsp;&nbsp;&nbsp;<Label circular color='green' size='large' >{getTotalQuantity()}</Label></div>
                            <div className="didTaxSelector">

                                <Select
                                    onChange={(e, data) => onStateChange(data.value)}
                                    value={stateSelected}
                                    options={statecodes}
                                    placeholder={'Taxes'} />
                                <Button
                                    onClick={handleReCalculateTaxes}
                                    basic
                                    color='teal' style={{ width: '100%', margin: '0', float: 'right' }}>Re-Calculate Taxes</Button>
                            </div>
                        </div>
                    )}

            </SalesOrderExcelAwareTable>
            <DocumentTaxes />



        </div>
    )
}

export default observer(DocumentItemDetails)