import React from 'react'

const UserProfile = () => {
    return (
        <div>
            This is my user profile
        </div>
    )
}

export default UserProfile
