import { observer } from 'mobx-react-lite'
import React from 'react'
import { Button, Grid, Header, Table } from 'semantic-ui-react'
import { useStore } from '../../../app/stores/Store';

const Silencers = () => {
    const { costingStore } = useStore();
    const { gettingRate, setWidth, getRateForSilencers, setQuotes } = costingStore;

    const computeRate = async () => {
        setWidth(Number.parseFloat('1'));
        let rate = await getRateForSilencers();
        if (rate !== undefined && rate !== null) {
            setQuotes(rate);
        }
    }


    return (
        <>
            <Header as='h2'>Silencers (not models/size to choose)</Header>
            <Grid columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        <Table>
                            <Table.Header>

                            </Table.Header>
                        </Table>
                        <Button positive loading={gettingRate} onClick={computeRate} floated='right'>Calculate</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>

    )
}

export default observer(Silencers)