import { observer } from 'mobx-react-lite';
import React, { useState } from 'react'
import { parse } from 'papaparse';
import { getBlankPurchaseOrderDetail, IPurchaseOrderDetail } from '../../../app/models/PurchaseOrder';


var divStyle = {
    width: '100%',
    border: '3px dashed red',
    margin: '0 auto'
};


interface IProps {
    addPurchaseOrderDetail: (purchaseOrderDetail: IPurchaseOrderDetail) => void
}


const PurchaseOrderExcelAwareTable: React.FC<IProps> = ({ children, addPurchaseOrderDetail }) => {
    const [highlighted, setHighlighted] = useState(false);
    return (
        <div
            style={highlighted ? divStyle : {}}
            onDragEnter={() => { setHighlighted(true) }}
            onDragLeave={() => { /*setHighlighted(false)*/ }}
            onDragOver={(e) => { e.preventDefault(); }}
            onDrop={(e) => {
                e.preventDefault();
                console.log('on drop works', e);
                setHighlighted(false)
                Array.from(e.dataTransfer.files)
                    //   .filter((file: any) => { return (file.type === "text/csv" || file.type === "application/vnd.ms-excel") })
                    .forEach(async file => {
                        const text = await file.text();
                        const items = parse(text, { header: true });

                        items.data.map((mydata: any) => {
                            let csvdata = getBlankPurchaseOrderDetail();
                            csvdata.po_part_tag_no = mydata.Code;
                            csvdata.po_part_description = mydata.Description;
                            csvdata.po_qty = mydata.Ordered;
                            csvdata.po_unit_price = mydata.Unit;
                            csvdata.po_total_amount = mydata.Total;

                            //if (mydata.Code && mydata.Description)
                            //    addPurchaseOrderDetail(csvdata);

                            return 0;
                        })
                    })

            }}
        >
            {children}
        </div>

    )
}

export default observer(PurchaseOrderExcelAwareTable)
