import React, { Fragment } from 'react'
import { Icon, Table, Button, ButtonGroup, Pagination } from 'semantic-ui-react'

import { observer } from 'mobx-react-lite';
import SalesOrderListPlaceholder from './SalesOrderListPlaceholder';
import { ICompactSalesOrder } from '../../../../app/models/SalesOrder';
import { isNumber } from 'util';
import SalesOrderDocumentView from '../tabs/documentview/SalesOrderDocumentView';
import { useStore } from '../../../../app/stores/Store';


const SalesOrderSearchResults = () => {
    const { salesOrderStore, dataSyncStore, searchStore } = useStore();
    const { salesorders, addTabPane, tabPanes,
        loadingSearch, loadSalesOrderOnPage, createSalesOrder,
        loadingSalesOrder } = salesOrderStore;


    const { SyncSalesOrder, syncingSalesOrders } = dataSyncStore;

    const { totalPages, currentPageNo } = searchStore;


    const addTab = (id: string, tabName: string) => {
        // addTabPane(tabName, {
        //     menuItem: tabName,
        //     render: () => <SalesOrderTabPane order={tabName} orderid={id} tabCount={tabPanes.length} />
        // });
        addTabPane(tabName, {
            menuItem: tabName,
            render: () => <SalesOrderDocumentView order={tabName} orderid={id} tabCount={tabPanes.length} />
            //render: () => <SalesOrderDocumentViewNew order={tabName} orderid={id} tabCount={tabPanes.length} />
        });
    }

    const getDateOnly = (datetime: Date | null) => {
        if (!datetime) return ' ? ';

        if (datetime.toString().startsWith('000'))
            return ' ? ';
        else
            return datetime.toString().split('T')[0];
    }

    const handlePageChange = (pageNo: string | number | undefined) => {
        if (isNumber(pageNo))
            loadSalesOrderOnPage(pageNo - 1)
    }

    const createNewAndOpen = () => {
        createSalesOrder().then((sorder) => {
            if (sorder) {
                addTab(`${sorder.sales_order_master_id}`, sorder.so_order_no);
            }
        });
    }

    const refreshData = (id: string, tabName: string) => {
        SyncSalesOrder(id).then(() => {
            addTab(id, tabName);
        });
    }

    if (loadingSearch) return <SalesOrderListPlaceholder />

    return (
        <Fragment>
            <Table fixed celled selectable compact size='small'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell width={3}>Customer Name</Table.HeaderCell>
                        <Table.HeaderCell textAlign='center'>Order</Table.HeaderCell>
                        <Table.HeaderCell>Net Amt</Table.HeaderCell>
                        <Table.HeaderCell>
                            <div className={'topHeading'}>Ship Date</div>
                            {/*<div className={'bottomHeading'}>Actual Ship Date</div>*/}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={3}>Shipping Information</Table.HeaderCell>
                        <Table.HeaderCell width={3}>Recipient: Remarks</Table.HeaderCell>
                        <Table.HeaderCell style={{ width: '5%' }}>
                            <Button icon color='green' floated='right' onClick={createNewAndOpen} loading={loadingSalesOrder}>
                                <Icon name='plus' />
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>

                    {
                        salesorders.map((salesorder: ICompactSalesOrder) => (
                            <Table.Row
                                key={salesorder.sales_order_master_id}

                                //positive={salesorder.so_order_status.startsWith('Pac')}
                                negative={salesorder.so_order_status.startsWith('On Hold')}
                                warning={salesorder.so_order_status.startsWith('Under')}
                                positive={salesorder.so_order_status.startsWith('Inv')}
                            >
                                <Table.Cell onClick={() => addTab(`${salesorder.sales_order_master_id}`, salesorder.so_order_no)}>
                                    {salesorder.so_order_status}
                                    <div>{getDateOnly(salesorder.so_updated_on)}</div>
                                </Table.Cell>
                                <Table.Cell onClick={() => addTab(`${salesorder.sales_order_master_id}`, salesorder.so_order_no)}>{salesorder.so_customer_name} ({salesorder.so_customer_alias})</Table.Cell>
                                <Table.Cell textAlign='center'>{salesorder.so_order_no}</Table.Cell>
                                <Table.Cell onClick={() => addTab(`${salesorder.sales_order_master_id}`, salesorder.so_order_no)}>
                                    <div className={'topRow'}>${salesorder.so_net_amount}</div>
                                </Table.Cell>
                                <Table.Cell width={'one'} onClick={() => addTab(`${salesorder.sales_order_master_id}`, salesorder.so_order_no)}>
                                    {getDateOnly(salesorder.so_ship_date)}
                                    {/*  / {getDateOnly(salesorder.so_actual_ship_date)} */}
                                </Table.Cell>
                                <Table.Cell onClick={() => addTab(`${salesorder.sales_order_master_id}`, salesorder.so_order_no)}>{salesorder.so_shipping_address}</Table.Cell>
                                <Table.Cell onClick={() => addTab(`${salesorder.sales_order_master_id}`, salesorder.so_order_no)}>{salesorder.so_order_recipient}: {salesorder.so_remarks}</Table.Cell>
                                <Table.Cell textAlign={'center'}>
                                    <ButtonGroup>
                                        <Button loading={syncingSalesOrders} basic icon color='green' onClick={() => refreshData(`${salesorder.sales_order_master_id}`, salesorder.so_order_no)}>
                                            <Icon name='sync' />
                                        </Button>
                                    </ButtonGroup>
                                </Table.Cell>
                            </Table.Row>
                        ))
                    }
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan='8'>
                            <Pagination
                                floated='right'
                                defaultActivePage={currentPageNo + 1}
                                totalPages={totalPages}
                                onPageChange={(e, data) => handlePageChange(data.activePage)} />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>


        </Fragment >
    )
}

export default observer(SalesOrderSearchResults)
