import { store } from './Store';
import { runInAction, toJS, makeAutoObservable } from 'mobx';
import { ICompactSalesOrder, ISalesOrderDetail, ILegacySalesOrder, getBlankSalesOrderDetail, getBlankSalesOrder, SalesOrderSetter } from '../models/SalesOrder';
import agent from '../api/agent';
import { toast } from 'react-toastify';
import { getTaxesForOntarioHardCoded, Taxes } from '../models/Taxes';

export default class SalesOrderStore {


    constructor() {
        makeAutoObservable(this);
        /*
        reaction(
            () => this.runningTotal,
            (total) => {
                this.reComputeTaxes(getTaxesForOntarioHardCoded());
            }
        )
        */
    }

    savingSalesOrder = false;
    loadingSalesOrderDetails = false;
    loadingSalesOrder = false;
    loadingSearch = false;
    loadingInitial = false;
    salesordersMap = new Map();
    salesorder: ICompactSalesOrder | null = null;

    loadingAcknowledge = false;
    acknowledge = false;
    acknowledgeMessage = "";

    provideAcknowledgement = () => {
        this.loadingAcknowledge = false;
        this.acknowledge = false;
        this.acknowledgeMessage = "";

    }

    setAcknowledgementForPO = async (poNumber: string) => {
        this.loadingAcknowledge = true;
        try {
            let acknowledgeDto = await agent.salesorder.duplicateCheckForPO(poNumber);
            runInAction(() => {
                this.acknowledge = acknowledgeDto.needsAuthorization;
                this.acknowledgeMessage = acknowledgeDto.message;
                this.loadingAcknowledge = false;
            })
        } catch (error: any) {
            runInAction(() => {
                this.loadingAcknowledge = false;
                if (!!error)
                    toast.error(error);
            })
        }
    }


    previousSalesOrderCopy: ILegacySalesOrder | null = null;

    tabPanes: any = [];
    activeTabIndex = -1;
    tabVsIndexMap = new Map();

    taxes: Taxes = getTaxesForOntarioHardCoded();
    newSalesOrderDetail: ISalesOrderDetail = getBlankSalesOrderDetail();


    addNewPartNo = (value: string) => {
        this.newSalesOrderDetail.so_part_no = value;
    }

    addNewPartDesc = (value: string) => {
        this.newSalesOrderDetail.so_part_description = value;
    }

    setActiveTabIndex = (tabIndex: any) => {
        this.activeTabIndex = tabIndex;
    }
    addTabPane = (key: string, tab: any) => {
        if (this.tabVsIndexMap.has(key)) {
            const index = this.tabVsIndexMap.get(key);
            this.setActiveTabIndex(index);
        } else {
            this.tabPanes = [...this.tabPanes, tab];
            this.setActiveTabIndex(this.tabPanes.length - 1);
            this.tabVsIndexMap.set(key, this.activeTabIndex);
        }

    }

    removeTabPane = (key: string) => {
        if (key === undefined)
            key = 'New Order';
        if (this.tabVsIndexMap.has(key)) {
            this.tabPanes = this.tabPanes.filter((tab: any) => {
                return tab.menuItem !== key;
            });
            this.tabVsIndexMap.delete(key);

            this.loadSalesOrders();

            this.setActiveTabIndex(0);
        }
    }

    get salesorders() {
        return Array.from(this.salesordersMap.values());
    }

    getLoadedSalesOrder = (orderno: string): ILegacySalesOrder => {
        return this.salesordersMap.get(parseInt(orderno));
    }



    currentSalesOrder: ILegacySalesOrder | null = null;
    runningTotal: number = 0;

    updateSalesOrder = (name: string, value: string) => {
        if (this.currentSalesOrder !== null) {
            this.currentSalesOrder = SalesOrderSetter.SetValue(this.currentSalesOrder, name, value);
        }
    }


    appendSalesOrderDetails = (salesOrderDetails: ISalesOrderDetail[]) => {
        runInAction(() => {
            if (this.currentSalesOrder === null) return;
            this.currentSalesOrder.salesOrderDetails = [...salesOrderDetails, ...this.currentSalesOrder.salesOrderDetails];
            this.computeTotals(this.currentSalesOrder.salesOrderDetails);
        });
    }


    updateSalesOrderDetail = (name: string, salesOrderDetail: ISalesOrderDetail, index: number) => {
        runInAction(() => {
            if (this.currentSalesOrder === null) return;

            if (salesOrderDetail.so_item_qty > 0 /*&& salesOrderDetail.so_item_sale_price > 0*/) {
                let qty = salesOrderDetail.so_item_qty;
                let price = salesOrderDetail.so_item_sale_price;
                salesOrderDetail.so_item_total_amount = (this.getRoundedValue(qty * price));
                if (name.includes("price") || name.includes("qty"))
                    this.updateSalesOrderTotals();
            } else {
                salesOrderDetail.so_item_total_amount = 0;
            }


            // if (salesOrderDetail.so_item_qty !== "" && salesOrderDetail.so_item_sale_price !== "") {
            //     let qty = parseInt(salesOrderDetail.so_item_qty);
            //     let price = parseFloat(salesOrderDetail.so_item_sale_price);
            //     salesOrderDetail.so_item_total_amount = (this.getRoundedValue(qty * price)).toString();
            //     if (name.includes("price") || name.includes("qty"))
            //         this.updateSalesOrderTotals();
            // } else {
            //     salesOrderDetail.so_item_total_amount = '0';
            // }



            this.currentSalesOrder.salesOrderDetails[index] = { ...this.currentSalesOrder.salesOrderDetails[index], ...salesOrderDetail };
            this.computeTotals(this.currentSalesOrder.salesOrderDetails);
        });
    }


    updateSalesOrderDetailPriceForDia = async (dia: string, salesOrderDetail: ISalesOrderDetail, index: number) => {
        if (this.currentSalesOrder === null) return;
        this.loadingSearch = true;
        try {
            let cleanPartDesc = salesOrderDetail.so_part_description.replace(/\//g, '');
            let price = await agent.salesorder.priceCheckForDia(this.currentSalesOrder.so_customer_name, cleanPartDesc, dia);
            runInAction(() => {
                if (price > 0) {
                    salesOrderDetail.so_item_sale_price = price;
                    this.updateSalesOrderDetail('so_item_sale_price', salesOrderDetail, index);
                } else {
                    salesOrderDetail.so_item_sale_price = 0;
                    this.updateSalesOrderDetail('so_item_sale_price', salesOrderDetail, index);
                }
                this.loadingSearch = false;
            });
        } catch {
            runInAction(() => {
                this.loadingSearch = false;
            });
        }
    }
    updateSalesOrderDetailPriceForDim = async (width: string, height: string, salesOrderDetail: ISalesOrderDetail, index: number) => {
        if (this.currentSalesOrder === null) return;
        this.loadingSearch = true;
        try {
            let cleanPartDesc = salesOrderDetail.so_part_description.replace(/\//g, '');

            let price = await agent.salesorder.priceCheckForDim(this.currentSalesOrder.so_customer_name, cleanPartDesc, width, height);
            runInAction(() => {
                if (price > 0) {
                    salesOrderDetail.so_item_sale_price = price;
                    this.updateSalesOrderDetail('so_item_sale_price', salesOrderDetail, index);
                } else {
                    salesOrderDetail.so_item_sale_price = 0;
                    this.updateSalesOrderDetail('so_item_sale_price', salesOrderDetail, index);
                }
                this.loadingSearch = false;
            });
        } catch {
            runInAction(() => {
                this.loadingSearch = false;
            });
        }
    }

    updateSalesOrderTotals = () => {
        runInAction(() => {
            if (this.currentSalesOrder === null) return;
            let total = 0;
            this.currentSalesOrder?.salesOrderDetails.forEach(soDetail => {
                if (soDetail.so_part_no !== "") {
                    total += soDetail.so_item_total_amount;
                }
            });
            this.runningTotal = total;
            this.currentSalesOrder.so_net_amount = total;
        });
    }

    getNumericValue = (str: string) => {
        try {
            return parseFloat(str);
        } catch {
            return 0;
        }
    }

    getPreviousSalesOrderForCustomer = async (customerName: string) => {
        this.loadingSearch = true;
        try {
            let cacheSalesOrder = await agent.salesorder.searchByCustomerName(customerName);
            runInAction(() => {
                this.previousSalesOrderCopy = cacheSalesOrder;
                this.loadingSearch = false;
            });
        } catch {
            runInAction(() => {
                this.loadingSearch = false;
            });
        }

    }


    searchSalesOrderDetailsForCustomer = async (partNoLike?: string) => {
        let salesOrderDetails: ISalesOrderDetail[] = [];
        if (this.currentSalesOrder === null) {
            return salesOrderDetails;
        }
        try {
            this.loadingSalesOrderDetails = true;
            let searchString = partNoLike ? partNoLike : '';
            let mySODetails = await agent.salesorder.lastCustomerDetails(this.currentSalesOrder.so_customer_name, searchString);
            runInAction(() => {
                this.loadingSalesOrderDetails = false;
            })
            return mySODetails;
        } catch (error) {
            runInAction(() => {
                this.loadingSalesOrderDetails = false;
            })
            return salesOrderDetails;
        }

    }

    loadSalesOrderByOrderNo = async (orderNo: string) => {
        if (orderNo === "create") {
            this.currentSalesOrder = getBlankSalesOrder(store.userStore.user?.displayname);
            return;
        }
        try {
            this.loadingSalesOrder = true;
            let salesOrder: ILegacySalesOrder | null = null;
            salesOrder = await agent.salesorder.detailsGivenOrderNo(orderNo);
            runInAction(() => {
                this.currentSalesOrder = salesOrder;
                this.loadingSalesOrder = false;
            })
            return this.currentSalesOrder;
        } catch (error) {
            runInAction(() => {
                this.loadingSalesOrder = false;
            })
            console.log(error);
        }
    }



    loadSalesOrder = async (id: string) => {
        let salesorder = this.getLoadedSalesOrder(id);
        if (false && salesorder) {
            return toJS(salesorder);  // converting from observable to Javascript object!
        } else {
            try {
                this.loadingSalesOrder = true;
                salesorder = await agent.salesorder.details(id);
                runInAction(() => {
                    this.salesordersMap.delete(salesorder.so_order_no);
                    this.salesordersMap.set(salesorder.so_order_no, salesorder);
                    this.loadingSalesOrder = false;
                })
                return salesorder;
            } catch (error) {
                runInAction(() => {
                })
                this.loadingSalesOrder = false;
                console.log(error);
            }
        }
    }

    generateInvoice = async (invoice: string) => {
        return await agent.pdfcreator.createInvoice(invoice);
    }
    generateCustomerAcknowlegement = async (orderno: string) => {
        return await agent.pdfcreator.createCustomerAcknowlegement(orderno);
    }
    generatePackingList = async (orderno: string) => {
        return await agent.pdfcreator.createPackingList(orderno);
    }
    generateWorkOrder = async (orderno: string) => {
        return await agent.pdfcreator.createWorkOrder(orderno);
    }

    generatePOSample = async () => {
        return await agent.pdfcreator.createPurchaseOrderSample();
    }


    searchSalesOrders = async (searchType: string, searchValue: string) => {
        this.loadingSearch = true;
        try {
            this.salesordersMap.clear();

            //await this.sleep(8000);

            store.searchStore.searchType = searchType;
            store.searchStore.searchValue = searchValue;
            //const salesorderEnvelope = await agent.salesorder.list(searchStore.getAxiosParams(searchType, searchValue));
            const salesorderEnvelope = await agent.salesorder.list(store.searchStore.getAxiosParams());
            const { salesOrders, salesOrderCount } = salesorderEnvelope;

            runInAction(() => {
                salesOrders.forEach(salesorder => {
                    this.salesordersMap.set(salesorder.sales_order_master_id, salesorder);
                });
                //searchStore.totalRecordCount = salesOrderCount;
                store.searchStore.totalRecordCount = salesOrderCount;
                this.loadingSearch = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadingSearch = false;
            });
        }
    }

    loadProgressiveSalesOrders = async () => {
        this.loadingInitial = true;
        try {
            const salesorderEnvelope = await agent.salesorder.list(store.searchStore.getAxiosParams());
            const { salesOrders, salesOrderCount } = salesorderEnvelope;
            runInAction(() => {
                salesOrders.forEach(salesorder => {
                    this.salesordersMap.set(salesorder.sales_order_master_id, salesorder);
                });
                store.searchStore.totalRecordCount = salesOrderCount;
                this.loadingInitial = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadingInitial = false;
            });
        }
    }

    sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    loadSalesOrders = async () => {
        this.loadingInitial = true;
        this.loadingSearch = true;
        try {

            this.salesordersMap.clear();
            //const salesorderEnvelope = await agent.salesorder.list(searchStore.getCurrentAxiosParams());
            const salesorderEnvelope = await agent.salesorder.list(store.searchStore.getAxiosParams());
            const { salesOrders, salesOrderCount } = salesorderEnvelope;

            runInAction(() => {
                salesOrders.forEach(salesorder => {
                    this.salesordersMap.set(salesorder.sales_order_master_id, salesorder);
                });

                //store.searchStore.totalRecordCount = salesOrderCount;
                store.searchStore.totalRecordCount = salesOrderCount;
                this.loadingInitial = false;
                this.loadingSearch = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadingInitial = false;
                this.loadingSearch = false;
            });
        }
    }


    loadSalesOrderOnPage = async (pageNo: number) => {
        this.loadingInitial = true;
        try {
            //store.searchStore.setPageNo(pageNo);
            //     console.log(store.searchStore.getCurrentAxiosParams().toString());
            //     const salesorderEnvelope = await agent.salesorder.list(store.searchStore.getCurrentAxiosParams());
            store.searchStore.currentPageNo = pageNo;
            const salesorderEnvelope = await agent.salesorder.list(store.searchStore.getAxiosParams());
            const { salesOrders, salesOrderCount } = salesorderEnvelope;

            runInAction(() => {
                this.salesordersMap.clear();
                salesOrders.forEach(salesorder => {

                    this.salesordersMap.set(salesorder.sales_order_master_id, salesorder);
                });
                //store.searchStore.totalRecordCount = salesOrderCount;
                store.searchStore.totalRecordCount = salesOrderCount;
                this.loadingInitial = false;
            });
        } catch (error) {
            runInAction(() => {
                console.log(error);
            });
        } finally {
            runInAction(() => {
                this.loadingInitial = false;
            });
        }
    }

    refreshOrderFromLegacyDB = async (orderNo: string) => {

        this.loadingSalesOrder = true;
        try {
            let refreshedSO = await agent.salesorder.refreshFromLegacy(orderNo);
            runInAction(() => {
                this.loadingSalesOrder = false;
                toast.success('Successfully Refreshed Your Data From The Old System!')
            })
            return refreshedSO;
        } catch (error) {
            // toast.error(error);
            runInAction(() => {
                this.loadingSalesOrder = false;
            });
        }
    }

    editSalesOrder = async (salesorder: ILegacySalesOrder) => {
        this.loadingSalesOrder = true;
        try {

            //console.log(salesorder);

            let editedSO = await agent.salesorder.edit(salesorder);

            runInAction(() => {
                this.loadingSalesOrder = false;
                toast.success('Edit Your changes are Saved!')
            })
            return editedSO;
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadingSalesOrder = false;
            });
        }
    }

    createSalesOrder = async () => {
        this.loadingSalesOrder = true;
        try {
            let salesorder = await agent.salesorder.create(getBlankSalesOrder(store.userStore.user?.displayname));
            runInAction(() => {
                this.salesordersMap.set(salesorder.sales_order_master_id, salesorder);
                this.loadingSalesOrder = false;
                toast.success('New SalesOrder created!')
            })
            return salesorder;
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadingSalesOrder = false;
            });
        }
    }

    cleanSalesOrderDetails = (salesorder: ILegacySalesOrder) => {

        let salesOrderDetails: ISalesOrderDetail[] = [];
        salesorder.salesOrderDetails.forEach(soDetail => {
            if (soDetail.so_part_no !== "") {
                salesOrderDetails.push(SalesOrderSetter.CleanDetailValues(soDetail));
            }
        });
        return salesOrderDetails;
    }

    createOrUpdateSalesOrder = async () => {
        if (this.currentSalesOrder === null)
            return;
        this.savingSalesOrder = true;
        this.currentSalesOrder.salesOrderDetails = this.cleanSalesOrderDetails(this.currentSalesOrder);
        console.log(`current sales order ${this.currentSalesOrder?.sales_agent}`)


        try {
            let salesorder: ILegacySalesOrder | null = null;
            if (this.currentSalesOrder.so_order_no === "") {
                salesorder = await agent.salesorder.create(this.currentSalesOrder);
                //console.log(toJS(this.currentSalesOrder));
                //salesorder = this.currentSalesOrder;
            } else {
                salesorder = await agent.salesorder.edit(this.currentSalesOrder);
            }
            runInAction(() => {
                this.currentSalesOrder = salesorder;
                this.savingSalesOrder = false;
                toast.success('New SalesOrder saved')
            })
            return salesorder;
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.savingSalesOrder = false;
            });
        }
    }

    addBlankSalesOrderItem = () => {
        this.addSalesOrderDetail(getBlankSalesOrderDetail());
    }

    addSalesOrderDetail = (salesOrderDetail: ISalesOrderDetail) => {
        if (this.currentSalesOrder === null) return;
        if (salesOrderDetail.sales_order_master_id === 0) {
            salesOrderDetail.sales_order_master_id = this.currentSalesOrder.sales_order_master_id;
        }
        this.currentSalesOrder.salesOrderDetails.push(salesOrderDetail);
    }

    addSalesOrderDetailsArray = (salesOrderDetails: ISalesOrderDetail[]) => {
        if (this.currentSalesOrder === null) return;
        this.currentSalesOrder.salesOrderDetails = salesOrderDetails;
    }


    editSalesOrderDetail = (index: number, salesOrderDetail: ISalesOrderDetail, name: string) => {
        if (this.currentSalesOrder === null) return;
        this.sanitizeTotalForOrder(salesOrderDetail, name);
        this.currentSalesOrder.salesOrderDetails[index] = { ...this.currentSalesOrder.salesOrderDetails[index], ...salesOrderDetail };
        this.computeTotals(this.currentSalesOrder.salesOrderDetails);
    }

    removeSalesOrderDetail = (index: number) => {
        if (this.currentSalesOrder === null) return;

        this.currentSalesOrder.salesOrderDetails.splice(index, 1);
        this.currentSalesOrder.salesOrderDetails = [...this.currentSalesOrder.salesOrderDetails];
        this.computeTotals(this.currentSalesOrder.salesOrderDetails);
    }


    addOrEditRowItem = (index: number, name: string, value: string) => {
        if (this.currentSalesOrder === null) return;
        let soDetail = this.currentSalesOrder.salesOrderDetails[index];
        soDetail = { ...soDetail, [name]: value };
        this.updateSalesOrderDetail(name, soDetail, index);

    }

    getExistingPartNoInDetails = (partDesc: string) => {
        if (this.currentSalesOrder === null) return 0;
        return this.currentSalesOrder.salesOrderDetails.filter(x => x.so_part_description === partDesc).length;
    }
    getSimilarSalesOrderDetail = async (partDesc: string) => {
        if (this.currentSalesOrder === null) return getBlankSalesOrderDetail();
        try {
            let similarSalesOrderDetail = agent.salesorder.getSimilarItem(this.getExistingPartNoInDetails(partDesc), partDesc, this.currentSalesOrder?.so_customer_name);
            return similarSalesOrderDetail;
        } catch {
            return getBlankSalesOrderDetail();
        }

    }

    sanitizeTotalForOrder = (salesOrderDetail: ISalesOrderDetail, name: string) => {
        if (name === 'so_item_qty') {
            salesOrderDetail.so_item_total_amount = this.getRoundedValue(salesOrderDetail.so_item_sale_price * salesOrderDetail.so_item_qty);
        }
        else if (name === 'so_item_sale_price') {

            salesOrderDetail.so_item_total_amount = this.getRoundedValue(salesOrderDetail.so_item_sale_price * salesOrderDetail.so_item_qty);
        }
        else if (name === 'so_item_total_amount') {
            if (salesOrderDetail.so_item_qty > 0) {
                salesOrderDetail.so_item_sale_price = this.getRoundedValue(salesOrderDetail.so_item_total_amount / salesOrderDetail.so_item_qty);
            }
        }
    }

    computeTotals = (soDetails: ISalesOrderDetail[]) => {
        let total = 0;
        soDetails.forEach((so: ISalesOrderDetail) => {
            total += so.so_item_total_amount;
        });




        this.runningTotal = this.getRoundedValue(total);
        this.reComputeTaxes(this.taxes);
    }

    getTaxesForState = async (stateCode: string) => {
        try {
            let taxes = await agent.taxes.gettaxes(stateCode);
            runInAction(() => {
                this.taxes = taxes;
            })
            return taxes;
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.taxes = getTaxesForOntarioHardCoded();
            });
        }
    }


    getHSTAmount = (taxData: Taxes) => {
        if (this.currentSalesOrder === null) return 0;
        if (this.currentSalesOrder.so_hst_amount >= 0) {
            return parseFloat(this.currentSalesOrder.so_hst_amount.toString());
        } else {
            return this.getRoundedValue(this.runningTotal * (taxData!.hst / 100));
        }
    }
    getGSTAmount = (taxData: Taxes) => {
        if (this.currentSalesOrder === null) return 0;
        if (this.currentSalesOrder.so_gst_amount >= 0) {
            return parseFloat(this.currentSalesOrder.so_gst_amount.toString());
        } else {
            return this.getRoundedValue(this.runningTotal * (taxData!.gst / 100));
        }
    }
    getPSTAmount = (taxData: Taxes) => {
        if (this.currentSalesOrder === null) return 0;
        if (this.currentSalesOrder.so_pst_amount >= 0) {
            return parseFloat(this.currentSalesOrder.so_pst_amount.toString());
        } else {
            return this.getRoundedValue(this.runningTotal * (taxData!.pst / 100));
        }
    }
    getQSTAmount = (taxData: Taxes) => {
        if (this.currentSalesOrder === null) return 0;
        if (this.currentSalesOrder.so_qst_amount >= 0) {
            return parseFloat(this.currentSalesOrder.so_qst_amount.toString());
        } else {
            return this.getRoundedValue(this.runningTotal * (taxData!.qst / 100));
        }
    }
    getRoundedValue = (value: number) => {
        return Math.round(value * 100) / 100;
    }

    getNetAmount = (taxData: Taxes) => {
        return this.getRoundedValue(this.runningTotal + this.getHSTAmount(taxData) + this.getGSTAmount(taxData) + this.getPSTAmount(taxData) + this.getQSTAmount(taxData));
    }

    reComputeTaxes = (taxdata: Taxes) => {
        runInAction(() => {
            if (this.currentSalesOrder === null) return;
            if (taxdata) {
                if (this.currentSalesOrder.isCustomerTaxExempt) {
                    this.currentSalesOrder.so_hst_amount = this.getRoundedValue(this.runningTotal * (0 / 100));
                    this.currentSalesOrder.so_pst_amount = this.getRoundedValue(this.runningTotal * (0 / 100));
                    this.currentSalesOrder.so_qst_amount = this.getRoundedValue(this.runningTotal * (0 / 100));

                } else {
                    if (this.isUSA()) {
                        this.currentSalesOrder.so_hst_amount = this.getRoundedValue(this.runningTotal * (0 / 100));
                        this.currentSalesOrder.so_pst_amount = this.getRoundedValue(this.runningTotal * (0 / 100));
                        this.currentSalesOrder.so_qst_amount = this.getRoundedValue(this.runningTotal * (0 / 100));
                    } else {
                        this.currentSalesOrder.so_hst_amount = this.getRoundedValue(this.runningTotal * (taxdata?.hst / 100));
                        this.currentSalesOrder.so_pst_amount = this.getRoundedValue(this.runningTotal * (taxdata?.pst / 100));
                        this.currentSalesOrder.so_qst_amount = this.getRoundedValue(this.runningTotal * (taxdata?.qst / 100));

                    }

                }
                this.currentSalesOrder.so_gst_amount = this.getRoundedValue(this.runningTotal * (taxdata?.gst / 100));

                this.currentSalesOrder.so_gross_amount = this.runningTotal;
                this.currentSalesOrder.so_net_amount = this.getRoundedValue(this.runningTotal
                    + this.currentSalesOrder.so_hst_amount
                    + this.currentSalesOrder.so_gst_amount
                    + this.currentSalesOrder.so_pst_amount
                    + this.currentSalesOrder.so_qst_amount);
            }
        });

    }

    isUSA = () => {
        return this.currentSalesOrder?.so_sold_address?.toLowerCase().endsWith('usa');
    }

}


//https://www.mobilize.net/blog/mauricios-blog-redux