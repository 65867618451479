import { pdf } from '@react-pdf/renderer';
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { Button, Container, Grid, Icon, Input, Label, Segment, Table } from 'semantic-ui-react'
import { ICost, IQuote, IQuoteDetail } from '../../../app/models/Costing';
import { useStore } from '../../../app/stores/Store';
import PdfDocument from './PdfDocument';




const CostQuotes = () => {
    const { costingStore, costQuotesStore } = useStore();
    const { quotes, resetQuotes, showCalculations, removeQuote } = costingStore;
    const { loadingQuotes, loadSalesOrderForCosting, selectedSalesOrder, setSelectedSalesOrder, createQuote } = costQuotesStore;

    const [salesOrder, setSalesOrder] = useState('');

    useEffect(() => {
        if (selectedSalesOrder !== null) {
            setSalesOrder(selectedSalesOrder.SalesOrder);
        }
    }, [selectedSalesOrder]);


    const getTotal = () => {
        let total = 0;
        for (let index = 0; index < quotes.length; index++) {
            const damper = quotes[index];
            if (damper.quantity !== '') {
                total += (damper.totalCost * Number.parseInt(damper.quantity));
            }
        }

        return Math.round((total + Number.EPSILON) * 100) / 100;
    }

    const getTotalMaterialCost = () => {
        let total = 0;
        for (let index = 0; index < quotes.length; index++) {
            const damper = quotes[index];
            if (damper.quantity !== '') {
                total += (damper.totalMaterialCost * Number.parseInt(damper.quantity));
            }

        }
        return Math.round((total + Number.EPSILON) * 100) / 100;
    }
    const getTotalLaborCost = () => {
        let total = 0;
        for (let index = 0; index < quotes.length; index++) {
            const damper = quotes[index];
            if (damper.quantity !== '') {
                total += (damper.totalLaborCost * Number.parseInt(damper.quantity));
            }

        }
        return Math.round((total + Number.EPSILON) * 100) / 100;
    }
    const getTotalOverheadsCost = () => {
        let total = 0;
        for (let index = 0; index < quotes.length; index++) {
            const damper = quotes[index];
            if (damper.quantity !== '') {
                total += (damper.totalOverheads * Number.parseInt(damper.quantity));
            }
        }

        return Math.round((total + Number.EPSILON) * 100) / 100;
    }

    const UpdateQuantity = (value: string, index: number) => {
        const damper = quotes[index];
        damper.quantity = value;
        getTotal();
    }

    const GetQuantity = (index: number) => {
        const damper = quotes[index];
        if (damper.quantity !== '') {
            return Number.parseInt(damper.quantity);
        } else {
            return 0;
        }
    }

    const GetRounded = (value: number) => {
        return Math.round((value + Number.EPSILON) * 100) / 100;
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            loadSalesOrderForCosting(salesOrder);
        }

    }

    const saveCalculations = () => {
        if (selectedSalesOrder === undefined || selectedSalesOrder === null || salesOrder === '' || selectedSalesOrder?.SalesOrder === null) {
            toast.error('You need to tag a sales order number to save your calculations', { autoClose: 5000, position: "top-right" });
            return;
        }


        let totalMaterialCost = 0;
        let totalLaborCost = 0;
        let totalOverheadCost = 0;
        let grandTotal = 0;
        const quoteDetails: IQuoteDetail[] = [];
        for (let index = 0; index < quotes.length; index++) {
            const damper = quotes[index];

            quoteDetails.push({
                product: damper.selectedProduct,
                materialCost: damper.totalMaterialCost,
                laborCost: damper.totalLaborCost,
                overheadCost: damper.totalOverheads,
                price: damper.totalCost,
                quantity: Number.parseInt(damper.quantity),
                actualCost: 0,
            });

            totalMaterialCost += (damper.totalMaterialCost * Number.parseInt(damper.quantity));
            totalLaborCost += (damper.totalLaborCost * Number.parseInt(damper.quantity));
            totalOverheadCost += (damper.totalOverheads * Number.parseInt(damper.quantity));
            grandTotal += (damper.totalCost * Number.parseInt(damper.quantity));


            setSalesOrder('');
            setSelectedSalesOrder(null);

        }

        const quote: IQuote = {
            id: 0,
            salesOrder: selectedSalesOrder?.SalesOrder,
            custmerAlias: selectedSalesOrder.CustmerAlias,
            custmerName: selectedSalesOrder.CustmerName,
            grossAmount: selectedSalesOrder.GrossAmount,
            netAmount: selectedSalesOrder.NetAmount,
            calculatedNetCost: GetRounded(grandTotal),
            totalMaterialCost: GetRounded(totalMaterialCost),
            totalLaborCost: GetRounded(totalLaborCost),
            totalOverheadCost: GetRounded(totalOverheadCost),
            currency: "CAD",
            quoteDetails: quoteDetails
        };

        createQuote(quote).then(() => {
            console.log("Saved!");
        });
    }

    const getProfit = () => {

        if (selectedSalesOrder === undefined || selectedSalesOrder === null) {
            return 0;

        }

        const profit = GetRounded(selectedSalesOrder?.GrossAmount - getTotal());
        return profit;

    }

    const generatePdfDocument = async (data: string, fileName: string) => {
        if (fileName === "")
            fileName = "quote";
        const blob = await pdf((
            <PdfDocument />
        )).toBlob();
        saveAs(blob, fileName);
    };

    return (
        <>
            <Container style={{ padding: '0em 0em' }}>
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>
                            {quotes !== null && quotes.length > 0 && (
                                <>
                                    <Grid columns='equal'>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Input loading={loadingQuotes}
                                                    icon='search'
                                                    placeholder='Sales Order...'
                                                    value={salesOrder}
                                                    onChange={(e) => { setSalesOrder(e.target.value) }}
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                {selectedSalesOrder !== null && (
                                                    <Segment>
                                                        Customer : {selectedSalesOrder?.CustmerAlias} {selectedSalesOrder?.CustmerName}
                                                    </Segment>
                                                )}
                                            </Grid.Column>
                                            <Grid.Column>
                                                {selectedSalesOrder !== null && (
                                                    <>
                                                        <Label color='red' key='1' size='large' basic={true}>
                                                            Gross  :$ {selectedSalesOrder?.GrossAmount}
                                                        </Label>
                                                    </>
                                                )}

                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                {selectedSalesOrder !== null && (
                                                    <>
                                                        {getProfit() >= 0 ? (
                                                            <Label color='green' key='1' size='huge' basic={true}>
                                                                Profit  :$ {getProfit()}
                                                            </Label>

                                                        ) : (
                                                            <Label color='red' key='1' size='huge' basic={true}>
                                                                Loss  :$ {getProfit()}
                                                            </Label>

                                                        )}
                                                    </>
                                                )}
                                            </Grid.Column>
                                            <Grid.Column>

                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    <br />
                                    <Segment>
                                        <Button.Group >
                                            <Button negative onClick={() => resetQuotes()}>Clear Calculations</Button>
                                            <Button.Or />
                                            <Button positive onClick={() => { saveCalculations() }}>Save Calculations</Button>
                                            <Button.Or />
                                            <Button onClick={() => { generatePdfDocument("", salesOrder) }}>Download</Button>

                                        </Button.Group>
                                    </Segment>
                                    <br />
                                    <Table color='green' fixed celled selectable compact size='small' striped>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell style={{ width: '10%' }} textAlign='center'>Id</Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center'>Product</Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center'>Material</Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center'>Labor</Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center'>OH</Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center'>Price</Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center'>Quantity</Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center'>Total</Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center'>Action</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        {

                                            quotes.map((myquote: ICost, index) => (
                                                <tbody key={index}>
                                                    <Table.Row>
                                                        <Table.Cell textAlign='center'>{(index + 1)}</Table.Cell>
                                                        <Table.Cell textAlign='center'><b>{myquote.selectedProduct}</b>
                                                            <br />
                                                            {myquote.width} x {myquote.height}
                                                        </Table.Cell>
                                                        <Table.Cell textAlign='center' color='red'>
                                                            {GetRounded(myquote.totalMaterialCost)}
                                                            <br />
                                                            ({myquote.totalMaterialPercentage}) %
                                                        </Table.Cell>
                                                        <Table.Cell textAlign='center'>
                                                            {GetRounded(myquote.totalLaborCost)}
                                                            <br />
                                                            ({myquote.totalLaborPercentage}) %
                                                        </Table.Cell>
                                                        <Table.Cell textAlign='center'>
                                                            {GetRounded(myquote.totalOverheads)}
                                                            <br />
                                                            ({myquote.totalOverheadPercentage}) %
                                                        </Table.Cell>
                                                        <Table.Cell textAlign='center'>{GetRounded(myquote.totalCost)}</Table.Cell>
                                                        <Table.Cell textAlign='center'>
                                                            <input type='text'
                                                                value={myquote.quantity}
                                                                onChange={(e) => { UpdateQuantity(e.target.value, index) }}
                                                            />
                                                        </Table.Cell>
                                                        <Table.Cell textAlign='center'>
                                                            <Label color='green' key='1' size='large' basic={true}>
                                                                {GetRounded(myquote.totalCost) * GetQuantity(index)}
                                                            </Label>
                                                        </Table.Cell>
                                                        <Table.Cell textAlign='center'>
                                                            <>
                                                                <Icon name='question circle outline' size='large'
                                                                    color='blue'
                                                                    onClick={() => showCalculations(myquote)} />
                                                                <Icon name='trash alternate outline' size='large'
                                                                    color='red'
                                                                    onClick={() => removeQuote(myquote)} />
                                                            </>
                                                        </Table.Cell>
                                                    </Table.Row>
                                                </tbody>
                                            ))
                                        }

                                        <Table.Footer fullWidth>
                                            <Table.Row >
                                                <Table.Cell positive textAlign='right'>
                                                </Table.Cell>
                                                <Table.Cell positive textAlign='right'>
                                                </Table.Cell>
                                                <Table.Cell positive textAlign='right'>
                                                    <Label color='blue' key='1' size='small' basic={true}>
                                                        {getTotalMaterialCost()}
                                                    </Label>
                                                </Table.Cell>
                                                <Table.Cell positive textAlign='right'>
                                                    <Label color='orange' key='1' size='small' basic={true}>
                                                        {getTotalLaborCost()}
                                                    </Label>

                                                </Table.Cell>
                                                <Table.Cell textAlign='right'>
                                                    <Label color='red' key='1' size='small' basic={true}>
                                                        {getTotalOverheadsCost()}
                                                    </Label>

                                                </Table.Cell>
                                                <Table.Cell positive colSpan="4" textAlign='right'>
                                                    <Label color='green' key='1' size='large' basic={true}>
                                                        Total  :$ {getTotal()}
                                                    </Label>

                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Footer>
                                    </Table>

                                </>
                            )}
                        </Grid.Column>
                    </Grid.Row>

                </Grid>
            </Container>


        </>

    )
}

export default observer(CostQuotes)
