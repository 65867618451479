import React from 'react'
import { Field } from 'react-final-form'
import DateInput from '../../../../../app/common/form/DateInput'
import { ILegacySalesOrder } from '../../../../../app/models/SalesOrder'

const tableTd = {
    textAlign: 'right',
    border: '1px solid #000',
    background: '#eee',
} as React.CSSProperties

interface IProps {
    salesorder: ILegacySalesOrder;
}

const DocumentCustomerContacts: React.FC<IProps> = ({ salesorder }) => {
    return (
        <div>
            <table className='table' id='meta' style={{ width: '100%', border: 'none', marginTop: '150px' }}>
                <thead>
                    <tr>
                        <th>Customer Code</th>
                        <th>Contact Name</th>
                        <th>Customer PO</th>
                        <th>Ship Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={tableTd}>
                            <Field
                                component='textarea'
                                id='textarea'
                                style={{ textAlign: 'left' }}
                                name='so_customer_alias'
                                value={salesorder.so_customer_alias} />
                        </td>
                        <td style={tableTd}>
                            <Field
                                component='textarea'
                                id='textarea'
                                style={{ textAlign: 'left' }}
                                name='so_order_recipient'
                                value={salesorder.so_order_recipient}
                            />
                        </td>
                        <td style={tableTd}>
                            <Field
                                component='textarea'
                                id='textarea'
                                style={{ textAlign: 'left' }}
                                name='so_purchase_order_no'
                                value={salesorder.so_purchase_order_no}
                            />
                        </td>
                        <td style={{ ...tableTd, width: '200px' }}>
                            <Field fluid date={true} placeholder='Ship Date' name='so_ship_date' component={DateInput} />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default DocumentCustomerContacts
