import React, { Fragment } from 'react'

const BillOfMaterials = () => {
    return (
        <Fragment>
            <div className="documentItemDetails">
                <div className="didCell">No</div>
                <div className="didCell">Part No</div>
                <div className="didCell">Description</div>
                <div className="didCell">Quantity</div>
            </div>
            <div className="documentItemDetails">
                <div className="didCell">1</div>
                <div className="didCell">Stop Seal</div>
                <div className="didCell">Blade Stop Seal</div>
                <div className="didCell">2</div>
            </div>
            <div className="documentItemDetails">
                <div className="didCell">2</div>
                <div className="didCell">Link Bar</div>
                <div className="didCell">Blade Stop Seal</div>
                <div className="didCell">4</div>
            </div>
            <div className="documentItemDetails">
                <div className="didCell">3</div>
                <div className="didCell">Bushing</div>
                <div className="didCell">Blade Stop Seal</div>
                <div className="didCell">3</div>
            </div>
            <div className="documentItemDetails">
                <div className="didCell">4</div>
                <div className="didCell">Spring</div>
                <div className="didCell">Blade Stop Seal</div>
                <div className="didCell">2</div>
            </div>

            <div className="documentItemDetails">
                <div className="didCell">4</div>
                <div className="didCell">Spring</div>
                <div className="didCell">Blade Stop Seal</div>
                <div className="didCell">2</div>
            </div>

            <div className="documentItemDetails">
                <div className="didCell">4</div>
                <div className="didCell">Spring</div>
                <div className="didCell">Blade Stop Seal</div>
                <div className="didCell">2</div>
            </div>

            <div className="documentItemDetails">
                <div className="didCell">4</div>
                <div className="didCell">Spring</div>
                <div className="didCell">Blade Stop Seal</div>
                <div className="didCell">2</div>
            </div>

            <div className="documentItemDetails">
                <div className="didCell">4</div>
                <div className="didCell">Spring</div>
                <div className="didCell">Blade Stop Seal</div>
                <div className="didCell">2</div>
            </div>

            <div className="documentItemDetails">
                <div className="didCell">4</div>
                <div className="didCell">Spring</div>
                <div className="didCell">Blade Stop Seal</div>
                <div className="didCell">2</div>
            </div>

            <div className="documentItemDetails">
                <div className="didCell">4</div>
                <div className="didCell">Spring</div>
                <div className="didCell">Blade Stop Seal</div>
                <div className="didCell">2</div>
            </div>

            <div className="documentItemDetails">
                <div className="didCell">4</div>
                <div className="didCell">Spring</div>
                <div className="didCell">Blade Stop Seal</div>
                <div className="didCell">2</div>
            </div>

            <div className="documentItemDetails">
                <div className="didCell">4</div>
                <div className="didCell">Spring</div>
                <div className="didCell">Blade Stop Seal</div>
                <div className="didCell">2</div>
            </div>

            <div className="documentItemDetails">
                <div className="didCell">4</div>
                <div className="didCell">Spring</div>
                <div className="didCell">Blade Stop Seal</div>
                <div className="didCell">2</div>
            </div>

            <div className="documentItemDetails">
                <div className="didCell"><p>1</p></div>
                <div className="didCell"><p>so_part_no</p></div>
                <div className="didCell"><p>so_part_description</p></div>
                <div className="didCell"><p>so_item_qty</p></div>
            </div>
            <div className="documentItemDetails">
                <div className="didCell"><p>1</p></div>
                <div className="didCell"><p>so_part_no</p></div>
                <div className="didCell"><p>so_part_description</p></div>
                <div className="didCell"><p>so_item_qty</p></div>
            </div>
            <div className="documentItemDetails">
                <div className="didCell"><p>1</p></div>
                <div className="didCell"><p>so_part_no</p></div>
                <div className="didCell"><p>so_part_description</p></div>
                <div className="didCell"><p>so_item_qty</p></div>
            </div>
            <div className="documentItemDetails">
                <div className="didCell"><p>1</p></div>
                <div className="didCell"><p>so_part_no</p></div>
                <div className="didCell"><p>so_part_description</p></div>
                <div className="didCell"><p>so_item_qty</p></div>
            </div>
            <div className="documentItemDetails">
                <div className="didCell"><p>1</p></div>
                <div className="didCell"><p>so_part_no</p></div>
                <div className="didCell"><p>so_part_description</p></div>
                <div className="didCell"><p>so_item_qty</p></div>
            </div>
            <div className="documentItemDetails">
                <div className="didCell"><p>1</p></div>
                <div className="didCell"><p>so_part_no</p></div>
                <div className="didCell"><p>so_part_description</p></div>
                <div className="didCell"><p>so_item_qty</p></div>
            </div>
            <div className="documentItemDetails">
                <div className="didCell"><p>1</p></div>
                <div className="didCell"><p>so_part_no</p></div>
                <div className="didCell"><p>so_part_description</p></div>
                <div className="didCell"><p>so_item_qty</p></div>
            </div>
            <div className="documentItemDetails">
                <div className="didCell"><p>1</p></div>
                <div className="didCell"><p>so_part_no</p></div>
                <div className="didCell"><p>so_part_description</p></div>
                <div className="didCell"><p>so_item_qty</p></div>
            </div>
            <div className="documentItemDetails">
                <div className="didCell"><p>1</p></div>
                <div className="didCell"><p>so_part_no</p></div>
                <div className="didCell"><p>so_part_description</p></div>
                <div className="didCell"><p>so_item_qty</p></div>
            </div>

        </Fragment>
    )
}

export default BillOfMaterials
