import { observer } from 'mobx-react-lite';
import React, { Fragment, useState } from 'react'
import { Grid, Header, Icon, Loader, Segment } from 'semantic-ui-react';
import { useStore } from '../../../../app/stores/Store';
//import { history } from '../../../..';
import InfiniteScroll from 'react-infinite-scroller';
import SalesOrderList from './SalesOrderList';
import SalesOrderListPlaceholder from './SalesOrderListPlaceholder';

const SalesOrderSearchResults = () => {
    const { salesOrderStore, searchStore } = useStore();
    const { loadProgressiveSalesOrders, loadingSearch, salesorders } = salesOrderStore;
    const { totalPages, currentPageNo, setPageNo } = searchStore;

    const [loadingNext, setLoadingNext] = useState(false);


    const handleScroll = async () => {
        setLoadingNext(true);
        setPageNo(currentPageNo + 1);
        loadProgressiveSalesOrders().then(async () => {
            await new Promise(r => setTimeout(r, 5000));
            setLoadingNext(false)
        });
    }


    if (loadingSearch) return <SalesOrderListPlaceholder />

    return (
        <Fragment>
            {salesorders.length <= 0 ? (
                <Grid>
                    <Grid.Column width={16}>
                        <Segment placeholder>
                            <Header icon>
                                <Icon name='asl' />
                                Darn it!, No documents found for your search.
                            </Header>
                        </Segment>
                    </Grid.Column>
                </Grid>

            ) : (
                <Grid>
                    <Grid.Column width={16}>
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={handleScroll}
                            hasMore={!loadingSearch && currentPageNo + 1 <= totalPages}
                            initialLoad={false}>
                            <SalesOrderList />
                        </InfiniteScroll>
                    </Grid.Column>
                    <Grid.Column width={16}>
                        <Loader active={loadingNext} />
                    </Grid.Column>
                </Grid >

            )}
            {/*
            <Grid>
                <Grid.Column width={8}>
                    {loadingSearch && currentPageNo === 0 ? <SearchResultsPlaceholder /> : (
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={handlePageChange}
                            hasMore={!loadingSearch && currentPageNo + 1 <= totalPages}
                            initialLoad={false}>
                            <SalesOrderList />
                        </InfiniteScroll>
                    )}
                </Grid.Column>
                <Grid.Column width={16}>
                    <Loader active={loadingSearch} />
                </Grid.Column>
            </Grid>

                    */}



        </Fragment >
    )
}

export default observer(SalesOrderSearchResults)
