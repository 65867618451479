import React from 'react'
import { Label } from 'semantic-ui-react'
import { ILegacySalesOrder } from '../../../../../app/models/SalesOrder'

const tablestyle = {
    width: '100%',

} as React.CSSProperties

const imagestyle = {
    width: '200px'

} as React.CSSProperties

const noborder = {
    border: 'none'
} as React.CSSProperties

const documentNumber = {
    display: 'inline',
    float: 'right',
    textTransform: 'uppercase',
    font: '20px Helvetica, Sans-Serif',
    color: 'rgb(117, 8, 8)',
    fontWeight: 400,
    letterSpacing: '5px',
    padding: '0 0 8px',
    margin: 30

} as React.CSSProperties


interface IProps {
    salesorder: ILegacySalesOrder;
}


const DocumentHeaderLogo: React.FC<IProps> = ({ salesorder }) => {
    return (
        <table className='table' style={tablestyle}>
            <tbody>
                <tr>
                    <td style={noborder}>
                        <img
                            style={imagestyle}
                            src='/assets/logo.png'
                            alt='BriskMfg Inc.'
                        />
                    </td>
                    <td style={noborder}>
                        <div style={documentNumber}>
                            {salesorder.so_invoice_no && (
                                <Label color='blue'>{salesorder.so_invoice_no}</Label>
                            )}
                            {/*
                            <Field component="textarea" id='textarea' style={tableTdTextArea} name='so_invoice_no' value={salesorder.so_invoice_no} />
                            */}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default DocumentHeaderLogo
