import React, { useEffect, Fragment } from 'react';
import { Tab } from 'semantic-ui-react';

import { observer } from 'mobx-react-lite';
import SalesOrderListPlaceholder from './results/SalesOrderListPlaceholder';
import SalesOrderSearchTab from './search/SalesOrderSearchTab';
import { useStore } from '../../../app/stores/Store';


const SalesDashboard = () => {
    const { salesOrderStore, searchStore } = useStore();
    const { loadSalesOrders, loadingInitial, tabPanes, addTabPane, activeTabIndex, setActiveTabIndex } = salesOrderStore;
    const { resetSearch } = searchStore;

    useEffect(() => {
        //console.log('called use SalesDashboard');
        resetSearch('customerAlias');
        loadSalesOrders();
        addTabPane('search', {
            menuItem: 'Sales Order Search',
            render: () => <SalesOrderSearchTab />
        });

        // addTabPane('20-9940', {
        //     menuItem: '20-9940',
        //     render: () => <SalesOrderTabPane order={'20-9940'} orderid={'5084'} tabCount={tabPanes.length} />
        // });

        // addTabPane('18-5131', {
        //     menuItem: { key: '18-5131', icon: 'file outline', content: '18-5131' },
        //     render: () => <SalesOrderDocumentView order={'18-5131'} orderid={'1631'} tabCount={tabPanes.length} />
        // });


    }, [loadSalesOrders, addTabPane, resetSearch])


    return (
        <Fragment>
            {
                loadingInitial ?
                    <SalesOrderListPlaceholder />
                    :
                    <Fragment>
                        <Tab activeIndex={activeTabIndex} panes={tabPanes} onTabChange={(e, data) => { setActiveTabIndex(data.activeIndex) }} />
                    </Fragment>
            }
        </Fragment>
    )
}

export default observer(SalesDashboard)
