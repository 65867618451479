import React, { useState } from 'react'
import { Menu, Header, Input, Button, Icon } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/Store';

const TrackingSearchForm = () => {
    const { scanStore } = useStore();
    const { searchDocuments, loadDocuments } = scanStore;
    const [searchValue, setSearchValue] = useState('');

    const onSearchDocuments = () => {
        if (searchValue === "" || searchValue.length === 0)
            loadDocuments();
        else
            searchDocuments(searchValue);
    }

    return (
        <Menu size={'large'} style={{ width: '100%', marginTop: 50 }}>
            <Header style={{ width: '20%' }} icon={'filter'} attached color={'teal'} content={'Work Orders'} />
            <Menu.Item style={{ width: '80%' }}>
                <Input type='text'
                    value={searchValue} onChange={(e) => setSearchValue(e.target.value)}
                    style={{ width: '70%' }}
                    placeholder='Search...' action>
                    <input />
                    <Button icon color='blue' onClick={onSearchDocuments} loading={false}>
                        <Icon name='search' />
                        Search
                    </Button>
                </Input>
            </Menu.Item>
        </Menu>
    )
}

export default observer(TrackingSearchForm)
