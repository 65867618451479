import React, { useState } from 'react';
import { parse } from 'papaparse';
import { getBlankSalesOrderDetail, ISalesOrderDetail } from '../../../../app/models/SalesOrder';


var divStyle = {
    width: '100%',
    border: '3px dashed red',
    margin: '0 auto'
};


interface IProps {
    addSalesOrderDetail: (salesOrderDetail: ISalesOrderDetail) => void
}


const SalesOrderExcelAwareTable: React.FC<IProps> = ({ children, addSalesOrderDetail }) => {
    const [highlighted, setHighlighted] = useState(false);

    return (
        <div
            style={highlighted ? divStyle : {}}
            onDragEnter={() => { setHighlighted(true) }}
            onDragLeave={() => { /*setHighlighted(false)*/ }}
            onDragOver={(e) => { e.preventDefault(); }}
            onDrop={(e) => {
                e.preventDefault();
                console.log('on drop works', e);
                setHighlighted(false)
                Array.from(e.dataTransfer.files)
                    //   .filter((file: any) => { return (file.type === "text/csv" || file.type === "application/vnd.ms-excel") })
                    .forEach(async file => {
                        const text = await file.text();
                        const items = parse(text, { header: true });

                        items.data.map((mydata: any) => {
                            let csvdata = getBlankSalesOrderDetail();
                            csvdata.so_part_no = mydata.Code;
                            csvdata.so_part_description = mydata.Description;
                            csvdata.so_item_width = mydata.Width;
                            csvdata.so_item_height = mydata.Height;
                            csvdata.so_item_dia = mydata.Diameter;
                            csvdata.so_item_qty = mydata.Ordered;
                            csvdata.so_item_back_ordered = mydata.BackOrder;
                            csvdata.so_item_shipped = mydata.Shipped;
                            csvdata.so_item_sale_price = mydata.Unit;
                            csvdata.so_item_total_amount = mydata.Total;

                            if (mydata.Code && mydata.Description)
                                addSalesOrderDetail(csvdata);

                            return 0;
                        })
                    })

            }}
        >
            { children}
        </div>
    )
}

export default SalesOrderExcelAwareTable
