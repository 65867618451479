import React, { Fragment, useEffect } from 'react'
import { Tab } from 'semantic-ui-react'
import TrackingSearchTab from './TrackingSearchTab';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/Store';

const TrackingDashboard = () => {
    const { commonStore, scanStore } = useStore();
    const { addTabPane, activeTabIndex, tabPanes, setActiveTabIndex, resetTabs } = commonStore;
    const { loadDocuments } = scanStore;

    useEffect(() => {
        resetTabs();
        loadDocuments();
        addTabPane('search', {
            menuItem: 'Work Order Search',
            render: () => <TrackingSearchTab />
        });
    }, [loadDocuments, addTabPane, resetTabs])

    return (
        <Fragment>
            <Tab activeIndex={activeTabIndex} panes={tabPanes} onTabChange={(e, data) => { setActiveTabIndex(data.activeIndex) }} />
        </Fragment>
    )
}

export default observer(TrackingDashboard)
