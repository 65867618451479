import React from 'react'
import { FieldRenderProps } from 'react-final-form';
import { FormFieldProps, Form, Label, Select } from 'semantic-ui-react';

interface IProps extends FieldRenderProps<string, HTMLElement>, FormFieldProps { }

const SelectInput: React.FC<IProps> = ({ input, width, options, placeholder, meta: { touched, error }, label, inline, style }) => {
    return (
        <Form.Field width={width} error={touched && !!error} inline={inline}>
            <label>{label}</label>
            <Select
                style={style}
                onChange={(e, data) => input.onChange(data.value)}
                value={input.value}
                options={options}
                placeholder={placeholder} />
            {touched && error && <Label basic color='red'>{error}</Label>}
        </Form.Field>
    )
}

export default SelectInput