import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Header, Icon, Table } from 'semantic-ui-react';
import { useStore } from '../../app/stores/Store';



const CostingReport = () => {
    const { costQuotesStore } = useStore();
    const { loadingQuotes, quotes, loadQuotes, deleteQuote } = costQuotesStore;


    useEffect(() => {
        loadQuotes();
    }, [loadQuotes]);

    const GetRounded = (value: number) => {
        return Math.round((value + Number.EPSILON) * 100) / 100;
    }

    if (loadingQuotes || quotes.length <= 0) {
        return <div>Loading...</div>
    }

    return (
        <div>
            <Header as='h2' dividing>
                Costing Report
            </Header>
            {/* <Grid columns={2} divided>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Fragment>
                            <div style={{ margin: '0px', width: '100%', marginRight: '20px' }}>
                                <Label style={{ width: '100px' }}>Start Date:</Label>
                                <DatePicker selected={startInvoiceDate} onChange={handleStartDateChange} dateFormat="yyyy-MM-dd" />
                                <Label style={{ width: '100px' }}>End Date:</Label>
                                <DatePicker selected={endInvoiceDate} onChange={handleEndDateChange} dateFormat="yyyy-MM-dd" />
                                <Label style={{ width: '100px' }}>Order Status:</Label>
                                <select style={{ margin: '0px', width: '230px', marginRight: '20px' }}
                                    value={orderStatus}
                                    onChange={e => setOrderStatus(e.target.value)}>
                                    {salesorderstatuses.map(o => (
                                        <option key={o.value} value={o.value}>{o.text}</option>
                                    ))}
                                </select>
                                <ButtonGroup>
                                    <a href={process.env.REACT_APP_API_URL + `/costing/analyzesalesorders?orderStatus=${orderStatus}&startDate=${startInvoiceDate.toISOString().split('T')[0]}&endDate=${endInvoiceDate.toISOString().split('T')[0]}&orderStatus=${orderStatus}`}><Button style={{ margin: '5px' }} primary basic>Download</Button></a>
                                </ButtonGroup>
                            </div>
                        </Fragment>
                    </Grid.Column>

                </Grid.Row>

            </Grid> */}

            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>SalesOrder</Table.HeaderCell>
                        <Table.HeaderCell>Customer</Table.HeaderCell>
                        <Table.HeaderCell>Gross Amount</Table.HeaderCell>
                        <Table.HeaderCell>Calculated Cost</Table.HeaderCell>
                        <Table.HeaderCell>Material, Labor,OH</Table.HeaderCell>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {quotes.map((quote, index) => (
                        <Table.Row key={index} error={quote.netAmount <= quote.calculatedNetCost} positive={quote.netAmount > quote.calculatedNetCost}>
                            <Table.Cell>{quote.salesOrder}</Table.Cell>
                            <Table.Cell>{quote.custmerName}</Table.Cell>
                            <Table.Cell>$ {quote.grossAmount}</Table.Cell>
                            <Table.Cell>$ {GetRounded(quote.calculatedNetCost)}</Table.Cell>
                            <Table.Cell>$ {GetRounded(quote.totalMaterialCost)} +<br />  ${GetRounded(quote.totalLaborCost)} +<br /> ${GetRounded(quote.totalOverheadCost)}</Table.Cell>
                            <Table.Cell>
                                <Icon name='trash alternate outline' size='large'
                                    color='red'
                                    onClick={() => deleteQuote(quote)} />

                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>



        </div>

    );
}
export default observer(CostingReport);
