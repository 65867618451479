import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, Fragment, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, ButtonGroup, Container, Grid, Header, Icon, Label, Segment, Table } from 'semantic-ui-react';
import { useStore } from '../../app/stores/Store';
import CostQuotes from './costproducts/CostQuotes';
import DatePicker from "react-datepicker";

const salesorderstatuses = [
    { key: 'all', text: 'All', value: 'All' },
    { key: 'underprocess', text: 'Under Process', value: 'Under Process' },
    { key: 'underprocess_packingslip', text: 'Under Process Or PackingSlip', value: 'Under Process Or Packing Slip' },
    { key: 'onhold', text: 'On Hold', value: 'On Hold' },
    { key: 'packingslip', text: 'Packing Slip', value: 'Packing Slip' },
    { key: 'cancelled', text: 'Cancelled', value: 'Cancelled' },
    { key: 'invoiced', text: 'Invoiced', value: 'Invoiced' },
    { key: 'not_invoiced', text: 'Not Invoiced', value: 'Not Invoiced' },
]




interface ParamAtributes {
    id: string
}


const productTypes = [
    { text: 'NA', value: 'NA' },
    { text: 'DynamicDamper', value: 'DynamicDamper' },
    { text: 'FireDamper', value: 'FireDamper' },
    { text: 'ControlDamper', value: 'ControlDamper' },
    { text: 'FireSmokeDamper', value: 'FireSmokeDamper' },
    { text: 'RCD', value: 'RCD' },
    { text: 'OvalDoor', value: 'OvalDoor' },
    { text: 'SquareDoor', value: 'SquareDoor' }
]

const CostSalesOrderCalculator: React.FC<RouteComponentProps<ParamAtributes>> = ({ match }) => {
    const { costingStore } = useStore();
    const {
        currentCalculation,
        getCostingForSalesOrder,
        salesOrderCosting,
        createRule,
        resetSalesOrderCosting
    } = costingStore;

    const [orderNumber, setOrderNumber] = useState(match.params.id);


    const [orderStatus, setOrderStatus] = useState('All');

    const [startInvoiceDate, setStartInvoiceDate] = useState(new Date(new Date().getTime() - (86400000 * 5)));
    const handleStartDateChange = (date: any) => setStartInvoiceDate(date);

    const [endInvoiceDate, setEndInvoiceDate] = useState(new Date());
    const handleEndDateChange = (date: any) => setEndInvoiceDate(date);



    const getFromattedDate = (myDate: Date) => {
        let mydate = myDate.toISOString().split('T')[0]
        return mydate;
    }

    const [productType, setProductType] = useState<string[]>([]);



    const GetRounded = (value: number) => {
        return Math.round((value + Number.EPSILON) * 100) / 100;
    }
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            if (event.target.name.includes('order')) {
                performSalesOrderCosting();
            }
        }
    }

    const handleFocus = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => event.target.select();

    const performSalesOrderCosting = () => {
        resetSalesOrderCosting();
        getCostingForSalesOrder(orderNumber)
        let maxLength = salesOrderCosting?.notFoundParts !== undefined ? salesOrderCosting?.notFoundParts.length : 0;
        let possibleNotFoundTypes: string[] = [];
        for (let i = 0; i < maxLength; i++) {
            possibleNotFoundTypes.push('NA');
        }
        setProductType(possibleNotFoundTypes);
    }

    // if (match.params.id !== '') {
    //     performSalesOrderCosting();
    // }

    // useEffect(() => {
    //     return () => {
    //         resetSalesOrderCosting();
    //     };
    // }, [resetSalesOrderCosting, match.params.id, performSalesOrderCosting]);


    const selectProductType = (index: number, value: string) => {

        productType[index] = value;
        setProductType(productType);

    }
    const SaveRule = async (index: number, productCode: string) => {
        console.log(`Save Value of  ${productType[index]} set to value ${productCode}`);
        await createRule({ "productCode": productCode, "productType": productType[index] });
        if (orderNumber !== '' && orderNumber !== null)
            performSalesOrderCosting();

    }

    return (
        <Fragment>
            <Grid columns={2} divided>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Fragment>
                            <div style={{ margin: '0px', width: '100%', marginRight: '20px' }}>
                                <Label style={{ width: '100px' }}>Start Date:</Label>
                                <DatePicker selected={startInvoiceDate} onChange={handleStartDateChange} dateFormat="yyyy-MM-dd" />
                                <Label style={{ width: '100px' }}>End Date:</Label>
                                <DatePicker selected={endInvoiceDate} onChange={handleEndDateChange} dateFormat="yyyy-MM-dd" />
                                <Label style={{ width: '100px' }}>Order Status:</Label>
                                <select style={{ margin: '0px', width: '230px', marginRight: '20px' }}
                                    value={orderStatus}
                                    onChange={e => setOrderStatus(e.target.value)}>
                                    {salesorderstatuses.map(o => (
                                        <option key={o.value} value={o.value}>{o.text}</option>
                                    ))}
                                </select>
                                <ButtonGroup>
                                    {/*<Button style={{ margin: '5px' }} primary basic onClick={() => getFromattedDate(startInvoiceDate)}>Test</Button>*/}
                                    <a href={process.env.REACT_APP_API_URL + `/costing/analyzesalesorders?orderStatus=${orderStatus}&startDate=${getFromattedDate(startInvoiceDate)}&endDate=${getFromattedDate(endInvoiceDate)}`}><Button style={{ margin: '5px' }} primary basic>Download</Button></a>
                                </ButtonGroup>
                            </div>
                        </Fragment>
                    </Grid.Column>

                </Grid.Row>

            </Grid>

            <Grid columns={3} style={{ padding: '0em 0em' }} divided>
                <Grid.Row textAlign='center'>
                    <Grid.Column width={8}>
                        <Container style={{ margin: '0em 0em' }}>
                            <div className="ui action input" style={{ float: 'left', marginBottom: '10px' }} >
                                <input name='orderNumber' type="text" value={orderNumber} placeholder="Sales Order No ..."
                                    onFocus={handleFocus}
                                    onKeyDown={handleKeyDown}
                                    onChange={(e) => setOrderNumber(e.target.value)}
                                />
                                <button className="ui icon button" onClick={performSalesOrderCosting} ><i aria-hidden="true" className="search icon"></i></button>
                            </div>

                            {salesOrderCosting && (
                                <Table celled padded>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='5'>Not Classified</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell singleLine>Product</Table.HeaderCell>
                                            <Table.HeaderCell>Dimensions</Table.HeaderCell>
                                            <Table.HeaderCell>Quantity</Table.HeaderCell>
                                            <Table.HeaderCell>Cost</Table.HeaderCell>
                                            <Table.HeaderCell>Match</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>

                                        {salesOrderCosting.notFoundParts.map((notfoundPart, index) => (
                                            <Table.Row key={index}>
                                                <Table.Cell>
                                                    {notfoundPart.partNo}<br />
                                                    {notfoundPart.partDescription}
                                                </Table.Cell>
                                                {(notfoundPart.height !== '' && notfoundPart.height !== '0' && notfoundPart.width !== '') ? (
                                                    <Table.Cell singleLine>{notfoundPart.height} x {notfoundPart.width} </Table.Cell>

                                                ) : (
                                                    <Table.Cell singleLine>{notfoundPart.diameter}</Table.Cell>
                                                )}
                                                <Table.Cell>
                                                    {notfoundPart.quantity}
                                                </Table.Cell>
                                                <Table.Cell textAlign='right'>
                                                    {GetRounded(notfoundPart.totalAmount)}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <select style={{ width: '130px' }}
                                                        value={productType[index]}
                                                        onChange={e => selectProductType(index, e.target.value)}
                                                    >
                                                        {productTypes.map(o => (
                                                            <option key={o.value} value={o.value}>{o.text}</option>
                                                        ))}
                                                    </select>
                                                    <br />
                                                    <Button animated='vertical' positive onClick={e => SaveRule(index, notfoundPart.partNo)}>
                                                        <Button.Content hidden>Save</Button.Content>
                                                        <Button.Content visible>
                                                            <Icon name='save' />
                                                        </Button.Content>
                                                    </Button>
                                                </Table.Cell>
                                            </Table.Row>

                                        ))}

                                    </Table.Body>
                                </Table>

                            )}

                            {salesOrderCosting && (
                                <Table celled padded>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan='5'>Matched Records</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell singleLine>Product</Table.HeaderCell>
                                            <Table.HeaderCell>Dimensions</Table.HeaderCell>
                                            <Table.HeaderCell>Quantity</Table.HeaderCell>
                                            <Table.HeaderCell>Cost</Table.HeaderCell>
                                            <Table.HeaderCell>Match</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>

                                        {salesOrderCosting.costs && salesOrderCosting.costs.map((cost, index) => (
                                            <Table.Row key={index}>
                                                <Table.Cell>
                                                    {cost.partNo}<br />
                                                    {cost.partDescription}
                                                </Table.Cell>
                                                {(cost.height > 0 && cost.width > 0) ? (
                                                    <Table.Cell singleLine>{cost.width} x {cost.height}</Table.Cell>

                                                ) : (
                                                    <Table.Cell singleLine> {cost.diameter} </Table.Cell>
                                                )}

                                                <Table.Cell>
                                                    {cost.quantity}
                                                </Table.Cell>
                                                <Table.Cell textAlign='right'>
                                                    {GetRounded(cost.costCharged)}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Header as='h4' textAlign='center'>
                                                        {cost.selectedProduct}
                                                    </Header>
                                                </Table.Cell>
                                            </Table.Row>

                                        ))}

                                    </Table.Body>
                                </Table>

                            )}


                        </Container>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <CostQuotes />
                    </Grid.Column>
                </Grid.Row>

                {currentCalculation && (
                    <Grid.Row>

                        <Grid.Column width={5}>
                            <Segment.Group>
                                <Segment color='green'>
                                    <p>Calculation CheatSheet For {currentCalculation.selectedProduct} ({currentCalculation.width} X {currentCalculation.height}) </p>

                                    <p>Total Cost of <u>{GetRounded(currentCalculation.totalCost)} </u></p>


                                </Segment>
                                <Segment color='green'>
                                    <Table>
                                        {currentCalculation.costBreakups.map((costBreakup, i) => (
                                            <Table.Row key={i}>
                                                <Table.Cell>
                                                    {costBreakup.factorName}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {GetRounded(costBreakup.factorValue)}
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table>
                                </Segment>
                            </Segment.Group>
                        </Grid.Column>
                    </Grid.Row>
                )}
            </Grid >
        </Fragment >

    )
};

export default observer(CostSalesOrderCalculator);
