import React from 'react';
import { RouteProps, RouteComponentProps, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../stores/Store';


interface IProps extends RouteProps {
    component: React.ComponentType<RouteComponentProps<any>>;
}
const PrivateRoute: React.FC<IProps> = ({ component: Component, ...rest }) => {
    const { userStore } = useStore();
    const { isLoggedIn } = userStore;

    return (
        <Route
            {...rest}
            render={(props) => (isLoggedIn ? <Component {...props} /> : <Redirect to={'/'} />)}
        />
    )
}

export default observer(PrivateRoute);
