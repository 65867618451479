import React from 'react'
import { Modal, Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/Store';

const DeleteModal = () => {
    const { deleteModalStore } = useStore();
    const { modal: { open, header, body, callBacKFunction }, closeDeleteModal } = deleteModalStore;
    return (
        <Modal open={open} onClose={closeDeleteModal} size={'mini'}>
            <Modal.Header>{header}</Modal.Header>
            <Modal.Content>
                <p> {body}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={closeDeleteModal}>No</Button>
                <Button
                    positive
                    icon='checkmark'
                    labelPosition='right'
                    content='Yes'
                    onClick={callBacKFunction!}
                />
            </Modal.Actions>
        </Modal>
    )
}

export default observer(DeleteModal)

