import { observer } from 'mobx-react-lite'
import React, { Fragment, useState } from 'react'
import { Container, Grid, Icon, Step } from 'semantic-ui-react';
import ControlDamperInput from './products/ControlDamperInput';
import FireDamperInput from './products/FireDamperInput';
import Quotes from './products/Quotes';

const RatesHome = () => {

    const [selectedCalculatorType, setSelectedCalcularType] = useState<string>('FireDamper');

    const getCalculatorInput = () => {
        if (selectedCalculatorType === "FireDamper")
            return <FireDamperInput />
        else if (selectedCalculatorType === "ControlDamper")
            return <ControlDamperInput />
        return <FireDamperInput />
    }

    return (
        <Fragment>
            <Grid columns={3} style={{ padding: '6em 0em' }} divided>
                <Grid.Row textAlign='center'>
                    <Grid.Column width={3}>
                        <Container style={{ margin: '5em 0em' }}>
                            <Step.Group vertical>
                                {
                                    <Step
                                        active={selectedCalculatorType === "FireDamper" ? true : false}
                                        onClick={() => setSelectedCalcularType("FireDamper")}>
                                        <Step.Content>
                                            <Step.Title><Icon name='calculator' />Fire Damper</Step.Title>
                                        </Step.Content>
                                    </Step>
                                }
                                {
                                    <Step active={selectedCalculatorType === "ControlDamper" ? true : false}
                                        onClick={() => setSelectedCalcularType("ControlDamper")}>
                                        <Step.Content>
                                            <Step.Title><Icon name='bullseye' />Control Damper</Step.Title>
                                        </Step.Content>
                                    </Step>
                                }
                                {
                                    <Step active={selectedCalculatorType === "Doors" ? true : false}
                                        onClick={() => setSelectedCalcularType("Doors")}>
                                        <Step.Content>

                                            <Step.Title><Icon name='industry' />Access Doors</Step.Title>
                                        </Step.Content>
                                    </Step>
                                }
                                {
                                    <Step active={selectedCalculatorType === "BackdraftDampers" ? true : false}
                                        onClick={() => setSelectedCalcularType("BackdraftDampers")}>
                                        <Step.Content>
                                            <Step.Title><Icon name='expand' />Backdraft Dampers</Step.Title>

                                        </Step.Content>
                                    </Step>
                                }
                                {
                                    <Step active={selectedCalculatorType === "CRD" ? true : false}
                                        onClick={() => setSelectedCalcularType("CRD")}>
                                        <Step.Content>
                                            <Step.Title><Icon name='copyright outline' />CRD</Step.Title>

                                        </Step.Content>
                                    </Step>
                                }
                                {
                                    <Step active={selectedCalculatorType === "FireDampers" ? true : false}
                                        onClick={() => setSelectedCalcularType("FireDampers")}>
                                        <Step.Content>
                                            <Step.Title><Icon name='fire extinguisher' />Fire Dampers</Step.Title>

                                        </Step.Content>
                                    </Step>
                                }
                            </Step.Group>
                        </Container>
                    </Grid.Column>
                    <Grid.Column width={6}>

                        {/*(() => {
                            if (selectedCalculatorType === "FireDamper") {
                                return (
                                    <FireDamperInput />
                                )
                            } else if (selectedCalculatorType === "ControlDamper") {
                                return (
                                    <ControlDamperInput />
                                )
                            } else {
                                return (
                                    <FireDamperInput />
                                )
                            }
                        })()*/}


                        {getCalculatorInput()}
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Quotes />
                    </Grid.Column>
                </Grid.Row>

            </Grid >
        </Fragment >

    )
}

export default observer(RatesHome)
