import { makeAutoObservable } from "mobx";

export class ModalStore {


    constructor() {
        makeAutoObservable(this);


    }

    modal = {
        open: false,
        isSizeLarge: false,
        body: null
    }

    openModal = (content: any, isSizeLarge: boolean = false) => {
        this.modal.open = true;
        this.modal.isSizeLarge = isSizeLarge;
        this.modal.body = content;
    }


    closeModal = () => {
        this.modal.open = false;
        this.modal.body = null;
    }

}