import React from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { IUserFormValuesWithSecret } from '../../app/models/User';
import { combineValidators, isRequired } from 'revalidate';
import { FORM_ERROR } from 'final-form';
import { Form, Header, Button } from 'semantic-ui-react';
import ErrorMessage from '../../app/common/form/ErrorMessage';
import TextInput from '../../app/common/form/TextInput';
import { useStore } from '../../app/stores/Store';


const validate = combineValidators({
    email: isRequired('Email'),
    userName: isRequired('User Name'),
    displayName: isRequired('Display Name'),
    password: isRequired('password')
})

const RegisterForm = () => {
    const { userStore } = useStore();
    const { register } = userStore;
    return (
        <FinalForm
            onSubmit={(values: IUserFormValuesWithSecret) => register(values).catch((error) => ({
                [FORM_ERROR]: error
            }))}
            validate={validate}
            render={({ handleSubmit, submitting, form, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                <Form onSubmit={handleSubmit} error>
                    <Header as='h2' content='Sign up to BriskMfg' color='teal' textAlign='center' />
                    <Field name='userName' placeholder='User Name' component={TextInput} />
                    <Field name='displayName' placeholder='Display Name' component={TextInput} />
                    <Field name='email' placeholder='Email' component={TextInput} />
                    <Field name='password' placeholder='Password' type='password' component={TextInput} />
                    <Field name='secretpassword' placeholder='secretpassword' type='password' component={TextInput} />
                    {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} />}
                    <Button fluid disabled={(invalid && !dirtySinceLastSubmit) || pristine} loading={submitting} color='teal' content='Register' />
                    {/* {<pre>{JSON.stringify(form.getState(),null,2)}</pre>} */}
                </Form>

            )}



        />
    )
}

export default RegisterForm
