import React, { Fragment } from 'react'
import { Line } from 'react-chartjs-2';

const data = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    datasets: [
        {
            label: '# of Processed',
            data: [12, 19, 3, 5, 2, 3],
            fill: false,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgba(255, 99, 132, 0.2)',
        },
    ],
};

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
};
const SalesOrderProcessing = () => {
    return (
        <Fragment>
            <div className='header'>
                <h1>Also try recharts!</h1>
                <h1 className='title'>Under Process Processing</h1>
            </div>
            <div style={{ width: '500px' }}>
                <Line type={'bar'} data={data} options={options} />
            </div>
        </Fragment>
    )
}

export default SalesOrderProcessing
