import React, { useEffect, Fragment } from 'react'
import { RouteComponentProps, withRouter, Route, Switch } from 'react-router-dom';
import LoadingComponent from './LoadingComponent';
import { ToastContainer } from 'react-toastify';
import HomePage from '../../features/home/HomePage';
import TopNav from './TopNav';
import { Container } from 'semantic-ui-react';
import PrivateRoute from './PrivateRoute';
import { observer } from 'mobx-react-lite';
import UserProfile from '../../features/profiles/UserProfile';
import SalesDashboard from '../../features/sales/dashboard/SalesDashboard';
import SalesOrderForm from '../../features/sales/forms/SalesOrderForm';
import LoginForm from '../../features/user/LoginForm';
import NotFound from './NotFound';
import ModalContainer from '../common/modals/ModalContainer';
import CustomerDashboard from '../../features/customers/dashboard/CustomerDashboard';
import CreateNewCustomer from '../../features/customers/create/CreateNewCustomer';
import DeleteModal from '../common/modals/DeleteModal';
import PODashboard from '../../features/purchaseorders/PODashboard';
import ScanDashboard from '../../features/scans/ScanDashboard';
import TrackingDashboard from '../../features/trackstatus/TrackingDashboard';
import ProductionKiosk from '../../features/kiosks/ProductionKiosk';
import DeliveryKiosk from '../../features/kiosks/DeliveryKiosk';
import DailyReport from '../../features/dailyreport/DailyReport';
import DailyReportKiosk from '../../features/kiosks/DailyReportKiosk';
import SalesOrderPlanner from '../../features/productionplanning/SalesOrderPlanner';
import Invoice from '../../features/documents/Invoices/Invoice';
import DataSyncDashboard from '../../features/datasyncs/DataSyncDashboard';
import ProductionPlanningPlaceholder from '../../features/productionplanning/ProductionPlanningPlaceholder';
import DailyReportForQB from '../../features/dailyreport/DailyReportForQB';
import { useStore } from '../stores/Store';
import SalesOrderHome from '../../features/salesnew/SalesOrderHome';
import SalesOrderDetail from '../../features/salesnew/details/SalesOrderDetail';
import SalesOrderProcessing from '../../features/charts/SalesOrderProcessing';
import CustomerSalesOrder from '../../features/reports/CustomerSalesOrder';
import PurchaseOrderHome from '../../features/purchaseordersnew/PurchaseOrderHome';
import PurchaseOrderDetail from '../../features/purchaseordersnew/details/PurchaseOrderDetail';
import FireDamperRatesHome from '../../features/rates/FireDamperRatesHome';
import ControlDamperRatesHome from '../../features/rates/ControlDamperRatesHome';
import InventoryDashboard from '../../features/inventory/InventoryDashboard';
import RatesHome from '../../features/rates/RatesHome';
import CostCalculator from '../../features/costing/CostCalculator';
import CostingReport from '../../features/costing/CostingReport';
import CostSalesOrderCalculator from '../../features/costing/CostSalesOrderCalculator';
import PdfWriter from '../../features/pdfcreator/PdfWriter';

const App: React.FC<RouteComponentProps> = ({ location }) => {
    const { commonStore, userStore } = useStore();
    const { appLoaded, setAppLoaded, token } = commonStore;
    const { getUser } = userStore;

    useEffect(() => {

        if (token) {
            getUser().finally(() => setAppLoaded());
        } else {
            setAppLoaded();
        }
    }, [setAppLoaded, token, getUser])

    if (!appLoaded) return (<LoadingComponent content='Loading ...' />);
    return (
        <Fragment>
            <DeleteModal />
            <ModalContainer />
            <ToastContainer autoClose={2000} position='bottom-right' />
            <Route exact path='/' component={HomePage} />
            <Route
                path={'/(.+)'}
                render={() => (
                    <Fragment>
                        <TopNav />
                        <Container style={{ marginTop: '5em' }}>
                            <Switch>
                                <PrivateRoute exact path='/salesold' component={SalesDashboard} />
                                <PrivateRoute exact path='/sales' component={SalesOrderHome} />
                                <PrivateRoute exact path='/purchaseorders' component={PurchaseOrderHome} />
                                <PrivateRoute exact path='/purchaseorderstabular' component={PODashboard} />


                                <PrivateRoute exact path='/costsalesorder' component={CostSalesOrderCalculator} />
                                <PrivateRoute exact path='/costsalesorder/:id' component={CostSalesOrderCalculator} />
                                <PrivateRoute exact path='/costcalculator' component={CostCalculator} />
                                <PrivateRoute exact path='/costingreport' component={CostingReport} />
                                <PrivateRoute exact path='/rates' component={RatesHome} />
                                <PrivateRoute exact path='/firedamperpricing' component={FireDamperRatesHome} />
                                <PrivateRoute exact path='/controldamperpricing' component={ControlDamperRatesHome} />

                                <PrivateRoute exact path='/template1' component={PdfWriter} />


                                <PrivateRoute exact path='/inventory' component={InventoryDashboard} />
                                <PrivateRoute exact path='/salesorder/:id' component={SalesOrderDetail} />
                                <PrivateRoute exact path='/purchaseorder/:id' component={PurchaseOrderDetail} />
                                <PrivateRoute exact path='/customers' component={CustomerDashboard} />
                                <PrivateRoute exact path='/createcustomer' component={CreateNewCustomer} />
                                <PrivateRoute exact path='/scan' component={ScanDashboard} />
                                <PrivateRoute exact path='/track' component={TrackingDashboard} />
                                <PrivateRoute
                                    key={location.key}
                                    path={['/createSalesOrder', '/manage/:id']}
                                    component={SalesOrderForm}
                                />
                                <PrivateRoute path='/profile/:username' component={UserProfile} />
                                <PrivateRoute exact path='/dailyreport' component={DailyReport} />
                                <PrivateRoute exact path='/clientreport' component={CustomerSalesOrder} />
                                <PrivateRoute exact path='/dailyreportforquickbooks' component={DailyReportForQB} />
                                <PrivateRoute exact path='/productionplanning' component={ProductionPlanningPlaceholder} />
                                <PrivateRoute exact path='/salesorderplanner' component={SalesOrderPlanner} />
                                <Route exact path='/dailyreportkiosk' component={DailyReportKiosk} />
                                <Route exact path='/productionkiosk' component={ProductionKiosk} />
                                <Route exact path='/deliverykiosk' component={DeliveryKiosk} />
                                <Route exact path='/salesorderchart' component={SalesOrderProcessing} />
                                <Route exact path='/sampleinvoice' component={Invoice} />
                                <Route exact path='/datasync' component={DataSyncDashboard} />
                                <Route path='/login' component={LoginForm} />
                                <Route component={NotFound} />
                            </Switch>
                        </Container>
                    </Fragment>
                )}
            />

        </Fragment>
    )
}

export default withRouter(observer(App));
