import { runInAction, makeAutoObservable } from 'mobx';
import agent from '../api/agent';

export default class ProductStore {


    constructor() {
        makeAutoObservable(this);


    }

    loadProductSearch = false;
    loadProductInitial = false;
    initialProductsMap = new Map();
    searchProductsMap = new Map();
    initialLoad = true;


    get products() {
        if (this.initialLoad)
            return Array.from(this.initialProductsMap.values());
        else
            return Array.from(this.searchProductsMap.values());
    }


    searchProducts = async (searchType: string, searchValue: string) => {
        this.initialLoad = false;
        this.loadProductSearch = true;
        try {
            this.searchProductsMap.clear();
            const products = await agent.product.searchProducts(searchType, searchValue);
            runInAction(() => {
                products.forEach(product => {
                    this.searchProductsMap.set(product.id, product);
                });

                this.loadProductSearch = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadProductSearch = false;
            });
        }
    }

    loadProducts = async () => {
        this.loadProductInitial = true;

        if (this.initialProductsMap.size > 0) {
            this.initialLoad = true;
            this.loadProductInitial = false;
            return;
        }

        try {
            const products = await agent.product.summaryList();
            runInAction(() => {
                products.forEach(product => {
                    this.initialProductsMap.set(product.id, product);
                });

                this.loadProductInitial = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadProductInitial = false;
            });
        }
    }
}