import { runInAction, toJS, makeAutoObservable } from "mobx";
import { IPartCompact } from "../models/Part";
import agent from "../api/agent";

const PARTNO_SEARCH = 'partno';
const PARTDESC_SEARCH = 'partdescription';

export default class PartStore {
    constructor() {
        makeAutoObservable(this);
    }

    searchedParts: IPartCompact[] = [];
    loadingParts = false;

    predicate = new Map();


    get axiosParams() {
        const params = new URLSearchParams();
        this.predicate.forEach((value, key) => {
            params.append(key, value);
        });
        return params;
    }

    setPredicate = (name: string, value: string | Date) => {
        if (name === 'all')
            this.predicate.clear();
        else
            this.predicate.set(name, value);
    }

    searchBy = (searchType: string, value: string) => {
        this.setPredicate('searchby', searchType);
        this.setPredicate('searchvalue', value);
    }
    searchByPartNo = (value: string) => {
        this.setPredicate('searchby', 'partno')
        this.setPredicate('searchvalue', value);
    }

    searchByPartDescription = (value: string) => {
        this.setPredicate('searchby', 'partdescription');
        this.setPredicate('searchvalue', value);
    }

    loadParts = async () => {
        this.loadingParts = true;
        try {
            this.predicate.clear();
            console.log(this.axiosParams.toString());
            const partEnvelope = await agent.part.list(this.axiosParams);
            runInAction(() => {
                this.searchedParts = partEnvelope.parts;
                console.log(toJS(this.searchedParts));
                this.loadingParts = false;
                return toJS(this.searchedParts);
            });

        } catch (error) {
            runInAction(() => {
                this.loadingParts = false;
            });
            console.log(error);
            return toJS(this.searchedParts);
        }

    }
    searchParts = async (searchType: string, value: string) => {
        this.loadingParts = true;
        try {
            this.searchBy(searchType, value);
            console.log(this.axiosParams.toString());
            const partEnvelope = await agent.part.list(this.axiosParams);
            runInAction(() => {
                this.searchedParts = partEnvelope.parts;
                this.loadingParts = false;
                console.log(toJS(this.searchedParts));
                return toJS(this.searchedParts);
            });

        } catch (error) {
            runInAction(() => {
                this.loadingParts = false;
            });
            console.log(error);
            return toJS(this.searchedParts);
        }

    }

    searchPartsByPartNo = async (partNo: string) => {
        await this.searchParts(PARTNO_SEARCH, partNo);
    }

    searchPartsByPartDescription = async (description: string) => {
        await this.searchParts(PARTDESC_SEARCH, description);
    }

}