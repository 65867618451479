import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { IInvoice } from '../../../app/models/IInvoice';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 36
    },
    billTo: {
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },
});

interface IProps {
    invoice: IInvoice;
}

const BillTo: React.FC<IProps> = ({ invoice }) => {
    return (
        <View style={styles.headerContainer}>
            <Text style={styles.billTo}>Bill To:</Text>
            <Text>{invoice.company}</Text>
            <Text>{invoice.address}</Text>
            <Text>{invoice.phone}</Text>
            <Text>{invoice.email}</Text>
        </View>
    )
}

export default BillTo
