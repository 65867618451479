import { observer } from 'mobx-react-lite'
import React, { Fragment, useEffect } from 'react'
import { useStore } from '../../app/stores/Store';
import SalesOrderSearch from './dashboard/search/SalesOrderSearch';
import "./sales.css";

const SalesOrderHome = () => {
    const { salesOrderStore, searchStore } = useStore();
    const { loadSalesOrders } = salesOrderStore;
    const { resetSearch } = searchStore;

    useEffect(() => {
        //resetSearch('customerAlias');
        loadSalesOrders();
    }, [loadSalesOrders, resetSearch])


    return (
        <Fragment>
            <SalesOrderSearch />
            {/*
                loadingInitial ?
                    <SalesOrderListPlaceholder />
                    :
                    <SalesOrderSearch />
            */}
        </Fragment>
    )
}

export default observer(SalesOrderHome)
