import { reaction, makeAutoObservable } from 'mobx';

export default class CommonStore {

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.token,
            (token) => {
                if (token) {
                    window.localStorage.setItem('Brisk_JWT', token);
                } else {
                    window.localStorage.removeItem('Brisk_JWT');
                }

            }
        );
    }


    appLoaded = false;
    token: string | null = window.localStorage.getItem('Brisk_JWT');
    purchaseOrderTabularView = false;


    tabPanes: any = [];
    activeTabIndex = -1;
    tabVsIndexMap = new Map();

    setToken = (token: string | null) => {
        this.token = token;
    }

    setAppLoaded = () => {
        this.appLoaded = true;
    }


    setPOView = (isTabular: boolean) => {
        this.purchaseOrderTabularView = isTabular;
    }

    addTabPane = (key: string, tab: any) => {


        if (this.tabVsIndexMap.has(key)) {

            console.log('tab is already open');
            const index = this.tabVsIndexMap.get(key);
            this.setActiveTabIndex(index);
        } else {
            console.log('creating a new tab', tab);
            this.tabPanes = [...this.tabPanes, tab];
            this.setActiveTabIndex(this.tabPanes.length - 1);
            this.tabVsIndexMap.set(key, this.activeTabIndex);
        }

    }

    removeTabPane = (key: string) => {
        //console.log(`Trying to delete ${key} in ${this.tabVsIndexMap}`);
        if (this.tabVsIndexMap.has(key)) {
            //console.log("Got in the loop");
            this.tabPanes = this.tabPanes.filter((tab: any) => {
                return tab.menuItem !== key;
            });
            this.tabVsIndexMap.delete(key);
            this.setActiveTabIndex(0);
        }
    }


    setActiveTabIndex = (tabIndex: any) => {
        this.activeTabIndex = tabIndex;
    }

    resetTabs = () => {
        this.tabPanes = [];
        this.activeTabIndex = -1;
        this.tabVsIndexMap.clear();
    }


}