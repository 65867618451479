import React, { useEffect, Fragment } from 'react'
import { observer } from 'mobx-react-lite';
import { Tab } from 'semantic-ui-react';
import CustomerSearchTab from './tabs/CustomerSearchTab';
import { useStore } from '../../../app/stores/Store';

const CustomerDashboard = () => {
    const { customerStore, commonStore, searchStore } = useStore();
    const { loadCustomers } = customerStore;
    const { addTabPane, activeTabIndex, tabPanes, setActiveTabIndex, resetTabs } = commonStore;
    const { resetSearch } = searchStore;


    useEffect(() => {
        resetTabs();
        resetSearch('customerName');

        loadCustomers();
        addTabPane('search', {
            menuItem: 'Customer Search',
            render: () => <CustomerSearchTab />
        });
    }, [loadCustomers, addTabPane, resetTabs, resetSearch])

    return (
        <Fragment>
            {
                <Tab activeIndex={activeTabIndex} panes={tabPanes} onTabChange={(e, data) => { setActiveTabIndex(data.activeIndex) }} />
            }
        </Fragment>
    )
}

export default observer(CustomerDashboard);
