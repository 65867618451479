import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, Fragment, useEffect } from 'react'
import { useStore } from '../../../app/stores/Store';



const purchaseorderstatuses = [
    { key: 'open', text: 'Open', value: 'Open' },
    { key: 'onhold', text: 'On Hold', value: 'On Hold' },
    { key: 'closed', text: 'Closed', value: 'Closed' },
    { key: 'cancelled', text: 'Cancelled', value: 'Cancelled' }
]

const purchaseorderscurrencies = [
    { key: 'CAD', text: 'CDN Dollars', value: 'CDN Dollars' },
    { key: 'USD', text: 'US Dollars', value: 'US Dollars' }
]



const DocumentHeaderAndAddress = () => {
    const { poStore } = useStore();
    const { loadingPO, currentPurchaseOrder, updatePurchaseOrder, runningTotal } = poStore;


    useEffect(() => {
        //console.log('running Total is ', runningTotal);
        //console.log('currentSalesOrder Running Total is ', currentSalesOrder?.po_net_amount);

    }, [runningTotal, currentPurchaseOrder])

    if (currentPurchaseOrder === null || loadingPO) {
        return <Fragment />
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        updatePurchaseOrder(name, value);
    }
    const handleSelectChange = (name: string, value: string) => {
        // console.log(event.target);
        // const target = event.target as HTMLSelectElement;
        //sconsole.log(event);
        updatePurchaseOrder(name, value);
    }

    const getDate = (datetime: Date | null) => {
        if (datetime === null || datetime === undefined)
            return;
        return datetime.toString().split('T')[0];
    }


    return (
        <div className='documentHeaderAndAddress'>
            <div className='lhs'>
                1090 Brevik Place,
                <br /> Mississauaga, L4W 3Y5, ON, Canada,
                <br />Phone: (905) 206-6888
            </div>
            {currentPurchaseOrder.po_invoice_no ? (
                <div className='rhs'>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Status</div>
                        <div className='rhs_data'>
                            <p>{currentPurchaseOrder.po_order_status}</p>
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Order #</div>
                        <div className='rhs_data'>
                            <p>{currentPurchaseOrder.po_order_no}</p>
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Order Date</div>
                        <div className='rhs_data'>
                            {/*<Field fluid date={true} placeholder='Ship Date' name='po_ship_date' component={DateInput} />*/}
                            <p>{getDate(currentPurchaseOrder.po_order_date)}</p>
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Currency</div>
                        <div className='rhs_data'>
                            <p>{currentPurchaseOrder.po_currency_name}</p>
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Amount Due</div>
                        <div className='rhs_data'>
                            <p>{currentPurchaseOrder.po_net_amount}</p>
                        </div>
                    </div>
                </div>

            ) : (
                <div className='rhs'>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Status :</div>
                        <div className='rhs_data'>
                            <select style={{ margin: '0px' }}
                                value={currentPurchaseOrder.po_order_status}
                                onChange={e => handleSelectChange("po_order_status", e.target.value)}>
                                {purchaseorderstatuses.map(o => (
                                    <option key={o.value} value={o.value}>{o.text}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Order # :</div>
                        <div className='rhs_data'>
                            {currentPurchaseOrder.po_order_no ? (<p>{currentPurchaseOrder.po_order_no}</p>) : (<p>N/A</p>)}
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Order Date :</div>
                        <div className='rhs_data'>
                            <input type='date' style={{ width: '100%' }} name='po_ship_date' onChange={handleInputChange} value={getDate(currentPurchaseOrder.po_order_date)} />
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Currency :</div>
                        <div className='rhs_data'>
                            <select style={{ margin: '0px' }}
                                value={currentPurchaseOrder.po_currency_name}
                                onChange={e => handleSelectChange("po_currency_name", e.target.value)}>
                                {purchaseorderscurrencies.map(o => (
                                    <option key={o.value} value={o.value}>{o.text}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Amount Due :</div>
                        <div className='rhs_data'>
                            <p>$ {currentPurchaseOrder.po_net_amount}</p>
                        </div>
                    </div>
                </div>

            )}

        </div>
    )
}

export default observer(DocumentHeaderAndAddress)
