import { observer } from 'mobx-react-lite';
import React, { useState } from 'react'
import { Dropdown, Grid } from 'semantic-ui-react';
import { useStore } from '../../app/stores/Store';


const partTypeOptions = [
    { key: 'All', text: 'All', value: 'All' },
    { key: 'BDD', text: 'BDD Name', value: 'BDD' },
    { key: 'Blades', text: 'Blades', value: 'Blades' },
    { key: 'Coils', text: 'Coils', value: 'Coils' },
    { key: 'ControlDamper', text: 'ControlDamper', value: 'ControlDamper' },
    { key: 'Doors', text: 'Doors', value: 'Doors' },
    { key: 'Fire and smoke', text: 'Fire and smoke', value: 'Fire and smoke' },
    { key: 'Link Holder', text: 'Link Holder', value: 'Link Holder' },
    { key: 'Links', text: 'Links', value: 'Links' },
    { key: 'Louver', text: 'Louver', value: 'Louver' },
    { key: 'MESH', text: 'MESH', value: 'MESH' },
    { key: 'Packing', text: 'Packing', value: 'Packing' },
    { key: 'Pins', text: 'Pins', value: 'Pins' },
    { key: 'RCD', text: 'RCD', value: 'RCD' },
    { key: 'Rivets', text: 'Rivets', value: 'Rivets' },
    { key: 'Sheets', text: 'Sheets', value: 'Sheets' },
    { key: 'Springs', text: 'Springs', value: 'Springs' },
    { key: 'Stainless Steel RCD', text: 'Stainless Steel RCD', value: 'Stainless Steel RCD' },
    { key: 'Stickers', text: 'Stickers', value: 'Stickers' },
    { key: 'Tape', text: 'Tape', value: 'Tape' },
    { key: 'Thermal Blanket', text: 'Thermal Blanket', value: 'Thermal Blanket' },
    { key: 'Washers', text: 'Washers', value: 'Washers' },
    { key: 'Wire', text: 'Wire', value: 'Wire' }
]



const InventorySearchForm = () => {
    const { inventoryStore } = useStore();
    const { loadInventory } = inventoryStore;
    const [searchValue,] = useState('');
    const [, setSearchType] = useState<any>('All');

    const onSelectChange = (value: any) => {
        setSearchType(value);
        loadInventory(value, searchValue);
    }


    return (
        <Grid columns='equal'>
            <Grid.Column>
                <b>Part Type</b>
                <Dropdown
                    inline
                    placeholder='Part Type'
                    fluid
                    search
                    selection
                    options={partTypeOptions}
                    onChange={(e, data) => { onSelectChange(data.value) }} />
            </Grid.Column>
            <Grid.Column>
                {/* 
                
                <b>Description</b>
                <br />
                <Input
                    type='text'
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder='Search...' action>
                    <input />
                    <Button icon color='blue' >
                        <Icon name='search' />
                    </Button>
                </Input>
                
                */}
            </Grid.Column>
        </Grid >


    )
}

export default observer(InventorySearchForm)
