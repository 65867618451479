import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, Fragment } from 'react'
import { Button, Icon } from 'semantic-ui-react';
import PartNoTypeAheadPure from '../../../app/common/form/PartNoTypeAheadPure';
import { getBlankSalesOrderDetail, ISalesOrderDetail } from '../../../app/models/SalesOrder'
import { useStore } from '../../../app/stores/Store';
import BillOfMaterials from './BillOfMaterials';


interface IProps {
    currentIndex: number;
    soDetail: ISalesOrderDetail;
    itemCount: number;
}

const MyLineItemRow: React.FC<IProps> = ({ soDetail, currentIndex, itemCount }) => {
    const { salesOrderStore, modalStore } = useStore();
    const { loadingSalesOrder, currentSalesOrder, addSalesOrderDetail,
        removeSalesOrderDetail, addOrEditRowItem,
        updateSalesOrderDetail, updateSalesOrderDetailPriceForDia, updateSalesOrderDetailPriceForDim } = salesOrderStore;
    const { openModal } = modalStore;

    if (currentSalesOrder === null || loadingSalesOrder) {
        return <Fragment />
    }

    const handleOnTypeAheadComplete = () => {
        if (currentIndex === (itemCount - 1)) {
            addSalesOrderDetail(getBlankSalesOrderDetail());
        }
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;

        let detail: ISalesOrderDetail | null = null;
        if (name.includes('so_item_qty')) {
            detail = { ...soDetail, [name]: value, 'so_item_shipped': parseInt(value) || 0 }
        } else {
            detail = { ...soDetail, [name]: value }
        }

        updateSalesOrderDetail(name, detail, currentIndex);
        if (currentSalesOrder.so_customer_name === "" || detail.so_part_description === "" || value === "" || value === null) {
            return;
        }
        if (name.includes('dia')) {
            updateSalesOrderDetailPriceForDia(value, detail, currentIndex);
        }

        if (name.includes('width') && detail.so_item_height !== "" && detail.so_item_height !== null) {
            updateSalesOrderDetailPriceForDim(value, detail.so_item_height, detail, currentIndex);
        }
        if (name.includes('height') && detail.so_item_width !== "" && detail.so_item_width !== null) {
            updateSalesOrderDetailPriceForDim(detail.so_item_width, value, detail, currentIndex);
        }

    }
    const handleFocus = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => event.target.select();


    return (
        <Fragment>
            {currentSalesOrder.so_invoice_no && currentSalesOrder.so_invoice_no !== "IN-8687" ? (
                <Fragment>
                    <div className="didCell rowspan2"><p>{currentIndex + 1}</p></div>
                    <div className="didCell"><p>{soDetail.so_part_no}</p></div>
                    <div className="didCell"><p>{soDetail.so_part_description}</p></div>
                    <div className="didCell"> <p>{soDetail.so_item_width}</p></div>
                    <div className="didCell"> <p>{soDetail.so_item_height}</p></div>
                    <div className="didCell"> <p>{soDetail.so_item_dia}</p></div>
                    <div className="didCell"><p>{currentSalesOrder.salesOrderDetails[currentIndex].so_item_qty}</p></div>
                    <div className="didCell"> <p>{currentSalesOrder.salesOrderDetails[currentIndex].so_item_shipped}</p></div>
                    <div className="didCell"> <p>{currentSalesOrder.salesOrderDetails[currentIndex].so_item_sale_price}</p></div>
                    <div className="didCell"><p>{currentSalesOrder.salesOrderDetails[currentIndex].so_item_total_amount}</p></div>
                    <div className="didRowCell">
                        <p className="didRightAlignedCell"><b>Part Remarks : </b></p>
                        <p>{soDetail.so_part_remarks}</p>
                    </div>

                </Fragment>
            ) : (
                <Fragment>
                    <div className="didCell rowspan2">
                        {currentIndex < (itemCount - 1) ? (
                            <div className="delete-wpr">
                                <div>{currentIndex + 1}</div>
                                <Button
                                    color='red'
                                    style={{ padding: '5px', margin: 0, marginTop: '5px' }}
                                    className="delete"
                                    onClick={() => removeSalesOrderDetail(currentIndex)}>X</Button>
                            </div>
                        ) : (
                            <Fragment>
                                {soDetail.so_part_no === "" && soDetail.so_part_description === "" ? (
                                    <div>{currentIndex + 1}</div>
                                ) : (
                                    <div className="delete-wpr">
                                        <div>{currentIndex + 1}</div>
                                        <Button
                                            color='red'
                                            style={{ padding: '5px', margin: 0, marginTop: '5px' }}
                                            className="delete"
                                            onClick={() => removeSalesOrderDetail(currentIndex)}>X</Button>
                                    </div>

                                )}
                            </Fragment>
                        )}
                    </div>
                    <div className="didCell">
                        <PartNoTypeAheadPure index={currentIndex} setData={addOrEditRowItem} name="so_part_no" value={soDetail.so_part_no}
                            handleOnTypeAheadComplete={handleOnTypeAheadComplete}
                        /></div>
                    <div className="didCell"><textarea
                        id='textarea'
                        name='so_part_description'
                        value={soDetail.so_part_description}
                        onChange={handleInputChange}
                    /></div>
                    <div className="didCell">
                        <textarea
                            id='textarea'
                            name='so_item_width'
                            value={soDetail.so_item_width}
                            onChange={handleInputChange}
                            onFocus={handleFocus}
                        /></div>
                    <div className="didCell">
                        <textarea
                            id='textarea'
                            name='so_item_height'
                            value={soDetail.so_item_height}
                            onChange={handleInputChange}
                            onFocus={handleFocus}
                        />
                    </div>
                    <div className="didCell">
                        <textarea
                            id='textarea'
                            name='so_item_dia'
                            value={soDetail.so_item_dia}
                            onChange={handleInputChange}
                            onFocus={handleFocus}
                        />
                    </div>
                    <div className="didCell">
                        <textarea
                            id='textarea'
                            name='so_item_qty'
                            value={currentSalesOrder.salesOrderDetails[currentIndex].so_item_qty}
                            onChange={handleInputChange}
                            onFocus={handleFocus}
                        />
                    </div>
                    <div className="didCell">
                        <textarea
                            id='textarea'
                            name='so_item_shipped'
                            value={currentSalesOrder.salesOrderDetails[currentIndex].so_item_shipped}
                            onChange={handleInputChange}
                            onFocus={handleFocus}
                        />
                    </div>
                    <div className="didCell">
                        <textarea
                            id='textarea'
                            name='so_item_sale_price'
                            value={currentSalesOrder.salesOrderDetails[currentIndex].so_item_sale_price}
                            onChange={handleInputChange}
                            onFocus={handleFocus}
                        />
                    </div>
                    <div className="didCell"><p>{currentSalesOrder.salesOrderDetails[currentIndex].so_item_total_amount}</p></div>
                    <div className="didRowCell">

                        <p className="didRightAlignedCell"> <Icon
                            onClick={() => openModal(<BillOfMaterials />, true)}
                            color='orange'
                            size='large'
                            name='info circle' /><b>Part Remarks : </b></p>
                        <textarea
                            id='textarea'
                            name='so_part_remarks'
                            value={soDetail.so_part_remarks ? soDetail.so_part_remarks : ''}
                            onChange={handleInputChange}
                        />
                    </div>

                </Fragment>
            )}

        </Fragment>
    )
}

export default observer(MyLineItemRow)
