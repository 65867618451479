import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react'
import { Button, Divider, Icon } from 'semantic-ui-react';
import SupplierNameTypeAhead from '../../../app/common/form/SupplierNameTypeAhead';
import { useStore } from '../../../app/stores/Store';
import CustomerAddress from '../../sales/dashboard/tabs/modals/CustomerAddress';

const DocumentSoldToShipTo = () => {
    const { poStore, modalStore } = useStore();
    const { loadingPO, currentPurchaseOrder, updatePurchaseOrder, getPreviousPurchaseOrderForSupplier, previousPurchaseOrderCopy, updatePurchaseOrderDetails } = poStore;
    const { openModal } = modalStore;

    const [customerName, setCustomerName] = useState('');

    useEffect(() => {
        if (currentPurchaseOrder?.po_ship_dimz === null || currentPurchaseOrder?.po_ship_dimz === '')
            updatePurchaseOrder('po_ship_dimz', 'Brisk Mfg Inc.');
    }, [updatePurchaseOrder, currentPurchaseOrder])



    if (currentPurchaseOrder === null || loadingPO) {
        return <Fragment />
    }




    const updateSoldToAddress = (value: string) => {
        updatePurchaseOrder('po_sold_address', value);
        //updatePurchaseOrder('po_billing_address', value);
    }
    const updateShipToAddress = (value: string) => {
        updatePurchaseOrder('po_shipping_address', value);
    }


    const setCustomerData = (name: string, value: string) => {

        console.log(`Name ${name} Value ${value}`);

        getPreviousPurchaseOrderForSupplier(value).then(() => {

            if (previousPurchaseOrderCopy !== null) {
                updatePurchaseOrder('po_customer_name', previousPurchaseOrderCopy.po_customer_name);
                updatePurchaseOrder('po_customer_alias', previousPurchaseOrderCopy.po_customer_alias);
                updatePurchaseOrder('po_customer_id', previousPurchaseOrderCopy.po_customer_id?.toString());
                updatePurchaseOrder('po_supplier_address', previousPurchaseOrderCopy.po_supplier_address);
                updatePurchaseOrder('po_shipping_address', previousPurchaseOrderCopy.po_shipping_address);
                updatePurchaseOrder('po_billing_address', previousPurchaseOrderCopy.po_billing_address);
                updatePurchaseOrder('po_order_recipient', previousPurchaseOrderCopy.po_order_recipient);
                updatePurchaseOrder('po_order_rep_name', previousPurchaseOrderCopy.po_order_rep_name);
                updatePurchaseOrder('po_shipping_carrier_name', previousPurchaseOrderCopy.po_shipping_carrier_name);
                updatePurchaseOrder('po_shipping_account_no', previousPurchaseOrderCopy.po_shipping_account_no);
                updatePurchaseOrderDetails(previousPurchaseOrderCopy.purchaseOrderDetails);
            }
        });

    }

    return (
        <div className='documentSoldToShipTo'>
            <Divider />

            {currentPurchaseOrder.po_invoice_no ? (
                <div className='soldToShipToGrid'>
                    <div className='ststDataSection'>
                        <div className='ststTitle'>Supplier</div>
                        <p>{currentPurchaseOrder.po_customer_name}</p>
                        <p className='wrapNewLines'>{currentPurchaseOrder.po_supplier_address}</p>
                    </div>
                    <div className='ststSearch'>
                    </div>
                    <div className='ststDataSection'>
                        <div className='ststTitle'>Ship To</div>
                        <p>{currentPurchaseOrder.po_customer_name}</p>
                        <p className='wrapNewLines'>{currentPurchaseOrder.po_shipping_address}</p>
                    </div>

                </div>
            ) : (
                <div className='soldToShipToGrid'>
                    <div className='ststDataSection'>
                        <div className='ststTitle'>Supplier</div>
                        <textarea className='ststTextArea shortTA' rows={1} name='po_customer_name'
                            value={currentPurchaseOrder.po_customer_name}
                            onChange={(e) => updatePurchaseOrder(e.target.name, e.target.value)} />
                        <Button basic floated={'right'} icon color='green' size='small'
                            onClick={() => openModal(<CustomerAddress
                                customerName={currentPurchaseOrder.po_customer_alias}
                                isForSoldToAddress={true}
                                setAddress={updateSoldToAddress}
                            />)} >
                            <Icon name='plus' />
                        </Button>
                        <textarea
                            rows={5}
                            className='ststTextArea longTA'
                            name='po_supplier_address'
                            value={currentPurchaseOrder.po_supplier_address}
                            onChange={(e) => updatePurchaseOrder(e.target.name, e.target.value)} />
                    </div>
                    <div className='ststSearch'>
                        <SupplierNameTypeAhead
                            setLocalState={setCustomerName}
                            setData={setCustomerData}

                            name="po_customer_name"
                            value={customerName} />
                    </div>
                    <div className='ststDataSection'>
                        <div className='ststTitle'>Ship To</div>
                        <textarea className='ststTextArea shortTA' rows={1} name='po_ship_dimz' value={currentPurchaseOrder.po_ship_dimz} onChange={(e) => updatePurchaseOrder(e.target.name, e.target.value)} />
                        <Button basic floated={'right'} icon color='green' size='small'
                            onClick={() => openModal(<CustomerAddress
                                customerName={currentPurchaseOrder.po_customer_alias}
                                isForSoldToAddress={true}
                                setAddress={updateShipToAddress}
                            />)} >
                            <Icon name='plus' />
                        </Button>
                        <textarea
                            rows={5}
                            className='ststTextArea longTA'
                            name='po_shipping_address'
                            value={currentPurchaseOrder.po_shipping_address}
                            onChange={(e) => updatePurchaseOrder(e.target.name, e.target.value)} />
                    </div>

                </div>
            )}
        </div>
    )
}

export default observer(DocumentSoldToShipTo)