import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Button, Grid, Header, Table } from 'semantic-ui-react'
import { useStore } from '../../../app/stores/Store';


const motorModels = [
    { text: '---- NA ----', value: '' },
    { text: 'Bellimo1', value: 'Bellimo1' },
    { text: 'Bellimo2', value: 'Bellimo2' },
    { text: 'Bellimo3', value: 'Bellimo3' }
]

const CostControlDamper = () => {
    const { costingStore } = useStore();
    const {
        gettingRate,
        setWidth,
        setHeight,
        motorModel,
        setMotorModel,
        getRateForControlDamper, setQuotes
    } = costingStore;

    const [widthString, setWidthString] = useState('12')
    const [heightString, setHeightString] = useState('12')

    const computeRate = async () => {
        setWidth(Number.parseFloat(widthString));
        setHeight(Number.parseFloat(heightString));

        let rate = await getRateForControlDamper();
        if (rate !== undefined && rate !== null) {
            setQuotes(rate);
        }
    }

    return (
        <>
            <Header as='h2'>Control Damper</Header>
            <Grid columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Width</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <input type='text'
                                            value={widthString}
                                            onChange={(e) => { setWidthString(e.target.value) }}
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Height</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <input type='text'
                                            value={heightString}
                                            onChange={(e) => { setHeightString(e.target.value) }}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>Motor Model</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={motorModel}
                                            onChange={e => setMotorModel(e.target.value)}
                                        >
                                            {motorModels.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                        </Table>
                        <Button positive loading={gettingRate} onClick={computeRate} floated='right'>Calculate</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>

    )
}

export default observer(CostControlDamper)
