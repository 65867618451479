import { runInAction, makeAutoObservable } from 'mobx';
import agent from '../api/agent';
import { toast } from 'react-toastify';
import { IQuote, ISalesOrderCosting } from '../models/Costing';

export default class CostQuotesStore {
    constructor() {
        makeAutoObservable(this);
    }

    savingQuotes = false;
    loadingQuoteDetails = false;
    loadingQuotes = false;

    quotes: IQuote[] = [];
    selectedQuote: IQuote | null = null;

    tabPanes: any = [];
    activeTabIndex = -1;
    tabVsIndexMap = new Map();

    selectedSalesOrder: ISalesOrderCosting | null = null;

    setActiveTabIndex = (tabIndex: any) => {
        this.activeTabIndex = tabIndex;
    }


    setSelectedSalesOrder = (salesOrder: ISalesOrderCosting | null) => {
        this.selectedSalesOrder = salesOrder;
    }


    addTabPane = (key: string, tab: any) => {
        if (this.tabVsIndexMap.has(key)) {
            const index = this.tabVsIndexMap.get(key);
            this.setActiveTabIndex(index);
        } else {
            this.tabPanes = [...this.tabPanes, tab];
            this.setActiveTabIndex(this.tabPanes.length - 1);
            this.tabVsIndexMap.set(key, this.activeTabIndex);
        }
    }

    removeTabPane = (key: string) => {
        if (key === undefined)
            key = 'New Order';
        if (this.tabVsIndexMap.has(key)) {
            this.tabPanes = this.tabPanes.filter((tab: any) => {
                return tab.menuItem !== key;
            });
            this.tabVsIndexMap.delete(key);

            this.loadQuotes();

            this.setActiveTabIndex(0);
        }
    }

    loadSalesOrderForCosting = async (orderno: string) => {
        try {
            this.loadingQuotes = true;
            const salesorder = await agent.salesorder.soCosting(orderno);
            runInAction(() => {
                this.selectedSalesOrder = salesorder;
                this.loadingQuotes = false;
            })
            return salesorder;
        } catch (error) {
            runInAction(() => {
                this.loadingQuotes = false;
            })
            toast.error('Sales Order Not Found!');
        }
    }

    loadQuotes = async () => {
        try {
            this.loadingQuotes = true;
            const myquotes = await agent.costing.listquotes();
            runInAction(() => {
                this.quotes = [];
                this.quotes = myquotes;
                this.loadingQuotes = false;
            })
        } catch (error) {
            runInAction(() => {
            })
            this.loadingQuotes = false;
            console.log(error);
        }
    }

    createQuote = async (quote: IQuote) => {
        this.loadingQuotes = true;
        try {

            await agent.costing.createQuote(quote);
            runInAction(() => {
                this.loadingQuotes = false;
                toast.success('New Quote Added!')
            })
        } catch (error) {
            runInAction(() => {
                this.loadingQuotes = false;
            });
        }
    }

    deleteQuote = async (quote: IQuote) => {
        this.loadingQuotes = true;
        try {

            await agent.costing.deleteQuote(quote.id);
            await this.loadQuotes();
            runInAction(() => {
                this.loadingQuotes = false;
                toast.success('Deleted Quote!')
            })
        } catch (error) {
            runInAction(() => {
                this.loadingQuotes = false;
            });
        }
    }





}
