import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { IInvoice } from '../../../app/models/IInvoice';
import InvoiceTableHeader from './table/InvoiceTableHeader';
import InvoiceTableRow from './table/InvoiceTableRow';
import InvoiceTableBlankSpace from './table/InvoiceTableBlankSpace';
import InvoiceTableFooter from './table/InvoiceTableFooter';

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
});

interface IProps {
    invoice: IInvoice;
}

const InvoiceItemsTable: React.FC<IProps> = ({ invoice }) => {
    return (
        <View style={styles.tableContainer}>
            <InvoiceTableHeader />
            <InvoiceTableRow items={invoice.items} />
            <InvoiceTableBlankSpace rowsCount={tableRowsCount - invoice.items.length} />
            <InvoiceTableFooter items={invoice.items} />
        </View>
    )
}

export default InvoiceItemsTable
