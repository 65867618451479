import { observer } from 'mobx-react-lite'
import React from 'react'
import { Button, Container, Grid, Header, Icon, Label, Table } from 'semantic-ui-react'
import { IDamperRateOutput } from '../../../app/models/DamperRateDto'
import { useStore } from '../../../app/stores/Store'

const Quotes = () => {
    const { rateStore } = useStore();
    const { quotes, resetQuotes, removeQuote
    } = rateStore;

    const getTotal = () => {
        let total = 0;
        for (let index = 0; index < quotes.length; index++) {
            const damper = quotes[index];
            total += damper.salesPrice;

        }
        return Math.round((total + Number.EPSILON) * 100) / 100;
    }

    return (
        <>
            <Container style={{ padding: '0em 0em' }}>
                <Grid columns={1}>
                    <Grid.Row>
                        <Grid.Column>
                            {quotes !== null && quotes.length > 0 && (
                                <>
                                    <Header as='h2'>Your Quotes</Header>
                                    <Button onClick={() => resetQuotes()} floated='right'>Clear Quotes</Button>
                                    <Table color='green' fixed celled selectable compact size='small' striped>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell style={{ width: '10%' }} textAlign='center'>Id</Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center'>Product</Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center'>Price</Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center'>Dimensions<br />(WXH)</Table.HeaderCell>
                                                <Table.HeaderCell textAlign='center'>Action</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        {

                                            quotes.map((myquote: IDamperRateOutput, index) => (
                                                <Table.Row key={index} >
                                                    <Table.Cell textAlign='center'>{(index + 1)}</Table.Cell>
                                                    <Table.Cell textAlign='center'><b>{myquote.damperType}</b></Table.Cell>
                                                    <Table.Cell textAlign='center'>
                                                        <Label color='green' key='1' size='large' basic>
                                                            {myquote.salesPrice}
                                                        </Label>
                                                    </Table.Cell>
                                                    <Table.Cell textAlign='center'>{myquote.width} x {myquote.height}</Table.Cell>
                                                    <Table.Cell textAlign='center'>
                                                        <Icon basic name='trash alternate outline' size='large' color='red'
                                                            onClick={() => removeQuote(myquote)} />
                                                    </Table.Cell>
                                                </Table.Row>
                                            ))
                                        }

                                        <Table.Footer fullWidth>
                                            <Table.Row >
                                                <Table.Cell positive colSpan="5" textAlign='right'>
                                                    <Label color='blue' key='1' size='large' basic>
                                                        Total  :$ {getTotal()}
                                                    </Label>

                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Footer>
                                    </Table>

                                </>




                            )}


                        </Grid.Column>
                    </Grid.Row>

                </Grid>
            </Container>


        </>

    )
}

export default observer(Quotes)
