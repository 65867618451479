import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { ICompactCustomer } from "../models/Customer";

export default class CustomerStore {
    constructor() {
        makeAutoObservable(this);
    }

    loadingSearch = false;
    supplierMap = new Map();
    searchedCustomers: ICompactCustomer[] = [];

    typeAheadSearchSuppliers = async (searchType: string, searchValue: string) => {
        this.loadingSearch = true;
        try {
            this.supplierMap.clear();
            const suppliers = await agent.customer.searchCustomers(searchType, searchValue);
            runInAction(() => {
                let results: ICompactCustomer[] = [];
                suppliers.forEach(customer => {
                    this.supplierMap.set(customer.yellowpages_id, customer);
                    results.push(customer);
                });
                this.searchedCustomers = results;
                this.loadingSearch = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loadingSearch = false;
            });
        }
    }

}