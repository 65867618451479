import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { useStore } from '../../../app/stores/Store';
import TopActionButtons from './TopActionButtons';
import './css/purchaseOrderDetail.css';
import DocumentHeaderLogo from './DocumentHeaderLogo';
import DocumentHeaderAndAddress from './DocumentHeaderAndAddress';
import DocumentSoldToShipTo from './DocumentSoldToShipTo';
import DocumentCustomerContacts from './DocumentCustomerContacts';
import DocumentItemDetails from './DocumentItemDetails';
import PurchaseOrderDetailsPlaceHolder from './PurchaseOrderDetailsPlaceHolder';

interface ParamAtributes {
    id: string
}

const PurchaseOrderDetail: React.FC<RouteComponentProps<ParamAtributes>> = ({ match }) => {

    const { poStore, dataSyncStore } = useStore();
    const { loadPurchaseOrderByOrderNo, loadingPO, currentPurchaseOrder } = poStore;
    const { syncingSalesOrders } = dataSyncStore;
    useEffect(() => {
        console.log(`trying to load ${match.params.id}`);
        loadPurchaseOrderByOrderNo(match.params.id);
    }, [loadPurchaseOrderByOrderNo, match.params.id])

    if (currentPurchaseOrder === null || loadingPO) {
        return <LoadingComponent />
    }


    return (
        <Fragment>

            <TopActionButtons />

            {syncingSalesOrders ? (
                <div className='invoiceDocument'>
                    <PurchaseOrderDetailsPlaceHolder />
                </div>
            ) : (
                <div className='invoiceDocument'>
                    <DocumentHeaderLogo />
                    <DocumentHeaderAndAddress />
                    <DocumentSoldToShipTo />
                    <DocumentCustomerContacts />
                    {/*<DocumentShipmentDetails />*/}
                    <DocumentItemDetails />

                </div>

            )}

        </Fragment>
    )
}

export default observer(PurchaseOrderDetail)
