import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Tab } from 'semantic-ui-react';
import { useStore } from '../../../app/stores/Store';
import InventoryDetailForm from './InventoryDetailForm';


interface IProps {
    inventoryitemid: string,
    tabCount: number
}


const InventoryTabPane: React.FC<IProps> = ({ inventoryitemid }) => {
    const { inventoryStore } = useStore();
    const { loadInventoryItem, loadingInventory, inventory, removeTabPane } = inventoryStore;


    useEffect(() => {
        loadInventoryItem(inventoryitemid);
    }, [loadInventoryItem, inventoryitemid])

    const handleOnCancel = () => {
        if (inventory === undefined || inventory === null) return;
        removeTabPane(inventory.partNo + inventory.id);
    }

    return (
        <Tab.Pane attached={true} key={inventoryitemid} loading={loadingInventory}>
            <InventoryDetailForm inventoryItem={inventory} onCancel={handleOnCancel} />
        </Tab.Pane >
    )
}

export default observer(InventoryTabPane)
