import React, { Fragment } from 'react'
import { Button, Grid, Header, Icon, Label } from 'semantic-ui-react';
import { useStore } from '../../../app/stores/Store';
import { history } from '../../..';
import { observer } from 'mobx-react-lite';
import Grn from './Grn';


const TopActionButtons = () => {
    const { poStore, dataSyncStore, modalStore } = useStore();
    const { loadingPO, currentPurchaseOrder, generatePurchaseOrder, createOrUpdatePurchaseOrder } = poStore;
    const { syncingSalesOrders } = dataSyncStore;
    const { openModal } = modalStore;


    if (currentPurchaseOrder === null || loadingPO) {
        return <Fragment />
    }


    const validateAdSave = () => {
        if (currentPurchaseOrder.po_order_status === 'Closed' && !hasGRNValue()) {
            openModal(<Grn forceEdit={true} />, true)
            return;
        }
        createOrUpdatePurchaseOrder();
    }

    const hasGRNValue = () => {
        if (currentPurchaseOrder.grn !== null && currentPurchaseOrder.grn !== '') {
            return true;
        }
        currentPurchaseOrder.purchaseOrderDetails.forEach(detail => {
            if (detail.grn !== null && detail.grn !== '') {
                return true;
            }
        });
        return false;

    }

    const getColorBasedOnStatus = (status: string) => {

        if (status === 'Invoiced') {
            return 'blue';
        }
        if (status === 'On Hold') {
            return 'red';
        }
        if (status === 'Under Process') {
            return 'teal';
        }
        if (status === 'Packing Slip') {
            return 'yellow';
        }

        return 'orange';
    }
    const CreatePurchaseOrder = () => {
        generatePurchaseOrder(currentPurchaseOrder.po_order_no).then((blob) => {
            saveAs(blob, `PO_${currentPurchaseOrder.po_order_no}.pdf`);
        });
    }

    const shouldDisableButton = () => {
        if (currentPurchaseOrder.po_order_status === "On Hold")
            return true;
        if (syncingSalesOrders)
            return true;
        return false;
    }
    /*
        const disablePackingSlipButton = (changedStatus: string) => {
            if (syncingSalesOrders)
                return true;
            if (currentPurchaseOrder.po_order_status === "On Hold")
                return true;
            if (currentPurchaseOrder.po_order_status === "Invoiced" || changedStatus === "Invoiced")
                return false;
            if (currentPurchaseOrder.po_order_status === "Packing Slip" || changedStatus === "Packing Slip")
                return false;
            return true;
        }
    
        const disableInvoiceButton = (changedStatus: string) => {
            if (syncingSalesOrders)
                return true;
            if (currentPurchaseOrder.po_order_status === "On Hold")
                return true;
            if (currentPurchaseOrder.po_order_status === "Invoiced" || changedStatus === "Invoiced")
                return false;
            return true;
        }
    */

    return (
        <Fragment>
            <Header>
                <Label as='a' color={getColorBasedOnStatus(currentPurchaseOrder.po_order_status)} ribbon>
                    Order # : {currentPurchaseOrder && currentPurchaseOrder.po_order_no}
                </Label>
                <Label size='small' color='red' basic>
                    Rep Name : {currentPurchaseOrder && currentPurchaseOrder.po_order_rep_name}
                </Label>
                {/*
                {currentPurchaseOrder.purchase_order_master_id > 0 && (
                    <Button size='large' basic color='green' floated='right' onClick={() => SyncSalesOrder(currentPurchaseOrder.purchase_order_master_id.toString())} ><Icon name='refresh' />Refresh</Button>

                )}
                */}
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column width='3'>
                        </Grid.Column>
                        <Grid.Column width='13' floated='left'>
                            <Button.Group floated='left'>
                                <Button color='teal' onClick={CreatePurchaseOrder} disabled={shouldDisableButton()}>
                                    <Icon name='file pdf outline' />Generate Purchase Order
                                </Button>

                            </Button.Group>
                            {currentPurchaseOrder.po_order_status !== 'Closeddd' && (
                                <Button icon color='green' floated='left'
                                    style={{ 'position': 'relative', 'zIndex': '99', marginLeft: '10px' }}
                                    disabled={syncingSalesOrders}
                                    onClick={validateAdSave}
                                >
                                    <Icon name='save' />
                                    Save
                                </Button>)}

                            <Button style={{ marginLeft: '10px' }} color='red' onClick={() => { history.push(`/purchaseorders`) }} disabled={syncingSalesOrders}>
                                <Icon name='close' />Close
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Header>
        </Fragment>

    )
}

export default observer(TopActionButtons)
