import React, { useState } from 'react'
import { Button, Label } from 'semantic-ui-react';
import CustomerNameTypeAhead from '../../app/common/form/CustomerNameTypeAhead';
import DatePicker from "react-datepicker";

const CustomerSalesOrder = () => {
    const [customerName, setCustomerName] = useState('');

    const [startInvoiceDate, setStartInvoiceDate] = useState(new Date());
    const handleStartDateChange = (date: any) => setStartInvoiceDate(date);

    const [endInvoiceDate, setEndInvoiceDate] = useState(new Date());
    const handleEndDateChange = (date: any) => setEndInvoiceDate(date);


    const setCustomerData = (name: string, value: string) => {
        console.log(`Name ${name} Value ${value}`);

    }
    return (
        <div>
            <CustomerNameTypeAhead
                setLocalState={setCustomerName}
                setData={setCustomerData}
                name="so_customer_name"
                value={customerName} />

            <Label>Start Date:</Label>
            <DatePicker selected={startInvoiceDate} onChange={handleStartDateChange} dateFormat="yyyy-MM-dd" />
            <Label>End Date:</Label>
            <DatePicker selected={endInvoiceDate} onChange={handleEndDateChange} dateFormat="yyyy-MM-dd" />
            <Label></Label>
            <a href={process.env.REACT_APP_API_URL + `/reports/customersalesorder?startDate=${startInvoiceDate.toISOString().split('T')[0]}&endDate=${endInvoiceDate.toISOString().split('T')[0]}&name=${customerName}`}>
                <Button primary basic size='mini'>Download Report</Button>
            </a>

        </div>
    )
}

export default CustomerSalesOrder
