import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react'
import { Button, Input, Icon, Menu, Header, Select } from 'semantic-ui-react'
import { useStore } from '../../../../app/stores/Store';


const options = [
    { key: 'customerName', text: 'Customer Name', value: 'customerName' },
    { key: 'customerAlias', text: 'Customer Alias', value: 'customerAlias' },
]

const SearchCustomersForm = () => {
    const { customerStore, searchStore } = useStore();
    const { loadingSearch, searchCustomers } = customerStore;

    // const [searchValue, setSearchValue] = useState('');
    // const [searchType, setSearchType] = useState<any>('alias');

    const { searchType, searchValue, setSearchType, setSearchValue } = searchStore;

    const onSelectChange = (value: any) => {
        setSearchType(value);
    }

    const onSearchCustomers = () => {
        searchCustomers(searchType, searchValue);
    }
    return (

        <Fragment>
            <Menu size={'large'} style={{ width: '100%', marginTop: 50 }}>
                <Header icon={'filter'} attached color={'teal'} content={'Customers'} />
                <Menu.Item style={{ width: '20%' }}>
                    {/* <Label content='Sort By Date' />
                    <Radio slider placeholder='Customer Alias' /> */}
                </Menu.Item>
                <Menu.Item style={{ width: '80%' }}>
                    <Input type='text'
                        value={searchValue} onChange={(e) => setSearchValue(e.target.value)}
                        style={{ width: '70%' }}
                        placeholder='Search...' action>
                        <input />
                        <Select compact options={options} value={searchType} onChange={(e, data) => { onSelectChange(data.value) }} />
                        <Button icon color='blue' onClick={onSearchCustomers} loading={loadingSearch}>
                            <Icon name='search' />
                        </Button>
                    </Input>
                </Menu.Item>
            </Menu>
        </Fragment>
    )
}

export default observer(SearchCustomersForm)
