import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, Fragment, useState } from 'react'
import { Button, Radio } from 'semantic-ui-react';
import { history } from '../../../..';
import { useStore } from '../../../../app/stores/Store';

/*
const options = [
    { key: 'customerName', text: 'Customer Name', value: 'customerName' },
    { key: 'customerAlias', text: 'Customer Alias', value: 'customerAlias' },
    { key: 'orderNumber', text: 'Order Number', value: 'orderNumber' },
    { key: 'orderStatus', text: 'Order Status', value: 'orderStatus' },
    { key: 'poNumber', text: 'P O Number', value: 'poNumber' }
]
*/





const SearchPurchaseOrdersForm = () => {
    const { poStore, searchStore, commonStore } = useStore();
    const { searchPurchaseOrders, loadPurchaseOrders } = poStore;
    const { resetSearch, showAllOrders } = searchStore;

    const { purchaseOrderTabularView, setPOView } = commonStore;



    const [orderNumber, setOrderNumber] = useState('');
    const [customerName, setCustomerName] = useState('');


    const toggleSearchOrderFilter = () => {

        setPOView(!purchaseOrderTabularView);

        // let newState = !shorGrid;

    }

    const searchOrderByOrderNumber = () => {
        setCustomerName('');
        showAllOrders();
        //setOrdersShowAll(true);
        searchPurchaseOrders('orderNumber', orderNumber);
    }
    const searchOrdersByCustomerName = () => {
        setOrderNumber('');
        showAllOrders();
        //setOrdersShowAll(true);
        searchPurchaseOrders('customerName', customerName);
    }

    const basicReset = () => {
        resetSearch();
        setCustomerName('');
        setOrderNumber('');
    }
    const resetForm = () => {
        console.log('reset form clicked');
        basicReset();
        //setOrdersShowAll(false);
        loadPurchaseOrders();
    }


    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            if (event.target.name.includes('order')) {
                searchOrderByOrderNumber();
            } else {
                searchOrdersByCustomerName();
            }
        }
    }

    const handleFocus = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => event.target.select();
    return (
        <Fragment>
            <div className='resultOptions'>
                <div className='resultOptions__toggle'>
                    {purchaseOrderTabularView ? (
                        <div className='resultOptions__toggle_heading toggle_heading_all'>View: Grid</div>
                    ) : (
                        <div className='resultOptions__toggle_heading'>View: Tabular</div>
                    )}

                    <Radio toggle checked={purchaseOrderTabularView} onChange={() => toggleSearchOrderFilter()} />
                </div>
                <div className="ui action input">
                    <input name='orderNumber' type="text" value={orderNumber} placeholder="Search Order No ..."
                        onFocus={handleFocus}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setOrderNumber(e.target.value)}
                    />
                    <button className="ui icon button" onClick={() => searchOrderByOrderNumber()} ><i aria-hidden="true" className="search icon"></i></button>
                </div>
                <div className="ui action input">
                    <input name='customerName' type="text" value={customerName} placeholder="Search Customer Name ..."
                        onFocus={handleFocus}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setCustomerName(e.target.value)}
                    />
                    <button className="ui icon button" onClick={() => searchOrdersByCustomerName()}><i aria-hidden="true" className="search icon"></i></button>
                </div>
                <div>
                    <Button color='green' onClick={() => history.push(`/purchaseorder/create`)} > Create New</Button>
                    <Button color='orange' onClick={resetForm} > Reset</Button>
                </div>
            </div>
        </Fragment >
    )
}

export default observer(SearchPurchaseOrdersForm)
