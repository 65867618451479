import React, { Fragment, useEffect, useState } from 'react';
import { IGnattData } from '../../app/models/Chart';
import TimeLine from "react-gantt-timeline";
import { observer } from 'mobx-react-lite';
import agent from '../../app/api/agent';
import { Button, ButtonGroup, Grid, Icon, Input, Label, Table } from 'semantic-ui-react';
import { format } from 'date-fns';
import { ISalesOrderDatePlan } from '../../app/models/PlanningModels';
import { useStore } from '../../app/stores/Store';

const SalesOrderPlanner = () => {
    const { planningStore } = useStore();
    const { updateDateForOrder, synchronizeData, loadingInitial } = planningStore;
    const [data, setData] = useState<IGnattData[]>([]);
    const [links,] = useState<any[]>([]);
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [selectedOrderItem, setSelectedOrderItem] = useState<ISalesOrderDatePlan | null>(null);

    const [rawData, setRawData] = useState<ISalesOrderDatePlan[]>([]);

    const [searchValue, setSearchValue] = useState('');
    const [filteringData, setfilteringData] = useState(false);
    const [daterange, setDateRange] = useState('');

    useEffect(() => {
        let myStateData: IGnattData[] = [];
        //getPlannedData();
        agent.orderplanning.list().then(mydata => {
            for (let index = 0; index < mydata.length; index++) {
                const element = mydata[index];
                let mycolor = 'black';
                if (element.operationType === "Shear") {
                    mycolor = "red";
                } else if (element.operationType === "Bend") {
                    mycolor = "blue";
                } else if (element.operationType === "Assemble") {
                    mycolor = "green";
                }
                let newdata = {
                    id: element.id,
                    start: new Date(element.startDate),
                    end: new Date(element.endDate),
                    name: element.name,
                    color: mycolor
                }
                myStateData.push(newdata);
            }
            setData(myStateData);
            setRawData(mydata);
        })
        //setLinks([{ id: 1, start: 1, end: 2 }]);

    }, []);


    const onUpdateTask = (item: any, props: any) => {
        console.log('on Update Task');
        item.start = props.start ? props.start : item.start;
        item.end = props.end ? props.end : item.end;
        item.name = props.name ? props.name : item.name;

        setData([...data]);
        setSelectedItem(item);
        updateDateForOrder(item);
        if (item.id) {
            const myOrder = rawData.filter(order => order.id === item.id);
            setSelectedOrderItem(myOrder[0]);
            console.log(selectedOrderItem);
        }

    }

    const onSelectItem = (item: any) => {
        console.log('onSelectItem');
        setSelectedItem(item)
        console.log(`Select Item ${item}`);

        const myorder = rawData.filter(orders => orders.id === item.id);
        console.log(myorder);
        //this.setState({ selectedItem: item });
    };

    const onFilterOrders = () => {
        console.log('search orders ', searchValue);
        let myStateData: IGnattData[] = [];
        setData([]);
        setfilteringData(true);
        if (searchValue && searchValue !== '') {
            agent.orderplanning.getOrderData(searchValue).then(mydata => {
                for (let index = 0; index < mydata.length; index++) {
                    const element = mydata[index];
                    let mycolor = 'black';
                    if (element.operationType === "Shear") {
                        mycolor = "red";
                    } else if (element.operationType === "Bend") {
                        mycolor = "blue";
                    } else if (element.operationType === "Assemble") {
                        mycolor = "green";
                    }
                    let newdata = {
                        id: element.id,
                        start: new Date(element.startDate),
                        end: new Date(element.endDate),
                        name: element.name,
                        color: mycolor
                    }
                    myStateData.push(newdata);
                }
                setData(myStateData);
                setfilteringData(false);
                if (myStateData.length > 0) {
                    setDateRange(myStateData[0].name + ' : ' + format(myStateData[0].start, 'MM/dd/yyyy') + ' - ' + format(myStateData[0].end, 'MM/dd/yyyy'));
                }
            }, error => {
                console.log(error);
                setfilteringData(false);
            });


        }
        else {
            agent.orderplanning.list().then(mydata => {
                for (let index = 0; index < mydata.length; index++) {
                    const element = mydata[index];
                    let mycolor = 'black';
                    if (element.operationType === "Shear") {
                        mycolor = "red";
                    } else if (element.operationType === "Bend") {
                        mycolor = "blue";
                    } else if (element.operationType === "Assemble") {
                        mycolor = "green";
                    }
                    let newdata = {
                        id: element.id,
                        start: new Date(element.startDate),
                        end: new Date(element.endDate),
                        name: element.name,
                        color: mycolor
                    }
                    myStateData.push(newdata);
                }
                setData(myStateData);
                setfilteringData(false);
            }, error => {
                console.log(error);
                setfilteringData(false);
            });
            setDateRange('');
        }
    }

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            onFilterOrders();
        }
    }

    return (
        <Fragment>
            <ButtonGroup floated="right">
                <Button basic secondary onClick={synchronizeData} loading={loadingInitial}>Sync. Data</Button>
            </ButtonGroup>
            <h1>Brisk Planner Tool</h1>

            {selectedOrderItem && (

                <Fragment>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Order#</Table.HeaderCell>
                                <Table.HeaderCell>Customer Name</Table.HeaderCell>
                                <Table.HeaderCell>Part #</Table.HeaderCell>
                                <Table.HeaderCell>Start Date</Table.HeaderCell>
                                <Table.HeaderCell>End Date</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>{selectedOrderItem.so_order_no}</Table.Cell>
                                <Table.Cell>{selectedOrderItem.so_customer_name}</Table.Cell>
                                <Table.Cell>{selectedOrderItem.so_part_description} X {selectedOrderItem.so_item_qty}</Table.Cell>
                                <Table.Cell positive>{format(selectedItem.start, 'MM/dd/yyyy')}</Table.Cell>
                                <Table.Cell negative>{format(selectedItem.end, 'MM/dd/yyyy')}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Fragment>

            )}

            <Grid>
                <Grid.Column width='5'>
                    <Input type='text'
                        value={searchValue} onChange={(e) => setSearchValue(e.target.value)}
                        style={{ width: '50%' }}
                        placeholder='Filter Order Number ...' action>
                        <input onKeyPress={handleKeyPress} />
                        <Button icon color='black' onClick={onFilterOrders} loading={filteringData}>
                            <Icon name='search' />
                        </Button>
                    </Input>
                </Grid.Column>
                <Grid.Column width='10'>
                    {daterange && daterange !== '' && (
                        <Label>{daterange}</Label>
                    )}
                </Grid.Column>
            </Grid>

            <div className="app-container">
                {/* DayWidth<input type="range" min="30" max="500" value={this.state.daysWidth} onChange={this.handleDayWidth} step="1"/>
       Item Height<input type="range" min="30" max="500" value={this.state.itemheight} onChange={this.handleItemHeight} step="1"/> */}
                <div className="time-line-container">
                    <TimeLine
                        data={data}
                        links={links}
                        onUpdateTask={onUpdateTask}
                        onSelectItem={onSelectItem}
                        selectedItem={selectedItem}
                    />
                </div>
            </div>
        </Fragment >

    )
}

export default observer(SalesOrderPlanner);
