import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useState } from "react";
import { Button, ButtonGroup, Grid, GridColumn, Header, Label, Table } from "semantic-ui-react";
import { ISalesOrderDailyReport } from "../../app/models/SalesOrder";
//import { formatDistance } from 'date-fns';
import DatePicker from "react-datepicker";
import { useStore } from "../../app/stores/Store";
//import { saveAs } from 'file-saver';



const salesorderstatuses = [
    { key: 'underprocess', text: 'Under Process', value: 'Under Process' },
    { key: 'underprocess_packingslip', text: 'Under Process Or PackingSlip', value: 'Under Process Or Packing Slip' },
    { key: 'onhold', text: 'On Hold', value: 'On Hold' },
    { key: 'packingslip', text: 'Packing Slip', value: 'Packing Slip' },
    { key: 'cancelled', text: 'Cancelled', value: 'Cancelled' },
    { key: 'invoiced', text: 'Invoiced', value: 'Invoiced' },
    { key: 'not_invoiced', text: 'Not Invoiced', value: 'Not Invoiced' },

]


const DailyReport = () => {
    const { kioskStore } = useStore();
    const { dailySalesOrders, loadDailSalesOrderReport, sendEmailOfDailySalesOrderReport, loadingDashboard } = kioskStore;



    const [orderStatus, setOrderStatus] = useState('Under Process');
    const [startInvoiceDate, setStartInvoiceDate] = useState(new Date(new Date().getTime() - 86400000));
    const handleStartDateChange = (date: any) => setStartInvoiceDate(date);

    const [endInvoiceDate, setEndInvoiceDate] = useState(new Date());
    const handleEndDateChange = (date: any) => setEndInvoiceDate(date);


    useEffect(() => {
        loadDailSalesOrderReport();
    }, [loadDailSalesOrderReport])


    const getDateOnly = (dateString: string) => {

        try {
            return dateString.split('T')[0];
        } catch {
            return dateString;
        }
    }
    /*
    function downloadFile() {
        agent.salesorder.dailyreportdownload().then((response) => {
            console.log(response);
    
            let url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
            saveAs(url, "Report.xlsx");
        });
    }
    */

    return (
        <Fragment>

            <Grid columns={2} divided>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <Fragment>
                            <div style={{ margin: '0px', width: '500px', marginRight: '20px' }}>
                                <Label style={{ width: '100px' }}>Start Date:</Label>
                                <DatePicker selected={startInvoiceDate} onChange={handleStartDateChange} dateFormat="yyyy-MM-dd" />
                            </div>
                            <div style={{ margin: '0px', width: '500px', marginRight: '20px' }}>
                                <Label style={{ width: '100px' }}>End Date:</Label>
                                <DatePicker selected={endInvoiceDate} onChange={handleEndDateChange} dateFormat="yyyy-MM-dd" />
                            </div>
                            <div style={{ margin: '0px', width: '500px', marginRight: '20px' }}>
                                <Label style={{ width: '100px' }}>Order Status:</Label>
                                <select style={{ margin: '0px', width: '230px', marginRight: '20px' }}
                                    value={orderStatus}
                                    onChange={e => setOrderStatus(e.target.value)}>
                                    {salesorderstatuses.map(o => (
                                        <option key={o.value} value={o.value}>{o.text}</option>
                                    ))}
                                </select>
                            </div>
                        </Fragment>
                    </Grid.Column>
                    <GridColumn></GridColumn>

                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={4} textAlign='center'>
                        <ButtonGroup>
                            <a href={process.env.REACT_APP_API_URL + `/salesorders/dailyreportdownload?startDate=${startInvoiceDate.toISOString().split('T')[0]}&endDate=${endInvoiceDate.toISOString().split('T')[0]}&orderStatus=${orderStatus}`}><Button style={{ margin: '5px' }} primary basic>Download</Button></a>
                            <Button style={{ margin: '5px' }} basic secondary onClick={sendEmailOfDailySalesOrderReport} loading={loadingDashboard}>Email</Button>
                        </ButtonGroup>
                    </Grid.Column>
                    <Grid.Column>
                    </Grid.Column>
                </Grid.Row>
            </Grid>


            {/*
            <Label>Start Date:</Label>
            <DatePicker selected={startInvoiceDate} onChange={handleStartDateChange} dateFormat="yyyy-MM-dd" />
            <br />
            <Label>End Date:</Label>
            <DatePicker selected={endInvoiceDate} onChange={handleEndDateChange} dateFormat="yyyy-MM-dd" />
            <Label>Order Status: </Label>

            <select style={{ margin: '0px', width: '150px', marginRight: '20px' }}
                value={orderStatus}
                onChange={e => setOrderStatus(e.target.value)}>
                {salesorderstatuses.map(o => (
                    <option key={o.value} value={o.value}>{o.text}</option>
                ))}
            </select>
            <ButtonGroup>
                <a href={process.env.REACT_APP_API_URL + '/salesorders/dailyreportdownload'}><Button style={{ margin: '5px' }} primary basic>Download</Button></a>
                <Button style={{ margin: '5px' }} basic secondary onClick={sendEmailOfDailySalesOrderReport} loading={loadingDashboard}>Email</Button>
            </ButtonGroup>
                */}
            {/*        
            <a href={process.env.REACT_APP_API_URL + `/reports/downloadinvoicedata?startDate=${startInvoiceDate.toISOString().split('T')[0]}&endDate=${endInvoiceDate.toISOString().split('T')[0]}&orderStatus=${orderStatus}`}>
                <Button primary basic size='mini'>Download Invoice Data</Button>
            </a>
            <a href={process.env.REACT_APP_API_URL + `/reports/downloadpodata?startDate=${startInvoiceDate.toISOString().split('T')[0]}&endDate=${endInvoiceDate.toISOString().split('T')[0]}&orderStatus=${orderStatus}`}>
                <Button color='orange' size='mini'>Download PO Data</Button>
            </a>
                */}

            <br />
            <hr />
            <br />
            <div style={{ marginTop: '10px' }}>
                <Header as='h3'>Under Process Orders Sorted By Ship Date ...</Header>
                <Table fixed celled selectable compact size='large' striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{ width: '10%' }}>Order No</Table.HeaderCell>
                            <Table.HeaderCell>Order Date</Table.HeaderCell>
                            <Table.HeaderCell>Ship Date</Table.HeaderCell>
                            <Table.HeaderCell>Customer Name</Table.HeaderCell>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                            <Table.HeaderCell>Purchase OrderDelay Shear Punch Assembly</Table.HeaderCell>
                            <Table.HeaderCell>Order Status</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            dailySalesOrders.map((salesorder: ISalesOrderDailyReport) => (
                                new Date(salesorder.so_ship_date) < new Date() ?
                                    (
                                        <Table.Row key={salesorder.sales_order_master_id} negative>
                                            <Table.Cell>{salesorder.so_order_no}</Table.Cell>
                                            <Table.Cell>{getDateOnly(salesorder.so_order_date)}</Table.Cell>
                                            <Table.Cell>{getDateOnly(salesorder.so_ship_date)}</Table.Cell>
                                            <Table.Cell>{salesorder.so_customer_name}</Table.Cell>
                                            <Table.Cell>{salesorder.so_net_amount}</Table.Cell>
                                            <Table.Cell>
                                                {/*formatDistance(Date.parse(salesorder.so_ship_date), new Date())} Ago */}
                                                {getDateOnly(salesorder.so_ship_date)}
                                            </Table.Cell>
                                            <Table.Cell>{salesorder.so_order_status}</Table.Cell>
                                        </Table.Row>
                                    )
                                    :
                                    <Table.Row key={salesorder.sales_order_master_id} positive>
                                        <Table.Cell>{salesorder.so_order_no}</Table.Cell>
                                        <Table.Cell>{getDateOnly(salesorder.so_order_date)}</Table.Cell>
                                        <Table.Cell>{getDateOnly(salesorder.so_ship_date)}</Table.Cell>
                                        <Table.Cell>{salesorder.so_customer_name}</Table.Cell>
                                        <Table.Cell>{salesorder.so_net_amount}</Table.Cell>
                                        <Table.Cell>
                                            {/*formatDistance(Date.parse(salesorder.so_ship_date.split('T')[0]), new Date())*/} Ahead
                                            {getDateOnly(salesorder.so_ship_date)}
                                        </Table.Cell>
                                        <Table.Cell>{salesorder.so_order_status}</Table.Cell>
                                    </Table.Row>
                            ))
                        }
                    </Table.Body>
                </Table>
            </div>
        </Fragment >
    )
}

export default observer(DailyReport)