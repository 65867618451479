import React, { Fragment, useState, useEffect } from 'react'
import { Step, Icon, Button, Header, Label, Grid, Segment, Table } from 'semantic-ui-react'
import { IDocumentSummary, IMovementWithSummary, IMovement } from '../../../app/models/IDocumentSummary'
import { observer } from 'mobx-react-lite'
import TrackingTablePlaceholder from './TrackingTablePlaceholder'
import { useStore } from '../../../app/stores/Store'

interface IProps {
    documentSummary: IDocumentSummary | null;
    onCancel: () => void;
}

const TrackingDetailsForm: React.FC<IProps> = ({ documentSummary, onCancel }) => {
    const { scanStore } = useStore();
    const { loadMovements, loadingMovements } = scanStore;

    const [labelsCreated, setLabelsCreated] = useState<IMovementWithSummary | null>(null);
    const [productionAcknowledged, setProductionAcknowledged] = useState<IMovementWithSummary | null>(null);
    const [InProduction, setInProduction] = useState<IMovementWithSummary | null>(null);
    const [deliveryAcknowledged, setDeliveryAcknowledged] = useState<IMovementWithSummary | null>(null);
    const [inDelivery, setInDelivery] = useState<IMovementWithSummary | null>(null);
    //const [inShipping, setInShipping] = useState<IMovementWithSummary | null>(null);
    //const [shipped, setShipped] = useState<IMovementWithSummary | null>(null);

    const [selectedMovements, setSelectedMovements] = useState<IMovement[] | null>(null);
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        if (documentSummary !== null) {
            let movement1 = documentSummary.movementsWithSummary.find(movement => movement.code === "LabelsCreated");
            if (movement1) {
                setLabelsCreated(movement1);
                if (movement1.movementId === documentSummary.lastKnownMovementId)
                    setSelectedMovements(movement1.movements);
            }
            let movement2 = documentSummary.movementsWithSummary.find(movement => movement.code === "ProductionAcknowledged");
            if (movement2) {
                setProductionAcknowledged(movement2);
                if (movement2.movementId === documentSummary.lastKnownMovementId)
                    setSelectedMovements(movement2.movements);
            }
            let movement3 = documentSummary.movementsWithSummary.find(movement => movement.code === "InProduction");
            if (movement3) {
                setInProduction(movement3);
                if (movement3.movementId === documentSummary.lastKnownMovementId)
                    setSelectedMovements(movement3.movements);
            }
            let movement4 = documentSummary.movementsWithSummary.find(movement => movement.code === "DeliveryAcknowledged");
            if (movement4) {
                setDeliveryAcknowledged(movement4);
                if (movement4.movementId === documentSummary.lastKnownMovementId)
                    setSelectedMovements(movement4.movements);
            }
            let movement5 = documentSummary.movementsWithSummary.find(movement => movement.code === "InDelivery");
            if (movement5) {
                setInDelivery(movement5);
                if (movement5.movementId === documentSummary.lastKnownMovementId)
                    setSelectedMovements(movement5.movements);
            }

            // let movement6 = documentSummary.movementsWithSummary.find(movement => movement.movementId === 6);
            // if (movement6) {
            //     setInShipping(movement6);
            //     if (movement6.movementId === documentSummary.lastKnownMovementId)
            //         setSelectedMovements(movement6.movements);
            // }
            // let movement7 = documentSummary.movementsWithSummary.find(movement => movement.movementId === 7);
            // if (movement7) {
            //     setShipped(movement7);
            //     if (movement7.movementId === documentSummary.lastKnownMovementId)
            //         setSelectedMovements(movement7.movements);
            // }
            setActiveStep(documentSummary.lastKnownMovementId);
        }
    }, [documentSummary]);

    const getMovements = (workorder: string, movementType: number, ignore: boolean) => {
        if (ignore) return;
        loadMovements(workorder, movementType).then(movements => {
            console.log(movements);
            if (movements !== undefined) {
                setSelectedMovements(movements);
                setActiveStep(movementType);
            }
        });
    }

    return (
        <Fragment>
            <Fragment>
                <Button icon color='red' style={{ 'position': 'relative', 'zIndex': '99' }} floated='right'
                    onClick={onCancel}>
                    <Icon name='close' />
                        Cancel
                </Button>
            </Fragment>

            <Header>
                <Label as='a' color='orange' ribbon>
                    Document # : {documentSummary && documentSummary!.workOrderNo}
                </Label>
            </Header>
            <Grid>
                <Grid.Row stretched textAlign='center'>
                    <Grid.Column width={8}>
                        <Segment.Group compact>
                            <Segment color='teal'>
                                <h5>Sales Order Consise Details:</h5>
                            </Segment>
                        </Segment.Group>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Segment.Group compact>
                            <Segment color='orange'>
                                <h5>Contact Details:</h5>
                            </Segment>
                        </Segment.Group>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row textAlign='center'>
                    <Grid.Column width={4}>
                        <Step.Group vertical>
                            {
                                <Step disabled={labelsCreated ? false : true} active={activeStep === 8 ? true : false}
                                    onClick={() => getMovements(documentSummary ? documentSummary.workOrderNo : "", 8, labelsCreated === null)}>
                                    <Icon name='print' />
                                    <Step.Content>
                                        <Step.Title>Labels Created</Step.Title>
                                        {
                                            labelsCreated ?
                                                (<Step.Description>{labelsCreated.count} labels created</Step.Description>)
                                                : (<Step.Description>No Details Found</Step.Description>)
                                        }
                                    </Step.Content>
                                </Step>
                            }
                            {
                                <Step disabled={productionAcknowledged ? false : true} active={activeStep === 9 ? true : false}
                                    onClick={() => getMovements(documentSummary ? documentSummary.workOrderNo : "", 9, productionAcknowledged === null)}>
                                    <Icon name='qrcode' />
                                    <Step.Content>
                                        <Step.Title>Workorder Scanned</Step.Title>
                                        {
                                            productionAcknowledged ?
                                                (<Step.Description>{productionAcknowledged.count} scans found</Step.Description>)
                                                : (<Step.Description>No Details Found</Step.Description>)
                                        }
                                    </Step.Content>
                                </Step>
                            }
                            {
                                <Step disabled={InProduction ? false : true} active={activeStep === 10 ? true : false}
                                    onClick={() => getMovements(documentSummary ? documentSummary.workOrderNo : "", 10, InProduction === null)}>
                                    <Icon name='box' />
                                    <Step.Content>
                                        <Step.Title>In-Production</Step.Title>
                                        {
                                            InProduction ?
                                                (<Step.Description>{InProduction.count} scans found</Step.Description>)
                                                : (<Step.Description>No Details Found</Step.Description>)
                                        }
                                    </Step.Content>
                                </Step>
                            }
                            {
                                <Step disabled={deliveryAcknowledged ? false : true} active={activeStep === 11 ? true : false}
                                    onClick={() => getMovements(documentSummary ? documentSummary.workOrderNo : "", 11, deliveryAcknowledged === null)}>
                                    <Icon name='dolly' />
                                    <Step.Content>
                                        <Step.Title>Delivery Scanned</Step.Title>
                                        {
                                            deliveryAcknowledged ?
                                                (<Step.Description>{deliveryAcknowledged.count} scans found</Step.Description>)
                                                : (<Step.Description>No Details Found</Step.Description>)
                                        }
                                    </Step.Content>
                                </Step>
                            }
                            {
                                <Step disabled={inDelivery ? false : true} active={activeStep === 12 ? true : false}
                                    onClick={() => getMovements(documentSummary ? documentSummary.workOrderNo : "", 12, inDelivery === null)}>
                                    <Icon name='pallet' />
                                    <Step.Content>
                                        <Step.Title>In-Delivery</Step.Title>
                                        {
                                            inDelivery ?
                                                (<Step.Description>{inDelivery.count} scans found</Step.Description>)
                                                : (<Step.Description>No Details Found</Step.Description>)
                                        }
                                    </Step.Content>
                                </Step>
                            }
                            {/* {
                                <Step disabled={inShipping ? false : true} active={activeStep === 6 ? true : false}
                                    onClick={() => getMovements(documentSummary ? documentSummary.workOrderNo : "", 6, inShipping === null)}>
                                    <Icon name='shipping fast' />
                                    <Step.Content>
                                        <Step.Title>Shipping</Step.Title>
                                        {
                                            inShipping ?
                                                (<Step.Description>{inShipping.count} scans found</Step.Description>)
                                                : (<Step.Description>No Details Found</Step.Description>)
                                        }
                                    </Step.Content>
                                </Step>
                            }
                            {
                                <Step disabled={shipped ? false : true} active={activeStep === 7 ? true : false}
                                    onClick={() => getMovements(documentSummary ? documentSummary.workOrderNo : "", 7, shipped === null)}>
                                    <Icon name='shipping' />
                                    <Step.Content>
                                        <Step.Title>Shipped</Step.Title>
                                        {
                                            shipped ?
                                                (<Step.Description>{shipped.count} scans found</Step.Description>)
                                                : (<Step.Description>No Details Found</Step.Description>)
                                        }
                                    </Step.Content>
                                </Step>
                            }
                            {
                                <Step disabled>
                                    <Icon name='dollar' />
                                    <Step.Content>
                                        <Step.Title>Billing</Step.Title>
                                        {
                                            productionAcknowledged ?
                                                (<Step.Description>{productionAcknowledged.count} scans found</Step.Description>)
                                                : (<Step.Description>No Details Found</Step.Description>)
                                        }
                                    </Step.Content>
                                </Step>
                            } */}
                        </Step.Group>

                    </Grid.Column>
                    <Grid.Column width={12}>

                        {loadingMovements ?
                            (
                                <TrackingTablePlaceholder />
                            ) :
                            (
                                <Table fixed celled selectable compact size='small' striped>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Code</Table.HeaderCell>
                                            <Table.HeaderCell>Description</Table.HeaderCell>
                                            <Table.HeaderCell>Item No</Table.HeaderCell>
                                            <Table.HeaderCell>Dimensions</Table.HeaderCell>
                                            <Table.HeaderCell>User Name</Table.HeaderCell>
                                            <Table.HeaderCell>Scanned Date / Time</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        {selectedMovements &&
                                            (selectedMovements.map(movement => (

                                                <Table.Row key={movement.id}>
                                                    <Table.Cell>{movement.materialCode}</Table.Cell>
                                                    <Table.Cell>{movement.materialDescription}</Table.Cell>
                                                    <Table.Cell>{movement.sequenceNo}</Table.Cell>
                                                    <Table.Cell>{movement.dimensions}</Table.Cell>
                                                    <Table.Cell>{movement.userName}</Table.Cell>
                                                    <Table.Cell>{movement.movementDate.split('T')[0]} / {movement.movementDate.split('T')[1].split('.')[0]}</Table.Cell>
                                                </Table.Row>

                                            )))}
                                    </Table.Body>
                                </Table>
                            )}


                    </Grid.Column>
                </Grid.Row>

            </Grid >

        </Fragment >
    )
}

export default observer(TrackingDetailsForm)
