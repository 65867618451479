import { makeAutoObservable } from "mobx";


const PAGE_SIZE = 12;

export default class SearchStore {


    constructor() {
        makeAutoObservable(this);
    }

    totalRecordCount = 0;
    currentPageNo = 0;

    searchType = 'customerAlias';
    searchValue = '';

    salesOrderFilterValue = 'InProgress';


    setPageNo = (pageNo: number) => {
        this.currentPageNo = pageNo;
    }

    resetSearch = (defaultSearchType: string = 'customerAlias') => {
        this.searchType = defaultSearchType;
        this.currentPageNo = 0;
        this.totalRecordCount = 0;
        this.searchValue = '';
        this.salesOrderFilterValue = 'InProgress';
    }

    showAllOrders = () => {
        this.salesOrderFilterValue = 'ShowAll';
    }
    showInProgressOnly = () => {
        this.salesOrderFilterValue = 'InProgress';
    }

    setSearchType = (value: string) => {
        this.searchType = value;
        if (value !== this.searchType) {
            this.currentPageNo = 0;
        }

    }
    setSearchValue = (value: string) => {
        if (value !== this.searchValue) {
            this.currentPageNo = 0;
        }

        this.searchValue = value;
    }

    get totalPages() {
        return Math.ceil(this.totalRecordCount / PAGE_SIZE);
    }


    getAxiosParams() {
        const params = new URLSearchParams();
        params.append('maxcount', PAGE_SIZE.toString());


        //console.log(`searchBy ${this.searchType} / searchValue ${this.searchValue}`);
        if (this.searchValue !== '') {
            params.append("searchby", this.searchType);
            params.append("searchvalue", this.searchValue);
            //     params.append('offset', '0')
            params.append('offset', `${this.currentPageNo ? this.currentPageNo * PAGE_SIZE : 0}`)
        } else {
            params.append('offset', `${this.currentPageNo ? this.currentPageNo * PAGE_SIZE : 0}`)
        }
        if (this.salesOrderFilterValue !== '') {
            params.append("orderStatus", this.salesOrderFilterValue);
        }

        //console.log(params.toString());
        return params;
    }

}