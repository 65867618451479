import { makeAutoObservable } from 'mobx';

export default class PdfStore {

    constructor() {
        makeAutoObservable(this);
    }

    heading: string = "";
    setHeading = (value: string) => {
        this.heading = value;
    }

}