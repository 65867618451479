import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Button, Grid, Header, Table } from 'semantic-ui-react'
import { useStore } from '../../../app/stores/Store'


const sizes = [
    { text: '5 Inches', value: '5' },
    { text: '6 Inches', value: '6' }
]

const CRD = () => {
    const { costingStore } = useStore();
    const { gettingRate, setWidth, getRateForCRD, setQuotes } = costingStore;

    const [myWidth, setMyWidth] = useState('5')

    const computeRate = async () => {
        setWidth(Number.parseFloat(myWidth));
        let rate = await getRateForCRD();
        if (rate !== undefined && rate !== null) {
            setQuotes(rate);
        }
    }

    return (
        <>
            <Header as='h2'>CRD [AMI - 55 CRD (502)]</Header>
            <Grid columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        <Table>
                            <Table.Header>

                                <Table.Row>
                                    <Table.HeaderCell>Size</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={myWidth}
                                            onChange={e => setMyWidth(e.target.value)}
                                        >
                                            {sizes.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                </Table.Row>

                            </Table.Header>
                        </Table>
                        <Button positive loading={gettingRate} onClick={computeRate} floated='right'>Calculate</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    )
}

export default observer(CRD)