import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { ICost, ICostingRule, ISalesOrderCostAnalysis } from "../models/Costing";
import { store } from "./Store";

export default class CostingStore {
    constructor() {
        makeAutoObservable(this);
    }


    gettingRate = false;
    selectedProduct = "FireDamper";
    salesOrderCosting: ISalesOrderCostAnalysis | null = null;
    upperDimension = 12;
    leftRightHeight = 12;
    width = 12;
    height = 12;
    isHorizontal = false;
    hasAngles = false;
    hasSleeves = false;
    hasCollars = false;
    motorModel = "";
    sleeveDepth = 16;
    currentCalculation: ICost | null = null;
    productCost: ICost | null = null;


    resetSalesOrderCosting = () => {
        this.salesOrderCosting = null;
        this.resetQuotes();
    }


    quotes: ICost[] = [];
    setQuotes = (rate: ICost) => {
        rate.quantity = '1';
        this.quotes = [...this.quotes, rate];
    }

    resetQuotes = () => {
        this.quotes = [];
        this.currentCalculation = null;
    }

    removeQuote = (quote: ICost) => {
        var index = this.quotes.indexOf(quote);
        if (index > -1) {
            this.quotes.splice(index, 1);
        }
    }

    showCalculations = (quote: ICost) => {
        var index = this.quotes.indexOf(quote);
        if (index > -1) {
            this.currentCalculation = this.quotes[index];
        }
    }


    setSelectedProduct = (value: string) => {
        this.selectedProduct = value;
    }

    setWidth = (value: number) => {
        this.width = value;
    }

    setHeight = (value: number) => {
        this.height = value;
    }

    setUpperDimension = (value: number) => {
        this.upperDimension = value;
    }

    setLeftRightHeight = (value: number) => {
        this.leftRightHeight = value;
    }

    setIsHorizontal = (value: boolean) => {
        this.isHorizontal = value;
    }


    setAngles = (value: boolean) => {
        this.hasAngles = value;
    }

    setSleeves = (value: boolean) => {
        this.hasSleeves = value;
    }

    setCollars = (value: boolean) => {
        this.hasCollars = value;
    }

    setMotorModel = (value: string) => {
        this.motorModel = value;
    }

    setSleeveDepth = (value: number) => {
        this.sleeveDepth = value;
    }


    get axiosParams() {
        const params = new URLSearchParams();
        params.append('SelectedProduct', this.selectedProduct);
        params.append('UpperDimension', this.upperDimension.toString());
        params.append('LeftRightHeight', this.leftRightHeight.toString());
        params.append('Width', this.width.toString());
        params.append('Height', this.height.toString());
        params.append('IsHorizontal', this.isHorizontal.toString());
        params.append('HasAngles', this.hasAngles.toString());
        params.append('HasSleeves', this.hasSleeves.toString());
        params.append('HasCollars', this.hasCollars.toString());
        params.append('MotorModel', this.motorModel);
        params.append('SleeveDepth', this.sleeveDepth.toString());
        return params;
    }


    getRateFromAxiosParams = async () => {
        this.gettingRate = true;
        try {
            console.log(this.axiosParams.toString());
            const computedRate = await agent.costing.getrate(this.axiosParams);
            runInAction(() => {
                this.productCost = computedRate;
                this.gettingRate = false;
            });

            return computedRate;
        } catch (error) {
            runInAction(() => {
                this.gettingRate = false;
            });
            console.log(error);
        }
    }


    getRateForProduct = async (product: string) => {
        this.selectedProduct = product;
        return await this.getRateFromAxiosParams();
    }


    getRateForFireDamper = async () => {
        this.selectedProduct = "FireDamper";
        return await this.getRateFromAxiosParams();
    }

    getRateForLouvers = async () => {
        this.selectedProduct = "Louvers";
        return await this.getRateFromAxiosParams();

    }

    getRateForSilencers = async () => {
        this.selectedProduct = "Silencer";
        return await this.getRateFromAxiosParams();
    }
    getRateForRCD = async (model: string) => {
        this.selectedProduct = model;
        return await this.getRateFromAxiosParams();
    }

    getRateForCRD = async () => {
        this.selectedProduct = "CRD";
        return await this.getRateFromAxiosParams();
    }


    getRateForControlDamper = async () => {
        this.selectedProduct = "ControlDamper";
        return await this.getRateFromAxiosParams();
    }

    getRateForDynamicDamper = async () => {
        this.selectedProduct = "DynamicDamper";
        return await this.getRateFromAxiosParams();
    }


    getRateForFireSmokeDamper = async () => {
        this.selectedProduct = "FireSmokeDamper";
        return await this.getRateFromAxiosParams();
    }


    getRateForDoors = async () => {
        this.gettingRate = true;
        return await this.getRateFromAxiosParams();
    }

    getCostingForSalesOrder = async (salesOrderNo: string) => {
        this.gettingRate = true;

        try {
            const salesOrderCosting = await agent.costing.getCostForSalesOrder(salesOrderNo.trim());
            runInAction(() => {
                this.salesOrderCosting = salesOrderCosting;
                this.gettingRate = false;
                this.quotes = salesOrderCosting.costs;
                store.costQuotesStore.setSelectedSalesOrder(salesOrderCosting.customerData);
            });
            return salesOrderCosting;
        } catch (error: any) {
            runInAction(() => {
                this.gettingRate = false;
            });
            console.log(error);
            if (error)
                toast.error(error.statusText);
        }
    }

    createRule = async (rule: ICostingRule) => {
        this.gettingRate = true;
        try {
            await agent.costing.createRule(rule);
            runInAction(() => {
                this.gettingRate = false;
                toast.success('New Rule Added!')
            })
        } catch (error) {
            runInAction(() => {
                this.gettingRate = false;
            });
        }
    }

}
