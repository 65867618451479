import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, Fragment, useEffect } from 'react'
import { useStore } from '../../../app/stores/Store';



const salesorderstatuses = [
    { key: 'underprocess', text: 'Under Process', value: 'Under Process' },
    { key: 'onhold', text: 'On Hold', value: 'On Hold' },
    { key: 'packingslip', text: 'Packing Slip', value: 'Packing Slip' },
    { key: 'cancelled', text: 'Cancelled', value: 'Cancelled' },
    { key: 'invoiced', text: 'Invoiced', value: 'Invoiced' },
    { key: 'complete', text: 'Complete', value: 'Complete' },
]

const netterms = [
    { key: 'net30', text: 'Net 30', value: 'Net 30' },
    { key: 'net40', text: 'Net 40', value: 'Net 40' },
    { key: 'net45', text: 'Net 45', value: 'Net 45' },
    { key: 'net60', text: 'Net 60', value: 'Net 60' },
]

const salesagent = [
    { key: 'Rony', text: 'Rony', value: 'Rony' },
    { key: 'Harry', text: 'Harry', value: 'Harry' },
    { key: 'Mahesh', text: 'Mahesh', value: 'Mahesh' },
]


const DocumentHeaderAndAddress = () => {
    const { salesOrderStore } = useStore();
    const { loadingSalesOrder, currentSalesOrder, updateSalesOrder, runningTotal } = salesOrderStore;


    useEffect(() => {
        //console.log('running Total is ', runningTotal);
        //console.log('currentSalesOrder Running Total is ', currentSalesOrder?.so_net_amount);

    }, [runningTotal, currentSalesOrder])

    if (currentSalesOrder === null || loadingSalesOrder) {
        return <Fragment />
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        updateSalesOrder(name, value);
    }
    const handleSelectChange = (name: string, value: string) => {
        // console.log(event.target);
        // const target = event.target as HTMLSelectElement;
        //sconsole.log(event);
        updateSalesOrder(name, value);
    }

    const getDate = (datetime: Date | null) => {
        if (datetime === null)
            return;
        return datetime.toString().split('T')[0];
    }


    return (
        <div className='documentHeaderAndAddress'>
            <div className='lhs'>
                1090 Brevik Place,
                <br /> Mississauaga, L4W 3Y5, ON, Canada,
                <br />Phone: (905) 206-6888
            </div>
            {currentSalesOrder.so_invoice_no ? (
                <div className='rhs'>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Status</div>
                        <div className='rhs_data'>
                            <p>{currentSalesOrder.so_order_status}</p>
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Order #</div>
                        <div className='rhs_data'>
                            <p>{currentSalesOrder.so_order_no}</p>
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Pkg Slip Date</div>
                        <div className='rhs_data'>
                            {/*<Field fluid date={true} placeholder='Package Slip Date' name='so_pack_slip_date' component={DateInput} />*/}
                            {/*<p>{getDate(currentSalesOrder.so_pack_slip_date)}</p>*/}
                            <input type='date' name='so_pack_slip_date' onChange={handleInputChange} value={getDate(currentSalesOrder.so_pack_slip_date)} />

                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Order Date</div>
                        <div className='rhs_data'>
                            <input type='date' name='so_order_date' onChange={handleInputChange} value={getDate(currentSalesOrder.so_order_date)} />
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Ship Date</div>
                        <div className='rhs_data'>
                            {/*<Field fluid date={true} placeholder='Ship Date' name='so_ship_date' component={DateInput} />*/}
                            {/*<p>{getDate(currentSalesOrder.so_ship_date)}</p>*/}
                            <input type='date' name='so_ship_date' onChange={handleInputChange} value={getDate(currentSalesOrder.so_ship_date)} />
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Invoice Date</div>
                        <div className='rhs_data'>
                            {/*<Field fluid date={true} placeholder='Ship Date' name='so_ship_date' component={DateInput} />*/}
                            {/*<p>{getDate(currentSalesOrder.so_ship_date)}</p>*/}
                            <input type='date' name='so_invoice_date' onChange={handleInputChange} value={getDate(currentSalesOrder.so_invoice_date)} />
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Amount Due</div>
                        <div className='rhs_data'>
                            <p>{currentSalesOrder.so_net_amount}</p>
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Terms</div>
                        <div className='rhs_data'>
                            <p>{currentSalesOrder.so_payment_terms}</p>
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Sales Agent</div>
                        <div className='rhs_data'>
                            <p>{currentSalesOrder.sales_agent}</p>
                        </div>
                    </div>

                </div>

            ) : (
                <div className='rhs'>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Status :</div>
                        <div className='rhs_data'>
                            <select style={{ margin: '0px' }}
                                value={currentSalesOrder.so_order_status}
                                onChange={e => handleSelectChange("so_order_status", e.target.value)}>
                                {salesorderstatuses.map(o => (
                                    <option key={o.value} value={o.value}>{o.text}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Order # :</div>
                        <div className='rhs_data'>
                            {currentSalesOrder.so_order_no ? (<p>{currentSalesOrder.so_order_no}</p>) : (<p>N/A</p>)}
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Order Date</div>
                        <div className='rhs_data'>
                            <input type='date' name='so_order_date' onChange={handleInputChange} value={getDate(currentSalesOrder.so_order_date)} />
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Pkg Slip Date :</div>
                        <div className='rhs_data'>
                            <input type='date' name='so_pack_slip_date' onChange={handleInputChange} value={getDate(currentSalesOrder.so_pack_slip_date)} />
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Ship Date :</div>
                        <div className='rhs_data'>
                            <input type='date' name='so_ship_date' onChange={handleInputChange} value={getDate(currentSalesOrder.so_ship_date)} />
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Amount Due :</div>
                        <div className='rhs_data'>
                            <p>{currentSalesOrder.so_net_amount}</p>
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Terms</div>
                        <div className='rhs_data'>
                            <select style={{ margin: '0px' }}
                                value={currentSalesOrder.so_payment_terms}
                                onChange={e => handleSelectChange("so_payment_terms", e.target.value)}>
                                {netterms.map(o => (
                                    <option key={o.value} value={o.value}>{o.text}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='rhs__grid'>
                        <div className='rhs_heading'>Sales Agent</div>
                        <div className='rhs_data'>
                            <select style={{ margin: '0px' }}
                                value={currentSalesOrder.sales_agent}
                                onChange={e => handleSelectChange("sales_agent", e.target.value)}>
                                {salesagent.map(o => (
                                    <option key={o.value} value={o.value}>{o.text}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>

            )}

        </div>
    )
}

export default observer(DocumentHeaderAndAddress)
