import React from 'react'
import { combineValidators, isRequired } from 'revalidate'
import { Form as FinalForm, Field } from 'react-final-form';
import { IUserFormValues } from '../../app/models/User';
import { FORM_ERROR } from 'final-form';
import { Form, Header, Button } from 'semantic-ui-react';
import TextInput from '../../app/common/form/TextInput';
import ErrorMessage from '../../app/common/form/ErrorMessage';
import { useStore } from '../../app/stores/Store';


const validate = combineValidators({
    email: isRequired('email'),
    password: isRequired('password')
});

const LoginForm = () => {
    const { userStore } = useStore();
    const { login } = userStore;

    return (
        <FinalForm
            onSubmit={(values: IUserFormValues) => login(values).catch((error) => ({
                [FORM_ERROR]: error
            }))}
            validate={validate}
            render={({ handleSubmit, submitting, form, submitError, invalid, pristine, dirtySinceLastSubmit }) => (
                <Form onSubmit={handleSubmit} error>
                    <Header as='h2' content='Login to BriskMfg Inc.' color='teal' textAlign='center' />
                    <Field name='email' placeholder='Email' component={TextInput} />
                    <Field name='password' placeholder='Password' type='password' component={TextInput} />
                    {submitError && !dirtySinceLastSubmit && <ErrorMessage error={submitError} text='Invalid UserName/Password' />}
                    <Button fluid disabled={(invalid && !dirtySinceLastSubmit) || pristine} loading={submitting} color='teal' content='Login' />
                    {/* {<pre>{JSON.stringify(form.getState(),null,2)}</pre>} */}
                </Form>
            )}
        />
    )
}

export default LoginForm
