import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Button, Grid, Header, Table } from 'semantic-ui-react'
import { useStore } from '../../../app/stores/Store'


const widths = [
    { text: '2 Inches', value: '2' },
    { text: '4 Inches', value: '4' }
]

const sizes = [
    { text: '8', value: '8' },
    { text: '10', value: '10' },
    { text: '12', value: '12' },
    { text: '14', value: '14' },
    { text: '16', value: '16' },
    { text: '18', value: '18' },
    { text: '20', value: '20' },
    { text: '24', value: '24' }
]



const Louvers = () => {
    const { costingStore } = useStore();
    const { gettingRate, setWidth, setHeight, getRateForLouvers, setQuotes } = costingStore;

    const [myWidth, setMyWidth] = useState('2')
    const [myHeight, setMyHeight] = useState('8')

    const computeRate = async () => {
        setWidth(Number.parseFloat(myWidth));
        setHeight(Number.parseFloat(myHeight));
        let rate = await getRateForLouvers();
        if (rate !== undefined && rate !== null) {
            setQuotes(rate);
        }
    }

    return (
        <>
            <Header as='h2'>Louvers</Header>
            <Grid columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        <Table>
                            <Table.Header>

                                <Table.Row>
                                    <Table.HeaderCell>Width</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={myWidth}
                                            onChange={e => setMyWidth(e.target.value)}
                                        >
                                            {widths.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Size</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={myHeight}
                                            onChange={e => setMyHeight(e.target.value)}
                                        >
                                            {sizes.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                </Table.Row>

                            </Table.Header>
                        </Table>
                        <Button positive loading={gettingRate} onClick={computeRate} floated='right'>Calculate</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    )
}

export default observer(Louvers)