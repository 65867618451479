import axios, { AxiosResponse } from 'axios';
import { history } from '../..';
import { toast } from 'react-toastify';
import { IUser, IUserFormValues } from '../models/User';
import { ICustomerEnvelope, ILegacyCustomer } from '../models/Customer';
import { ISalesOrder, ILegacySalesOrder, ISalesOrderEnvelope, ISalesOrderDailyReport, ISalesOrderDetail, IAcknowledge } from '../models/SalesOrder';
import { IProduct, IProductSummary } from '../models/Product';
import { IPartEnvelope } from '../models/Part';
import { IPurchaseOrderEnvelope, ILegacyPurchaseOrder } from '../models/PurchaseOrder';
import { IDocumentSummary, IMovement } from '../models/IDocumentSummary';
import { IProductionDashboard, IDeliveryDashboard, IDocument } from '../models/Kiosk';
import { ISalesOrderDatePlan, IUpdatedDate } from '../models/PlanningModels';
import { Taxes } from '../models/Taxes';
import { IDamperRateOutput } from '../models/DamperRateDto';
import { IInventoryEnvelope, IInventoryItem } from '../models/Inventory';
import { ICost, ICostingRule, IQuote, ISalesOrderCostAnalysis, ISalesOrderCosting } from '../models/Costing';

//axios.defaults.baseURL = 'http://localhost:5000/api';
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
    const token = window.localStorage.getItem('Brisk_JWT');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    Promise.reject(error);
})

axios.interceptors.response.use(undefined, error => {

    if (error.message === 'Network Error' && !error.response) {
        toast.error('Network error - make sure API is running!')
    }
    const { status, data, config, headers } = error.response;

    if (status === 401 && headers['www-authenticate'] === 'Bearer error="invalid_token", error_description="The access token expired"') {
        window.localStorage.removeItem('Brisk_JWT');
        history.push('/');
        toast.info('Your session has expired!, please login again');
    }

    if (status === 404) {
        // history.push('/notfound')
    }
    if (status === 400 && config.method === 'get' && data.errors.hasOwnProperty('id')) {
        history.push('/notfound')
    }
    if (status === 500) {
        toast.error('Server error - check the terminal for more info!')
    }
    throw error.response;
})

const responseBody = (response: AxiosResponse) => response.data;

// const sleep = (ms: number) => (response: AxiosResponse) =>
//     new Promise<AxiosResponse>(resolve => setTimeout(() => resolve(response), ms));

const requests = {
    // get: (url: string) => axios.get(url).then(sleep(1000)).then(responseBody),
    get: (url: string) => axios.get(url).then(responseBody),
    post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
    put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
    del: (url: string) => axios.delete(url).then(responseBody),
    postForm: (url: string, file: Blob) => {
        let formData = new FormData();
        formData.append('File', file);
        return axios.post(url, formData, {
            headers: { 'Content-type': 'multipart/form-data' }
        }).then(responseBody);
    },
    getAsByteStream: (url: string) => axios.get(url, { responseType: 'blob' }).then(responseBody)
};

const user = {
    current: (): Promise<IUser> => requests.get('/user'),
    login: (user: IUserFormValues): Promise<IUser> => requests.post(`/user/login`, user),
    register: (user: IUserFormValues): Promise<IUser> => requests.post(`/user/register`, user)
}

const customer = {
    list: (params: URLSearchParams): Promise<ICustomerEnvelope> => axios.get('/customers', { params: params }).then(responseBody),
    searchCustomers: (searchType: string, searchValue: string): Promise<ILegacyCustomer[]> => requests.get(`customers/filter/${searchType}/${searchValue}`),
    details: (id: string): Promise<ILegacyCustomer> => requests.get(`/customers/${id}`),
    edit: (customer: ILegacyCustomer) => requests.put(`/customers/${customer.yellowpages_id}`, customer),
    create: (customer: ILegacyCustomer): Promise<string> => requests.post(`/customers`, customer),
    delete: (id: number) => requests.del(`/customers/${id}`),
    getSoldToAddresses: (name: string): Promise<string[]> => requests.get(`/customers/soldtoaddress/${name}`),
    getShipToAddresses: (name: string): Promise<string[]> => requests.get(`/customers/shiptoaddress/${name}`),
    addressFilterSearch: (customerAlias: string, searchValue: string, isSoldTo: boolean): Promise<string[]> => requests.get(`/customers/addressFilter/${customerAlias}/${searchValue}/${isSoldTo}`)

}

const supplier = {
    list: (params: URLSearchParams): Promise<ICustomerEnvelope> => axios.get('/suppliers', { params: params }).then(responseBody),
}


const salesorder = {
    // list: (): Promise<ICompactSalesOrder[]> => requests.get('/salesorders'),
    list: (params: URLSearchParams): Promise<ISalesOrderEnvelope> => axios.get('/salesorders', { params: params }).then(responseBody),
    searchSalesOrders: (searchType: string, searchValue: string): Promise<ISalesOrder[]> => requests.get(`salesorders/filter/${searchType}/${searchValue}`),
    details: (id: string): Promise<ILegacySalesOrder> => requests.get(`/salesorders/${id}`),
    soCosting: (id: string): Promise<ISalesOrderCosting> => requests.get(`/costing/salesorder/${id}`),
    detailsGivenOrderNo: (orderno: string): Promise<ILegacySalesOrder> => requests.get(`/salesorders/orderno/${orderno}`),
    edit: (salesorder: ILegacySalesOrder): Promise<ILegacySalesOrder> => requests.put(`/salesorders`, salesorder),
    create: (salesorder: ILegacySalesOrder): Promise<ILegacySalesOrder> => requests.post(`/salesorders`, salesorder),
    refreshFromLegacy: (orderNo: string): Promise<ILegacySalesOrder> => requests.post(`/salesorders/refreshdatafromlegacy/${orderNo}`, {}),
    delete: (id: number) => requests.del(`/salesorders/${id}`),
    dailyreport: (): Promise<ISalesOrderDailyReport[]> => requests.get('salesorders/dailyreport'),
    emaildailyreport: () => requests.get('salesorders/dailyreportsendmail'),
    dailyreportdownload: (): Promise<AxiosResponse<any>> => requests.getAsByteStream('salesorders/dailyreportdownload'),
    searchByCustomerName: (customerName: string): Promise<ILegacySalesOrder> => requests.get(`/salesorders/bycustomer/${customerName}`),
    getSimilarItem: (skipRecords: number, partDesc: string, customerName: string): Promise<ISalesOrderDetail> => requests.get(`/salesorders/similardetail/${skipRecords}/${partDesc}/${customerName}`),
    priceCheckForDia: (customerName: string, partDesc: string, dia: string): Promise<number> => requests.get(`/salesorders/priceCheckForDia/${customerName}/${partDesc}/${dia}`),
    priceCheckForDim: (customerName: string, partDesc: string, width: string, height: string): Promise<number> => requests.get(`/salesorders/priceCheckForDim/${customerName}/${partDesc}/${width}/${height}`),
    lastCustomerDetails: (customerName: string, partNoLike?: string): Promise<ISalesOrderDetail[]> => requests.get(`/salesorders/lastCustomerOrderDetails/${customerName}/${partNoLike}`),
    duplicateCheckForPO: (purchaseOrder: string): Promise<IAcknowledge> => requests.get(`/salesorders/checkForDuplicatePO/${purchaseOrder}`),
}

const orderplanning = {
    list: (): Promise<ISalesOrderDatePlan[]> => requests.get('/orderplanning'),
    getOrderData: (orderno: string): Promise<ISalesOrderDatePlan[]> => requests.get('/orderplanning?orderno=' + orderno),
    sync: () => requests.get('/orderplanning/sync'),
    updateDates: (data: IUpdatedDate) => requests.post('/orderplanning', data),

}

const part = {
    list: (params: URLSearchParams): Promise<IPartEnvelope> => axios.get('/parts', { params: params }).then(responseBody)
}

const pdfcreator = {
    createInvoice: (invoice: string): Promise<any> => requests.getAsByteStream(`/pdfcreator/invoice/${invoice}`),
    createCustomerAcknowlegement: (orderno: string): Promise<any> => requests.getAsByteStream(`/pdfcreator/customeracknowlegement/${orderno}`),
    createPackingList: (orderno: string): Promise<any> => requests.getAsByteStream(`/pdfcreator/packinglist/${orderno}`),
    createWorkOrder: (orderno: string): Promise<any> => requests.getAsByteStream(`/pdfcreator/workorder/${orderno}`),
    createPurchaseOrder: (orderno: string): Promise<any> => requests.getAsByteStream(`/pdfcreator/purchaseorder/${orderno}`),
    createPurchaseOrderSample: (): Promise<any> => requests.getAsByteStream(`/pdfcreator/purchaseorder/20-5410`),
}

const product = {
    list: (): Promise<IProduct[]> => requests.get('/products'),
    summaryList: (): Promise<IProductSummary[]> => requests.get(`products/summary`),
    searchProducts: (searchType: string, searchValue: string): Promise<IProductSummary[]> => requests.get(`products/filter/${searchType}/${searchValue}`),
}

const purchaseorder = {
    list: (params: URLSearchParams): Promise<IPurchaseOrderEnvelope> => axios.get('/purchaseorders', { params: params }).then(responseBody),
    details: (id: string): Promise<ILegacyPurchaseOrder> => requests.get(`/purchaseorders/${id}`),
    edit: (po: ILegacyPurchaseOrder) => requests.put(`/purchaseorders`, po),
    create: (po: ILegacyPurchaseOrder): Promise<ILegacyPurchaseOrder> => requests.post(`/purchaseorders`, po),
    delete: (id: number) => requests.del(`/purchaseorders/${id}`),
    searchBySupplierName: (supplierName: string): Promise<ILegacyPurchaseOrder> => requests.get(`/purchaseorders/bysupplier/${supplierName}`),

}

const scanorders = {
    workorder: (params: URLSearchParams) => axios.get('movements/scanworkorder', { params: params }).then(responseBody),
    material: (params: URLSearchParams) => axios.get('movements/scan', { params: params }).then(responseBody),
    scanmovement: (params: URLSearchParams) => axios.get('movements/scanmovement', { params: params }).then(responseBody),
    scanmovementmanual: (params: URLSearchParams) => axios.get('movements/scanmovement', { params: params }).then(responseBody),
    list: (): Promise<IDocument[]> => requests.get('/movements'),
    documentstatus: (workorderid: string): Promise<IDocumentSummary> => requests.get(`/movements/status?workorderid=${workorderid}`),
    getMovements: (workorderid: string, movementid: number): Promise<IMovement[]> => requests.get(`/movements/details?workorderid=${workorderid}&movementid=${movementid}`),
    searchDocuments: (searchvalue: string): Promise<IDocument[]> => requests.get(`/movements/querydocs?searchvalue=${searchvalue}`),
}

const kiosk = {
    productiondashboard: (): Promise<IProductionDashboard> => axios.get('kiosk/production').then(responseBody),
    deliverydashboard: (): Promise<IDeliveryDashboard> => axios.get('kiosk/delivery').then(responseBody)
}

const taxes = {
    gettaxes: (stateCode: string): Promise<Taxes> => axios.get(`taxes/${stateCode}`, {}).then(responseBody),

}

const rates = {
    getrate: (params: URLSearchParams): Promise<IDamperRateOutput> => axios.get('/rates/damper', { params: params }).then(responseBody)
}
const costing = {
    getrate: (params: URLSearchParams): Promise<ICost> => axios.get('/costing', { params: params }).then(responseBody),
    getCostForSalesOrder: (salesOrderNo: string): Promise<ISalesOrderCostAnalysis> => axios.get(`/costing/analyzesalesorder/${salesOrderNo}`, {}).then(responseBody),
    listquotes: (): Promise<IQuote[]> => axios.get('/costing/quotes', {}).then(responseBody),
    createQuote: (quote: IQuote) => requests.post(`/costing`, quote),
    createRule: (rule: ICostingRule) => requests.post(`/costing/createrule`, rule),
    deleteQuote: (id: number) => requests.del(`/costing/${id}`)
}


const inventory = {
    list: (params: URLSearchParams): Promise<IInventoryEnvelope> => axios.get('/inventory/items', { params: params }).then(responseBody),
    details: (id: string): Promise<IInventoryItem> => requests.get(`/inventory/${id}`),
    searchInventoryCorrect: (searchvalue: string): Promise<IDocument[]> => requests.get(`/movements/querydocs?searchvalue=${searchvalue}`),
    searchInventory: (searchvalue: string): Promise<IInventoryItem[]> => requests.get(`/inventory/items?maxCount=30&offset=0`),
}
const inventorypart = {
    list: (params: URLSearchParams): Promise<IPartEnvelope> => axios.get('/inventoryparts', { params: params }).then(responseBody)
}



const syncdata = {
    customers: (recordcount: number) => axios.post(`datasync/customers/${recordcount}`, {}),
    parts: (recordcount: number) => axios.post(`datasync/parts/${recordcount}`, {}),
    poparts: (recordcount: number) => axios.post(`datasync/poparts/${recordcount}`, {}),
    purchaseorders: (recordcount: number) => axios.post(`datasync/purchaseorders/${recordcount}`, {}),
    salesorders: (recordcount: number) => axios.post(`datasync/salesorders/${recordcount}`, {}),
    salesorder: (id: string) => requests.get(`salesorders/refresh/${id}`),
    refreshunderprocessorders: () => requests.get(`salesorders/refreshunderprocess`),
    refreshordersinpackaging: () => requests.get(`salesorders/refreshpackaging`),
    refreshneworders: () => requests.get(`salesorders/refreshneworders`),
    deletetestsalesorders: () => requests.del(`salesorders/deletetestsalesorders`),

}

export default {
    user,
    customer,
    salesorder,
    purchaseorder,
    product,
    part,
    scanorders,
    kiosk,
    orderplanning,
    pdfcreator,
    syncdata,
    taxes,
    supplier,
    rates,
    costing,
    inventory,
    inventorypart
}

