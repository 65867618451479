import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react'
import { useStore } from '../../../app/stores/Store';

const DocumentShipmentDetails = () => {
    const { salesOrderStore } = useStore();
    const { loadingSalesOrder, currentSalesOrder, updateSalesOrder } = salesOrderStore;
    if (currentSalesOrder === null || loadingSalesOrder) {
        return <Fragment />
    }

    return (
        <Fragment>
            {currentSalesOrder.so_invoice_no ? (
                <div className='documentCustomerContacts'>
                    <div className='dcc_col1 dcc_header'>
                        Sales Rep.
                    </div>
                    <div className='dcc_col2 dcc_header'>
                        Shipping Method
                    </div>
                    <div className='dcc_col3 dcc_header'>
                        Account No
                    </div>
                    <div className='dcc_col1 dcc_value'>
                        <p>{currentSalesOrder.so_order_rep_name}</p>
                    </div>
                    <div className='dcc_col2 dcc_value'>
                        <p>{currentSalesOrder.so_shipping_carrier_name}</p>
                    </div>
                    <div className='dcc_col3 dcc_value'>
                        <p>{currentSalesOrder.so_shipping_account_no}</p>
                    </div>
                </div>

            ) : (
                <div className='documentCustomerContacts'>
                    <div className='dcc_col1 dcc_header'>
                        Sales Rep.
                    </div>
                    <div className='dcc_col2 dcc_header'>
                        Shipping Method
                    </div>
                    <div className='dcc_col3 dcc_header'>
                        Account No
                    </div>
                    <div className='dcc_col1 dcc_value'>
                        <textarea
                            id='textarea'
                            name='so_order_rep_name'
                            value={currentSalesOrder.so_order_rep_name}
                            onChange={(e) => updateSalesOrder(e.target.name, e.target.value)}
                        />
                    </div>
                    <div className='dcc_col2 dcc_value'>
                        <textarea
                            id='textarea'
                            name='so_shipping_carrier_name'
                            value={currentSalesOrder.so_shipping_carrier_name}
                            onChange={(e) => updateSalesOrder(e.target.name, e.target.value)}

                        />
                    </div>
                    <div className='dcc_col3 dcc_value'>
                        <textarea
                            id='textarea'
                            name='so_shipping_account_no'
                            value={currentSalesOrder.so_shipping_account_no}
                            onChange={(e) => updateSalesOrder(e.target.name, e.target.value)}
                        />
                    </div>
                </div>

            )}
        </Fragment>
    )
}

export default observer(DocumentShipmentDetails)
