import { createContext, useContext } from 'react';
import CommonStore from './CommonStore';
import UserStore from './UserStore';
import { ModalStore } from './ModalStore';
import CustomerStore from './CustomerStore';
import SalesOrderStore from './SalesOrderStore';
import ProductStore from './ProductStore';
import SearchStore from './SearchStore';
import { DeleteModalStore } from './DeleteModalStore';
import PartStore from './PartStore';
import POStore from './POStore';
import ScanStore from './ScanStore';
import KioskStore from './KioskStore';
import PlanningStore from './PlanningStore';
import DataSyncStore from './DataSyncStore';
import SupplierStore from './SupplierStore';
import RateStore from './RateStore';
import InventoryStore from './InventoryStore';
import InventoryPartStore from './InventoryPartStore';
import CostingStore from './CostingStore';
import CostQuotesStore from './CostQuotesStore';
import PdfStore from './PdfStore';


interface Store {
    commonStore: CommonStore;
    userStore: UserStore;
    modalStore: ModalStore;
    deleteModalStore: DeleteModalStore;
    customerStore: CustomerStore;
    salesOrderStore: SalesOrderStore;
    productStore: ProductStore;
    searchStore: SearchStore;
    partStore: PartStore;
    poStore: POStore;
    scanStore: ScanStore;
    kioskStore: KioskStore;
    planningStore: PlanningStore;
    dataSyncStore: DataSyncStore;
    supplierStore: SupplierStore;
    rateStore: RateStore;
    costingStore: CostingStore;
    costQuotesStore: CostQuotesStore;
    inventoryStore: InventoryStore;
    inventoryPartStore: InventoryPartStore;
    pdfStore: PdfStore;
}

export const store: Store = {
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    deleteModalStore: new DeleteModalStore(),
    customerStore: new CustomerStore(),
    salesOrderStore: new SalesOrderStore(),
    productStore: new ProductStore(),
    searchStore: new SearchStore(),
    partStore: new PartStore(),
    poStore: new POStore(),
    scanStore: new ScanStore(),
    kioskStore: new KioskStore(),
    planningStore: new PlanningStore(),
    dataSyncStore: new DataSyncStore(),
    supplierStore: new SupplierStore(),
    rateStore: new RateStore(),
    costingStore: new CostingStore(),
    costQuotesStore: new CostQuotesStore(),
    inventoryStore: new InventoryStore(),
    inventoryPartStore: new InventoryPartStore(),
    pdfStore: new PdfStore()
}

export const StoreContext = createContext(store);


export function useStore() {
    return useContext(StoreContext)
}