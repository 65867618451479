import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Button, Grid, Header, Table } from 'semantic-ui-react'
import { useStore } from '../../../app/stores/Store';



const sizes = [
    { text: '5 Inches', value: '5' },
    { text: '6 Inches', value: '6' },
    { text: '7 Inches', value: '7' },
    { text: '8 Inches', value: '8' },
    { text: '10 Inches', value: '10' },
    { text: '12 Inches', value: '12' },
    { text: '14 Inches', value: '14' },
    { text: '16 Inches', value: '16' },
    { text: '20 Inches', value: '20' },
]

const models = [
    { text: 'RCD 300', value: 'RCD300' },
    { text: 'RCD 300S', value: 'RCD300S' },
    { text: 'RCD MD', value: 'RCDMD' }
]

const RCD = () => {
    const { costingStore } = useStore();
    const { gettingRate, setWidth, getRateForRCD, setQuotes } = costingStore;

    const [myModel, setMyModel] = useState('RCD300')
    const [myWidth, setMyWidth] = useState('5')

    const computeRate = async () => {
        setWidth(Number.parseFloat(myWidth));
        let rate = await getRateForRCD(myModel);
        if (rate !== undefined && rate !== null) {
            setQuotes(rate);
        }
    }


    const updateModel = (model: string) => {
        setMyModel(model);
        setMyWidth('5');
    }

    const getSizesByModel = () => {
        if (myModel !== 'RCD300') {
            let acc: { text: string, value: string }[] = [];
            let mysizes = sizes.reduce((accumulator, currentVal) => {
                if (currentVal.value !== '14' && currentVal.value !== '16' && currentVal.value !== '20')
                    accumulator.push(currentVal);
                return accumulator;
            }, acc);
            return mysizes;
        }
        return sizes;
    }


    return (
        <>
            <Header as='h2'>RCD</Header>
            <Grid columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        <Table>
                            <Table.Header>

                                <Table.Row>
                                    <Table.HeaderCell>Model</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={myModel}
                                            onChange={e => updateModel(e.target.value)}
                                        >
                                            {models.map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>Sizes</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <select
                                            value={myWidth}
                                            onChange={e => setMyWidth(e.target.value)}
                                        >
                                            {getSizesByModel().map(o => (
                                                <option key={o.value} value={o.value}>{o.text}</option>
                                            ))}
                                        </select>
                                    </Table.HeaderCell>
                                </Table.Row>

                            </Table.Header>
                        </Table>
                        <Button positive loading={gettingRate} onClick={computeRate} floated='right'>Calculate</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    )
}

export default observer(RCD)