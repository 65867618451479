import React, { useEffect, Fragment } from 'react'
import { Tab } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import POSearchTab from './search/POSearchTab';
import { useStore } from '../../app/stores/Store';

const PODashboard = () => {
    const { poStore, searchStore } = useStore();
    const { loadPurchaseOrders, addTabPane, activeTabIndex, tabPanes, setActiveTabIndex, resetTabs } = poStore;
    //const { addTabPane, activeTabIndex, tabPanes, setActiveTabIndex, resetTabs } = commonStore;
    const { resetSearch } = searchStore;


    useEffect(() => {
        resetTabs();
        resetSearch('suppliername');

        loadPurchaseOrders();
        addTabPane('search', {
            menuItem: 'PO Search',
            render: () => <POSearchTab />
        });


        // addTabPane('20-5410', {
        //     menuItem: '20-5410',
        //     render: () => <PurchaseOrderDocumentView order={'20-5410'} orderid={'532'} tabCount={tabPanes.length} />
        // });
    }, [loadPurchaseOrders, addTabPane, resetTabs, resetSearch])

    return (
        <Fragment>
            <Tab activeIndex={activeTabIndex} panes={tabPanes} onTabChange={(e, data) => { setActiveTabIndex(data.activeIndex) }} />
        </Fragment>
    )
}

export default observer(PODashboard);
