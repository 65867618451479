import { observer } from 'mobx-react-lite';
import React from 'react';
import './pdfstyles.css';

const PdfWriter = () => {
    return (
        <div className='pdfRoot'>
            <div className='pdfHeader'>
                <div className='documentHeading'>
                    square and rectangular insulated access doors,
                    c/w Flange Frame & Double cam locks
                    Model: PAA,PAB
                </div>
                <div className='pdfLogo'>
                    <img alt='' src='/assets/logo.png' />
                </div>
            </div>
            <article>

                <h1 className="h1Class ladyfinger">MODEL : ASR-C & ASR-H</h1>
                <img alt='' style={{ width: '40%' }} src="samples/table1.png" className="square right imgClass" />
                <ul>
                    <li>24 guage or 24 guage flanged frame and double panel
                        galvanized steel door, guage depends on size.</li>
                    <li>Notched knock - over</li>
                    <li>High density, closed cell gasketing bonds, the door to the frame.</li>
                    <li>Model ASR-C & ASR-H are complete with two (2) camlocks four (4) may
                        be requiredon large size doors.</li>
                    <li>Safety retaining chain is available as an option.</li>
                    <li>Doors are complete with continous piano type hinge on one side and one (1) or
                        two(2) camlocks on the opposite side for sizes upto 14".</li>
                    <li>Over 14" same continous hinge on one side, and two (2) camlocks on
                        the other.</li>
                </ul>
                <h4>NOTE:</h4>
                <img alt='' style={{ width: '40%' }} src="samples/sample2.png" className="square left" />
                <ul>
                    <li>Meets SMACNA specifications
                        Stainless steel 316</li>
                    <li>Manufacture any size-upto 44x44</li>
                    <li>Door c/w 1" (25mm) or 2" (50mm) insulation
                        Camlock is available in galvanised
                        steel finish only</li>
                </ul>

            </article>


        </div >
    )
}

export default observer(PdfWriter)