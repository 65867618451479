import React, { Fragment, useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react';

import PartNoTypeAheadPure from '../../../../../app/common/form/PartNoTypeAheadPure';
import { getBlankSalesOrderDetail, ISalesOrderDetail } from '../../../../../app/models/SalesOrder';

const tableRow = {
    border: 2,
    verticalAlign: 'top'
} as React.CSSProperties;


const tdMid = {
    width: '175px',
    border: '1px solid #000',
} as React.CSSProperties;

const tdSmall = {
    width: '10px',
    border: '1px solid #000',
    textAlign: 'center',
    verticalAlign: 'middle'
} as React.CSSProperties;

const tdTextArea = {
    width: '100%',
    resize: 'none',
    overflow: 'hidden',
    border: 0,
    backgroundColor: 'rgb(227, 241, 245)'
} as React.CSSProperties;

const tdBig = {
    width: '300px',
    border: '1px solid #000',
} as React.CSSProperties;

const tdTextAreaCenter = {
    width: '100%',
    textAlign: 'center'
} as React.CSSProperties;

const tableTd = {
    textAlign: 'right',
    border: '1px solid #000',
    background: '#eee',
} as React.CSSProperties

interface IProps {
    currentIndex: number;
    soDetail: ISalesOrderDetail;
    itemCount: number;
    removeSalesOrderDetail: (index: number) => void;
    addSalesOrderDetail: (salesOrderDetail: ISalesOrderDetail) => void;
    editSalesOrderDetail: (index: number, salesOrderDetail: ISalesOrderDetail, propertyName: string) => void;
}
const DocumentItemDetail: React.FC<IProps> = ({ soDetail, itemCount, currentIndex, removeSalesOrderDetail, addSalesOrderDetail, editSalesOrderDetail }) => {
    const [salesOrderDetail, setSalesOrderDetail] = useState<ISalesOrderDetail>(soDetail);

    const [saleprice, setsaleprice] = useState(soDetail.so_item_sale_price.toString());
    const [totalamount, settotalamount] = useState(soDetail.so_item_total_amount.toString());


    useEffect(() => {
        setsaleprice(soDetail.so_item_sale_price.toString())
        settotalamount(soDetail.so_item_total_amount.toString())
    }, [soDetail])

    const handleOnTypeAheadComplete = () => {
        if (currentIndex === (itemCount - 1)) {
            addSalesOrderDetail(getBlankSalesOrderDetail());
        }
    }

    const setData = (index: number, name: string, value: string) => {
        if (index < 0) {
            setSalesOrderDetail({ ...soDetail, [name]: value });
        }
        else {
            if (name === 'so_part_no') {
                salesOrderDetail.so_part_no = value;
            }
            else if (name === 'so_part_description')
                salesOrderDetail.so_part_description = value;
            else if (name === 'so_item_width')
                salesOrderDetail.so_item_width = value;
            else if (name === 'so_item_height')
                salesOrderDetail.so_item_height = value;
            else if (name === 'so_item_dia')
                salesOrderDetail.so_item_dia = value;
            else if (name === 'so_item_qty') {
                //salesOrderDetail.so_item_qty = parseInt(value);
                //salesOrderDetail.so_item_total_amount = salesOrderDetail.so_item_sale_price * salesOrderDetail.so_item_qty;
            }
            else if (name === 'so_item_back_ordered') {
                //salesOrderDetail.so_item_back_ordered = parseInt(value);
            } else if (name === 'so_item_shipped') {
                //salesOrderDetail.so_item_shipped = parseInt(value);
            } else if (name === 'so_item_sale_price') {
                // salesOrderDetail.so_item_sale_price = parseFloat(value);
                // salesOrderDetail.so_item_total_amount = getRoundedValue(salesOrderDetail.so_item_sale_price * salesOrderDetail.so_item_qty);
            }
            else if (name === 'so_item_total_amount') {
                //salesOrderDetail.so_item_total_amount = parseFloat(value);
                //if (salesOrderDetail.so_item_qty !== 0) {
                //    salesOrderDetail.so_item_sale_price = getRoundedValue(salesOrderDetail.so_item_total_amount / salesOrderDetail.so_item_qty);
                //}
            }
            else if (name === 'so_part_remarks')
                salesOrderDetail.so_part_remarks = value;

            editSalesOrderDetail(currentIndex, salesOrderDetail, name);
        }
    }


    return (
        <Fragment>
            <tr style={tableRow}>
                <td rowSpan={2} style={tdSmall}>
                    {currentIndex < (itemCount - 1) ? (
                        <div className="delete-wpr">
                            <div>{currentIndex + 1}</div>
                            <Button
                                color='red'
                                style={{ padding: '5px', margin: 0, marginTop: '5px' }}
                                className="delete"
                                onClick={() => removeSalesOrderDetail(currentIndex)}>X</Button>
                        </div>
                    ) : (
                        <div>{currentIndex + 1}</div>
                    )}
                </td>
                <td style={tdMid}>
                    <PartNoTypeAheadPure index={currentIndex} setData={setData} name="so_part_no" value={soDetail.so_part_no}
                        handleOnTypeAheadComplete={handleOnTypeAheadComplete}
                    />
                </td>
                <td style={tdBig}>
                    <textarea
                        id='textareaFat'
                        style={tdTextArea}
                        name='so_part_description'
                        value={soDetail.so_part_description}
                        onChange={(e) => { editSalesOrderDetail(currentIndex, { ...soDetail, so_part_description: e.target.value }, 'so_part_description') }}
                    />

                </td>
                <td style={tableTd}>
                    <textarea
                        id='textarea'
                        style={tdTextAreaCenter}
                        name='so_item_width'
                        value={soDetail.so_item_width}
                        onChange={(e) => { editSalesOrderDetail(currentIndex, { ...soDetail, so_item_width: e.target.value }, 'so_item_width') }}
                    />
                </td>
                <td style={tableTd}>
                    <textarea
                        id='textarea'
                        style={tdTextAreaCenter}
                        name='so_item_height'
                        value={soDetail.so_item_height}
                        onChange={(e) => { editSalesOrderDetail(currentIndex, { ...soDetail, so_item_height: e.target.value }, 'so_item_height') }}
                    />
                </td>
                <td style={tableTd}>
                    <textarea
                        id='textarea'
                        style={tdTextAreaCenter}
                        name='so_item_dia'
                        value={soDetail.so_item_dia}
                        onChange={(e) => { editSalesOrderDetail(currentIndex, { ...soDetail, so_item_dia: e.target.value }, 'so_item_dia') }}
                    />
                </td>
                <td style={tableTd}>
                    <textarea
                        id='textarea'
                        style={tdTextAreaCenter}
                        name='so_item_qty'
                        value={soDetail.so_item_qty}
                        onChange={(e) => {
                            try {
                                if (e.target.value) {
                                    editSalesOrderDetail(currentIndex, { ...soDetail, so_item_qty: parseInt(e.target.value) || 0 }, 'so_item_qty')
                                } else {
                                    if (e.target.value.length === 0)
                                        editSalesOrderDetail(currentIndex, { ...soDetail, so_item_qty: 0 }, 'so_item_qty')
                                }
                            } catch (error) {
                                editSalesOrderDetail(currentIndex, { ...soDetail, so_item_qty: 0 }, 'so_item_qty')
                            }
                        }}
                    />
                </td>
                <td style={tableTd}>
                    <textarea
                        id='textarea'
                        style={tdTextAreaCenter}
                        name='so_item_sale_price'
                        value={saleprice}
                        onBlur={(e) => {
                            try {
                                console.log(e.target.value);
                                if (e.target.value) {
                                    editSalesOrderDetail(currentIndex, { ...soDetail, so_item_sale_price: parseFloat(e.target.value) || 0 }, 'so_item_sale_price')
                                } else {
                                    if (e.target.value.length === 0)
                                        editSalesOrderDetail(currentIndex, { ...soDetail, so_item_sale_price: 0 }, 'so_item_sale_price')
                                }
                            } catch (error) {
                                editSalesOrderDetail(currentIndex, { ...soDetail, so_item_sale_price: 0 }, 'so_item_sale_price')
                            }
                        }}
                        onChange={(e) => { setsaleprice(e.target.value); }}
                    />
                </td>
                <td style={tableTd}>
                    <textarea
                        id='textarea'
                        style={tdTextAreaCenter}
                        name='so_item_total_amount'
                        value={totalamount}
                        onBlur={(e) => {
                            try {
                                if (e.target.value) {
                                    editSalesOrderDetail(currentIndex, { ...soDetail, so_item_total_amount: parseFloat(e.target.value) || 0 }, 'so_item_total_amount')
                                } else {
                                    if (e.target.value.length === 0)
                                        editSalesOrderDetail(currentIndex, { ...soDetail, so_item_total_amount: 0 }, 'so_item_total_amount')
                                }
                            } catch (error) {
                                editSalesOrderDetail(currentIndex, { ...soDetail, so_item_total_amount: 0 }, 'so_item_total_amount')
                            }

                        }}
                        onChange={(e) => { settotalamount(e.target.value); }}
                    />
                </td>
            </tr>
            <tr style={tableRow}>
                <td style={{ ...tableTd, textAlign: 'right', border: 'none' }} >
                    <b>Part Remarks:</b>
                </td>
                <td colSpan={7} style={tableTd}>
                    <textarea
                        id='textarea'
                        style={tdTextArea}
                        name='so_part_remarks'
                        value={soDetail.so_part_remarks}
                        onChange={(e) => { editSalesOrderDetail(currentIndex, { ...soDetail, so_part_remarks: e.target.value }, 'so_part_remarks') }}
                    />
                </td>
            </tr>
        </Fragment>
    )
}

export default DocumentItemDetail
