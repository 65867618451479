import { observer } from 'mobx-react-lite'
import React from 'react'
import { useStore } from '../../app/stores/Store';
import PODashboard from '../purchaseorders/PODashboard';
import "./purchaseorder.css";
import PurchaseOrderGrid from './PurchaseOrderGrid';

const PurchaseOrderHome = () => {
    const { commonStore } = useStore();
    const { purchaseOrderTabularView } = commonStore;


    return (
        <div style={{ marginTop: '7rem' }}>
            {purchaseOrderTabularView ? (
                <PODashboard />
            ) : (
                <PurchaseOrderGrid />
            )}
        </div>
    )
}

export default observer(PurchaseOrderHome)
