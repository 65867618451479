import { formatDistance } from 'date-fns'
import { observer } from 'mobx-react-lite'
import React, { Fragment, useEffect } from 'react'
import { Table } from 'semantic-ui-react'
import { ISalesOrderDailyReport } from '../../app/models/SalesOrder'
import { useStore } from '../../app/stores/Store'

const DailyReportKiosk = () => {
    const { kioskStore } = useStore();
    const { dailySalesOrders, loadDailSalesOrderReport } = kioskStore;


    useEffect(() => {
        loadDailSalesOrderReport();
        const timeInMinutes = 60 * 3;
        const oneMinute = 60000;
        const interval = setInterval(() => loadDailSalesOrderReport(), oneMinute * timeInMinutes);
        return () => {
            clearInterval(interval);
        };
    }, [loadDailSalesOrderReport])

    return (
        <Fragment>
            <Table fixed celled selectable compact size='large' striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell style={{ width: '10%' }}>Order No</Table.HeaderCell>
                        <Table.HeaderCell>Order Date</Table.HeaderCell>
                        <Table.HeaderCell>Ship Date</Table.HeaderCell>
                        <Table.HeaderCell>Customer Name</Table.HeaderCell>
                        <Table.HeaderCell>Delay</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        dailySalesOrders.map((salesorder: ISalesOrderDailyReport) => (
                            new Date(salesorder.so_ship_date.split('T')[0]) < new Date() ?
                                (
                                    <Table.Row key={salesorder.sales_order_master_id} negative>
                                        <Table.Cell>{salesorder.so_order_no}</Table.Cell>
                                        <Table.Cell>{salesorder.so_order_date.split('T')[0]}</Table.Cell>
                                        <Table.Cell>{salesorder.so_ship_date.split('T')[0]}</Table.Cell>
                                        <Table.Cell>{salesorder.so_customer_name}</Table.Cell>
                                        <Table.Cell>
                                            {formatDistance(Date.parse(salesorder.so_ship_date.split('T')[0]), new Date())} Ago
                                    </Table.Cell>
                                    </Table.Row>
                                )
                                :
                                <Table.Row key={salesorder.sales_order_master_id} positive>
                                    <Table.Cell>{salesorder.so_order_no}</Table.Cell>
                                    <Table.Cell>{salesorder.so_order_date.split('T')[0]}</Table.Cell>
                                    <Table.Cell>{salesorder.so_ship_date.split('T')[0]}</Table.Cell>
                                    <Table.Cell>{salesorder.so_customer_name}</Table.Cell>
                                    <Table.Cell>
                                        {formatDistance(Date.parse(salesorder.so_ship_date.split('T')[0]), new Date())} Ahead
                            </Table.Cell>
                                </Table.Row>
                        ))
                    }
                </Table.Body>
            </Table>
        </Fragment >
    )
}

export default observer(DailyReportKiosk)
