import { observer } from 'mobx-react-lite'
import React, { Fragment, useEffect } from 'react'
import { useStore } from '../../app/stores/Store';
import PurchaseOrderSearch from './dashboard/search/PurchaseOrderSearch';
import "./purchaseorder.css";

const PurchaseOrderGrid = () => {
    const { poStore, searchStore } = useStore();
    const { loadPurchaseOrders } = poStore;
    const { resetSearch } = searchStore;

    useEffect(() => {
        resetSearch('customerAlias');
        loadPurchaseOrders();
    }, [loadPurchaseOrders, resetSearch])


    return (
        <Fragment>
            <PurchaseOrderSearch />
        </Fragment>
    )
}

export default observer(PurchaseOrderGrid)
