import React from 'react';
import {
    Document,
    Page,
    Text,
    View,
    Image,
    StyleSheet
} from '@react-pdf/renderer';
import { useStore } from '../../../app/stores/Store';

// Create styles
const styles = StyleSheet.create({
    title: {
        fontSize: 24,
        textAlign: 'center',
    },
    pageHeading: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    image: {
        padding: '15px',
        width: '150px'
    },
    documentHeading: {
        padding: '15px',
        marginRight: '20px',
        fontSize: 20,
        fontWeight: 'bold'
    },
    table: {
        padding: 15,
        width: '100%',
        marginTop: 20,
        marginBottom: 8,
    },
    break: {
        flexBasis: 100,
        height: 0
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        borderTop: '2px solid #EEE',
        border: 1
    },
    header: {
        borderTop: 'none',
        marginTop: '10px'
    },
    heading: {
        padding: '5px',
        fontWeight: 'bold',
        fontSize: 16
    },
    bold: {
        fontWeight: 'bold',
    },
    smallText: {
        fontSize: 10,
        fontStyle: 'italic',
    },
    col1: {
        fontSize: 12,
        width: '5%',
        textAlign: 'center',
        borderLeft: 1,
        padding: '2px'
    },
    col2: {
        fontSize: 12,
        width: '30%',
        textAlign: 'center',
        borderLeft: 1,
        padding: '2px'

    },
    col3: {
        fontSize: 12,
        width: '10%',
        textAlign: 'center',
        borderLeft: 1,
        padding: '2px'

    },
    col4: {
        fontSize: 12,
        width: '10%',
        textAlign: 'center',
        borderLeft: 1,
        padding: '2px'

    },
    col5: {
        fontSize: 12,
        width: '10%',
        textAlign: 'center',
        borderLeft: 1,
        padding: '2px'

    },
    col6: {
        fontSize: 12,
        width: '10%',
        textAlign: 'center',
        borderLeft: 1,
        padding: '2px'

    },
    col7: {
        fontSize: 12,
        width: '10%',
        textAlign: 'center',
        borderLeft: 1,
        padding: '2px'

    },
    col8: {
        fontSize: 12,
        width: '15%',
        textAlign: 'center',
        borderLeft: 1,
        borderRight: 1,
        padding: '2px'
    }
})
const PdfDocument = () => {
    const { costingStore } = useStore();
    const { quotes } = costingStore;

    const GetRounded = (value: number) => {
        return Math.round((value + Number.EPSILON) * 100) / 100;
    }

    const GetQuantity = (index: number) => {
        const damper = quotes[index];
        if (damper.quantity !== '') {
            return Number.parseInt(damper.quantity);
        } else {
            return 0;
        }
    }
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.pageHeading}>
                    <Image
                        style={styles.image}
                        src="/assets/logo.png"
                    />
                    <Text style={styles.documentHeading}>Quotes</Text>
                </View>
                <View style={styles.table}>
                    <View style={[styles.row, styles.bold, styles.header]}>
                        <Text style={[styles.col1, styles.heading]}>Id</Text>
                        <Text style={[styles.col2, styles.heading]}>Product</Text>
                        <Text style={[styles.col3, styles.heading]}>Mat</Text>
                        <Text style={[styles.col4, styles.heading]}>Lab</Text>
                        <Text style={[styles.col5, styles.heading]}>OH</Text>
                        <Text style={[styles.col6, styles.heading]}>Cost</Text>
                        <Text style={[styles.col7, styles.heading]}>Qty</Text>
                        <Text style={[styles.col8, styles.heading]}>Total</Text>
                    </View>
                    {quotes.map((myquote, i) => (
                        <View key={i} style={styles.row} wrap={false}>
                            <Text style={styles.col1}>
                                <Text style={styles.bold}>{i + 1}</Text>
                            </Text>
                            <Text style={styles.col2}>
                                <Text style={styles.bold}>{myquote.selectedProduct}</Text>{"\n"}
                                <Text style={styles.smallText}>{myquote.width} x {myquote.height}</Text>
                            </Text>
                            <Text style={styles.col3}>
                                <Text style={styles.bold}>{GetRounded(myquote.totalMaterialCost)}</Text>{"\n"}
                                <Text style={styles.smallText}>{myquote.totalMaterialPercentage}%</Text>
                            </Text>
                            <Text style={styles.col4}>
                                <Text style={styles.bold}>{GetRounded(myquote.totalLaborCost)}</Text> {"\n"}
                                <Text style={styles.smallText}>{myquote.totalLaborPercentage}%</Text>
                            </Text>
                            <Text style={styles.col5}>
                                <Text style={styles.bold}>{GetRounded(myquote.totalOverheads)}</Text>{"\n"}
                                <Text style={styles.smallText}>{myquote.totalOverheadPercentage}%</Text>
                            </Text>
                            <Text style={styles.col6}>$ {GetRounded(myquote.totalCost)}</Text>
                            <Text style={styles.col7}>{GetQuantity(i)}</Text>
                            <Text style={styles.col8}>$ {GetRounded(myquote.totalCost) * GetQuantity(i)}</Text>
                        </View>

                    ))}
                </View>
            </Page>
        </Document>
    )
}

export default PdfDocument