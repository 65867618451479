import React from 'react'

const DocumentFooter = () => {
    return (
        <div id="terms">
            <br />
            <h5>Terms</h5>
                Please reference the invoice number on your check
        </div>
    )
}

export default DocumentFooter
