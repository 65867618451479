import React, { Fragment } from "react"
import { Grid, Placeholder, Segment } from "semantic-ui-react"

const PurchaseOrderListPlaceholder = () => {
    return (
        <Fragment>
            <Placeholder fluid style={{ marginTop: 50 }}>
                <Grid>
                    <Grid.Column width={4}>
                        <Segment.Group>
                            <Segment style={{ minHeight: 150 }}>
                                <Placeholder>
                                    <Placeholder.Header image>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Header>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            </Segment>
                            <Segment style={{ minHeight: 10 }}>
                                <Placeholder>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder>
                            </Segment>
                            <Segment secondary style={{ minHeight: 70 }}>
                                <Placeholder>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder>
                            </Segment>
                        </Segment.Group>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Segment.Group>
                            <Segment style={{ minHeight: 150 }}>
                                <Placeholder>
                                    <Placeholder.Header image>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Header>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            </Segment>
                            <Segment style={{ minHeight: 10 }}>
                                <Placeholder>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder>
                            </Segment>
                            <Segment secondary style={{ minHeight: 70 }}>
                                <Placeholder>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder>
                            </Segment>
                        </Segment.Group>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Segment.Group>
                            <Segment style={{ minHeight: 150 }}>
                                <Placeholder>
                                    <Placeholder.Header image>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Header>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            </Segment>
                            <Segment style={{ minHeight: 10 }}>
                                <Placeholder>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder>
                            </Segment>
                            <Segment secondary style={{ minHeight: 70 }}>
                                <Placeholder>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder>
                            </Segment>
                        </Segment.Group>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Segment.Group>
                            <Segment style={{ minHeight: 150 }}>
                                <Placeholder>
                                    <Placeholder.Header image>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Header>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            </Segment>
                            <Segment style={{ minHeight: 10 }}>
                                <Placeholder>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder>
                            </Segment>
                            <Segment secondary style={{ minHeight: 70 }}>
                                <Placeholder>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder>
                            </Segment>
                        </Segment.Group>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Segment.Group>
                            <Segment style={{ minHeight: 150 }}>
                                <Placeholder>
                                    <Placeholder.Header image>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Header>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            </Segment>
                            <Segment style={{ minHeight: 10 }}>
                                <Placeholder>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder>
                            </Segment>
                            <Segment secondary style={{ minHeight: 70 }}>
                                <Placeholder>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder>
                            </Segment>
                        </Segment.Group>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Segment.Group>
                            <Segment style={{ minHeight: 150 }}>
                                <Placeholder>
                                    <Placeholder.Header image>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Header>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            </Segment>
                            <Segment style={{ minHeight: 10 }}>
                                <Placeholder>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder>
                            </Segment>
                            <Segment secondary style={{ minHeight: 70 }}>
                                <Placeholder>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder>
                            </Segment>
                        </Segment.Group>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Segment.Group>
                            <Segment style={{ minHeight: 150 }}>
                                <Placeholder>
                                    <Placeholder.Header image>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Header>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            </Segment>
                            <Segment style={{ minHeight: 10 }}>
                                <Placeholder>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder>
                            </Segment>
                            <Segment secondary style={{ minHeight: 70 }}>
                                <Placeholder>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder>
                            </Segment>
                        </Segment.Group>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Segment.Group>
                            <Segment style={{ minHeight: 150 }}>
                                <Placeholder>
                                    <Placeholder.Header image>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Header>
                                    <Placeholder.Paragraph>
                                        <Placeholder.Line />
                                        <Placeholder.Line />
                                    </Placeholder.Paragraph>
                                </Placeholder>
                            </Segment>
                            <Segment style={{ minHeight: 10 }}>
                                <Placeholder>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder>
                            </Segment>
                            <Segment secondary style={{ minHeight: 70 }}>
                                <Placeholder>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder>
                            </Segment>
                        </Segment.Group>
                    </Grid.Column>
                </Grid >

            </Placeholder>
        </Fragment>
    )
}

export default PurchaseOrderListPlaceholder
