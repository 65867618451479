import React, { Fragment, useState } from 'react'
import { Menu, Input, Select, Button, Icon, Header, Radio } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/Store';

const options = [
    { key: 'suppliername', text: 'Supplier Name', value: 'suppliername' },
    { key: 'ordernumber', text: 'Order Number', value: 'ordernumber' },
]

const SearchPurchaseOrdersForm = () => {
    const { poStore, commonStore } = useStore();
    const { searchPurchaseOrders, loadingSearch } = poStore;
    //const { searchType, searchValue, setSearchType, setSearchValue } = searchStore;
    const { purchaseOrderTabularView, setPOView } = commonStore;

    const [searchValue, setSearchValue] = useState('');
    const [searchType, setSearchType] = useState<any>('ordernumber');


    const toggleSearchOrderFilter = () => {

        setPOView(!purchaseOrderTabularView);

        // let newState = !shorGrid;

    }

    const onSelectChange = (value: any) => {
        setSearchType(value);
    }

    const onSearchPurchaseOrders = () => {
        searchPurchaseOrders(searchType, searchValue);
    }
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            searchPurchaseOrders(searchType, searchValue);
        }
    }

    return (

        <Fragment>
            <Menu size={'large'} style={{ width: '100%', marginTop: 50 }}>
                <Header></Header>
                <Menu.Item style={{ width: '80%' }}>
                    <Input type='text'
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onKeyDown={handleKeyDown}
                        style={{ width: '70%' }}
                        placeholder='Search...' action>
                        <input />
                        <Select compact options={options} value={searchType} onChange={(e, data) => { onSelectChange(data.value) }} />
                        <Button icon color='blue' onClick={onSearchPurchaseOrders} loading={loadingSearch}>
                            <Icon name='search' />
                        </Button>
                    </Input>
                </Menu.Item>
                <div className='resultOptions__toggle' style={{ marginTop: '10px', marginLeft: '10px' }}>
                    {purchaseOrderTabularView ? (
                        <div className='resultOptions__toggle_heading toggle_heading_all'>View: Grid</div>
                    ) : (
                        <div className='resultOptions__toggle_heading'>View: Tabular</div>
                    )}

                    <Radio toggle checked={purchaseOrderTabularView} onChange={() => toggleSearchOrderFilter()} />
                </div>
            </Menu>
        </Fragment>
    )
}

export default observer(SearchPurchaseOrdersForm)
