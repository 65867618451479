import React from 'react'

const ProductionPlanningPlaceholder = () => {
    return (
        <div>
            Production Planning UI here....
        </div>
    )
}

export default ProductionPlanningPlaceholder
