import React, { useEffect } from 'react';
import { Tab } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite';
import CustomerForm from '../../CustomerForm';
import { useStore } from '../../../../app/stores/Store';

interface IProps {
    customerid: string,
    alias: string,
    tabCount: number
}

const CustomerTabPane: React.FC<IProps> = ({ customerid, alias, tabCount }) => {
    const { commonStore, customerStore } = useStore();
    const { removeTabPane } = commonStore;
    const { loadCustomer, selectedCustomer, loadingCustomer } = customerStore;

    useEffect(() => {
        loadCustomer(customerid);
    }, [loadCustomer, customerid])

    const handleOnCancel = () => {
        removeTabPane(alias);
    }

    return (
        <Tab.Pane attached={true} key={customerid} loading={loadingCustomer}>
            <CustomerForm intialValue={selectedCustomer} onCancel={handleOnCancel} />
        </Tab.Pane >

    )
}


export default observer(CustomerTabPane)
