import { observer } from 'mobx-react-lite'
import React, { Fragment, useEffect } from 'react'
import { Tab } from 'semantic-ui-react';
import { useStore } from '../../app/stores/Store';
import InventoryHomeTab from './InventoryHomeTab';

const InventoryDashboard = () => {
    const { inventoryStore, searchStore } = useStore();
    const { loadInventory, addTabPane, activeTabIndex, tabPanes, setActiveTabIndex, resetTabs } = inventoryStore;
    const { resetSearch } = searchStore;

    useEffect(() => {
        resetTabs();
        resetSearch('suppliername');

        loadInventory();
        addTabPane('search', {
            menuItem: 'Inventory Data',
            render: () => <InventoryHomeTab />
        });
    }, [loadInventory, addTabPane, resetTabs, resetSearch])

    return (
        <Fragment>
            <Tab activeIndex={activeTabIndex} panes={tabPanes} onTabChange={(e, data) => { setActiveTabIndex(data.activeIndex) }} />
        </Fragment>
    )
}

export default observer(InventoryDashboard)
