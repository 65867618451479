import React from 'react'
import { Container, Grid } from 'semantic-ui-react'
import ScanInputForm from './ScanInputForm'

const ScanDashboard = () => {
    return (
        <Container>
            <Grid fluid='true' padded>
                <Grid.Row />
                <Grid.Row />
                <Grid.Row />
                <Grid.Row />
                <Grid.Row />
                <Grid.Row />
                <Grid.Row />
                <Grid.Row />
                <Grid.Row>
                    <Grid.Column width={16}>
                        <ScanInputForm />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    )
}

export default ScanDashboard
