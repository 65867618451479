import React from 'react'
import { Icon, Item, Label, Segment } from 'semantic-ui-react'
import { ICompactSalesOrder } from '../../../../app/models/SalesOrder'
import { history } from '../../../..';


const SalesOrderListItem: React.FC<{ salesorder: ICompactSalesOrder }> = ({ salesorder }) => {


    const getDateOnly = (datetime: Date | null) => {
        if (!datetime) return ' ? ';

        if (datetime.toString().startsWith('000'))
            return ' ? ';
        else
            return datetime.toString().split('T')[0];
    }

    const salesOrderDetails = (id: string) => {
        history.push(`/salesorder/${id}`);
    }

    const isGreenColored = (status: string) => {

        return status !== "Under Process" &&
            status !== "Cancelled" &&
            status !== "Packing Slip";
    }

    return (
        <Segment.Group className="mygridcell" onClick={() => salesOrderDetails(salesorder.so_order_no)}>
            <Segment color={'red'}>
                <Item.Group>
                    <Item >
                        <Item.Content className="gridheader">
                            <Item.Header><Label circular color='green' size='mini' /> &nbsp;&nbsp;
                                Order :<b>{salesorder.so_order_no}</b>
                            </Item.Header>
                            <Item.Description>
                                {salesorder.so_order_status === "Under Process" && <Label size='large' color='orange' basic content={salesorder.so_order_status} />}
                                {salesorder.so_order_status === "Cancelled" && <Label size='large' color='red' basic content={salesorder.so_order_status} />}
                                {salesorder.so_order_status === "Packing Slip" && <Label size='large' color='blue' basic content={salesorder.so_order_status} />}
                                {isGreenColored(salesorder.so_order_status) && <Label size='large' color='green' basic content={salesorder.so_order_status} />}
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
                <Item.Group>
                    <Item className="gridheader">
                        <Item.Header>&nbsp;
                        </Item.Header>
                        <Item.Description>
                            {salesorder.sales_agent !== null && salesorder.sales_agent !== '' && (
                                <Label color='brown'>
                                    <Icon name='male' />{salesorder.sales_agent}
                                </Label>)}

                            <Label>
                                <Icon name='dollar sign' />{salesorder.so_net_amount}
                            </Label>
                        </Item.Description>
                    </Item>
                </Item.Group>
            </Segment>
            <Segment color={'red'}>
                <Item.Group>
                    <Item>
                        <Item.Content>
                            <Item.Header as='a'>
                                <div><u>{salesorder.so_customer_name}</u></div>
                            </Item.Header>

                            <Item.Description>Last Update : <b>{getDateOnly(salesorder.so_updated_on)}</b></Item.Description>
                            <Item.Description>Shipping Date : <Icon name='clock' /><b>{getDateOnly(salesorder.so_ship_date)}</b>  / <i>Recipient ({salesorder.so_order_recipient})</i></Item.Description>
                            <Item.Description>Purchase Order : {salesorder.so_purchase_order_no}</Item.Description>
                            <Item.Description>
                                <b><u>Address :</u></b>
                            </Item.Description>
                            <Item.Description>
                                <textarea className="mytextarea" readOnly={true} cols={30} rows={6} defaultValue={salesorder.so_shipping_address} />
                            </Item.Description>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>
            <Segment secondary clearing>
                Delivery Type: <div>{salesorder.so_shipping_carrier_name}</div>
            </Segment>

        </Segment.Group>

    )
}

export default SalesOrderListItem
