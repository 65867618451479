import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react'
import DocumentHeaderAddressAndDetails from './DocumentHeaderAddressAndDetails';
import DocumentCustomerContacts from './DocumentCustomerContacts';
import DocumentFooter from './DocumentFooter';
import './documentStyle.css';
import DocumentHeaderLogo from './DocumentHeaderLogo';
import DocumentItemDetails from './DocumentItemDetails';
import DocumentShipmentDetails from './DocumentShipmentDetails';
import DocumentSoldToShipTo from './DocumentSoldToShipTo';
import { Form as FinalForm } from 'react-final-form';
import { getBlankSalesOrder, getBlankSalesOrderDetail, ILegacySalesOrder, ISalesOrderDetail } from '../../../../../app/models/SalesOrder';
import LoadingComponent from '../../../../../app/layout/LoadingComponent';
import { Button, Grid, Header, Icon, Label, Tab, Image } from 'semantic-ui-react';
import NotImplemented from '../../../../../app/common/form/NotImplemented';
import { saveAs } from 'file-saver';
import DocumentItemDetailsReadOnly from './DocumentItemDetailsReadOnly';
import { useStore } from '../../../../../app/stores/Store';


const docstyle = {
    width: '1000px',
    margin: '0 auto',
    //  float: 'left',
    marginLeft: '100px',
    border: '2px solid #1b972d',
    borderCollapse: 'collapse',
    borderRadius: '15px',
    padding: '30px',
    backgroundColor: '#fff'
} as React.CSSProperties




interface IProps {
    orderid: string,
    order: string,
    tabCount: number
}

const SalesOrderDocumentView: React.FC<IProps> = ({ orderid, tabCount }) => {

    const { salesOrderStore, modalStore } = useStore();
    const { loadSalesOrder, removeTabPane, loadingSalesOrder,
        generateInvoice, generateCustomerAcknowlegement,
        generatePackingList, generateWorkOrder, editSalesOrder } = salesOrderStore;
    const { openModal } = modalStore;


    const [loadingSOData, setLoadingSOData] = useState(true);
    const [salesorder, setsalesorder] = useState<ILegacySalesOrder>(getBlankSalesOrder());
    const [salesorderdetails, setSalesOrderDetails] = useState<ISalesOrderDetail[]>([]);

    //const [forceDirty, setForceDirty] = useState(false);
    const [newSOItemCount, setNewSOItemCount] = useState(0);
    const [runningTotal, setRunningTotal] = useState(0);



    const sanitizeDates = (sorder: ILegacySalesOrder) => {
        if (sorder) {
            sorder.so_order_date = sorder.so_order_date ? new Date(sorder.so_order_date) : null;
            sorder.so_invoice_date = sorder.so_invoice_date ? new Date(sorder.so_invoice_date) : null;
            sorder.so_ship_date = sorder.so_ship_date ? new Date(sorder.so_ship_date) : null;
            sorder.so_pack_slip_date = sorder.so_pack_slip_date ? new Date(sorder.so_pack_slip_date) : null;
        }
    }

    useEffect(() => {
        setLoadingSOData(true);
        loadSalesOrder(orderid).then((sorder) => {

            if (sorder) {
                sanitizeDates(sorder);
            }
            if (sorder !== undefined) {
                if (sorder?.salesOrderDetails === undefined || sorder?.salesOrderDetails === null)
                    sorder.salesOrderDetails = [];
                setSalesOrderDetails([...sorder.salesOrderDetails, getBlankSalesOrderDetail()]);
                let total = 0;
                sorder.salesOrderDetails.forEach((so: ISalesOrderDetail) => {
                    total += so.so_item_total_amount;
                });
                setRunningTotal(getRoundedValue(total));
                setsalesorder({ ...getBlankSalesOrder(), ...sorder });
                setLoadingSOData(false);
            }
            setLoadingSOData(false);

        });
    }, [loadSalesOrder, tabCount, orderid])



    const removeSalesOrderDetail = (index: number) => {
        //    setForceDirty(true);
        const sodetails = salesorderdetails.filter((salesOrder, i) => {
            return index !== i;
        });

        setSalesOrderDetails([...sodetails]);
        computeTotals(sodetails);
    }

    const editLoadedSalesOrder = (name: string, value: string) => {
        //       setForceDirty(true);
        setsalesorder({ ...salesorder, [name]: value });
        console.log(salesorder);
    }

    const editSalesOrderTaxes = (newSalesOrder: ILegacySalesOrder) => {
        //       setForceDirty(true);
        console.log('updating', newSalesOrder.so_hst_amount);

        setsalesorder({ ...salesorder, ...newSalesOrder });
    }

    const editSalesOrderDetail = (index: number, salesOrderDetail: ISalesOrderDetail, name: string) => {
        //      setForceDirty(true);
        sanitizeTotalForOrder(salesOrderDetail, name);
        salesorderdetails[index] = { ...salesorderdetails[index], ...salesOrderDetail };
        setSalesOrderDetails([...salesorderdetails]);

        computeTotals(salesorderdetails);
    }

    const computeTotals = (soDetails: ISalesOrderDetail[]) => {
        let total = 0;
        soDetails.forEach((so: ISalesOrderDetail) => {
            total += so.so_item_total_amount;
        });



        setRunningTotal(getRoundedValue(total));
    }
    const sanitizeTotalForOrder = (salesOrderDetail: ISalesOrderDetail, name: string) => {
        if (name === 'so_item_qty') {
            salesOrderDetail.so_item_total_amount = getRoundedValue(salesOrderDetail.so_item_sale_price * salesOrderDetail.so_item_qty);
        }
        else if (name === 'so_item_sale_price') {

            salesOrderDetail.so_item_total_amount = getRoundedValue(salesOrderDetail.so_item_sale_price * salesOrderDetail.so_item_qty);
        }
        else if (name === 'so_item_total_amount') {
            if (salesOrderDetail.so_item_qty > 0) {
                salesOrderDetail.so_item_sale_price = getRoundedValue(salesOrderDetail.so_item_total_amount / salesOrderDetail.so_item_qty);
            }
        }
    }
    const getRoundedValue = (value: number) => {
        return Math.round(value * 100) / 100;
    }


    const addSalesOrderDetail = (salesOrderDetail: ISalesOrderDetail) => {
        //      setForceDirty(true);

        if (salesOrderDetail.sales_order_master_id === 0) {
            salesOrderDetail.sales_order_master_id = salesorder.sales_order_master_id;
            let newCount = newSOItemCount + 1;
            setNewSOItemCount(newCount);
            salesOrderDetail.sales_order_detail_id = newCount * -1;
        }
        salesorderdetails.push(salesOrderDetail);

        setSalesOrderDetails([...salesorderdetails]);
    }
    const removeAllSalesOrderDetails = () => {
        //      setForceDirty(true);
        setSalesOrderDetails([]);
        salesorder.salesOrderDetails = [];
    }

    let saveChangesToDb = false;
    const handleFinalFormSubmit = (values: any) => {
        if (!saveChangesToDb) return;


        const salesOrder: ILegacySalesOrder = values;

        salesOrder.so_shipping_address = salesorder.so_shipping_address;
        salesOrder.so_billing_address = salesorder.so_billing_address;
        salesOrder.so_sold_address = salesorder.so_sold_address;

        let cleanSalesOrderDetails: ISalesOrderDetail[] = [];
        let totalNetAmount = 0;
        salesorderdetails.forEach(salesorderdetail => {
            if (salesorderdetail.so_part_no !== "" && salesorderdetail.so_part_description !== "") {
                cleanSalesOrderDetails.push(salesorderdetail);
                totalNetAmount = totalNetAmount + salesorderdetail.so_item_total_amount;
            }
        });


        salesOrder.salesOrderDetails = cleanSalesOrderDetails;
        salesorder.so_net_amount = parseFloat(getRoundedValue(runningTotal).toString());
        salesorder.so_hst_amount = parseFloat(getRoundedValue(salesorder.so_hst_amount).toString());
        salesorder.so_gst_amount = parseFloat(getRoundedValue(salesorder.so_gst_amount).toString());
        salesorder.so_pst_amount = parseFloat(getRoundedValue(salesorder.so_pst_amount).toString());
        salesorder.so_qst_amount = parseFloat(getRoundedValue(salesorder.so_qst_amount).toString());
        salesorder.so_net_amount = getRoundedValue(salesorder.so_net_amount
            + salesorder.so_hst_amount
            + salesorder.so_gst_amount
            + salesorder.so_pst_amount
            + salesorder.so_qst_amount);

        editSalesOrder(salesOrder).then(savedsalesorder => {
            if (savedsalesorder) {
                sanitizeDates(savedsalesorder);
                setsalesorder(savedsalesorder);
            }
        })

    }

    const CreateInvoice = () => {
        generateInvoice(salesorder.so_invoice_no).then((blob) => {
            saveAs(blob, `Invoice_${salesorder.so_invoice_no}.pdf`);
        });
    }

    const CreateCustomerAcknowledgement = () => {
        generateCustomerAcknowlegement(salesorder.so_order_no).then((blob) => {
            saveAs(blob, `OrderAck_${salesorder.so_order_no}.pdf`);
        });
    }

    const CreatePackingList = () => {
        generatePackingList(salesorder.so_order_no).then((blob) => {
            saveAs(blob, `Pkg_${salesorder.so_order_no}.pdf`);
        });
    }

    const CreateWorkOrder = () => {
        generateWorkOrder(salesorder.so_order_no).then((blob) => {
            saveAs(blob, `Wrk_${salesorder.so_order_no}.pdf`);
        });
    }

    const shouldDisableButton = (changedStatus: string) => {
        if (salesorder.so_order_status === "On Hold")
            return true;
        return false;
    }

    const disablePackingSlipButton = (changedStatus: string) => {
        if (salesorder.so_order_status === "On Hold")
            return true;
        if (salesorder.so_order_status === "Invoiced" || changedStatus === "Invoiced")
            return false;
        if (salesorder.so_order_status === "Packing Slip" || changedStatus === "Packing Slip")
            return false;
        return true;
    }

    const disableInvoiceButton = (changedStatus: string) => {
        if (salesorder.so_order_status === "On Hold")
            return true;
        if (salesorder.so_order_status === "Invoiced" || changedStatus === "Invoiced")
            return false;
        return true;
    }

    const setCustomerData = (name: string, value: string) => {
        //salesorder.so_customer_alias = value;
        console.log(`Setting Customer Info [${name}] = [${value}]`);
        //     setForceDirty(true);
        if (name === "so_customer_alias") {
            console.log(`Setting so_customer_alias ${name} = ${value}`);
            salesorder.so_customer_alias = value;
        } else if (name === "alias") {
            console.log(`Setting alias ${name} = ${value}`);
            salesorder.so_customer_alias = value;
        } else if (name === "fullname") {
            console.log(`Setting fullname ${name} = ${value}`);
            salesorder.so_customer_name = value;
        } else if (name === "billing_address") {
            console.log(`Setting billing_address ${name} = ${value}`);
            salesorder.so_billing_address = value;
        } else if (name === 'shipping_address') {
            console.log(`Setting shipping_address ${name} = ${value}`);
            salesorder.so_shipping_address = value;
        }

        setsalesorder({ ...salesorder });
    }

    const addSalesOrderDetailsArray = (salesOrderDetailsArray: ISalesOrderDetail[]) => {
        //      setForceDirty(true);
        setSalesOrderDetails([...salesorderdetails, ...salesOrderDetailsArray]);
        salesOrderDetailsArray.forEach(myDetail => {
            salesorder.salesOrderDetails.push(myDetail);
        })
    }

    const getColorBasedOnStatus = (status: string) => {

        if (status === 'Invoiced') {
            return 'blue';
        }
        if (status === 'On Hold') {
            return 'red';
        }
        if (status === 'Under Process') {
            return 'teal';
        }
        if (status === 'Packing Slip') {
            return 'yellow';
        }

        return 'orange';
    }


    if (loadingSalesOrder && salesorder.so_order_no === '0') return <LoadingComponent />


    return (
        <Tab.Pane attached={true} key={orderid} loading={loadingSOData}>
            <FinalForm initialValues={salesorder} onSubmit={handleFinalFormSubmit}>
                {({ handleSubmit, pristine, form, values }) => (
                    <Fragment>
                        <Header>
                            <Label as='a' color={getColorBasedOnStatus(salesorder.so_order_status)} ribbon>
                                Order # : {salesorder && salesorder.so_order_no}
                            </Label>
                            <Label size='small' color='red' basic>
                                Rep Name : {salesorder && salesorder.so_order_rep_name}
                            </Label>
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column width='3'>
                                    </Grid.Column>
                                    <Grid.Column width='13' floated='left'>
                                        <Button.Group floated='left'>
                                            <Button color='teal' onClick={CreateCustomerAcknowledgement} disabled={shouldDisableButton(values.so_order_status)}>
                                                <Icon name='file pdf outline' />Acknowlegement
                                            </Button>
                                            <Button.Or />
                                            <Button color='orange' onClick={CreateWorkOrder} disabled={shouldDisableButton(values.so_order_status)}>
                                                <Icon name='file pdf outline' />Work Order
                                            </Button>
                                            <Button.Or />
                                            <Button color='yellow' onClick={CreatePackingList} disabled={disablePackingSlipButton(values.so_order_status)}>
                                                <Icon name='file pdf outline' />Packing List
                                            </Button>
                                            <Button.Or />
                                            <Button color='blue' onClick={CreateInvoice} disabled={disableInvoiceButton(values.so_order_status)}>
                                                <Icon name='file pdf outline' />Invoice
                                            </Button>
                                            <Button.Or />
                                            <Button icon onClick={() => openModal(<NotImplemented />, false)} disabled={true}>
                                                <Icon name='mail' />Email
                                            </Button>

                                        </Button.Group>
                                        <Button icon color='green' floated='left'
                                            disabled={false && (pristine /*&& !forceDirty*/)}
                                            style={{ 'position': 'relative', 'zIndex': '99', marginLeft: '10px' }}
                                            onClick={() => { saveChangesToDb = true; form.submit() }}>
                                            <Icon name='save' />
                                            Save
                                        </Button>
                                        <Button color='red' onClick={() => { removeTabPane(salesorder !== undefined ? salesorder.so_order_no : '-1') }}>
                                            <Icon name='close' />Close
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Header>

                        <br />


                        <div style={docstyle}>
                            <Image
                                fluid
                                style={{ margin: 0, padding: 0 }}
                                label={{ as: 'a', color: 'red', corner: 'right', icon: 'close', size: 'mini' }}
                                onClick={() => { removeTabPane(salesorder !== undefined ? salesorder.so_order_no : '-1') }}
                            />

                            <form onSubmit={handleSubmit}>
                                {/*<button type='submit'> Submit form</button>*/}
                                <DocumentHeaderLogo salesorder={salesorder} />
                                <DocumentHeaderAddressAndDetails salesorder={salesorder} />
                                <div style={{ clear: 'both' }}></div>
                                <DocumentSoldToShipTo salesorder={salesorder} setCustomerData={setCustomerData} />
                                <DocumentCustomerContacts salesorder={salesorder} />
                                <DocumentShipmentDetails salesorder={salesorder} />
                                {salesorder.so_invoice_no ? (
                                    <DocumentItemDetailsReadOnly salesorder={salesorder} />
                                ) : (
                                    <DocumentItemDetails
                                        salesorder={salesorder}
                                        salesorderdetails={salesorderdetails}
                                        addSalesOrderDetail={addSalesOrderDetail}
                                        removeSalesOrderDetail={removeSalesOrderDetail}
                                        removeAllSalesOrderDetails={removeAllSalesOrderDetails}
                                        editSalesOrderDetail={editSalesOrderDetail}
                                        addSalesOrderDetailsArray={addSalesOrderDetailsArray}
                                        editSalesOrderTaxes={editSalesOrderTaxes}
                                        editLoadedSalesOrder={editLoadedSalesOrder}
                                        runningTotal={runningTotal}
                                    />
                                )}

                                <DocumentFooter />
                            </form>
                        </div>
                    </Fragment>

                )}
            </FinalForm>
        </Tab.Pane >

    )
}

export default observer(SalesOrderDocumentView)
