import React, { Fragment } from 'react';
import { Grid } from 'semantic-ui-react';
import { ILegacySalesOrder } from '../../../../../app/models/SalesOrder';
import SalesOrderDetailsCsvDownload from '../SalesOrderDetailsCsvDownload';


const tableRow = {
    border: 2,
    verticalAlign: 'top'
} as React.CSSProperties;


const tdSmall = {
    width: '10px',
    border: '1px solid #000',
    textAlign: 'center',
    verticalAlign: 'middle'
} as React.CSSProperties;


const tableStyle = {
    clear: 'both',
    width: '100%',
    margin: '10px 0 0',
    border: '1px solid #000',
} as React.CSSProperties;

const tableHeading = {
    background: '#eee'
} as React.CSSProperties;

const tableTd = {
    textAlign: 'center',
    border: '1px solid #000',
    background: '#eee',
} as React.CSSProperties

const tdBlank = {
    border: '0'
} as React.CSSProperties;

const tdTotals = {
    borderRight: '0',
    textAlign: 'right'
} as React.CSSProperties;

interface IProps {
    salesorder: ILegacySalesOrder;
}

const DocumentItemDetailsReadOnly: React.FC<IProps> = ({ salesorder }) => {
    return (
        <Fragment>
            <Grid divided style={{ padding: 0, marginTop: '5px' }}>
                <Grid.Row>
                    <Grid.Column >
                        <SalesOrderDetailsCsvDownload salesOrderDetails={salesorder.salesOrderDetails} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <table className='table' style={tableStyle}>
                <thead>
                    <tr style={tableHeading}>
                        <th>No</th>
                        <th>Item</th>
                        <th>Description</th>
                        <th>Width</th>
                        <th>Height</th>
                        <th>Dia</th>
                        <th>Quantity</th>
                        <th>Unit Cost</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {salesorder.salesOrderDetails.map((soDetail, i) => (
                        <Fragment key={i}>
                            <tr key={i} style={tableRow}>
                                <td rowSpan={2} style={tdSmall}>{i + 1}</td>
                                <td style={tableTd}>{soDetail.so_part_no}</td>
                                <td style={tableTd}>{soDetail.so_part_description}</td>
                                <td style={tableTd}>{soDetail.so_item_width}</td>
                                <td style={tableTd}>{soDetail.so_item_height}</td>
                                <td style={tableTd}>{soDetail.so_item_dia}</td>
                                <td style={tableTd}>{soDetail.so_item_qty}</td>
                                <td style={tableTd}>{soDetail.so_item_sale_price}</td>
                                <td style={tableTd}>{soDetail.so_item_total_amount}</td>
                            </tr>
                            <tr style={tableRow}>
                                <td style={{ ...tableTd, textAlign: 'right', border: 'none' }} >
                                    <b>Part Remarks:</b>
                                </td>
                                <td colSpan={7} style={tableTd}>{soDetail.so_part_remarks}</td>
                            </tr>
                        </Fragment>
                    ))}
                    <tr>
                        <td colSpan={8}>&nbsp;</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={4} style={tdBlank}></td>
                        <td colSpan={4} style={tdTotals}>Subtotal</td>
                        <td style={tableTd}>
                            <b>${salesorder.so_net_amount}</b>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4} style={{ ...tdBlank, textAlign: 'left' }}>&nbsp;&nbsp;Remarks :&nbsp;<b>{salesorder.so_remarks}</b></td>
                        <td colSpan={4} style={tdTotals}>HST</td>
                        <td style={tableTd}>
                            ${salesorder.so_hst_amount}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4} style={tdBlank}></td>
                        <td colSpan={4} style={tdTotals}>GST</td>
                        <td style={tableTd}>
                            ${salesorder.so_gst_amount}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4} style={{ ...tdBlank, textAlign: 'left' }}>&nbsp;&nbsp;Client Notes :&nbsp;<b>{salesorder.so_client_notes}</b></td>
                        <td colSpan={4} style={tdTotals}>PST</td>
                        <td style={tableTd}>
                            ${salesorder.so_pst_amount}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4} style={tdBlank}></td>
                        <td colSpan={4} style={tdTotals}>QST</td>
                        <td style={tableTd}>
                            ${salesorder.so_qst_amount}
                        </td>
                    </tr>

                    <tr>
                        <td colSpan={4} style={tdBlank}></td>
                        <td colSpan={4} style={tdTotals}>Total Amount</td>
                        <td style={tableTd}>
                            <b>${salesorder.so_net_amount}</b>
                        </td>
                    </tr>
                </tfoot>
            </table>

        </Fragment>

    )
}

export default DocumentItemDetailsReadOnly
