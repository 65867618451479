import React from 'react'
import { FieldRenderProps } from 'react-final-form';
import { Form, Label } from 'semantic-ui-react';
import { DateTimePicker } from 'react-widgets';
import { DateTimePickerProps } from 'react-widgets/lib/DateTimePicker';

interface IProps extends FieldRenderProps<Date, HTMLElement | any>, DateTimePickerProps {
    label: string;
}

const DateInput: React.FC<IProps> = ({ input, placeholder, date = false, time = false, meta: { touched, error }, label, ...rest }) => {
    return (
        <Form.Field width={10} error={touched && !!error} >
            {label && <label>{label.toString()}</label>}
            <DateTimePicker
                placeholder={placeholder}
                onChange={input.onChange}
                onKeyDown={(e) => e.preventDefault()}
                onBlur={input.onBlur}
                date={date}
                time={time}
                value={input.value || null}
                {...rest}
            />
            {touched && error && <Label basic color='red'>{error}</Label>}
        </Form.Field>
    )
}



export default DateInput    