import React, { Fragment, useState } from 'react';
import { Menu, Header, Input, Label, Button, Icon, Radio, Select } from 'semantic-ui-react';

import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../app/stores/Store';


const options = [
    { key: 'customerName', text: 'Customer Name', value: 'customerName' },
    { key: 'customerAlias', text: 'Customer Alias', value: 'customerAlias' },
    { key: 'orderNumber', text: 'Order Number', value: 'orderNumber' },
    { key: 'orderStatus', text: 'Order Status', value: 'orderStatus' },
    { key: 'poNumber', text: 'P O Number', value: 'poNumber' }
]


const SearchSalesOrdersForm = () => {
    const { salesOrderStore } = useStore();
    const { searchSalesOrders, loadingSearch } = salesOrderStore;
    //  const { searchValue, setSearchValue } = rootContext.searchStore;

    const [searchValue, setSearchValue] = useState('');
    const [searchType, setSearchType] = useState<any>('orderNumber');

    const onSelectChange = (value: any) => {
        setSearchType(value);

    }

    const onSearchSalesOrders = () => {
        searchSalesOrders(searchType, searchValue);
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            searchSalesOrders(searchType, searchValue);
        }
    }
    return (
        <Fragment>
            <Menu size={'large'} style={{ width: '100%', marginTop: 50 }}>
                <Header icon={'filter'} attached color={'teal'} content={'Sales Orders'} />
                <Menu.Item style={{ width: '20%' }}>
                    <Label content='Sort By Date' />
                    <Radio slider placeholder='Customer Alias' />
                </Menu.Item>
                <Menu.Item style={{ width: '80%' }}>
                    <Input
                        type='text'
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onKeyDown={handleKeyDown}
                        style={{ width: '70%' }}
                        placeholder='Search...' action>
                        <input />
                        <Select compact options={options} value={searchType} onChange={(e, data) => { onSelectChange(data.value) }} />
                        <Button icon color='blue' onClick={onSearchSalesOrders} loading={loadingSearch}>
                            <Icon name='search' />
                        </Button>
                    </Input>
                </Menu.Item>

            </Menu>
        </Fragment >
    )
}

export default observer(SearchSalesOrdersForm)
