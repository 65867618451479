import React from 'react'
import { Grid, Tab } from 'semantic-ui-react'
import SearchCustomersForm from '../search/SearchCustomersForm'
import CustomerSearchResults from '../results/CustomerSearchResults'
import { observer } from 'mobx-react-lite'

const CustomerSearchTab = () => {
    return (

        <Tab.Pane attached={true}>
            <Grid fluid='true'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <SearchCustomersForm />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <CustomerSearchResults />
                    </Grid.Column>
                </Grid.Row>

            </Grid>

        </Tab.Pane>
    )
}

export default observer(CustomerSearchTab)
