import { ICustomer } from "./Customer";

export interface ISalesOrderEnvelope {
     salesOrders: ICompactSalesOrder[];
     salesOrderCount: number;
}

export interface ICompactSalesOrder {
     sales_order_master_id: number;
     so_order_no: string;
     so_order_date: Date | null;
     so_customer_id: number;
     so_customer_name: string;
     so_customer_alias: string;
     so_sold_address: string;
     so_shipping_address: string;
     so_billing_address: string;
     so_order_status: string;
     so_invoice_no: string;
     so_invoice_date: Date | null;
     so_ship_date: Date | null;
     so_net_amount_i: number;
     so_net_amount: number;
     so_actual_ship_date: Date | null;
     so_order_recipient: string;
     so_purchase_order_no: string;
     so_remarks: string;
     so_shipping_carrier_name: string;
     sales_agent: string;
     so_updated_on: Date | null;
}


export interface ILegacySalesOrder {
     sales_order_master_id: number;
     so_order_no: string;
     so_order_date: Date | null;
     so_quote_no: string;
     so_customer_id: number;
     so_customer_name: string;
     so_customer_alias: string;
     so_sold_address: string;
     so_shipping_address: string;
     so_billing_address: string;
     so_order_status: string;
     so_invoice_no: string;
     so_invoice_date: Date | null;
     so_pack_slip_no: string;
     so_pack_slip_date: Date | null;
     so_ship_date: Date | null;
     so_gross_amount_i: number;
     so_discount_amount_i: number;
     so_hst_amount_i: number;
     so_gst_amount_i: number;
     so_pst_amount_i: number;
     so_qst_amount_i: number;
     so_net_amount_i: number;
     so_actual_ship_date: Date | null;
     so_order_recipient: string;
     so_order_recipient_email: string;
     so_order_recipient_phone: string;
     so_order_rep_name: string;
     so_order_picked_by: string;
     so_order_taken_by: string;
     so_country_name: string;
     so_currency_name: string;
     so_client_notes: string;
     so_ship_weight: string;
     so_ship_dimz: string;
     so_freight: string;
     so_remarks: string;
     so_shipping_carrier_name: string;
     so_shipping_account_no: string;
     so_ship_via: string;
     so_shipping_instructions: string;
     so_shipping_handling_charges: string;
     so_payment_terms: string;
     so_purchase_order_no: string;
     so_fein_irs_no: string;
     so_gross_amount: number;
     so_discount_amount: number;
     so_hst_amount: number;
     so_hst_percent: number;
     so_gst_amount: number;
     so_gst_percent: number;
     so_pst_amount: number;
     so_pst_percent: number;
     so_qst_amount: number;
     so_qst_percent: number;
     so_net_amount: number;
     so_is_new_order: boolean;
     so_service_po_number: string;
     so_is_back_order: boolean;
     so_is_order_complete: boolean;
     so_is_return_exchange: boolean;
     so_is_other_notes: string;
     so_is_not_complete: boolean;
     so_order_complete: boolean;
     so_order_complete_date: Date | null;
     so_planned_date: Date | null;
     so_order_shipped: boolean;
     so_status_id: boolean;
     so_syslog: string;
     so_added_on: string;
     so_added_by: number;
     so_updated_on: string;
     so_updated_by: number;
     sales_agent: string;
     salesOrderDetails: ISalesOrderDetail[];
     isCustomerTaxExempt: boolean;
}


export class SalesOrderSetter {
     static SetValue(salesOrder: ILegacySalesOrder, name: string, value: string) {
          console.log(`settings value ${name}  ${value}`)
          if (this.hasToBeInteger(name)) {
               return { ...salesOrder, [name]: parseInt(value) || 0 };
          } else if (this.hasToBeFloat(name)) {
               return { ...salesOrder, [name]: parseFloat(value) || 0 };
          } else {
               return { ...salesOrder, [name]: value };
          }
     }

     static SetDetailValue(salesOrder: ISalesOrderDetail, name: string, value: string) {
          if (this.hasToBeInteger(name)) {
               return { ...salesOrder, [name]: parseInt(value) || 0 };
          } else if (this.hasToBeFloat(name)) {
               return { ...salesOrder, [name]: parseFloat(value) || 0 };
          } else {
               return { ...salesOrder, [name]: value };
          }
     }

     static CleanDetailValues(salesOrder: ISalesOrderDetail) {
          let cleanDetail = SalesOrderSetter.SetDetailValue(salesOrder, "so_item_qty", salesOrder.so_item_qty.toString());
          cleanDetail = SalesOrderSetter.SetDetailValue(cleanDetail, "so_item_shipped", salesOrder.so_item_shipped.toString());
          cleanDetail = SalesOrderSetter.SetDetailValue(cleanDetail, "so_item_sale_price", salesOrder.so_item_sale_price.toString());
          return cleanDetail;
     }


     static hasToBeInteger(name: string) {
          return name === "so_customer_id" ||
               name === "sales_order_master_id" ||
               name === "sales_order_master_id" ||
               name === "so_item_qty" ||
               name === "so_ordered_qty" ||
               name === "so_item_shipped";
     }
     static hasToBeFloat(name: string) {
          return name === "so_item_sale_price" ||
               name === "so_item_total_amount";
     }

}

export interface ISalesOrderDetail {
     sales_order_detail_id: number;
     sales_order_master_id: number;
     so_order_no: string;
     so_part_tariff_no: string;
     so_ordered_qty: number;
     so_backordered_qty: number;
     so_part_ref_no: string;
     so_part_no: string;
     so_part_serial_no: string;
     so_part_origin_country_alias: string;
     so_part_delv_period: string;
     so_part_description: string;
     so_part_remarks: string;
     so_material_details: string;
     so_item_sortseq: number;
     so_item_width: string;
     so_item_height: string;
     so_item_dia: string;
     so_item_qty: number;
     so_item_back_ordered: number;
     so_item_shipped: number;
     so_item_sale_price: number;
     so_item_cost_price: number;
     so_item_discount_amount: number;
     so_item_total_amount: number;
}

export interface ISalesOrder {
     id: number,
     salesOrderNo: string,
     salesOrderDate: string,
     orderStatus: string,
     purchaseOrderNo: string,
     purchaseOrderDate: string,
     invoiceNo: string,
     invoiceDate: string,
     customer: ICustomer,
     trackingInformation: ITrackingInformation,
     productDetails: IProductDetail[],
     totalOrderedInDollars: number,
     totalBackOrderInDollars: number,
     totalShippedInDollars: number,
     promisedShipDate: string,
     actualShipDate: string,
     notes: string,
     orderRecipient: string
}


export interface ISalesOrderDailyReport {
     sales_order_master_id: number;
     so_order_no: string;
     so_order_date: string;
     so_customer_name: string;
     so_pack_slip_date: string;
     so_ship_date: string;
     so_gross_amount_i: number;
     so_net_amount: number;
     shearDate: string;
     bendDate: string;
     assemblyDate: string;
     so_order_status: string;
}


export interface IProductDetail {
     id: number,
     productId: number,
     productCode: string,
     description: string,
     width: string,
     height: string,
     diameter: string,
     orderedQuantity: number,
     backOrderedQuantity: number,
     shippedQuantity: number,
     inventoryQuantity: number,
     instructions: string,
     unitPrice: number,
     orderedInDollars: number,
     backOrderInDollars: number,
     shippedInDollars: number
}

export interface ITrackingInformation {
     id: number,
     shippingAccountNo: string,
     shippedVia: string,
     trackingNo: string,
     shippingDetails: string
}

export interface IAcknowledge {
     needsAuthorization: boolean;
     message: string;
}

export const getBlankSalesOrderDetail = () => {
     return {
          sales_order_detail_id: 0,
          sales_order_master_id: 0,
          so_order_no: '',
          so_part_tariff_no: '',
          so_ordered_qty: 0,
          so_backordered_qty: 0,
          so_part_ref_no: '',
          so_part_no: '',
          so_part_serial_no: '',
          so_part_origin_country_alias: '',
          so_part_delv_period: '',
          so_part_description: '',
          so_part_remarks: '',
          so_material_details: '',
          so_item_sortseq: 0,
          so_item_width: '',
          so_item_height: '',
          so_item_dia: '',
          so_item_qty: 0,
          so_item_back_ordered: 0,
          so_item_shipped: 0,
          so_item_sale_price: 0,
          so_item_cost_price: 0,
          so_item_discount_amount: 0,
          so_item_total_amount: 0
     }
}


export const getBlankSalesOrder = (defaultUserName: string = '') => {
     return {
          sales_order_master_id: 0,
          so_order_no: '',
          so_order_date: null,
          so_quote_no: '',
          so_customer_id: 0,
          so_customer_name: '',
          so_customer_alias: '',
          so_sold_address: '',
          so_shipping_address: '',
          so_billing_address: '',
          so_order_status: 'Under Process',
          so_invoice_no: '',
          so_invoice_date: null,
          so_pack_slip_no: '',
          so_pack_slip_date: null,
          so_ship_date: null,
          so_gross_amount_i: 0,
          so_discount_amount_i: 0,
          so_hst_amount_i: 0,
          so_gst_amount_i: 0,
          so_pst_amount_i: 0,
          so_qst_amount_i: 0,
          so_net_amount_i: 0,
          so_actual_ship_date: null,
          so_order_recipient: '',
          so_order_recipient_email: '',
          so_order_recipient_phone: '',
          so_order_rep_name: defaultUserName,
          so_order_picked_by: '',
          so_order_taken_by: '',
          so_country_name: '',
          so_currency_name: '',
          so_client_notes: '',
          so_ship_weight: '',
          so_ship_dimz: '',
          so_freight: '',
          so_remarks: '',
          so_shipping_carrier_name: '',
          so_shipping_account_no: '',
          so_ship_via: '',
          so_shipping_instructions: '',
          so_shipping_handling_charges: '',
          so_payment_terms: '',
          so_purchase_order_no: '',
          so_fein_irs_no: '',
          so_gross_amount: 0,
          so_discount_amount: 0,
          so_hst_amount: 0,
          so_hst_percent: 0,
          so_gst_amount: 0,
          so_gst_percent: 0,
          so_pst_amount: 0,
          so_pst_percent: 0,
          so_qst_amount: 0,
          so_qst_percent: 0,
          so_net_amount: 0,
          so_is_new_order: false,
          so_service_po_number: '',
          so_is_back_order: false,
          so_is_order_complete: false,
          so_is_return_exchange: false,
          so_is_other_notes: '',
          so_is_not_complete: false,
          so_order_complete: false,
          so_order_complete_date: null,
          so_planned_date: null,
          so_order_shipped: false,
          so_status_id: false,
          so_syslog: '',
          so_added_on: '',
          so_added_by: 0,
          so_updated_on: '',
          so_updated_by: 0,
          sales_agent: 'Rony',
          salesOrderDetails: [],
          isCustomerTaxExempt: false
     }
}
