import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import { Button, Icon } from 'semantic-ui-react';
import { ISalesOrderDetail } from '../../../../app/models/SalesOrder';


const headers = [
    { label: "Code", key: "so_part_no" },
    { label: "Description", key: "so_part_description" },
    { label: "Width", key: "so_item_width" },
    { label: "Height", key: "so_item_height" },
    { label: "Diameter", key: "so_item_dia" },
    { label: "Ordered", key: "so_item_qty" },
    { label: "BackOrder", key: "so_item_back_ordered" },
    { label: "Shipped", key: "so_item_shipped" },
    { label: "Unit", key: "so_item_sale_price" },
    { label: "Total", key: "so_item_total_amount" },
];


interface IProps {
    salesOrderDetails: ISalesOrderDetail[]
}

const SalesOrderDetailsCsvDownload: React.FC<IProps> = ({ salesOrderDetails }) => {

    const [data,] = useState<any>([]);

    useEffect(() => {
        salesOrderDetails.forEach(salesOrderDetail => {
            data.push({ ...salesOrderDetail });
        });

    }, [salesOrderDetails, data]);


    const exportDataToCSV = () => {
    }

    return (
        <div>
            <Button basic floated={'right'} icon color='blue' size='small'>
                <Icon name='list' /><CSVLink data={data} headers={headers} filename="SalesOrderItems.csv" onClick={exportDataToCSV}>Export to CSV</CSVLink>
            </Button>
        </div >
    )
}

export default SalesOrderDetailsCsvDownload
