import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react'
import { Divider } from 'semantic-ui-react';
import { useStore } from '../../../app/stores/Store';

const DocumentCustomerContacts = () => {
    const { poStore } = useStore();
    const { loadingPO, currentPurchaseOrder, updatePurchaseOrder } = poStore;
    if (currentPurchaseOrder === null || loadingPO) {
        return <Fragment />
    }
    const getDate = (datetime: Date | null) => {
        if (datetime === null || datetime === undefined)
            return;
        return datetime.toString().split('T')[0];
    }
    return (
        <Fragment>
            {/* 
            <div className="wrapper">
                <div className="item">BOX 1</div>
                <div className="item">BOX 2</div>
                <div className="item">BOX 3</div>
                <div className="item">BOX 4</div>
                <div className="item">BOX 5</div>
            </div>
            
            
            */}
            <Divider />


            {currentPurchaseOrder.po_invoice_no ? (
                <div className='documentCustomerContacts'>
                    <div className='dcc_col1 dcc_header'>
                        Supplier Code
                    </div>
                    <div className='dcc_col2 dcc_header'>
                        Contact Name
                    </div>
                    <div className='dcc_col3 dcc_header'>
                        Picked By
                    </div>
                    <div className='dcc_col4 dcc_header'>
                        Shipping Date
                    </div>
                    <div className='dcc_col1 dcc_value'>
                        <p>{currentPurchaseOrder.po_customer_alias}</p>
                    </div>
                    <div className='dcc_col2 dcc_value'>
                        <p>{currentPurchaseOrder.po_order_recipient}</p>
                    </div>
                    <div className='dcc_col3 dcc_value'>
                        <p>{currentPurchaseOrder.po_order_picked_by}</p>
                    </div>
                    <div className='dcc_col4 dcc_value'>
                        <p>{currentPurchaseOrder.po_ship_date}</p>
                    </div>
                </div>

            ) : (
                <div className='documentCustomerContacts'>
                    <div className='dcc_col1 dcc_header'>
                        Supplier Code
                    </div>
                    <div className='dcc_col2 dcc_header'>
                        Contact Name
                    </div>
                    <div className='dcc_col3 dcc_header'>
                        Picked By
                    </div>
                    <div className='dcc_col4 dcc_header'>
                        Shipping Date
                    </div>
                    <div className='dcc_col1 dcc_value'>
                        <textarea
                            id='textarea'
                            name='po_customer_alias'
                            value={currentPurchaseOrder.po_customer_alias}
                            onChange={(e) => updatePurchaseOrder(e.target.name, e.target.value)} />
                    </div>
                    <div className='dcc_col2 dcc_value'>
                        <textarea
                            id='textarea'
                            name='po_order_recipient'
                            value={currentPurchaseOrder.po_order_recipient}
                            onChange={(e) => updatePurchaseOrder(e.target.name, e.target.value)}
                        />
                    </div>
                    <div className='dcc_col3 dcc_value'>
                        <textarea
                            id='textarea'
                            name='po_order_picked_by'
                            value={currentPurchaseOrder.po_order_picked_by}
                            onChange={(e) => updatePurchaseOrder(e.target.name, e.target.value)} />
                    </div>
                    <div className='dcc_col4 dcc_value'>
                        <input type='date' style={{ width: '80%', height: '100%' }} name='po_ship_date' onChange={(e) => updatePurchaseOrder(e.target.name, e.target.value)}
                            value={getDate(currentPurchaseOrder.po_ship_date)} />
                    </div>
                </div>

            )}
        </Fragment >
    )
}

export default observer(DocumentCustomerContacts)
