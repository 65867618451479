import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { IInventoryItem } from "../models/Inventory";
import { store } from "./Store";


const PAGE_SIZE = 30;
export default class InventoryStore {
    constructor() {
        makeAutoObservable(this);
    }

    loadingInventory = false;
    loadingSearch = false;
    loadingInitial = false;
    inventoryMap = new Map();
    inventory: IInventoryItem | null = null;

    tabPanes: any = [];
    activeTabIndex = -1;
    tabVsIndexMap = new Map();


    totalRecordCount = 0;
    currentPageNo = 0;


    setPageNo = (pageNo: number) => {
        this.currentPageNo = pageNo;
    }

    get totalPages() {
        return Math.ceil(this.totalRecordCount / PAGE_SIZE);
    }


    resetSearch = () => {
        this.currentPageNo = 0;
        this.totalRecordCount = 0;
    }


    getAxiosParams = (partType: string = '', descSearch: string = '') => {
        const params = new URLSearchParams();
        params.append('maxcount', PAGE_SIZE.toString());
        params.append('offset', `${this.currentPageNo ? this.currentPageNo * PAGE_SIZE : 0}`)

        if (partType !== '' && partType !== 'all') {
            console.log('adding params ', partType);
            params.append('partType', partType);

        }
        if (descSearch !== '')
            params.append('description', descSearch);

        return params;
    }


    get inventoryitems() {
        return Array.from(this.inventoryMap.values());
    }

    setActiveTabIndex = (tabIndex: any) => {
        this.activeTabIndex = tabIndex;
    }

    resetTabs = () => {
        this.tabPanes = [];
        this.activeTabIndex = -1;
        this.tabVsIndexMap.clear();
    }


    addTabPane = (key: string, tab: any) => {
        if (this.tabVsIndexMap.has(key)) {
            const index = this.tabVsIndexMap.get(key);
            this.setActiveTabIndex(index);
        } else {
            this.tabPanes = [...this.tabPanes, tab];
            this.setActiveTabIndex(this.tabPanes.length - 1);
            this.tabVsIndexMap.set(key, this.activeTabIndex);
        }
    }

    removeTabPane = (key: string) => {
        if (this.tabVsIndexMap.has(key)) {
            this.tabPanes = this.tabPanes.filter((tab: any) => {
                return tab.menuItem !== key;
            });
            this.tabVsIndexMap.delete(key);
            this.setActiveTabIndex(0);
        }
    }

    loadInventory = async (partType: string = '', descSearch: string = '') => {
        this.loadingInitial = true;
        try {
            let params = this.getAxiosParams(partType, descSearch);
            const invEnvelope = await agent.inventory.list(params);
            const { inventoryItems, inventoryItemCount } = invEnvelope;

            runInAction(() => {
                this.inventoryMap.clear();
                inventoryItems.forEach(inventoryItem => {
                    this.inventoryMap.set(inventoryItem.id, inventoryItem);
                });
                store.searchStore.totalRecordCount = inventoryItemCount;
                this.loadingInitial = false;
            });
        } catch (error) {
            runInAction(() => {
                console.log(error);
            });
        } finally {
            runInAction(() => {
                this.loadingInitial = false;
            });
        }
    }


    searchInventory = async (searchvalue: string) => {
        this.loadingInitial = true;
        try {
            const inventoryItems = await agent.inventory.searchInventory(searchvalue);
            runInAction(() => {
                this.inventoryMap.clear();
                inventoryItems.map((inventoryItem) => {
                    this.inventoryMap.set(inventoryItem.id, inventoryItem);
                    return this.inventoryMap;
                });
                store.searchStore.totalRecordCount = 20;
                this.loadingInitial = false;
            });
        } catch (error) {
            runInAction(() => {
                console.log(error);
            });
        } finally {
            runInAction(() => {
                this.loadingInitial = false;
            });
        }
    }

    loadInventoryItem = async (inventoryId: string) => {
        this.loadingInitial = true;
        try {
            const inventoryItem = await agent.inventory.details(inventoryId);
            runInAction(() => {
                this.inventory = inventoryItem;
                this.loadingInitial = false;
            });
        } catch (error) {
            runInAction(() => {
                console.log(error);
            });
        } finally {
            runInAction(() => {
                this.loadingInitial = false;
            });
        }
    }


}