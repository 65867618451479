import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Search } from 'semantic-ui-react';
import { useStore } from '../../stores/Store';

interface IProps {
    index: number,
    name: string,
    value: string,
    setData: (index: number, name: string, value: string) => void,
    handleOnTypeAheadComplete: (index: number) => void
}

const PartNoTypeAheadPure: React.FC<IProps> = ({ index, name, value, setData, handleOnTypeAheadComplete }) => {
    const { partStore } = useStore();
    const { loadingParts, searchPartsByPartNo, searchedParts } = partStore;

    const [searchTerm, setSearchTerm] = useState(value);
    const [handler, setHandler] = useState<any>(null);


    useEffect(() => {
        if (value === '')
            setSearchTerm('');
        if (value !== searchTerm)
            setSearchTerm(value);

    }, [value, searchTerm])

    const handleResultSelect = (e: any, { result }: any) => {
        setSearchTerm(result.part_no);
        setData(index, 'so_part_description', result.part_description);
        setData(index, 'so_part_no', result.part_no);
        handleOnTypeAheadComplete(index);
    }
    const handleSearchChange = (e: any, { value }: any) => {
        if (searchTerm === value) return;
        setSearchTerm(value);
        setData(index, name, value);
        if (handler) {
            clearTimeout(handler);
        }
        let newHandler = setTimeout(() => {
            searchPartsByPartNo(value);
        }, 500);
        setHandler(newHandler);
    }

    const handleOnBlur = () => {
        setData(index, name, searchTerm);
        //handleOnTypeAheadComplete(index);
    }

    return (
        <Search style={{ margin: 0 }}
            fluid
            loading={loadingParts}
            onResultSelect={handleResultSelect}
            onSearchChange={handleSearchChange}
            results={searchedParts ? searchedParts : []}
            value={searchTerm}
            name={name}
            onBlur={handleOnBlur}
            resultRenderer={(result) =>
            (
                result && result.part_no &&
                <div key={result.part_no} className='content'>
                    {result.part_no && <div className='title'>{result.part_no}</div>}
                </div>
            )}
        />
    )
}

export default observer(PartNoTypeAheadPure)
