import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite';
import { Menu, Dropdown, Icon, Image } from 'semantic-ui-react';
import { NavLink, Link } from 'react-router-dom';
import { useStore } from '../stores/Store';


interface IProps {
    location: string;
}
const TopNav = () => {
    const { userStore } = useStore();
    const { user, logout } = userStore;

    useEffect(() => {
        if (!!user) {
            //console.log(user.displayname.toLowerCase());
        }
    }, [user])

    const isBusinessUser = () => {
        try {
            if (!!user) {
                if (user.displayname.toLowerCase() === "dhiru") return true;
                if (user.displayname.toLowerCase() === "santosh") return true;
                if (user.displayname.toLowerCase() === "harry") return true;
                if (user.displayname.toLowerCase() === "mahesh") return true;
            }
            return false;
        } catch {
            return false;
        }

    }

    return (
        <Menu fixed='top' inverted>
            <Menu.Item header as={NavLink} exact to='/'>
                <img src='/assets/logo.png' alt='BriskMfg.Inc' style={{ width: '180px', marginRight: 100, borderRadius: '5%' }} />
            </Menu.Item>
            <Menu.Item name='Sales' as={NavLink} to='/sales' />
            <Menu.Item name='Purchase Orders' as={NavLink} to='/purchaseorders' />
            <Menu.Item name='Inventory' as={NavLink} to='/inventory' />
            {/*
                <Menu.Item name='Get Rates' as={NavLink} to='/getrates' />
            */}
            {/*
                <Menu.Item name='Purchase Orders Legacy' as={NavLink} to='/purchaseordersold' />
            */}
            <Menu.Item name='Customers' as={NavLink} to='/customers' />
            <Dropdown item icon='wrench' simple>
                <Dropdown.Menu>
                    <Dropdown.Item>
                        <Icon name='dropdown' />
                        <span className='text'>Reports</span>
                        <Dropdown.Menu>
                            <Dropdown.Item text='Daily Report' icon='connectdevelop' as={Link} to='/dailyreport' />
                            <Dropdown.Item text='Quick Books' icon='connectdevelop' as={Link} to='/dailyreportforquickbooks' />
                            <Dropdown.Item text='Clients By SalesOrders' icon='connectdevelop' as={Link} to='/clientreport' />
                        </Dropdown.Menu>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <Icon name='dropdown' />
                        <span className='text'>Legacy Screens</span>
                        <Dropdown.Menu>
                            <Dropdown.Item text='Sales Orders' icon='connectdevelop' as={Link} to='/salesold' />
                        </Dropdown.Menu>
                    </Dropdown.Item>

                    {isBusinessUser() && (
                        <Dropdown.Item>
                            <Icon name='dropdown' />
                            <span className='text'>BI</span>
                            <Dropdown.Menu>
                                <Dropdown.Item text='Rates' icon='gavel' as={Link} to='/rates' />
                                <Dropdown.Item text='Rates For Fire Dampers' icon='gavel' as={Link} to='/firedamperpricing' />
                                <Dropdown.Item text='Rates For Control Dampers' icon='gavel' as={Link} to='/controldamperpricing' />
                                <Dropdown.Item text='Sales Order Processing' icon='connectdevelop' as={Link} to='/salesorderchart' />
                                <Dropdown.Item text='Purchase Orders' icon='connectdevelop' as={Link} to='/purchaseorderstabular' />
                            </Dropdown.Menu>
                        </Dropdown.Item>
                    )}
                    {isBusinessUser() && (
                        <Dropdown.Item>
                            <Icon name='dropdown' />
                            <span className='text'>Cost Calculator</span>
                            <Dropdown.Menu>
                                <Dropdown.Item text='Rates' icon='calculator' as={Link} to='/costcalculator' />
                                <Dropdown.Item text='By Sales Order' icon='calculator' as={Link} to='/costsalesorder' />
                                <Dropdown.Item text='Show Report' icon='law' as={Link} to='/costingreport' />
                            </Dropdown.Menu>
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item>
                        <Icon name='dropdown' />
                        <span className='text'>Pdf Manual Creator</span>
                        <Dropdown.Menu>
                            <Dropdown.Item text='Style 1' icon='pdf' as={Link} to='/template1' />
                        </Dropdown.Menu>
                    </Dropdown.Item>

                    <Dropdown.Item>
                        <Icon name='dropdown' />
                        <span className='text'>Planning Tools</span>
                        <Dropdown.Menu>
                            <Dropdown.Item text='Sales Order Planner' icon='gg' as={Link} to='/salesorderplanner' />
                            <Dropdown.Item text='Production Planning' icon='gavel' as={Link} to='/productionplanning' />
                        </Dropdown.Menu>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <Icon name='dropdown' />
                        <span className='text'>Track and Trace</span>
                        <Dropdown.Menu>
                            <Dropdown.Item text='Scan' icon='gg' as={Link} to='/scan' />
                            <Dropdown.Item text='Track Status' icon='gavel' as={Link} to='/track' />
                        </Dropdown.Menu>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <Icon name='dropdown' />
                        <span className='text'>Add</span>
                        <Dropdown.Menu>
                            <Dropdown.Item text='Sales Order' icon='connectdevelop' as={Link} to='/addsalesOrder' />
                            <Dropdown.Item text='Purchase Order' icon='gavel' as={Link} to='/addpurchaseorder' />
                            <Dropdown.Item text='Part' icon='gg' as={Link} to='/addpart' />
                            <Dropdown.Item text='Customer' icon='group' as={Link} to='/createcustomer' />
                            <Dropdown.Item text='Movement Types' icon='group' as={Link} to='/createcustomer' />
                        </Dropdown.Menu>
                    </Dropdown.Item>
                    <Dropdown.Item>
                        <Icon name='dropdown' />
                        <span className='text'>Raspberry Pi</span>
                        <Dropdown.Menu>
                            <Dropdown.Item text='Production Kiosk' icon='warehouse' as={Link} to='/productionkiosk' />
                            <Dropdown.Item text='Shipping Kiosk' icon='shipping' as={Link} to='/deliverykiosk' />
                            <Dropdown.Item text='DailyReport Kiosk' icon='shipping' as={Link} to='/dailyreportkiosk' />
                        </Dropdown.Menu>
                    </Dropdown.Item>
                    {/*<Dropdown.Divider />
                    <Dropdown.Item text='Synchronise Data' icon='sync' as={Link} to='/datasync' />*/}
                </Dropdown.Menu>
            </Dropdown>
            {user && (
                <Menu.Item position='right'>
                    <Image avatar spaced='right' src={'/assets/user.png'} />
                    <Dropdown pointing='top right' text={user.displayname}>
                        <Dropdown.Menu>
                            <Dropdown.Item as={Link} to={`/profile/${user.username}`} text='My profile' icon='user' />
                            <Dropdown.Item onClick={logout} text='Logout' icon='power' />
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Item>
            )}
        </Menu>
    )
}

export default observer(TopNav)
