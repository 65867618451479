import React, { useState, useEffect } from 'react'
import { Segment, Grid, Divider, Header, Icon, Menu, Input, Select, Button } from 'semantic-ui-react'
import useDebounce from '../../app/common/util/useDebounce';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/Store';


const options = [
    { key: 'workorder', text: 'Work Order In Production', value: 'ProductionAcknowledged' },
    { key: 'inproduction', text: 'In Production', value: 'InProduction' },
    { key: 'deliveryacknowleged', text: 'Work Order In Delivery', value: 'DeliveryAcknowledged' },
    { key: 'indelivery', text: 'In Delivery', value: 'InDelivery' },
]

const ScanInputForm = () => {
    const { scanStore } = useStore();
    const { scanBarcode, scanManually } = scanStore;

    const [, setScanningData] = useState(false);
    const [typingdata, setTypingData] = useState(false);
    const [searchType, setSearchType] = useState<any>('ProductionAcknowledged');

    const [scannedText, setScannedText] = useState('');
    const [typedText, setTypedText] = useState('');

    const debouncedScannedData = useDebounce(scannedText, 300);
    //const debouncedTypedData = useDebounce(typedText, 1500);



    useEffect(() => {
        if (debouncedScannedData) {
            setScanningData(true);
            scanBarcode(debouncedScannedData).then(results => {
                setScannedText('');
                setScanningData(false);
            });
        } else {
            //setScanResult('');
        }
        /*
        if (debouncedTypedData) {
            setTypingData(true);
            scanManually(debouncedTypedData, searchType).then(results => {
                setTypedText('');
                setTypingData(false);
            });
        } else {
            //setScanResult('');
        }
        */

    },
        [debouncedScannedData, scanBarcode, scanManually, searchType]
    );

    const onSelectChange = (value: any) => {
        console.log(value);
        setSearchType(value);
    }

    const handleKeyPress = (target: any) => {
        if (target.charCode === 13) {
            setTypingData(true);
            scanManually(typedText, searchType).then(results => {
                setTypedText('');
                setTypingData(false);
                setScanningData(false);
            });
        }
    }
    const handleTypedData = () => {
        setTypingData(true);
        scanManually(typedText, searchType).then(results => {
            setTypedText('');
            setTypingData(false);
            setScanningData(false);
        });
    }

    return (
        <Segment placeholder>
            <Grid columns={2} stackable textAlign='center'>
                <Divider vertical>Or</Divider>

                <Grid.Row verticalAlign='middle'>
                    <Grid.Column>
                        <Header icon>
                            <Icon name='barcode' />
                                Scan
                        </Header>
                        <Menu size={'large'} style={{ width: '100%', marginTop: 20 }}>
                            <Menu.Item style={{ width: '100%' }}>
                                <Input
                                    style={{ border: '0.5px solid grey', borderRadius: '4px' }}
                                    type='text'
                                    value={scannedText} onChange={(e) => setScannedText(e.target.value)}
                                    placeholder='Search...' action>
                                    <input />
                                </Input>
                            </Menu.Item>
                        </Menu>
                    </Grid.Column>

                    <Grid.Column>
                        <Header icon>
                            <Icon name='keyboard' />
                                Type
                        </Header>
                        <Menu size={'large'} style={{ width: '100%', marginTop: 20 }}>
                            <Menu.Item style={{ width: '100%' }}>
                                <Input type='text'
                                    value={typedText} onChange={(e) => setTypedText(e.target.value)}
                                    style={{ width: '100%' }}
                                    onKeyPress={handleKeyPress}
                                    placeholder='Search...' action>
                                    <input />
                                    <Select loading={typingdata} compact options={options} value={searchType} onChange={(e, data) => { onSelectChange(data.value) }} />
                                </Input>
                                <Button type='submit'
                                    color='green'
                                    loading={typingdata}
                                    onClick={handleTypedData}
                                    style={{ 'position': 'relative', 'zIndex': '99' }} floated='right'>
                                    <Icon name='save' />
                                    Save
                            </Button>
                            </Menu.Item>
                        </Menu>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}

export default observer(ScanInputForm)
