import React from 'react'
import { Modal } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/Store';

const ModalContainer = () => {
    const { modalStore } = useStore();
    const { modal: { open, body, isSizeLarge }, closeModal } = modalStore;
    return (
        <Modal open={open} onClose={closeModal} size={isSizeLarge ? 'large' : 'mini'}>
            <Modal.Content>
                {body}
            </Modal.Content>
        </Modal>
    )
}

export default observer(ModalContainer);
