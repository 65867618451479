import { observer } from 'mobx-react-lite';
import React, { useState } from 'react'
import { Equation } from 'react-equation';
import { Button, Container, Grid, Header, Label, Segment, Table } from 'semantic-ui-react'
import { IDamperRateOutput } from '../../app/models/DamperRateDto';
import { useStore } from '../../app/stores/Store';
import "./rates.css";



const heights = [
    { text: '6', value: '6' },
    { text: '8', value: '8' },
    { text: '10', value: '10' },
    { text: '12', value: '12' },
    { text: '16', value: '16' },
    { text: '20', value: '20' },
    { text: '24', value: '24' },
    { text: '28', value: '28' },
    { text: '32', value: '32' },
    { text: '36', value: '36' },
    { text: '40', value: '40' },
    { text: '44', value: '44' },
    { text: '48', value: '48' },
    { text: '52', value: '52' },
    { text: '56', value: '56' },
    { text: '60', value: '60' },

]

const widths = [
    { text: '6', value: '6' },
    { text: '8', value: '8' },
    { text: '10', value: '10' },
    { text: '12', value: '12' },
    { text: '16', value: '16' },
    { text: '20', value: '20' },
    { text: '24', value: '24' },
    { text: '28', value: '28' },
    { text: '32', value: '32' },
    { text: '36', value: '36' },
    { text: '40', value: '40' },
    { text: '44', value: '44' },
    { text: '48', value: '48' },
    { text: '52', value: '52' },
    { text: '56', value: '56' },
    { text: '60', value: '60' },

]
const producttypes = [
    { text: 'Horizontal', value: 'Horizontal' },
    { text: 'Vertical', value: 'Vertical' },
]

const products = [
    { text: 'A', value: 'A' },
    { text: 'B', value: 'B' },
    { text: 'C', value: 'C' },
    { text: 'CR', value: 'CR' },
    { text: 'CO', value: 'CO' },
    { text: 'DA', value: 'DA' },
    { text: 'DB', value: 'DB' },
]

const yesnos = [
    { text: 'Yes', value: 'Yes' },
    { text: 'No', value: 'No' },
]


const FireDamperRatesHome = () => {
    const { rateStore } = useStore();
    const {
        gettingRate,
        product, setProduct,
        productType, setProductType,
        costOfSheet, setCostOfSheet,
        discountPercentage, setDiscountPercentage,
        width, setWidth,
        height, setHeight,
        angles, setAngles,
        sleeves, setSleeves,
        expectedDeliveryFactor, setExpectedDeliveryFactor,
        salesCommission, setSalesCommission,
        getRateForFireDamper, damperRateCalc
    } = rateStore;

    const [quotes, setQuotes] = useState<IDamperRateOutput[]>([]);

    const computeRate = async () => {
        let rate = await getRateForFireDamper();
        if (rate !== undefined && rate !== null) {
            setQuotes([...quotes, rate]);
        }
    }

    return (
        <>
            <Container style={{ padding: '5em 0em' }}>
                <Header as='h2'>Rates Calculator For Fire Dampers</Header>
                <Grid columns={2}>

                    <Grid.Row>


                        <Grid.Column>
                            <Segment.Group>
                                <Segment>
                                    <p>Inputs </p>
                                </Segment>
                                <Segment.Group>
                                    <Table>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>Width</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <select
                                                        value={width}
                                                        onChange={e => setWidth(e.target.value)}
                                                    >
                                                        {widths.map(o => (
                                                            <option key={o.value} value={o.value}>{o.text}</option>
                                                        ))}
                                                    </select>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>Height</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <select
                                                        value={height}
                                                        onChange={e => setHeight(e.target.value)}
                                                    >
                                                        {heights.map(o => (
                                                            <option key={o.value} value={o.value}>{o.text}</option>
                                                        ))}
                                                    </select>
                                                </Table.HeaderCell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.HeaderCell>Product Type</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <select
                                                        value={productType}
                                                        onChange={e => setProductType(e.target.value)}
                                                    >
                                                        {producttypes.map(o => (
                                                            <option key={o.value} value={o.value}>{o.text}</option>
                                                        ))}
                                                    </select>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>Product</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <select
                                                        value={product}
                                                        onChange={e => setProduct(e.target.value)}
                                                    >
                                                        {products.map(o => (
                                                            <option key={o.value} value={o.value}>{o.text}</option>
                                                        ))}
                                                    </select>

                                                </Table.HeaderCell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.HeaderCell>Cost Of Galvanised Sheet</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <input type='text'
                                                        value={costOfSheet}
                                                        onChange={(e) => { setCostOfSheet(e.target.value) }}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>Discount Percentage</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <input type='text'
                                                        value={discountPercentage}
                                                        onChange={(e) => { setDiscountPercentage(e.target.value) }}
                                                    />
                                                </Table.HeaderCell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.HeaderCell>Include Angles</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <select
                                                        value={angles}
                                                        onChange={e => setAngles(e.target.value)}
                                                    >
                                                        {yesnos.map(o => (
                                                            <option key={o.value} value={o.value}>{o.text}</option>
                                                        ))}
                                                    </select>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>Include Sleeves</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <select
                                                        value={sleeves}
                                                        onChange={e => setSleeves(e.target.value)}
                                                    >
                                                        {yesnos.map(o => (
                                                            <option key={o.value} value={o.value}>{o.text}</option>
                                                        ))}
                                                    </select>
                                                </Table.HeaderCell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.HeaderCell>Expected Delivery Factor</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <input type='text'
                                                        value={expectedDeliveryFactor}
                                                        onChange={(e) => { setExpectedDeliveryFactor(e.target.value) }}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>Sales Commission</Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <input type='text'
                                                        value={salesCommission}
                                                        onChange={(e) => { setSalesCommission(e.target.value) }}
                                                    />
                                                </Table.HeaderCell>
                                            </Table.Row>

                                        </Table.Header>

                                    </Table>

                                </Segment.Group>
                            </Segment.Group>
                        </Grid.Column>

                        <Grid.Column>

                            <Segment.Group>
                                <Segment textAlign='center'>
                                    <Button.Group>
                                        <Button positive loading={gettingRate} onClick={computeRate}>Add To Quote</Button>
                                        <Button.Or />
                                        <Button onClick={() => setQuotes([])}>Clear Quotes</Button>
                                    </Button.Group>

                                </Segment>
                            </Segment.Group>
                            {quotes !== null && quotes.length > 0 && (

                                <Table fixed celled selectable compact size='small' striped>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell style={{ width: '10%' }} textAlign='center'>Id</Table.HeaderCell>
                                            <Table.HeaderCell textAlign='center'>Price</Table.HeaderCell>
                                            <Table.HeaderCell textAlign='center'>Width</Table.HeaderCell>
                                            <Table.HeaderCell textAlign='center'>Height</Table.HeaderCell>
                                            <Table.HeaderCell textAlign='center'>Calcs</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    {

                                        quotes.map((myquote: IDamperRateOutput, index) => (
                                            <Table.Row key={index} >
                                                <Table.Cell textAlign='center'>{(index + 1)}</Table.Cell>
                                                <Table.Cell textAlign='center'>
                                                    <Label color='red' key='1' size='large' basic>
                                                        {myquote.salesPrice}
                                                    </Label>
                                                </Table.Cell>
                                                <Table.Cell textAlign='center'>{myquote.width}</Table.Cell>
                                                <Table.Cell textAlign='center'>{myquote.height}</Table.Cell>
                                                <Table.Cell textAlign='center'>
                                                    Base Rate : {myquote.baseRate.toPrecision(2)}<br />
                                                    Cost Of Sheet:{myquote.costOfSheet.toPrecision(2)} <br />
                                                    Cost Of Angles:{myquote.angleCost.toPrecision(2)} <br />
                                                    Cost Of Sleeves:{myquote.sleeveCost.toPrecision(2)} <br />
                                                </Table.Cell>
                                            </Table.Row>
                                        ))
                                    }
                                </Table>
                            )}

                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment.Group>
                                <Segment color='blue'>
                                    <p>Formulae</p>

                                </Segment>
                                <Segment color='blue'>
                                    <p>
                                        <Equation
                                            value='Product Multiplier = Factor Based on Type Of Product'
                                        />
                                    </p>
                                    <p>
                                        <Equation
                                            value='Product Type Multiplier = Factor Based on Horizontal vs Veritical'
                                        />
                                    </p>
                                    <p>
                                        [
                                        <Equation
                                            value='Angle Factor = (No Angles) = 0'
                                        />
                                        ]  Or  [
                                        <Equation
                                            value='Angle Factor = (With Angles) = 0.2 X 0.9'
                                        />
                                        ]
                                    </p>
                                    <p>
                                        [
                                        <Equation
                                            value='Sleeves Factor = (No Sleeves) = 0'
                                        />
                                        ]  Or  [
                                        <Equation
                                            value='Sleeves Factor = (With Sleeves) = 0.5 X 0.85'
                                        />
                                        ]
                                    </p>
                                    <p>
                                        <Equation
                                            value='DiscountFactor = 1 - (Discount Percentage / 100)'
                                        />
                                    </p>

                                    <p>
                                        <Equation
                                            value='Calculated Value = (Base Rate / 0.53) X Cost Of Sheet X Cost Adjuster (0.6) X Product Multiplier'
                                        />
                                    </p>
                                    <p>
                                        <Equation
                                            value='Calculated Value = Calculated Value + Product Type Multiplier'
                                        />
                                    </p>
                                    <p>
                                        <Equation
                                            value='Angles Cost = Calculated Value X Angles Factor'
                                        />
                                    </p>
                                    <p>
                                        <Equation
                                            value='Sleeve Cost = Calculated Value X Sleeve Factor'
                                        />
                                    </p>


                                    <p>
                                        <Equation
                                            value='Calculated Value = Calculated Value + DiscountFactor +  Angles Cost + Sleeve Cost'
                                        />
                                    </p>
                                    <p>
                                        <Equation
                                            value='Calculated Value = Calculated Value + Calculated Value ( Sales Commission / 100)'
                                        />
                                    </p>
                                    <p>
                                        <Equation
                                            value='Calculated Value = Calculated Value + Calculated Value ( Expected Delivery Factor / 100)'
                                        />
                                    </p>
                                </Segment>
                            </Segment.Group>

                        </Grid.Column>

                        <Grid.Column>
                            {damperRateCalc && (
                                <Segment.Group>
                                    <Segment color='green'>
                                        <p>Applied Calculations</p>

                                    </Segment>
                                    <Segment color='green'>
                                        <p>
                                            Product Multiplier = {damperRateCalc?.productMultiplier}
                                        </p>
                                        <p>
                                            Product Type Multiplier = {damperRateCalc?.productTypeMultiplier}
                                        </p>
                                        <p>
                                            Angle Factor = {damperRateCalc?.angleFactor}
                                        </p>
                                        <p>
                                            Angle Cost = {damperRateCalc?.angleCost}
                                        </p>
                                        <p>
                                            Sleeve Factor = {damperRateCalc?.sleeveFactor}
                                        </p>
                                        <p>
                                            Sleeve Cost = {damperRateCalc?.sleeveCost}
                                        </p>
                                        <p>
                                            DiscountFactor = {damperRateCalc?.discountFactor}
                                        </p>

                                        <p>
                                            BaseRate = {damperRateCalc?.baseRate},
                                            Cost Of Sheet = {damperRateCalc?.costOfSheet},
                                            Cost Adjuster = 0.6,
                                            Product Multiplier = {damperRateCalc?.productMultiplier}

                                        </p>
                                        <p>
                                            Sales Commission = {damperRateCalc?.salesCommission}
                                        </p>
                                        <p>
                                            Expected Delivery Factor = {damperRateCalc?.expectedDeliveryFactor}
                                        </p>
                                    </Segment>
                                </Segment.Group>

                            )}

                        </Grid.Column>
                    </Grid.Row>

                </Grid>
            </Container>


        </>
    )
}

export default observer(FireDamperRatesHome)
