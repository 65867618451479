import { observer } from 'mobx-react-lite';
import React, { Fragment, useState } from 'react'
import { Button, Divider, Icon } from 'semantic-ui-react';
import CustomerNameTypeAhead from '../../../app/common/form/CustomerNameTypeAhead';
import { useStore } from '../../../app/stores/Store';
import CustomerAddress from '../../sales/dashboard/tabs/modals/CustomerAddress';

const DocumentSoldToShipTo = () => {
    const { salesOrderStore, modalStore } = useStore();
    const { loadingSalesOrder, currentSalesOrder, updateSalesOrder, getPreviousSalesOrderForCustomer, previousSalesOrderCopy } = salesOrderStore;
    const { openModal } = modalStore;

    const [customerName, setCustomerName] = useState('');

    if (currentSalesOrder === null || loadingSalesOrder) {
        return <Fragment />
    }


    const updateSoldToAddress = (value: string) => {
        updateSalesOrder('so_sold_address', value);
        updateSalesOrder('so_billing_address', value);
    }
    const updateShipToAddress = (value: string) => {
        updateSalesOrder('so_shipping_address', value);
    }


    const setCustomerData = (name: string, value: string) => {
        getPreviousSalesOrderForCustomer(value).then(() => {
            if (previousSalesOrderCopy !== null) {

                updateSalesOrder('so_customer_name', previousSalesOrderCopy.so_customer_name);
                updateSalesOrder('so_customer_alias', previousSalesOrderCopy.so_customer_alias);
                updateSalesOrder('so_customer_id', previousSalesOrderCopy.so_customer_id?.toString());
                updateSalesOrder('so_sold_address', previousSalesOrderCopy.so_sold_address);
                updateSalesOrder('so_shipping_address', previousSalesOrderCopy.so_shipping_address);
                updateSalesOrder('so_billing_address', previousSalesOrderCopy.so_billing_address);
                updateSalesOrder('so_order_recipient', previousSalesOrderCopy.so_order_recipient);
                //updateSalesOrder('so_order_rep_name', previousSalesOrderCopy.so_order_rep_name);
                updateSalesOrder('so_shipping_carrier_name', previousSalesOrderCopy.so_shipping_carrier_name);
                updateSalesOrder('so_shipping_account_no', previousSalesOrderCopy.so_shipping_account_no);
            }
        });


    }

    return (
        <div className='documentSoldToShipTo'>

            <Divider />

            {currentSalesOrder.so_invoice_no ? (
                <div className='soldToShipToGrid'>
                    <div className='ststDataSection'>
                        <div className='ststTitle'>Sold To</div>
                        <p>{currentSalesOrder.so_customer_name}</p>
                        <p className='wrapNewLines'>{currentSalesOrder.so_sold_address}</p>
                    </div>
                    <div className='ststSearch'>
                    </div>
                    <div className='ststDataSection'>
                        <div className='ststTitle'>Ship To</div>
                        <p>{currentSalesOrder.so_customer_name}</p>
                        <p className='wrapNewLines'>{currentSalesOrder.so_shipping_address}</p>
                    </div>

                </div>
            ) : (
                <div className='soldToShipToGrid'>
                    <div className='ststDataSection'>
                        <div className='ststTitle'>Sold To</div>
                        <textarea className='ststTextArea shortTA' rows={1} name='so_customer_name' value={currentSalesOrder.so_customer_name} onChange={(e) => updateSalesOrder(e.target.name, e.target.value)} />
                        <Button basic floated={'right'} icon color='green' size='small'
                            onClick={() => openModal(<CustomerAddress
                                customerName={currentSalesOrder.so_customer_alias}
                                isForSoldToAddress={true}
                                setAddress={updateSoldToAddress}
                            />)} >
                            <Icon name='plus' />
                        </Button>
                        <textarea
                            rows={5}
                            className='ststTextArea longTA'
                            name='so_sold_address'
                            value={currentSalesOrder.so_sold_address}
                            onChange={(e) => updateSalesOrder(e.target.name, e.target.value)} />
                    </div>
                    <div className='ststSearch'>
                        <CustomerNameTypeAhead
                            setLocalState={setCustomerName}
                            setData={setCustomerData}

                            name="so_customer_name"
                            value={customerName} />
                    </div>
                    <div className='ststDataSection'>
                        <div className='ststTitle'>Ship To</div>
                        <textarea className='ststTextArea shortTA' rows={1} name='so_customer_name' value={currentSalesOrder.so_customer_name} onChange={(e) => updateSalesOrder(e.target.name, e.target.value)} />
                        <Button basic floated={'right'} icon color='green' size='small'
                            onClick={() => openModal(<CustomerAddress
                                customerName={currentSalesOrder.so_customer_alias}
                                isForSoldToAddress={true}
                                setAddress={updateShipToAddress}
                            />)} >
                            <Icon name='plus' />
                        </Button>
                        <textarea
                            rows={5}
                            className='ststTextArea longTA'
                            name='so_shipping_address'
                            value={currentSalesOrder.so_shipping_address}
                            onChange={(e) => updateSalesOrder(e.target.name, e.target.value)} />
                    </div>

                </div>
            )}
        </div>
    )
}

export default observer(DocumentSoldToShipTo)